<template>
    <div class="chat-container">

        <div class="conversations-header">
            <h2 class="section-header-container">
                <ecm-select v-if="localAgents && selectedAgent" class="-ml-5" :options="localAgents" :value="selectedAgent" option-value-attribute="agent" option-display-attribute="name" @input="setAgent" />
                <div v-else class="w-4 h-4 mt-4 double-loader" />
            </h2>
        </div>

        <section id="chatContainer" class="flex h-full min-h-0">

            <ChatPreviews :counters="counters" :agent="agent" @statusChange="setStatus" ref="chatPreviews"></ChatPreviews>

            <Chat :agent="agent" :status="status" @showSubscriberModal="showSubscriberModal"></Chat>

            <tw-modal ref="subscriberDetail" :title="$t('appforms.subscriber.title')" close-button="yes" classname="subscriberDetail" :visible-on-load="$route.query['contact-modal']">

                <template #default="slotProps">

                    <subscriber-form :modal="slotProps" />

                </template>

            </tw-modal>

        </section>

    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(updateLocale)
import Pusher from 'pusher-js'

import ChatPreviews from '@component/Chat/ChatPreviews.vue'
import Chat from '@component/Chat/Chat.vue'

import TabRow from '@component/Tabs/TabRow.vue'
import TabItem from '@component/Tabs/TabItem.vue'

export default {
    name: 'conversations',

    store: store,

    props: ['hash'],

    components: {
        TabRow,
        TabItem,
        Chat,
        ChatPreviews
    },

    data () {
        return {
            loadingStatus: false,
            selectedAgent: null,
            agent: null,
            totalConversations: '',
            status: 'open'
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState('chatModule', [
            'counters',
            'agents',
            'app_id',
        ]),
        ...mapState(['user']),
        localAgents () {
            let total = 0
            let localAgents = []
            if(this.agents) {
                Object.values(this.agents).forEach(item => {
                    let count = this.counters[item.agent + '_open'] ?? 0
                    total += count
                    localAgents.push({name: item.agent + ' (' + count + ')', agent: item.agent})
                })
            }
            let count = this.counters['none_open'] ?? 0
            total += count
            localAgents.push({name: this.$t('appforms.chat.filter.unassigned') + ' (' + count + ')', agent: 'none'})
            localAgents.unshift({name: this.$t('chat.conversations.all_conversations_option') + ' (' + total + ')', agent: 'all'})

            return localAgents
        }
    },

    async created() {
        await this.loadAgentsAndConversations()

        var pusher = new Pusher('7fe551531dcb2e11555f', {
            cluster: 'eu'
        })

        var channel = pusher.subscribe(this.app_id + '-messages')
        channel.bind('message', (data) => {
            console.log('PUSHER MESSAGE', data)
            if(data.conversation.last_message_content && typeof data.conversation.last_message_content === 'string') {
                data.conversation.last_message_content = JSON.parse(data.conversation.last_message_content)
            }
            // assign request returns messages without timestamp => otherwise the 'assigned' message is doubled
            if(data.message.timestamp != null) {
                this.addMessageToConversation(data)
            }
        })
        channel.bind('userreads', (data) => {
        })
        channel.bind('typing', (data) => {
            if(data.type === 'incoming') {
                store.dispatch('chatModule/setTypingInConversation', data.chat_id)
                setTimeout(() => {
                    store.dispatch('chatModule/unsetTypingInConversation', data.chat_id)
                }, 5000)
            }
        })

        this.agent = this.user.email
        this.selectedAgent = this.user.email
        await store.dispatch('chatModule/setAgent', this.user.email)
        store.dispatch('chatModule/setApi', {app_id: this.app_id, hash: this.hash, agent: this.user.email})

        dayjs.updateLocale('en', {
            relativeTime: {
                future : 'in %s',
                past   : '%s',
                s  : function (number, withoutSuffix) {
                    return withoutSuffix ? 'now' : 'a few seconds ago'
                },
                m  : '1m',
                mm : '%dm',
                h  : '1h',
                hh : '%dh',
                d  : '1d',
                dd : '%dd',
                M  : '1mth',
                MM : '%dmth',
                y  : '1y',
                yy : '%dy'
            }
        })
    },

    methods: {
        async loadAgentsAndConversations() {
            await store.dispatch('chatModule/loadConversations', {agent: this.selectedAgent, status: this.status, sortBy: 'newest'})
            await store.dispatch('chatModule/loadAgents', true)
        },
        showSubscriberModal(email) {
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (email.match(regexEmail)) {
                this.$refs['subscriberDetail'].showModalRawData({
                    email: email
                })
            } else {
                this.$refs['subscriberDetail'].showModalRawData({
                    chat_contact_id: email
                })
            }
        },
        addMessageToConversation(data) {
            store.dispatch('chatModule/addMessageToConversation', data)
        },
        setStatus(status) {
            this.status = status
        },
        async setAgent(agent) {
            if(agent === null) {
                agent = 'unassigned'
            }
            this.selectedAgent = agent

            this.loadingStatus = true
            await store.dispatch('chatModule/setAgent', agent)
            await store.dispatch('chatModule/loadConversations', {agent: this.selectedAgent, status: this.status, sortBy: this.$refs.chatPreviews.selectedSort})
            this.loadingStatus = false
        }
    }
}
</script>
