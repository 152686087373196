<template>

    <div class="flex flex-col mt-1">
        <p class="text-gray-900 txt-14" v-html="$t('contacts.index.delete_list_warning.description', {list: list})" />

        <div class="delete-list-frame space-y-2.5">
            <div v-if="pipelines">
                <h5 class="mb-2 text-gray-1000">
                    {{ $t('contacts.index.delete_list_warning.pipelines') }}
                </h5>
                <ul class="list-inside list-disc space-y-2">
                    <li v-for="(pipeline, index) in pipelines" :key="`pipeline-${index}`" class="text-gray-900 font-semibold txt-14">
                        <span class="ml-3">{{ pipeline.name }}</span>
                    </li>
                </ul>
            </div>
            <div v-if="campaigns">
                <h5 class="mb-2 text-gray-1000">
                    {{ $t('contacts.index.delete_list_warning.campaigns') }}
                </h5>
                <ul class="list-inside list-disc space-y-2">
                    <li v-for="(campaign, index) in campaigns" :key="`campaign-${index}`" class="text-gray-900 font-semibold txt-14">
                        <span class="ml-2">{{ campaign.name }}</span>
                    </li>
                </ul>
            </div>
            <div v-if="integrations">
                <h5 class="mb-2 text-gray-1000">
                    {{ $t('contacts.index.delete_list_warning.integrations') }}
                </h5>
                <ul class="list-inside list-disc space-y-2">
                    <li v-for="(integration, index) in integrations" :key="`integration-${index}`" class="text-gray-900 font-semibold txt-14">
                        <span class="ml-2">{{ integration }}</span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="text-right">
            <button class="btn btn-primary tw-modal-close-button btn-lg px-10" @click.stop.prevent="closeModal">{{ $t('common.cancel') }}</button>
        </div>
    </div>

</template>

<script>
export default {
    name: 'DeleteListWarning',

    props: ['modal'],

    data() {
        return {
            list: null,
            pipelines: null,
            campaigns: null,
            integrations: null
        }
    },

    mounted() {
        this.list = this.modal.mdata.list
        this.pipelines = this.modal.mdata.pipelines
        this.campaigns = this.modal.mdata.campaigns
        this.integrations = this.modal.mdata.integrations
    },

    methods: {
        closeModal() {
            this.modal.mclose()
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "~sass/variables";

    .delete-list-frame {
        margin: 19px 0 30px;
        padding: 18px;
        border: 1px solid $grey-50;
        box-sizing: border-box;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
    }

    ::marker {
        color: $grey-65;
    }

    .btn {
        padding: 0 36px;
    }
</style>
