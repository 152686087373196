<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.segments.title') }}</span>
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="pt-2 flex flex-col">

            <div v-if="!segmentsListLoaded" class="w-4 h-4 mt-4 double-loader" />

            <div v-else-if="segmentsList && Object.keys(segmentsList).length" class="pt-2 space-y-7">
                <div class="flex flex-row justify-end space-x-2">
                    <button class="btn btn-primary icon-left w-full md:w-max" @click.prevent="showNewSegmentModal"><icon icon="plus-solid" size="small" /> <span>{{ $t('contacts.segments.new_segment_button') }}</span></button>
                </div>

                <div class="w-full overflow-x-auto md:overflow-x-visible">
                    <div class="table tw-table tw-table-large max-w-full">
                        <div class="table-header-group">
                            <div class="table-row text-gray-800 txt-13 semibold">
                                <div class="table-cell" :class="{ 'active': orderBy.rule === 'name' }">
                                    <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline" @click.prevent="orderResults('name')">
                                        <span>{{ $t('contacts.segments.name') }}</span>
                                        <icon icon="sort-solid" />
                                    </a>
                                </div>
                                <div class="table-cell text-right" :class="{ 'active': orderBy.rule === 'count' }">
                                    <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('count')">
                                        <span>{{ $t('contacts.segments.contacts_count') }}</span>
                                        <icon icon="sort-solid" />
                                    </a>
                                </div>
                                <div class="table-cell" />
                            </div>
                        </div>
                        <div v-for="(segment, index) in segmentsList" :key="`segment-${index}`" class="table-row ">
                            <div class="table-cell align-middle truncate w-full" style="max-width: 0px; min-width: 150px;">
                                <div class="flex items-center space-x-4 group">
                                    <icon class="text-gray-650 group-hover:text-gray-800" icon="cubes-solid" size="large" />
                                    <router-link class="txt-14 link-blue semibold" :to="{name: 'subscribersSegmentsDetail', params: {list: list.id, segment: segment.id}}">
                                        {{ segment.name ? segment.name : '[N/A]' }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="table-cell align-middle text-right">
                                <span v-if="segment.count === 'refreshing'" class="flex justify-end"><icon icon="clock-solid" size="large" class="grey-65" /></span>
                                <span v-else class="txt-13 text-gray-800 whitespace-nowrap" v-html="$tc('contacts.segments.count', parseInt(segment.count), {contacts: numberToLocale(segment.count)})" />
                            </div>
                            <div class="table-cell align-middle text-right" style="min-width: 150px">
                                <div class="flex items-center space-x-2.5 justify-end">
                                    <div v-dropdown="'open'" class="tw-dropdown">
                                        <div class="btn-group">
                                            <router-link class="btn btn-tertiary" :to="{name: 'subscribersSegmentsDetail', params: {list: list.id, segment: segment.id}}">
                                                {{ $t('contacts.segments.dropdown.edit') }}
                                            </router-link><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                        </div>
                                        <div class="tw-dropdown-items flex-col">
                                            <a href="#" @click.prevent="duplicateSegment(segment.id)">{{ $t('contacts.segments.dropdown.duplicate') }}</a>
                                            <a href="#" class="dd-divided dd-critical" @click.prevent="deleteSegment(segment)">{{ $t('contacts.segments.dropdown.delete') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="flex items-center space-x-10 mt-7 px-0 md:px-20">
                <div class="flex flex-col">
                    <h2>{{ $t('contacts.segments.heading') }}</h2>
                    <span class="txt-14 text-gray-900 mt-2.5" v-html="$t('contacts.segments.description')" />
                    <button class="btn btn-primary btn-lg icon-left mt-6 w-min" @click="showNewSegmentModal"><icon icon="plus-solid" size="small" /><span>{{ $t('contacts.segments.create_segment_button') }}</span></button>
                </div>
                <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-lists.png" style="width: 304.76px" alt="list-ilustration" class="hidden md:block">
            </div>
        </div>

        <tw-confirm ref="deleteSegmentConfirm" @confirmDelete="deleteSegmentConfirmed" />

        <tw-modal ref="newSegmentModal" :title="$t('appforms.contacts.segment.title')" width="472" close-button="yes">

            <template #default="slotProps">
                <add-segment-form :modal="slotProps" :list-id="list.id" />
            </template>

        </tw-modal>
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'

import toTemplate from '@mixins/toTemplate'
import Tracker from '@js/tracker'
import Pusher from 'pusher-js'

export default {
    name: 'SubscriberSegments',

    store,

    mixins: [toTemplate],

    props: ['list'],

    data() {
        return {
            activated: true,
            orderBy: {
                rule: null,
                order: 'ASC'
            },
            pusher: null,
        }
    },
    computed: {
        ...mapState(['account']),
        ...mapState('contactsModule', [
            'segmentsListLoaded', 'segmentsList'
        ])
    },
    created() {
        this.pusher = new Pusher('d9227f1530fb2760f117', {
            cluster: 'eu',
            secret: 'dd261a845fc434b77a4b',
            appId: '199657',
        })
  
        const channelName = `segments-recalculate.${this.account.name}`
        this.pusher = this.pusher.subscribe(channelName)
    },
  
    mounted() {
        this.pusher.bind('segments-refreshed', (data) => {
            store.dispatch('contactsModule/setSegmentCount', data)
        })
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('contactsModule/loadSegmentsList', {listId: to.params.list})
        })
    },

    methods: {
        async orderResults(orderBy) {
            if(this.orderBy.rule === orderBy)
                this.orderBy.order = this.orderBy.order === 'DESC' ? this.orderBy.order = 'ASC' : this.orderBy.order = 'DESC'
            try {
                await store.dispatch('contactsModule/loadSegmentsList', {listId: this.list.id, orderBy: {rule: orderBy, order: this.orderBy.order}})
                this.orderBy.rule = orderBy
            }
            catch(error) {
                this.$message.error(error.message)
            }
        },
        showNewSegmentModal() {
            this.$refs['newSegmentModal'].showModal()
            new Tracker().trackEvent('BUTTON_CLICKED', {
                scope: 'contacts',
                name: 'Create new segment'
            })
        },
        deleteSegment(segment) {
            this.$refs['deleteSegmentConfirm'].setData({
                twConfirmTitle: this.$t('contacts.segments.delete_segment.title'),
                twConfirmText: this.$t('contacts.segments.delete_segment.text'),
                twCheckbox: this.$t('contacts.segments.delete_segment.confirm_text', {segment: segment.name}),
                data: segment.id,
                twClose: this.$t('common.close'),
                twDelete: this.$t('contacts.segments.delete_segment.button'),
            })
            this.$refs['deleteSegmentConfirm'].showModal()
        },
        async deleteSegmentConfirmed(segment) {  
            try {
                await this.$http.delete(`/contacts/${this.list.id}/segments/delete/${segment}`)
                this.$message.success(this.$t('contacts.segments.segment_deleted'))
                this.refreshSegments()
            }
            catch(error) {
                this.$message.error(error.message)
            }
        },
        async duplicateSegment(segment) {
            try {
                const response = await this.$http.post(`/contacts/${this.list.id}/segments/duplicate/${segment}`)
                this.$message.success(this.$t('contacts.segments.segment_duplicated'))
                this.$router.push({name: 'subscribersSegmentsDetail', params: {list: this.list.id, segment: response.data.segmentId}})
            }
            catch(error) {
                this.$message.error(error.message)
            }
        },
        async recountSegments() {
            try {
                await store.dispatch('contactsModule/setSegmentsRecounting')
                await this.$http.get(`/contacts/${this.list.id}/segments/refresh`)
                this.$message.success(this.$t('contacts.segments.segments_recounted'))
            }
            catch(error) {
                this.$message.error(error.message)
            }
        },
        async refreshSegments() {
            try {
                await store.dispatch('contactsModule/loadSegmentsList', {listId: this.list.id, orderBy: {rule: this.orderBy.rule, order: this.orderBy.order}})
            }
            catch(error) {
                this.$message.error(error.message)
            }
        }
    }
}
</script>
