
<div>
    <portal v-if="activated" to="contacts-section-header">
        <div class="flex flex-grow items-center justify-between">
            <div class="flex items-center space-x-5">
                <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                    {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                </router-link>
                <div class="hidden lg:block">
                    <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                </div>
                <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.settings.title') }}</span>
            </div>
            <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                <icon class="mr-1" icon="caret-left-solid" />
                <span class="txt-12">{{ $t('common.back') }}</span>
            </router-link>
        </div>
    </portal>

    <div class="flex flex-col lg:flex-row lg:space-x-5 space-y-2.5 lg:space-y-0">
        <div class="flex flex-col w-full lg:w-2/6 space-y-1 lg:mt-2">
            <h4 class="text-gray-1000 font-normal">
                {{ $t('contacts.settings.lists_heading') }}
            </h4>
            <p class="text-gray-900 txt-14">
                {{ $t('contacts.settings.lists_description') }}
            </p>
        </div>
        <div class="panel px-9 pt-8 pb-6 flex flex-col w-full lg:w-4/6">
            <div v-if="!settingsLoaded" class="w-4 h-4 mt-4 double-loader" />
            <list-settings v-else :list="list" :currencies="currencies" :domains-loading="domainsLoading" :domains="domains" />
        </div>
    </div>

    <div class="divider my-5" />

    <div class="flex flex-col lg:flex-row lg:space-x-5 space-y-2.5 lg:space-y-0">
        <div class="flex flex-col w-full lg:w-2/6 space-y-1 lg:mt-2">
            <h4 class="text-gray-1000 font-normal">
                {{ $t('contacts.settings.optin_heading') }}
            </h4>
            <p class="text-gray-900 txt-14">
                {{ $t('contacts.settings.optin_description') }}
            </p>
        </div>
        <div class="panel px-5 pt-4 pb-6 flex flex-col w-full lg:w-4/6">
            <div v-if="!settingsLoaded" class="w-4 h-4 mt-4 double-loader" />
            <optin-settings v-else :list="list" :domains="domains" />
        </div>
    </div>

    <div class="divider my-5" />

    <div class="flex flex-col lg:flex-row lg:space-x-5 space-y-2.5 lg:space-y-0">
        <div class="flex flex-col w-full lg:w-2/6 space-y-1 lg:mt-2">
            <h4 class="text-gray-1000 font-normal">
                {{ $t('contacts.settings.optout_heading') }}
            </h4>
            <p class="text-gray-900 txt-14">
                {{ $t('contacts.settings.optout_description') }}
            </p>
        </div>
        <div class="panel px-5 pt-8 pb-6 flex flex-col w-full lg:w-4/6">
            <div v-if="!settingsLoaded" class="w-4 h-4 mt-4 double-loader" />
            <optout-settings v-else :list="list" />
        </div>
    </div>

    <div class="divider my-5" />

    <div class="flex flex-col lg:flex-row lg:space-x-5 space-y-2.5 lg:space-y-0">
        <div class="flex flex-col w-full lg:w-2/6 space-y-1 lg:mt-2">
            <h4 class="text-gray-1000 font-normal">
                {{ $t('contacts.settings.notifications_heading') }}
            </h4>
            <p class="text-gray-900 txt-14">
                {{ $t('contacts.settings.notifications_description') }}
            </p>
        </div>
        <div class="panel px-5 pt-4 pb-6 flex flex-col w-full lg:w-4/6">
            <div v-if="!settingsLoaded" class="w-4 h-4 mt-4 double-loader" />
            <notifications-settings v-else :list="list" />
        </div>
    </div>

    <div class="divider my-5" />

    <div class="flex flex-col lg:flex-row lg:space-x-5 space-y-2.5 lg:space-y-0">
        <div class="flex flex-col w-full lg:w-2/6 space-y-1 lg:mt-2">
            <h4 class="text-gray-1000 font-normal">
                {{ $t('contacts.settings.translations_heading') }}
            </h4>
            <p class="text-gray-900 txt-14">
                {{ $t('contacts.settings.translations_description') }}
            </p>
        </div>
        <div class="panel px-5 pt-4 pb-6 flex flex-col w-full lg:w-4/6">
            <div v-if="!settingsLoaded" class="w-4 h-4 mt-4 double-loader" />
            <translation-settings v-else :list-id="list.id" :translations="list.unsub_texts" />
        </div>
    </div>

    <div class="divider my-5" />

    <div class="flex flex-col lg:flex-row lg:space-x-5 space-y-2.5 lg:space-y-0">
        <div class="flex flex-col w-full lg:w-2/6 space-y-1 lg:mt-2">
            <h4 class="text-gray-1000 font-normal">
                {{ $t('contacts.settings.webhook_heading') }}
            </h4>
            <p class="text-gray-900 txt-14" v-html="$t('contacts.settings.webhook_description')" />
        </div>
        <div class="panel px-5 pt-4 pb-6 flex flex-col w-full lg:w-4/6">
            <div v-if="!settingsLoaded" class="w-4 h-4 mt-4 double-loader" />
            <webhook-settings v-else :list-id="list.id" :url="list.settings && list.settings['webhook'] && list.settings['webhook']['url'] ? list.settings['webhook']['url'] : null" />
        </div>
    </div>
</div>
