
<div v-if="imports" class="panel px-8 py-5 mb-8">
    <div class="mb-4">
        <span class="text-gray-1000 font-semibold txt-14">{{ $t('appforms.contacts.import.currently_running_imports') }}</span>
    </div>
    <div class="space-y-6">
        <div v-for="(importProgress, i) in imports" :key="`import-bar-${i}`" class="flex items-end text-gray-900 semibold" style="font-size: 13px">
            <div class="flex-grow">
                <div>
                    <span class="font-normal">{{ $t('appforms.contacts.import.target_list') }} <span class="text-gray-1000 font-semibold">{{ importProgress.list_name }}</span></span>
                    <span v-if="!importProgress.error" class="float-right">{{ importProgress.chunked_count }} <span class="text-gray-700">/ {{ importProgress.chunk_total_count }}</span></span>
                    <span v-else class="float-right">ERROR</span>
                </div>
                <div class="mt-2 mb-1 relative rounded-lg bg-gray-300 w-full" style="height: 6px">
                    <div :class="{'bg-green-400': !importProgress.error, 'bg-messages-error-100': importProgress.error}" :style="{width: Math.round(importProgress.percentage) + '%'}" class="absolute rounded-lg left-0 h-full" style="transition: 0.5s" />
                </div>
            </div>
            <div class="text-right ml-9">
                <icon v-show="importProgress.complete" :id="`import-status-${importProgress.import_id}`" class="float-right text-green-400" icon="check-circle-solid" size="large" />

                <icon v-if="importProgress.error" v-tooltip="importProgress.error_message" class="float-right text-messages-error-100" icon="times-circle-solid" size="large" />

                <span v-else class="text-gray-900 font-semibold txt-13">{{ Math.round(importProgress.percentage) }} %</span>
            </div>
        </div>
    </div>
    <div class="text-gray-900 txt-13 mt-5">
        {{ $t('appforms.contacts.import.progress_bar_hint') }}
    </div>
</div>
