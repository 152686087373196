<template>
    <div class="folder" :class="{'bb': status === 3, 'disabled': count === 0}" @click="filterCampaigns(status)">
        <div class="items-center">
            <icon icon="folder-solid" class="mr-2 text-gray-650" />
            <span>{{ $t(`campaigns.folders.status_${status}`) }}</span>
        </div>
        <span class="tw-lbl outline">{{ count }}</span>
    </div>
</template>

<script>
export default {

    name: 'CampaignFolderFilters',

    props: {
        status: {
            type: Number,
            required: true
        },
        count: {
            type: Number,
            required: true
        },
        modal: {
            type: Object,
            required: true
        }
    },

    methods: {
        filterCampaigns(status) {
            this.modal.hideDropdown()
            this.$emit('filterCampaigns', status)
        },
    }
    
}
</script>