

<div class="chat-header px-4">
    <div class="flex items-center">
        <div class="avatar border-white border" :style="{ backgroundColor: stringToColor(selectedChat.contact) }">
            {{ selectedChat.contact[0] }}
        </div>
        <div class="flex flex-col ml-3">
            <button @click="showSubscriberModal(selectedChat.contact)">
                <h3 class="truncate text-gray-1000 font-semibold">
                    {{ selectedChat.contact }}
                </h3>
            </button>
            <span v-if="selectedChat.contact_email" class="truncate text-gray-900">
                {{ selectedChat.contact_email }}
            </span>
        </div>
    </div>
    <div class="flex items-center space-x-4">
        <div v-if="assigningConversation" class="w-4 h-4 mt-4 double-loader" />
        <ecm-select v-else :options="localAgents" :value="selectedChat.agent" option-value-attribute="agent" option-display-attribute="name" @input="assignConversation" />

        <button v-if="status === 'open'" class="status-switch" @click="closeConversation(selectedChatId)"><icon icon="check-solid" /></button>
        <button v-else class="status-switch" @click="reopenConversation(selectedChatId)"><icon icon="inbox-solid" /></button>
    </div>
</div>

