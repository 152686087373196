<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon key="breadcrumb-icon-1" class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <router-link :to="{name: 'subscribersSegments', params: {list: list.id}}" class="hidden lg:block txt-18 text-gray-800 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.segments.title') }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon key="breadcrumb-icon-2" class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <div v-if="!segmentLoaded" class="w-4 h-4 mt-4 double-loader" />
                    <span v-else class="hidden lg:block txt-18 text-gray-1000">{{ segment.name }}</span>
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="pt-2 flex flex-col">

            <div v-if="!segmentLoaded" class="w-4 h-4 mt-4 double-loader" />

            <segment-form-new
                    v-else
                    :list-id="list.id"
                    :query="segment && segment['query'] && segment['query'].length ? segment['query'] : null"
                    :segment-name="segment && segment['name'] ? segment['name'] : null"
                    :segment-id="segment['id']"
                    :parent="segment && segment['based_on'] ? segment['based_on'] : null"
                    :customfields="list.custom_fields"
                    :marketer="account.hasAllowedMarketerFunctions"
                    :account-name="account.name"
                    :user="user"
                    :is-exclusionary="segment && segment['is_exclusionary'] ? segment['is_exclusionary'] : false"
            />
        </div>
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'

export default {
    name: 'SubscriberSegmentsDetail',

    store,

    props: ['list'],

    data() {
        return {
            activated: true
        }
    },

    computed: {
        ...mapState(['account', 'user']),
        ...mapState('contactsModule', [
            'segmentLoaded', 'segment'
        ])
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('contactsModule/loadSegment', {listId: to.params.list, segmentId: to.params.segment})
        })
    }
}
</script>