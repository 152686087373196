
<div class="table-cell relative align-top" :class="{'bottom-margin': Object.values(selectfields).includes('__newfield__')}">
    <div v-if="field === '__newfield__'" class="dummy" />

    <div class="container" :class="{'absolute top-0 new_field': field === '__newfield__'}">
        <ecm-select v-model="field" :options="options" searchable />

        <div v-if="field == '__newfield__'" class="flex flex-col">
            <ValidationProvider ref="mergetype" v-slot="{ errors }" rules="required" slim>
                <div class="flex flex-col mt-2">
                    <ecm-select v-model="ftype" class="w-full" :options="customFieldOptions" />
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            
            <ValidationProvider ref="mergename" v-slot="{ errors }" rules="required|max:255|uniquefieldname" slim>
                <div class="flex flex-col mt-2">
                    <input
                            v-model="fname"
                            :placeholder="$t('mapper.newfield_name')"
                            class="tw-input"
                            :class="{'tw-err': errors.length > 0}"
                            type="text"
                            name="fieldName">
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <ValidationProvider ref="mergetag" v-slot="{ errors }" rules="required|alpha_dash|startwithletter|max:255|uniquemergetag" slim>
                <div class="flex flex-col mt-2">
                    <input
                            v-model="merge"
                            :class="{'tw-err': errors.length > 0}"
                            :placeholder="$t('mapper.newfield_merge')"
                            class="tw-input"
                            name="fieldMerge"
                            type="text">
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
        </div>

    </div>
</div>
