<template>
    <transition name="long-fadeout">
        <div class="ec-container md:spa-container relative main-container flex flex-wrap mx-auto flex-col w-full md:flex-row">
            <div class="mc-r w-full h-full">
                <div class="main-panel solo spa" :class="{scrolled: scrolled}">

                    <h2 class="flex justify-between items-center px-9 py-3 text-gray-1000 txt-22">
                        {{ $t('contacts.index.title') }}

                        <button class="btn btn-primary btn-lg icon-left" @click="createList"><icon icon="plus-solid" size="small" /> <span>{{ $t('contacts.index.new_list_button') }}</span></button>
                    </h2>

                    <div v-if="!listsLoaded" ref="content-layout" class="w-4 h-4 mt-6 ml-9 double-loader" />
                    <vue-custom-scrollbar v-else ref="content-layout" :settings="settings" class="spa-container" @ps-y-reach-start="scrolled = false" @ps-scroll-y="handleScroll()">

                        <div v-if="lists && Object.keys(lists).length" class="w-full overflow-x-auto md:overflow-x-visible">
                            <alert v-if="success === true" class="mb-4" type="success" :message="message" />
                            <alert v-else-if="success === false" class="mb-4" type="error" :message="message" />

                            <div class="table tw-table tw-table-large max-w-full">
                                <div v-for="(list, index) in lists" :key="`list-${index}`" class="table-row ">
                                    <div class="table-cell align-middle w-full max-w-0">
                                        <div class="flex items-center">
                                            <icon icon="list-regular" size="large" class="mr-5 grey-65" />
                                            <a @click="goToSubscribersOverview(list.id)" class="txt-14 link-blue semibold cursor-pointer">
                                                {{ list.name }}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="table-cell align-middle text-right whitespace-nowrap text-gray-800" v-html="$tc('contacts.index.active', list.active_subscribers)" />
                                    <div class="table-cell align-middle whitespace-nowrap text-gray-800" v-html="$t('contacts.index.created', {created: list.created ? list.created : 'N/A'})" />
                                    <div class="table-cell align-middle text-right" style="min-width: 200px">
                                        <div v-dropdown="'open'" class="tw-dropdown">
                                            <div class="btn-group">
                                                <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="btn btn-tertiary whitespace-nowrap">
                                                    <div v-if="exportSending === list.id" class="double-loader loader-sm loader-grey mr-2" /><span v-else>{{ $t('contacts.index.overview') }}</span>
                                                </router-link><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                            </div>
                                            <div class="tw-dropdown-items flex-col">
                                                <router-link v-if="user.role !== 'custom' || checkRole(user, 'manage-contacts-import')" :to="{name: 'subscribersImport', params: {list: list.id}}">
                                                    {{ $t('contacts.index.import_button') }}
                                                </router-link>
                                                <router-link v-if="user.role !== 'custom' || checkRole(user, 'manage-contacts-forms')" :to="{name: 'subscribersForms', params: {list: list.id}}">
                                                    {{ $t('contacts.index.form_button') }}
                                                </router-link>
                                                <button @click="getExport(list.id)">{{ $t('contacts.index.export_button') }}</button>
                                                <button class="dd-divided dd-critical" @click="showDeleteList(list)">{{ $t('contacts.index.delete_button') }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else class="px-16 py-8 bg-white mt-3 md:mx-40 lg:mx-64">
                            <div class="flex flex-row items-center justify-between">
                                <div class="flex flex-col">
                                    <p class="mb-2.5 txt-18 text-gray-1000">
                                        {{ $t('contacts.index.heading') }}
                                    </p>
                                    <div>
                                        <span class="txt-14 text-gray-900" v-html="$t('contacts.index.description')" />
                                        <br>
                                        <button class="btn btn-primary btn-lg icon-left mt-6" @click="createList"><icon icon="plus-solid" size="small" /><span>{{ $t('contacts.index.new_list_button') }}</span></button>
                                    </div>
                                </div>
                                <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-lists.png" style="width: 305px" alt="feed-ilustration">
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </div>
            </div>

            <tw-modal ref="createListWarning" :title="$t('contacts.index.create_list_warning.title')" close-button="yes" width="512">
                <template #default="slotProps">

                    <create-list-warning :modal="slotProps" @continue="showCreateList" />

                </template>
            </tw-modal>

            <tw-modal ref="createListModal" :title="$t('contacts.index.create_new_list')" width="472" close-button="yes">
                <template #default="slotProps">

                    <new-list :modal="slotProps" @listCreated="listCreated" />

                </template>
            </tw-modal>

            <tw-modal ref="deleteListWarning" :title="$t('contacts.index.delete_list_warning.title')" close-button="yes" width="512">
                <template #default="slotProps">

                    <delete-list-warning :modal="slotProps" />

                </template>
            </tw-modal>

            <tw-confirm ref="deleteListModal" @confirmDelete="deleteList" />

        </div>
    </transition>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'
import vueCustomScrollbar from 'vue-custom-scrollbar'

import permissions from '@mixins/permissions'
import Tracker from "@js/tracker";

export default {
    name: 'Index',

    store,

    mixins: [permissions],

    components: {
        vueCustomScrollbar
    },

    data() {
        return {
            success: null,
            message: null,
            settings: {
                suppressScrollX: true,
                tagname: 'div',
                scrollYMarginOffset: 48,
                handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel']
            },
            scrolled: false,
            exportSending: null
        }
    },

    computed: {
        ...mapState(['user']),
        ...mapState('contactsModule', [
            'listsLoaded', 'lists', 'usedInPipeline', 'usedInCampaign', 'usedInIntegration'
        ])
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('contactsModule/getLists')
            vm.$root.showSpaLoader = false
        })
    },

    beforeRouteUpdate(to, from, next) {
        store.dispatch('contactsModule/getLists')
        next()
    },

    mounted() {
        let vueAppEl = document.getElementById('vueApp')
        vueAppEl.addEventListener('wheel', (event) => this.scrolledContainer(event))
    },

    methods: {
        scrolledContainer(event) {
            if(event.target.id === 'vueApp' && this.$refs['content-layout']) {
                event.stopPropagation()
                event.preventDefault()
                event.cancelBubble = true
                let mouseEvent = new MouseEvent('wheel')
                mouseEvent.deltaY = event.deltaY
                mouseEvent.deltaX = event.deltaX
                mouseEvent.deltaZ = event.deltaZ
                this.$refs['content-layout'].$el.dispatchEvent(mouseEvent)
            }
        },

        handleScroll() {
            this.scrolled = this.$refs['content-layout'].$el.scrollTop !== 0
        },

        setRoute(routeName) {
            if(routeName === 'reportsCompare')
                routeName = 'reportsComparative'
            if(this.$route.name !== routeName)
                this.$router.push({name: routeName})
        },

        createList() {
            if(localStorage.getItem('createListWarningDisabled')) {
                this.showCreateList()
                new Tracker().trackEvent('BUTTON_CLICKED', {
                    scope: 'contacts',
                    name: 'New contact list'
                })
            }
            else {
                this.showCreateListWarning()
            }
        },

        showCreateListWarning() {
            this.$refs['createListWarning'].showModal()
        },

        showCreateList() {
            this.$refs['createListModal'].showModal()
        },

        listCreated(listName) {
            new Tracker().trackEvent('BUTTON_CLICKED', {
                scope: 'contacts',
                name: 'New list created'
            })
            this.refreshLists()
            this.success = true
            this.message = this.$t('contacts.index.list_created_alert', {list: listName})
        },

        showDeleteList(list) {
            if(this.usedInPipeline[list.id] || this.usedInCampaign[list.id] || this.usedInIntegration[list.id]) {
                this.$refs['deleteListWarning'].showModalRawData({
                    list: list.name,
                    campaigns: this.usedInCampaign[list.id],
                    pipelines: this.usedInPipeline[list.id],
                    integrations: this.usedInIntegration[list.id]
                })
            }
            else {
                this.$refs['deleteListModal'].setData({
                    twConfirmTitle: this.$t('contacts.index.delete_list.title'),
                    twConfirmText: this.$t('contacts.index.delete_list.text'),
                    twCheckbox: this.$t('contacts.index.delete_list.confirm', {list: list.name}),
                    data: list,
                    twClose: this.$t('common.close'),
                    twDelete: this.$t('contacts.index.delete_list.button'),
                })
                this.$refs['deleteListModal'].showModal()
            }
        },

        async deleteList(list) {
            try {
                await this.$http.delete(`/contacts/${list.id}/delete`)

                this.refreshLists()
                this.success = true
                this.message = this.$t('contacts.index.list_deleted_alert', {list: list.name})
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }
        },

        async getExport(listId) {
            this.exportSending = listId

            try {
                await this.$http.get(`/contacts/${listId}/export`, {params: {status: 'active'}})
                this.success = true
                this.message = this.$t('contacts.index.export_queued_alert')
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }
            this.exportSending = null
        },

        refreshLists() {
            store.dispatch('contactsModule/getLists')
        },
        goToSubscribersOverview(listId) {
            this.$router.push({name: 'subscribersOverview', params: {list: listId}})
            new Tracker().trackEvent('LINK_CLICKED', {
                scope: 'contacts',
                name: 'Contact list',
                listId: listId
            })
        }
    }
}
</script>
