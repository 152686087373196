
<div>
    <portal v-if="activated" to="contacts-section-header">
        <div class="flex flex-grow items-center justify-between">
            <div class="flex items-center space-x-5">
                <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                    {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                </router-link>
                <div class="hidden lg:block">
                    <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                </div>
                <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.remove_subscribers.title') }}</span>
            </div>
            <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                <icon class="mr-1" icon="caret-left-solid" />
                <span class="txt-12">{{ $t('common.back') }}</span>
            </router-link>
        </div>
    </portal>

    <div class="flex flex-col lg:flex-row lg:space-x-5 space-y-2.5 lg:space-y-0">
        <div class="flex flex-col w-full lg:w-2/6 space-y-1 lg:mt-2">
            <h4 class="text-gray-1000 font-normal">
                {{ $t('contacts.remove_subscribers.heading') }}
            </h4>
            <p class="text-gray-900 txt-14">
                {{ $t('contacts.remove_subscribers.description') }}
            </p>
        </div>
        <div class="panel p-9 flex flex-col w-full lg:w-4/6">
            <alert v-if="message && success !== null" class="mb-6" :type="success === true ? 'success' : 'error'" :label="messageTitle" :message="message" />

            <ValidationProvider v-slot="{ errors }" name="emails" rules="required|max:150000" class="flex flex-col">
                <label for="deleteEmails" class="tw-label mb-2.5">{{ $t('contacts.remove_subscribers.emails_label') }}</label>
                <textarea id="deleteEmails" v-model="emails" name="emails" class="tw-input" style="min-height: 260px; resize: none;" :placeholder="$t('contacts.remove_subscribers.emails_placeholder')" />
                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
            </ValidationProvider>
            <div class="mt-7">
                <button :disabled="sending || !emails.length" class="btn btn-primary btn-lg" @click="removeSubscribers"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.remove_subscribers.button') }}</button>
            </div>
        </div>
    </div>

    <tw-confirm ref="removeSubscribersConfirm" @confirmDelete="removeSubscribersConfirmed" />
</div>
