
<ValidationObserver ref="observer" slim>
    <form ref="addMessengerDomain" novalidate class="flex flex-col" @submit.prevent="addDomain">

        <alert v-if="success === true" class="mb-4" type="success" :message="message" />

        <alert v-if="success === false" class="mb-4" type="error" :message="message" />

        <ValidationProvider v-slot="{ errors }" name="domainName" rules="required|max:255" slim>
            <div class="input flex flex-col">
                <label for="domainName" class="tw-label mb-2.5">{{ $t('contacts.facebook_lead.modal.label') }}</label>
                <input id="domainName" v-model="newDomain" type="text" name="domainName" class="tw-input" :placeholder="$t('contacts.facebook_lead.modal.placeholder')" :class="{'tw-err': errors.length > 0 }">
                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>

        <div v-if="addedDomains && addedDomains.length" class="delete-list-frame flex flex-col mt-6 space-y-2.5">
            <h5 class="text-gray-1000">
                {{ $t('contacts.facebook_lead.modal.added_domains_title') }}
            </h5>
            <ul class="list-inside list-disc space-y-2">
                <li v-for="(domain, index) in addedDomains" :key="`domain-${index}`" class="text-gray-900 font-semibold txt-14">
                    <span class="ml-3">{{ domain }}</span>
                </li>
            </ul>
        </div>

        <div class="tw-modal-buttons">
            <button type="button" class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('common.cancel') }}</button>
            <button :disabled="sending" class="btn btn-primary float-right btn-lg"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.facebook_lead.modal.button') }}</button>
        </div>
    </form>
</ValidationObserver>
