<template>

    <div class="chat-content flex flex-col flex-grow overflow-hidden border-l border-gray-500">

        <div ref="messagesScroller" class="overflow-y-auto px-5 pt-8 pb-7.5 space-y-7" @scroll="onMessagesScroll">
            <div v-if="selectedChatMessages === 'loading'" class="w-4 h-4 mt-4 double-loader" />
            <template v-else>
                <div v-for="(message, index) in selectedChatMessages.slice().reverse()" :key="message.uid" class="message" :class="{'info': message.type !== 'incoming' && message.type !== 'outgoing' && message.type !== 'note', 'incoming': message.type === 'incoming', 'outgoing': message.type === 'outgoing', 'note': message.type === 'note', 'no-avatar': index > 0 && selectedChatMessages.slice().reverse()[index-1].type === message.type}">
                    <div v-if="message.type === 'incoming'" class="avatar" :style="{ backgroundColor: stringToColor(selectedChat.contact) }">
                        {{ selectedChat.contact[0] }}
                    </div>
                    <div v-else-if="message.type === 'outgoing' || message.type === 'note'" class="avatar" :style="{ backgroundColor: stringToColor(account.name) }">
                        <template v-if="message.bot">
                            <img src="https://d70shl7vidtft.cloudfront.net/chat/img/chatbot.png" alt="Chatbot">
                        </template>
                        <template v-else-if="users[message.agent] && users[message.agent].avatar">
                            <img :src="users[message.agent].avatar + '?width=100&height=100'" :alt="message.agent">
                        </template>
                        <template v-else>
                            {{ account.agent[0] }}
                        </template>
                    </div>
                    <div class="flex flex-col">
                        <div v-if="message.type !== 'incoming' && message.type !== 'outgoing' && message.type !== 'note'" class="text">
                            <template v-if="message.type === 'assigned'">
                                {{ message.agent === message.target ? $t('chat.conversations.messages.assigned_themselves', {agent: message.agent}) : $t('chat.conversations.messages.assigned', {agent: message.agent, target: message.target}) }}
                            </template>
                            <template v-else-if="message.type === 'reopened'">
                                {{ $t('chat.conversations.messages.reopened', {agent: message.agent}) }}
                            </template>
                            <template v-else-if="message.type === 'closed'">
                                {{ $t('chat.conversations.messages.closed', {agent: message.agent}) }}
                            </template>
                            <template v-else>
                                {{ message.type }}
                            </template>

                            <span ref="timestamp" :data-time="message.timestamp">{{ reformatTimestamp(message.timestamp) }}</span>
                        </div>
                        <template v-else>
                            <div class="text">
                                {{ message.message }}
                            </div>

                            <div class="timestamp">
                                <span ref="timestamp" :data-time="message.timestamp">{{ reformatTimestamp(message.timestamp) }}</span>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <div v-if="isTypingInChat(selectedChatId)" class="message incoming">
                <div class="avatar" :style="{ backgroundColor: stringToColor(selectedChat.contact) }">
                    {{ selectedChat.contact[0] }}
                </div>
                <div class="flex flex-col dots">
                    <div class="text">
                        <div class="isTyping">
                            <div class="dot dot1" />
                            <div class="dot dot2" />
                            <div class="dot dot3" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="reply-box mt-auto m-4">
            <form class="box flex flex-col drop-shadow-sm mb-0 px-3" :class="messageType" @submit.prevent.stop="onMessageSubmit">
                <tab-row v-model="messageType" :options="['small']">
                    <template #tab="slotProps">
                        <tab-item key="chat-reply-option" value="outgoing" :title="$t('chat.conversations.messages.reply')" :selected="messageType" :trigger-select="slotProps.triggerSelect" :options="slotProps.options" />
                        <tab-item key="chat-note-option" value="note" :title="$t('chat.conversations.messages.note')" :selected="messageType" :trigger-select="slotProps.triggerSelect" :options="slotProps.options" />
                    </template>
                </tab-row>
                <textarea ref="messageInput" name="input" :placeholder="messageType === 'outgoing' ? $t('chat.conversations.messages.type_reply') : $t('chat.conversations.messages.type_note')" class="focus:outline-none txt-14 text-gray-1000 py-4 placeholder-gray-800" :class="{'bg-alert-200': messageType === 'note'}" @keyup.enter="onMessageSubmit" />
                <div class="flex justify-end pb-3">
                    <button class="btn btn-default btn-primary">{{ messageType === 'outgoing' ? $t('chat.conversations.messages.send') : $t('common.save') }}</button>
                </div>
            </form>
        </div>
    </div>

</template>

<script>

import store from '@stores/index'
import {mapState} from 'vuex'
import dayjs from 'dayjs'

import TabRow from '@component/Tabs/TabRow.vue'
import TabItem from '@component/Tabs/TabItem.vue'

export default {

    name: 'ChatWindow',

    store: store,

    props: ['agent'],

    components: {
        TabRow,
        TabItem
    },

    data () {
        return {
            messageType: 'outgoing'
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState('chatModule', [
            'selectedChatId',
            'typingConversations',
            'selectedChatMessages',
            'users',
        ]),
        selectedChat() {
            return store.getters['chatModule/getSelectedChat']
        },
    },

    watch: {
        selectedChat() {
            this.$nextTick(() => {
                let scroller = this.$refs.messagesScroller
                if(scroller) {
                    scroller.scrollTop = scroller.scrollHeight
                }
            })
        },
        selectedChatMessages() {
            this.$nextTick(() => {
                let scroller = this.$refs.messagesScroller
                if(scroller) {
                    scroller.scrollTop = scroller.scrollHeight
                }
            })
        }
    },

    mounted() {
        setInterval(() => {
            if(this.$refs.timestamp) {
                this.$refs.timestamp.forEach(element => {
                    element.innerText = this.reformatTimestamp(element.getAttribute('data-time'))
                })
            }
        }, 60 * 1000) // 60 * 1000 milsec
    },

    methods: {
        onMessagesScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
            if(scrollTop === 0) {
                // console.log('Previous messages')
                // store.dispatch('loadPreviousChatMessages')
            }
        },
        reformatTimestamp(timestamp) {
            return dayjs.unix(Math.round(timestamp / 1000)).fromNow()
        },
        isTypingInChat(chat_id) {
            return this.typingConversations.indexOf(chat_id) !== -1
        },
        onMessageSubmit () {
            store.dispatch('chatModule/postMessageToConversation', {message: this.$refs.messageInput.value, agent: this.agent, type: this.messageType})
            this.$refs.messageInput.value = ''
        },
        stringToColor(str) {
            var hash = 0
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash)
            }
            var colour = '#'
            for (let i = 0; i < 3; i++) {
                var value = (hash >> (i * 8)) & 0xFF
                colour += ('00' + value.toString(16)).substr(-2)
            }
            return colour
        },
    }
}
</script>