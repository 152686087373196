<template>

    <transition name="long-fadeout">
        <div v-if="visible" class="register-modal-bg">

            <div class="register-modal-container">

                <div class="register-modal-panel">
                    <img src="https://ecomail-assets.s3.amazonaws.com/old/images/logo-tiny.png" class="register-modal-logo" alt="Ecomail logo">
                    <div class="mt-10 space-y-3">
                        <div v-for="(step, index) in steps" :key="`side-panel-description-${index}`" class="flex items-center">
                            <template v-if="stepIsCompleted(step)">
                                <svg-vue icon="check-circle-regular" class="w-6 text-auth-green-500" style="min-width: 1.5rem" />
                                <span class="text-auth-xs ml-4">{{ $t(`appforms.extended_registration.side_panel.${step}`) }}</span>
                            </template>
                            <template v-else-if="stepIsInProgress(step)">
                                <svg-vue icon="arrow-circle-right-regular" class="w-6 text-white" style="min-width: 1.5rem" />
                                <span class="text-auth-xs ml-4">{{ $t(`appforms.extended_registration.side_panel.${step}`) }}</span>
                            </template>
                            <template v-else>
                                <span class="text-auth-xs ml-10 text-auth-gray-400">{{ $t(`appforms.extended_registration.side_panel.${step}`) }}</span>
                            </template>
                        </div>
                    </div>

                    <div class="mt-auto">
                        <img src="https://ecomail-assets.s3.amazonaws.com/old/images/kaucky-photo.png" class="rounded-full w-20" alt="Testimonial photo">
                        <p class="text-auth-sm mt-7.5" v-html="$t('appforms.extended_registration.quote')" />
                        <span class="text-auth-gray-400 mt-3.5 block">{{ $t('appforms.extended_registration.quote_author') }}</span>
                    </div>
                </div>

                <div class="register-modal-content">
                    
                    <h2 class="text-auth-2xl text-auth-black font-semibold" v-html="$t(`appforms.extended_registration.content.${activeStep}.title`)" />
                    <p class="mt-3 text-auth-gray-700">
                        {{ $t(`appforms.extended_registration.content.${activeStep}.description`) }}
                    </p>

                    <template v-if="activeStep === 'account_info'">
                        <FormInput
                                key="website"
                                v-model="website"
                                name="website"
                                type="url"
                                :errors="websiteErrors"
                                :label="$t('appforms.extended_registration.content.account_info.website_label')"
                                :placeholder="$t('appforms.extended_registration.content.account_info.website_placeholder')"
                                class="w-full mt-9" />

                        <FormInput
                                v-if="!needsProvisioning"
                                key="phone"
                                v-model="phone"
                                name="phone"
                                type="text"
                                :errors="phoneErrors"
                                :label="$t('appforms.extended_registration.content.account_info.phone_label')"
                                :placeholder="$t('appforms.extended_registration.content.account_info.phone_placeholder')"
                                class="w-full mt-3.5" />

                        <FormSelect
                                key="userbase-select"
                                v-model="userbase"
                                name="subscribersPlan"
                                :errors="subscribersErrors"
                                :label="$t('appforms.extended_registration.content.account_info.subscribers_label')"
                                class="mt-3.5">
                            <template v-if="userbase" #selectedLabel>
                                {{ subscribersPlans.find(plan => plan.value === userbase).label }}
                            </template>
                            <template #default="{ triggerSelect, search }">
                                <FormSelectOption v-for="(plan, index) in subscribersPlans" :key="`plan-${index}`" :label="plan.label" :value="plan.value" :trigger-select="triggerSelect" :search="search" />
                            </template>
                        </FormSelect>
                        <div class="mt-7.5">
                            <div class="text-auth-gray-700 text-auth-sm mb-1 font-semibold">
                                {{ $t('appforms.extended_registration.content.account_info.purpose_label') }}
                            </div>

                            <div class="flex flex-col md:flex-row md:justify-between space-y-4 md:space-y-0">
                                <div v-for="(value, key, index) in eshopPlatformOptions" :key="`purpose-${index}`" class="box-border py-2.5 px-4.5 bg-white border rounded-md border-auth-gray-200 hover:border-auth-green-500 cursor-pointer font-semibold text-auth-sm text-auth-gray-1000" :class="{'purpose-shadow border-2 border-auth-green-500 -m-px': value}" @click="eshopPlatformOptions[key] = !eshopPlatformOptions[key]">
                                    {{ $t(`appforms.extended_registration.content.account_info.eshop_platform_options.${key}`) }}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="activeStep === 'eshop_platform'">
                        <FormSelect
                                key="eshopPlatform"
                                v-model="eshopPlatform"
                                name="eshopPlatform"
                                :errors="eshopErrors" 
                                :title="$t('appforms.extended_registration.content.eshop_platform.select_placeholder')"
                                class="mt-10 mb-7">
                            <template v-if="eshopPlatform" #icon>
                                <img :src="`/images/integrations/${eshopOptions.find(eshop => eshop.value === eshopPlatform).image}.png`" class="h-8 mr-5" :alt="eshopPlatform">
                            </template>
                            <template v-if="eshopPlatform" #selectedLabel>
                                {{ eshopOptions.find(eshop => eshop.value === eshopPlatform).label }}
                            </template>
                            <template #default="{ triggerSelect }">
                                <FormSelectOption v-for="(platform, index) in eshopOptions" :key="`platform-${index}`" :label="platform.label" :value="platform.value" :trigger-select="triggerSelect">
                                    <template #icon>
                                        <img :src="`/images/integrations/${platform.image}.png`" class="h-8 mr-5" :alt="platform.label">
                                    </template>
                                </FormSelectOption>
                            </template>
                        </FormSelect>

                        <div class="panel p-3 panel-white" :class="{'border-green': customEshop}">
                            <div class="tw-checkbox-solid">
                                <input id="customEshop" v-model="customEshop" name="customEshop" type="checkbox">
                                <label for="customEshop">
                                    <span class="font-semibold text-auth-gray-700" v-html="$t('appforms.extended_registration.content.eshop_platform.checkbox')" />
                                </label>
                            </div>
                        </div>

                        <FormInput
                                v-if="customEshop"
                                key="customEshopValue"
                                v-model="customEshopValue"
                                name="customEshopValue"
                                :errors="customEshopValueErrors"
                                :label="$t('appforms.extended_registration.content.eshop_platform.custom_eshop_label')"
                                :placeholder="$t('appforms.extended_registration.content.eshop_platform.custom_eshop_placeholder')"
                                class="w-full mt-3.5" />
                    </template>

                    <template v-else-if="activeStep === 'approval_request'">
                        <FormSelect
                                key="verification-option"
                                v-model="verificationOption"
                                name="verificationOption"
                                :errors="verificationOptionErrors" 
                                :title="$t('appforms.extended_registration.content.approval_request.select_label')"
                                :placeholder="$t('appforms.extended_registration.content.approval_request.select_placeholder')"
                                class="mt-8">
                            <template v-if="verificationOption" #selectedLabel>
                                {{ verificationOptions.find(option => option.value === verificationOption).label }}
                            </template>
                            <template #default="{ triggerSelect }">
                                <FormSelectOption v-for="(option, index) in verificationOptions" :key="`verification-${index}`" :label="option.label" :value="option.value" :trigger-select="triggerSelect" />
                            </template>
                        </FormSelect>

                        <FormInput
                                v-if="verificationOption === 'web'"
                                key="verification-website"
                                v-model="website"
                                name="website"
                                type="url"
                                :errors="verificationInputErrors"
                                :label="$t('appforms.extended_registration.content.approval_request.website_label')"
                                :placeholder="$t('appforms.extended_registration.content.approval_request.website_placeholder')"
                                class="w-full mt-4" />

                        <FormInput
                                v-else-if="verificationOption === 'non_web'"
                                key="verification-social"
                                v-model="socialMedia"
                                name="website"
                                type="url"
                                :errors="verificationInputErrors"
                                :label="$t('appforms.extended_registration.content.approval_request.social_label')"
                                :placeholder="$t('appforms.extended_registration.content.approval_request.social_placeholder')"
                                class="w-full mt-4" />

                        <div class="flex flex-col h-full mt-5">
                            <label for="verificationInfo" class="flex flex-col">
                                <span class="text-auth-black font-semibold">{{ $t('appforms.extended_registration.content.approval_request.about_title') }}</span>
                                <span class="text-auth-gray-600">{{ $t('appforms.extended_registration.content.approval_request.about_subtitle') }}</span>
                            </label>
                            <textarea
                                    id="verificationInfo"
                                    v-model="verificationInformation"
                                    name="verificationInfo"
                                    class="block mt-1 px-[14px] py-[8px] w-full focus:ring-4 h-[44px] focus:outline-none transition duration-150 ease-in-out border rounded-[4px] border-auth-gray-300 text-auth-sm leading-5"
                                    style="min-height: 154px"
                                    :class="{'text-auth-red-800 bg-red-50 hover:bg-white focus:bg-white  focus-visible:border-auth-red-800 border-auth-red-300 placeholder-auth-red-500 ring-red-500/25 focus:border-red-800': verificationInformationErrors.length > 0,
                                             'ring-auth-green-500/25 hover:bg-auth-white focus:border-auth-green-600 focus:bg-auth-white bg-auth-gray-50 placeholder-auth-gray-500': verificationInformationErrors.length === 0,}" />
                            <div v-if="verificationInformationErrors[0]" class="text-auth-sm text-left text-auth-red-600">
                                {{ verificationInformationErrors[0] }}
                            </div>
                        </div>
                    </template>

                    <div class="flex mt-auto justify-between flex-col md:flex-row-reverse">
                        <FormButton class="mt-7.5" type="button" :loading="loading" base primary square @click="nextStep">
                            <span class="text-auth-base">{{ $t('common.continue') }}</span>
                        </FormButton>
                        <FormButton v-if="!needsProvisioning" class="hidden md:flex mt-7.5" type="button" :loading="loading" base secondary square @click="skipStep">
                            <span class="text-auth-base">{{ $t('common.skip') }}</span>
                        </FormButton>
                        <FormButton v-if="activeStep !== 'account_info'" class="mt-7.5" type="button" :loading="loading" base secondary square @click="previousStep">
                            <span class="text-auth-base">{{ $t('common.back') }}</span>
                        </FormButton>
                    </div>

                    <a v-if="!needsProvisioning" href="#" class="md:hidden mt-7.5 txt-16 text-center font-semibold text-auth-green-500" @click="skipStep">{{ $t('common.skip') }}</a>
                </div>
            </div>

        </div>
    </transition>

</template>

<script>
import confetti from '@js/mixins/confetti'
import toTemplate from '@js/mixins/toTemplate'
import Tracker from '../../tracker'
import { validate } from 'vee-validate'

import FormInput from '@component/Auth/FormInput.vue'
import FormSelect from '@component/Auth/FormSelect.vue'
import FormSelectOption from '@component/Auth/FormSelectOption.vue'
import FormButton from '@component/Auth/FormButton.vue'

export default {

    name: 'ExtendedRegistrationForm',

    mixins: [confetti, toTemplate],

    props: {
        show: {
            type: Boolean,
            default: false
        },
        needsProvisioning: {
            type: Boolean,
            default: false
        },
        accountPhone: {
            type: String,
            default: null
        },
    },

    components: {
        FormInput,
        FormSelect,
        FormSelectOption,
        FormButton
    },


    data () {
        return {
            visible: false,
            activeStep: 'account_info',
            steps: ['basic_info', 'account_info', 'approval_request'],
            completedSteps: ['basic_info'],
            skippedSteps: [],

            // account info
            website: null,
            websiteErrors: [],
            phone: null,
            phoneErrors: [],
            userbase: null,
            subscribersPlans: [
                {
                    label: this.$t('appforms.extended_registration.content.account_info.subscribers_up_to', {num: this.numberToLocale(200)}),
                    value: '200',
                },
                {
                    label: this.$t('appforms.extended_registration.content.account_info.subscribers_up_to', {num: this.numberToLocale(5000)}),
                    value: '5000',
                },
                {
                    label: this.$t('appforms.extended_registration.content.account_info.subscribers_up_to', {num: this.numberToLocale(15000)}),
                    value: '15000',
                },
                {
                    label: this.$t('appforms.extended_registration.content.account_info.subscribers_up_to', {num: this.numberToLocale(25000)}),
                    value: '25000',
                },
                {
                    label: this.$t('appforms.extended_registration.content.account_info.subscribers_up_to', {num: this.numberToLocale(40000)}),
                    value: '40000',
                },
                {
                    label: this.$t('appforms.extended_registration.content.account_info.subscribers_more_than', {num: this.numberToLocale(40000)}),
                    value: 'over',
                },
            ],
            subscribersErrors: [],
            eshopPlatformOptions: {
                eshop: false,
                newsletter: false,
                agency: false
            },

            // ecommerce platform
            eshopPlatform: null,
            eshopErrors: [],
            eshopOptions: [
                {
                    label: this.$t('appforms.extended_registration.content.eshop_platform.options.shoptet'),
                    value: 'shoptet',
                    image: 'shoptetApi'
                },
                {
                    label: this.$t('appforms.extended_registration.content.eshop_platform.options.shopify'),
                    value: 'shopify',
                    image: 'shopify'
                },
                {
                    label: this.$t('appforms.extended_registration.content.eshop_platform.options.woocommerce'),
                    value: 'woocommerce',
                    image: 'woocommerce'
                },
                {
                    label: this.$t('appforms.extended_registration.content.eshop_platform.options.prestashop'),
                    value: 'prestashop',
                    image: 'prestashop'
                },
                {
                    label: this.$t('appforms.extended_registration.content.eshop_platform.options.magento'),
                    value: 'magento',
                    image: 'magento'
                },
                {
                    label: this.$t('appforms.extended_registration.content.eshop_platform.options.opencart'),
                    value: 'opencart',
                    image: 'opencart'
                }
            ],
            customEshop: false,
            customEshopValue: null,
            customEshopValueErrors: [],

            // approval request
            verificationOptions: [
                {
                    value: 'web',
                    label: this.$t('appforms.extended_registration.content.approval_request.options.web_option'),
                },
                {
                    value: 'non_web',
                    label: this.$t('appforms.extended_registration.content.approval_request.options.non_web_option'),
                },
            ],
            verificationOptionErrors: [],
            verificationOption: 'web',
            socialMedia: null,
            verificationInputErrors: [],
            verificationInformation: null,
            verificationInformationErrors: [],

            loading: false,
        }
    },

    watch: {
        website() {
            this.websiteErrors = []
            this.verificationInputErrors = []
        },
        phone() {
            this.phoneErrors = []
        },
        customEshopValue() {
            this.customEshopValueErrors = []
        },
        socialMedia() {
            this.verificationInputErrors = []
        },
        verificationInformation() {
            this.verificationInformationErrors = []
        },
        eshopPlatform(val) {
            if(val) {
                this.customEshop = false
            }
        },
        customEshop(val) {
            if(val) {
                this.eshopPlatform = null
            }
        }
    },

    created() {
        this.$options.tracker = new Tracker()
    },

    mounted() {
        if(this.show != null) {
            this.visible = JSON.parse(this.show)
        }

        if(this.accountPhone != null) {
            this.phone = this.accountPhone
        }
    },

    methods: {
        stepIsCompleted(step) {
            switch (step) {
            case 'account_info':
                return this.completedSteps.includes(step) && this.activeStep !== 'eshop_platform'
            default:
                return this.completedSteps.includes(step)
            }
        },
        stepIsInProgress(step) {
            switch (step) {
            case 'account_info':
                return this.activeStep === 'account_info' || this.activeStep === 'eshop_platform'
            default:
                return this.activeStep === step
            }
        },
        async nextStep() {
            // if user left some fields empty and account doesn't need provisioning => mark as skipped
            if(this.needsProvisioning || !this.hasEmptyFields()) {

                const valid = await this.validateStep()
                if (!valid) {
                    return
                }

                let index = this.skippedSteps.indexOf(this.activeStep)
                if(index !== -1) {
                    this.skippedSteps.splice(index, 1)
                }

                this.completedSteps.push(this.activeStep)

                if(this.needsProvisioning) {
                    this.$options.tracker.trackEvent('PROVISION_STEP_DONE', {
                        step: this.activeStep,
                    })
                } else {
                    this.$options.tracker.trackEvent('EXTENDED_REGISTRATION_STEP_DONE', {
                        step: this.activeStep,
                    })
                }

                this.showNextStep()
            }
            else
                this.skipStep()
        },

        showNextStep(skipped = false) {
            switch (this.activeStep) {
            case 'account_info':
                if(this.eshopPlatformOptions.eshop && !skipped)
                    this.activeStep = 'eshop_platform'
                else if(this.needsProvisioning)
                    this.activeStep = 'approval_request'
                else
                    this.save()
                break
            case 'eshop_platform':
                if(this.needsProvisioning)
                    this.activeStep = 'approval_request'
                else
                    this.save()
                break
            case 'approval_request':
                this.save()
            }
        },

        previousStep() {
            switch (this.activeStep) {
            case 'eshop_platform':
                this.activeStep = 'account_info'
                break
            case 'approval_request':
                if(this.eshopPlatformOptions.eshop  && this.skippedSteps.indexOf('account_info') === -1)
                    this.activeStep = 'eshop_platform'
                else
                    this.activeStep = 'account_info'
            }
        },

        skipStep() {
            if(this.skippedSteps.indexOf(this.activeStep) === -1) {
                this.skippedSteps.push(this.activeStep)
            }
            this.$options.tracker.trackEvent('EXTENDED_REGISTRATION_STEP_SKIPPED', {
                step: this.activeStep,
            })
            this.showNextStep(true)
        },

        async validateStep() {
            if(this.activeStep === 'account_info') {
                const websiteValidation = this.needsProvisioning ? await validate(this.website, 'required|website|no_path') : await validate(this.website, 'website|no_path')
                this.websiteErrors = websiteValidation.errors

                const phoneValidation = this.needsProvisioning ? await validate(this.phone, '') : await validate(this.phone, 'phone')
                this.phoneErrors = phoneValidation.errors

                const subscribersValidation = this.needsProvisioning ? await validate(this.userbase, 'required') : await validate(this.userbase, '')
                this.subscribersErrors = subscribersValidation.errors

                return websiteValidation.valid && phoneValidation.valid && subscribersValidation.valid
            }

            if(this.activeStep === 'eshop_platform') {
                if(this.customEshop) {
                    const eshopValidation = this.needsProvisioning ? await validate(this.customEshopValue, 'required') : await validate(this.customEshopValue, '')
                    this.customEshopValueErrors = eshopValidation.errors
                    return eshopValidation.valid
                }
                const eshopValidation = this.needsProvisioning ? await validate(this.eshopPlatform, 'required') : await validate(this.eshopPlatform, '')
                this.eshopErrors = eshopValidation.errors
                return eshopValidation.valid
            }

            if(this.activeStep === 'approval_request') {
                const verificationOptionValidation = await validate(this.verificationOption, 'required')
                this.verificationOptionErrors = verificationOptionValidation.errors

                const verificationInputValidation = this.verificationOption === 'web' ? await validate(this.website, 'required|website|no_path') : await validate(this.socialMedia, 'required|website')
                this.verificationInputErrors = verificationInputValidation.errors

                const verificationInformationValidation = await validate(this.verificationInformation, 'required')
                this.verificationInformationErrors = verificationInformationValidation.errors

                return verificationOptionValidation.valid && verificationInputValidation.valid && verificationInformationValidation.valid
            }

            return false
        },

        hasEmptyFields() {
            if(this.activeStep === 'account_info')
                return !this.website || !this.phone || !this.userbase
            else if(this.activeStep === 'eshop_platform')
                return !this.customEshopValue && !this.eshopPlatform
            else
                true
        },

        closeModal() {
            this.visible = false
            this.$emit('closed')
        },

        async save() {
            this.sent = true

            let data = {
                skipped_steps: this.skippedSteps,
            }

            if(this.needsProvisioning) {
                this.$options.tracker.trackEvent('PROVISION_SENT')
            } else {
                this.$options.tracker.trackEvent('EXTENDED_REGISTRATION_DONE')
            }

            let tool_for = []
            for (let option in this.eshopPlatformOptions) {
                if(this.eshopPlatformOptions[option] === true) {
                    tool_for.push(option)
                }
            }
            data = {...data, ...{
                phone: this.phone,
                userbase: this.userbase,
                tool_for: tool_for,
                website: this.website,
            }}

            if(this.eshopPlatformOptions.eshop) {
                data = {...data, ...{
                    eshop_platform: this.eshopPlatform,
                }}

                if(this.eshopPlatform === 'custom') {
                    data = {...data, ...{
                        custom_eshop_platform: this.customEshopValue,
                    }}
                }
            }

            data = {...data, ...{
                verification_option: this.verificationOption,
                social_media: this.socialMedia,
                verification_information: this.verificationInformation,
            }}

            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                'registrationFormUserbase': data.userbase ? data.userbase: 'null',
                'registrationFormEcomailFor': data.tool_for ? data.tool_for: 'null',
                'registrationFormEshopPlatform': data.eshop_platform ? (data.eshop_platform === 'custom' ? `Custom - ${data.custom_eshop_platform}`: data.eshop_platform): 'null',
                'event': 'registrationConfirmed'
            })

            await this.$http.post('/signup/signup-props', data)

            window.location.href = '/?showOnboarding'
        }

    },

}
</script>

<style lang="scss" scoped>

    @import '~sass/variables';

    .register-modal-bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: grid;
        overflow: auto;
        z-index: 100;

        .register-modal-container {
            transition: all .3s ease 0s;
            width: 100%;
            height: 100%;
            display: flex;

            .register-modal-panel {
                display: none;
            }

            .register-modal-content {
                background-color: white;
                padding: 30px 30px 40px;
                display: flex;
                flex-direction: column;

                .purpose-shadow  {
                    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
                }
            }
        }
    }

     @media (min-width: 768px) {
        .register-modal-bg {
            background-color: rgba(15, 17, 32, 0.35);
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: grid;
            overflow: auto;
            z-index: 100;
            backdrop-filter: blur(2.5px) grayscale(50%);

            .register-modal-container {
                border-radius: 6px;
                margin: auto;
                box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
                position: relative;
                transition: all .3s ease 0s;
                width: 875px;
                height: auto;
                min-height: 560px;
                display: flex;

                .register-modal-panel {
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                    width: 340px;
                    background: url('/images/envelope-bg.png') #323232;
                    background-size: contain;
                    background-repeat: no-repeat;
                    padding: 32px;
                    display: flex;
                    flex-direction: column;
                    color: white;

                    .register-modal-logo {
                        width: 36px;
                    }
                }

                .register-modal-content {
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                    width: 535px;
                    background-color: white;
                    padding: 30px 40px 36px;
                    display: flex;
                    flex-direction: column;

                    .purpose-shadow  {
                        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
                    }
                }
            }
        }
     }

    .tw-checkbox-solid label::before {
        border: 1px solid #D7D7D7;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    }
    .tw-checkbox-solid label::after {
        background-color: transparent;
    }

    .tw-checkbox-solid input[type="checkbox"]:checked + label::before {
        background-color: #44A600;
        border-color: #44A600;
        box-shadow: none;
        }

    .panel.border-green {
        margin: -1px;
        border-width: 2px;
        border-color: #47AD00;
    }
</style>