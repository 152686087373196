import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const initialState = () => {
    return {
        'selectedFields': {},
        'usedMerges': [],
        'customFields': {},
        'newFieldTypes': {},
        'newFieldNames': {},
        'newFieldMerges': {},
    }
}

export default new Vuex.Store({

    state: {
        initialState,

        'selectedFields': {},
        'usedMerges': [],
        'usedMergeNames': [],
        'customFields': {},
        'newFieldTypes': {},
        'newFieldNames': {},
        'newFieldMerges': {},
    },

    mutations: {
        RESET_DATA (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => {
                state[key] = initial[key]
            })
        },
        SET_CUSTOM_FIELDS (state, fields) {
            state.customFields = fields
            for (let key in fields) {
                state.usedMergeNames.push(fields[key].name.toLowerCase())
                state.usedMerges.push(key.toLowerCase())
            }
        },
        SET_BASE_TAGS (state, baseTags) {
            for (let key in baseTags) {
                state.usedMerges.push(baseTags[key].toLowerCase())
            }
        },
        ADD_FIELD (state, props) {
            state.newFieldTypes[props.index] = props.type
            state.newFieldNames[props.index] = props.name
            state.newFieldMerges[props.index] = props.merge
        },
        REMOVE_FIELD (state, index) {
            delete state.newFieldTypes[index]
            delete state.newFieldNames[index]
            delete state.newFieldMerges[index]
        },
        SET_FIELD_PROP (state, props) {
            switch(props.prop) {
            case 'type':
                state.newFieldTypes[props.index] = props.val
                break
            case 'name':
                state.newFieldNames[props.index] = props.val
                break
            default:
                state.newFieldMerges[props.index] = props.val
            }
        },
        SELECT_FIELD (state, field) {
            state.selectedFields[field.index] = field.val
        },
        DESELECT_FIELD (state, index) {
            delete state.selectedFields[index]
        }
    },

    actions: {
        resetData({commit}) {
            commit('RESET_DATA')
        },
        setBaseTags ({commit}, baseTags) {
            commit('SET_BASE_TAGS', baseTags)
        },
        setCustomFields ({commit}, fields) {
            commit('SET_CUSTOM_FIELDS', fields)
        },
        addField ({commit}, props) {
            commit('ADD_FIELD', props)
        },
        removeField ({commit}, index) {
            commit('REMOVE_FIELD', index)
        },
        setFieldProp ({commit}, index) {
            commit('SET_FIELD_PROP', index)
        },
        selectField ({commit}, field) {
            commit('SELECT_FIELD', field)
        },
        deselectField ({commit}, index) {
            commit('DESELECT_FIELD', index)
        }
    }

})
