<template>
    <div>    

        <portal v-if="activated" to="chat-section-header">
            <span>{{ $t('chat.m-dashboard') }}</span>
        </portal>

        <section class="grid md:grid-cols-3 xl:grid-cols-5 gap-3 -mx-3">

            <div class="w-full p-5 hover-box">

                <div class="flex space-x-4">
                    <div class="bg-gray-200 flex-shrink-0 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                        <icon class="text-sail-50" icon="inbox-solid" size="large" />
                    </div>
                    <div class="flex flex-col">
                        <span class="txt-13 semibold text-gray-800 lh-15 whitespace-nowrap">{{ $t('chat.stats.total_tickets') }}</span>
                        <span class="txt-35 lh-37 bold text-gray-1000">{{ numberToLocale(1000) }}</span>
                    </div>
                </div>

            </div>

            <div class="w-full p-5 hover-box">

                <div class="flex space-x-4">
                    <div class="bg-gray-200 flex-shrink-0 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                        <icon icon="check-solid" class="text-sail-500" size="large" />
                    </div>
                    <div class="flex flex-col">
                        <span class="txt-13 semibold text-gray-800 lh-15 whitespace-nowrap">{{ $t('chat.stats.tickets_resolved') }}</span>
                        <span class="txt-35 lh-37 bold text-gray-1000">{{ numberToLocale(1000) }}</span>
                    </div>
                </div>

            </div>

            <div class="w-full p-5 hover-box">

                <div class="flex space-x-4">
                    <div class="bg-gray-200 flex-shrink-0 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                        <icon icon="paper-plane-solid" class="text-sail-500" size="large" />
                    </div>
                    <div class="flex flex-col">
                        <span class="txt-13 semibold text-gray-800 lh-15 whitespace-nowrap">{{ $t('chat.stats.messages_sent') }}</span>
                        <span class="txt-35 lh-37 bold text-gray-1000">{{ numberToLocale(1000) }}</span>
                    </div>
                </div>

            </div>

            <div class="w-full p-5 hover-box">

                <div class="flex space-x-4">
                    <div class="bg-gray-200 flex-shrink-0 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                        <icon icon="paper-plane-solid" class="text-sail-500" size="large" />
                    </div>
                    <div class="flex flex-col">
                        <span class="txt-13 semibold text-gray-800 lh-15 whitespace-nowrap">{{ $t('chat.stats.avg_response_time') }}</span>
                        <span class="txt-35 lh-37 bold text-gray-1000">{{ numberToLocale(1000) }}</span>
                    </div>
                </div>

            </div>

            <div class="w-full p-5 hover-box">

                <div class="flex space-x-4">
                    <div class="bg-gray-200 flex-shrink-0 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                        <icon icon="paper-plane-solid" class="text-sail-500" size="large" />
                    </div>
                    <div class="flex flex-col">
                        <span class="txt-13 semibold text-gray-800 lh-15 whitespace-nowrap">{{ $t('chat.stats.avg_resolution_time') }}</span>
                        <span class="txt-35 lh-37 bold text-gray-1000">{{ numberToLocale(1000) }}</span>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
import toTemplate from '@mixins/toTemplate'

export default {
    name: 'dashboard',
  
    mixins: [toTemplate],

    data() {
        return {
            activated: true
        }
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },
}
</script>
