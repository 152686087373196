
<div>
    <portal v-if="activated" to="contacts-section-header">
        <div class="flex flex-grow items-center justify-between">
            <div class="flex items-center space-x-5">
                <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                    {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                </router-link>
                <div class=" hidden lg:block">
                    <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                </div>
                <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.import_subscribers.title') }}</span>
            </div>
            <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                <icon class="mr-1" icon="caret-left-solid" />
                <span class="txt-12">{{ $t('common.back') }}</span>
            </router-link>
        </div>
    </portal>

    <div class="pt-2 px-0 md:px-20">

        <div v-if="consentRequired">
            <alert v-if="message && success === false" class="mb-6" type="error" :message="message" />

            <alert
                    type="info"
                    :message="$t('contacts.import_subscribers.consent')" />

            <div class="flex justify-between mt-8">
                <button class="btn btn-danger btn-lg" @click="goBack">{{ $t('common.cancel') }}</button>
                <button :disabled="sending" class="btn btn-primary btn-lg" @click="sendImport"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.import_subscribers.continue') }}</button>
            </div>
        </div>

        <ValidationObserver v-else ref="observer" slim>

            <import-progress-bar :account-name="account.name" :list-name="list.name" />

            <tab-row :value="type" class="mb-4" :options="['stretch']" @input="changeTab">
                <template #tab="slotProps">
                    <tab-item v-for="(tab, index) in tabs" :key="`recipient-group-option-${index}`" :value="tab.code" :title="tab.text" :icon="{name: tab.icon, size: 'small'}" :selected="type" :trigger-select="slotProps.triggerSelect" :options="slotProps.options" />
                </template>
            </tab-row>

            <p class="txt-14 text-gray-900 mt-6">
                {{ $t('appforms.contacts.import.p_' + type) }}
            </p>

            <alert
                    v-if="assistedImportSuccessful"
                    class="mt-6"
                    type="success"
                    :message="$t('contacts.import_subscribers.assisted_import_successful')" />

            <alert
                    v-if="type !== 'assisted'"
                    type="info"
                    class="mt-6"
                    :label="$t('appforms.contacts.import.callout')"
                    :message="$t('appforms.contacts.import.callout2') + '<br>' + $t('appforms.contacts.import.callout_list')"
                    :primary-action="account.isEnglishAccount ? null : {text: $t('appforms.contacts.import.callout_action'), url: 'https://support.ecomail.cz/cs/articles/2492259-jak-upravim-chybnou-diakritiku-u-kontaktu'}" />


            <div v-if="type === 'paste' || type === 'excel'" class="mt-6">
                <ValidationProvider v-slot="{ errors }" name="importData" rules="required|max:150000" slim>
                    <textarea id="importData" v-model="importText" :name="type === 'paste' ? 'paste' : 'excelData'" rows="6" class="tw-input w-full resize-none" :placeholder="$t('appforms.contacts.import.ph_' + type)" />
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div v-else class="mt-6">
                <ValidationProvider v-slot="{ errors }" name="file_url" :rules="{required: true, csv_format: type === 'csv'}" slim>
                    <upload id="csvUpload" name="file_json" bucket="ecomail-imports" @changed="changedFile" />
                    <input type="hidden" name="file_url" :value="importFile">
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div v-if="type === 'assisted'" class="mt-4 flex flex-col">
                <label for="importNotes" class="txt-14 font-semibold text-gray-1000 mb-2.5">{{ $t('appforms.contacts.import.note') }}</label>
                <textarea id="importNotes" v-model="importNote" name="note" rows="3" class="tw-input w-full resize-none" :placeholder="$t('appforms.contacts.import.ph_note')" />
            </div>

            <div class="flex flex-row-reverse mt-8">
                <button :disabled="emptyInput" class="btn btn-primary btn-lg" @click="continueToConsent">{{ $t('common.continue') }}</button>
            </div>

            <div v-if="showVerifyPrompt" class="panel p-4 flex w-full space-x-4" style="margin-top: 50px">
                <icon icon="lightbulb-on-solid" size="large" class="text-green-600" />
                <div class="text-gray-900 txt-14" v-html="$t('appforms.contacts.import.verify_emails_prompt')" />
                <a href="#" @click.prevent="hideVerifyPrompt"><icon icon="times-solid" size="small" class="text-gray-800 ml-auto" /></a>
            </div>

        </ValidationObserver>
    </div>
</div>
