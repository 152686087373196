<template>
    <ValidationObserver ref="observer" slim>
        <form ref="addMessengerDomain" novalidate class="flex flex-col" @submit.prevent="addDomain">

            <alert v-if="success === true" class="mb-4" type="success" :message="message" />

            <alert v-if="success === false" class="mb-4" type="error" :message="message" />

            <ValidationProvider v-slot="{ errors }" name="domainName" rules="required|max:255" slim>
                <div class="input flex flex-col">
                    <label for="domainName" class="tw-label mb-2.5">{{ $t('contacts.facebook_lead.modal.label') }}</label>
                    <input id="domainName" v-model="newDomain" type="text" name="domainName" class="tw-input" :placeholder="$t('contacts.facebook_lead.modal.placeholder')" :class="{'tw-err': errors.length > 0 }">
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div v-if="addedDomains && addedDomains.length" class="delete-list-frame flex flex-col mt-6 space-y-2.5">
                <h5 class="text-gray-1000">
                    {{ $t('contacts.facebook_lead.modal.added_domains_title') }}
                </h5>
                <ul class="list-inside list-disc space-y-2">
                    <li v-for="(domain, index) in addedDomains" :key="`domain-${index}`" class="text-gray-900 font-semibold txt-14">
                        <span class="ml-3">{{ domain }}</span>
                    </li>
                </ul>
            </div>

            <div class="tw-modal-buttons">
                <button type="button" class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('common.cancel') }}</button>
                <button :disabled="sending" class="btn btn-primary float-right btn-lg"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.facebook_lead.modal.button') }}</button>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
export default {
    name: 'MessengerDomainsForm',

    props: ['modal'],

    data() {
        return {
            success: null,
            message: null,
            sending: false,
            addedDomains: null,
            newDomain: ''
        }
    },

    computed: {
        listId() {
            return this.modal.mdata.listId
        },
        pageId() {
            return this.modal.mdata.pageId
        }
    },

    mounted() {
        this.loadWhitelistedDomains()
    },

    methods: {
        closeModal() {
            this.modal.mclose()
        },
        async loadWhitelistedDomains() {
            try {
                const response = await this.$http.get(`/contacts/${this.listId}/facebook/whitelisted-domains/${this.pageId}`)
                this.addedDomains = response.data.whitelisted_domains
            }
            catch(error) {
                this.$message.error(error.message)
            }
        },
        async addDomain() {
            this.success = null
            this.message = null

            const isValid = await this.$refs.observer.validate()
            if(isValid) {
                this.sending = true
                try {
                    await this.$http.post(`/contacts/${this.listId}/facebook/whitelist-domain`, {
                        page: this.pageId,
                        domain: this.newDomain
                    })
                    this.success = true
                    this.message = this.$t('contacts.facebook_lead.modal.domain_added_alert', {domain: this.newDomain})
                    this.newDomain = ''
                    this.loadWhitelistedDomains()
                }
                catch(error) {
                    this.success = false
                    this.message = error.message
                }
                this.sending = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "~sass/variables";

    .delete-list-frame {
        padding: 13px 18px 18px 18px;
        border: 1px solid $grey-50;
        box-sizing: border-box;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
    }

    ::marker {
        color: $grey-65;
    }
</style>
