<template>
    <div class="mb-5" @keyup.enter="signIn">
        <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]">
            {{ $t('auth.join.create_account') }}
        </div>

        <div class="text-auth-base text-auth-gray-500 text-center">
            {{ $t('auth.join.your_email') }}<br><span class="semibold text-auth-gray-900">{{ email }}</span>
        </div>

        <hr class="mt-5 mb-6">

        <FormInput v-model="password" :errors="passwordErrors" :autofocus="true" class="w-full mt-5" type="password" :label="$t('auth.login.password.label')" required :placeholder="$t('auth.join.choose_password')" />
        <FormCheckbox v-model="constentGiven" class="mt-6" :label="$t('auth.join.consent_label')" />
        <div class="text-auth-xs ml-[32px] mt-1 text-auth-gray-700" v-html="$t('auth.join.consent_description')" />

        <FormButton type="button" :loading="loading" :disabled="!constentGiven" xlarge primary square class="mt-8 w-full" @click="signIn">
            <span class="text-auth-base">{{ $t('auth.login.sign_in_cta') }}</span>
        </FormButton>

    </div>
</template>

<script>
import FormInput from '@component/Auth/FormInput.vue'
import FormCheckbox from '@component/Auth/FormCheckbox.vue'
import FormButton from '@component/Auth/FormButton.vue'
import hasher from '@mixins/hasher'
import {validate} from 'vee-validate'

export default {
    name: 'AuthLayout',

    mixins: [hasher],

    components: {
        FormInput,
        FormCheckbox,
        FormButton,
    },

    data() {
        return {
            email: null,
            sec: null,
            password: null,
            constentGiven: false,
            loading: false,
            passwordErrors: [],
            requestError: null,
        }
    },

    computed: {
        accountUrl() {
            return window.location.hostname
        }
    },

    created() {
        let params = new URLSearchParams(window.location.search.replace('&%3B', '&'))

        if(params.get('email') && params.get('sec')) {
            this.email = params.get('email')
            this.sec = params.get('sec')
        } else {
            window.location.href = '/auth/login'
        }
    },

    methods: {
        async signIn() {
            try {
                const valid = await this.validateForm()
                if(!valid)
                    return

                this.loading = true
                const response = await this.$http.post('/public/join/set-password', {
                    email: this.email,
                    sec: this.sec,
                    password: this.hashLoginPassword(this.password),
                })
                if(response.status === 204)
                    window.location.href = '/'
                this.loading = false
            } catch(e) {
                this.requestError = e.message
                this.loading = false
            }
        },
        async validateForm() {
            const passwordValidation = await validate(this.password, 'required|min:6')
            this.passwordErrors = passwordValidation.errors

            return passwordValidation.valid
        }
    }
}
</script>

<style scoped lang="scss">

</style>