
<a
        v-if="!search || value.includes(search)"
        v-tooltip.top="{content: label, 'trigger': isTruncated(label) ? 'hover' : 'manual', autoHide: false}"
        class="ecomail-select-item text-auth-gray-700 hover:text-auth-gray-700 hover:bg-auth-gray-50"
        @click.prevent="triggerSelect(value)">
    <span class="flex items-center gap-2" :class="{'ecomail-select-item-group-title': hasChildren}">
        <slot name="icon" />
        {{ formatDisplayAttribute(label) }}
    </span>
    <slot name="meta" class="ml-2 ecomail-select-item-meta" />
    <slot />
</a>
