

<transition name="slidein">
    <div v-if="visible" class="tw-modal-bg" @mousedown.self="closeModal" @keyup="keyUp">

        <div class="tw-modal-container pt-4 px-6 pb-16" :style="{width: width ? `${width}px`: ''}">
            <div class="flex items-end justify-end">
                <a href="#" class="tw-modal-close bg-gray-500 hover:bg-gray-600 hover:text-gray-1000 rounded-full" @click.stop.prevent="closeModal"><div class="svg-wrapper"><svg-vue icon="times-solid" class="text-gray-900" /></div></a>
            </div>

            <div class="">
                <slot :mclose="closeModal" />
            </div>

        </div>

    </div>
</transition>

