
<div v-if="pages.length || nextPage">
    <ul class="ecm-pagination">
        <li class="page-items-container">
            <div v-for="(page, index) in pages" :key="`page-${page}`" class="page-item" :class="{'active': page === currentPage}">
                <a href="#" @click.prevent="changePage(page)">{{ index + 1 }}</a>
            </div>
        </li>

        <li>
            <button class="arrow-button right-arrow" :disabled="!nextPageToken" @click.prevent="nextPage(nextPageToken)"><icon key="right" icon="angle-right-solid" /></button>
        </li>
    </ul>
</div>
