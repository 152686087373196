

<div ref="facebookPanel" class="panel">
    <div v-if="message && success !== null" class="facebook-alert">
        <alert :type="success === true ? 'success' : 'error'" :message="message" />
    </div>
    
    <div v-if="visiblePages" class="flex flex-col panel panel-white pages-panel m-5">
        <template v-if="visiblePages.length">
            <span class="txt-14 font-semibold mb-4">{{ $t('contacts.facebook_lead.select_page_label') }}</span>
            <div class="w-full overflow-x-auto md:overflow-x-visible">
                <div class="table tw-table tw-table-large max-w-full">
                    <div v-for="(page, index) in visiblePages" :key="`facebook-page-${index}`" class="table-row ">
                        <div class="table-cell align-middle truncate w-full" style="max-width: 0px;">
                            <div class="flex items-center space-x-4 group">
                                <a href="#" class="txt-14 link-blue semibold" @click.prevent="connectPage(page)">{{ page.name }} ({{ page.id }})</a>
                            </div>
                        </div>
                        <div class="table-cell align-middle text-right">
                            <button class="btn btn-tertiary icon-left" @click="connectPage(page)"><div v-if="connecting === page.id" class="double-loader loader-sm loader-grey mr-2" /><icon v-else class="text-gray-900" icon="plug-solid" />{{ $t('contacts.facebook_lead.select_page_button') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else>
            <div class="txt-14 text-gray-900">{{ $t('contacts.facebook_lead.no_pages') }}</div>
        </div>
        <button class="btn btn-lg btn-primary w-min mt-7.5" @click="closePages">{{ $t('common.cancel') }}</button>
    </div>

    <div v-else class="m-9">
        <a href="#" class="btn btn-lg btn-fb with-img" @click.stop.prevent="connectFb"><icon icon="facebook-square-brands" size="large" class="mr-3" /> {{ $t('facebook.connect') }}</a>
        <p v-if="$te('contacts.facebook_lead.facebook_help')" class="txt-14 text-gray-900 mt-5" v-html="$t('contacts.facebook_lead.facebook_help')" />
    </div>
</div>

