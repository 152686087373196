
<div>    

    <portal v-if="activated" to="chat-section-header">
        <span>{{ $t('chat.m-bots') }}</span>
    </portal>

    <div class="w-full overflow-x-auto md:overflow-x-visible">
        <table class="tw-table mb-40">
            <tr v-for="chatbot in chatbots">
                <td style="width: 1%;">
                    <span class="pill pill-success">{{ $t('chat.bots.active') }}</span>
                </td>
                <td>
                    <a href="" class="link-blue txt-14 semibold" style="display: none">{{ chatbot.name }}</a>
                    <router-link :to="`/chat/chatbots/${chatbot.bot_id}`" class="txt-14 semibold text-gray-800 group-hover:text-gray-900 hover:no-underline">
                        {{ chatbot.name }}
                    </router-link>
                </td>
                <td class="txt-13 dimmed80 w-1/12 whitespace-nowrap text-right">
                    <strong class="txt-16 bold grey-100">x</strong>
                    y
                </td>
                <td class="txt-13 dimmed80 w-1/12 whitespace-nowrap text-right">
                    <strong class="txt-16 bold grey-100">x</strong>
                    y
                </td>
                <td class="text-right w-1/12 whitespace-nowrap">
                    <div class="tw-dropdown" v-dropdown="'open'">
                        <div class="btn-group"><a href="" class="btn btn-tertiary whitespace-nowrap">{{ $t('chat.bots.overview') }}</a><button class="btn btn-tertiary icon-only" dropdown-toggle></button></div>
                        <div class="tw-dropdown-items flex-col">
                            <a href="#">{{ $t('chat.bots.edit') }}</a>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
