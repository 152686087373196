// App basics
import navbar from '@component/Navbar/Navbar.vue'
import twDropDown from '@component/DropDown/DropDown.vue'
import userMenuComponent from '@component/UserMenu/UserMenu.vue'
import notificationsMenuComponent from '@component/Notifications/NotificationsMenu.vue'
import notifications from '@component/Notifications/Notifications.vue'
import alert from '@component/Alerts/Alert.vue'
import globalMessages from '@component/Alerts/GlobalMessages.vue'
import textInput from './components/Inputs/TextInput.vue'
import keyInput from '@component/Inputs/KeyInput.vue'
import checkbox from '@component/Inputs/Checkbox.vue'
import codeInput from '@component/Inputs/CodeInput.vue'
import tabs from '@component/Storybook/Navigation/Tabs.vue'
import dropdown from './directives/dropdown'
import accordion from './directives/accordion'
import tabbed from './directives/ec-tabs'
import scrollableTable from './directives/scrollable-table'
import folders from '@component/Folders/Folders.vue'
import moveToFolder from '@component/Folders/MoveToFolder.vue'
import inlineEdit from '@component/Inputs/InlineEdit.vue'
import campaignsSelector from '@component/Tables/CampaignsSelector.vue'
import icon from '@component/Icons/Icon.vue'
import pagination from '@component/Pagination/Pagination.vue'
import simplePagination from '@component/Pagination/SimplePagination.vue'
import dynamicPagination from '@component/Pagination/DynamicPagination.vue'

// Contacts
import createListWarning from '@component/Contacts/CreateListWarning.vue'
import deleteListWarning from '@component/Contacts/DeleteListWarning.vue'

// Forms
import listSettings from '@component/AppForms/Lists/ListSettings.vue'
import optinSettings from '@component/AppForms/Lists/OptinSettings.vue'
import optoutSettings from '@component/AppForms/Lists/OptoutSettings.vue'
import notificationsSettings from '@component/AppForms/Lists/NotificationsSettings.vue'
import translationSettings from '@component/AppForms/Lists/TranslationSettings.vue'
import webhookSettings from '@component/AppForms/Lists/WebhooksSettings.vue'
import facebookConnect from '@component/AppForms/Lists/FacebookConnect.vue'
import messengerDomainsForm from '@component/AppForms/Facebook/MessengerDomainsForm.vue'
import addSegmentForm from '@component/AppForms/Lists/AddSegmentForm.vue'
import customFieldForm from '@component/AppForms/Lists/CustomFieldForm.vue'
import newForm from '@component/AppForms/Forms/NewForm.vue'
import newCampaign from '@component/AppForms/Campaigns/NewCampaign.vue'
import renameCampaign from '@component/AppForms/Campaigns/RenameCampaign.vue'
import editAutomation from '@component/AppForms/Pipelines/EditAutomation.vue'
import newAutomation from '@component/AppForms/Pipelines/NewAutomation.vue'
import duplicateAutomationForm from '@component/AppForms/Pipelines/DuplicateAutomationForm.vue'
import tailorMadeAutomationForm from '@component/AppForms/Pipelines/TailorMadeAutomationForm.vue'
import scenarioPreview from '@component/AppForms/Pipelines/ScenarioPreview.vue'
import newTemplate from '@component/Templates/NewTemplate.vue'
import subscriberForm from '@component/AppForms/Subscriber/SubscriberForm.vue'
import addContactForm from '@component/AppForms/Subscriber/AddContactForm.vue'
import ChooseMjml from '@component/Templates/ChooseMjml.vue'
import ecmSelect from '@component/Inputs/EcmSelect.vue'
import ecmAutocomplete from '@component/Inputs/EcmAutocomplete.vue'

// Cashier
import MarketerUpgrade from '@component/AppForms/Cashier/MarketerUpgrade.vue'

// Modals
import basicModal from '@component/Modal/BasicModal.vue'
import twModal from '@component/Modal/TwModal.vue'
import supportModal from '@component/Modal/SupportModal.vue'
import twConfirm from '@component/Modal/TwConfirm.vue'
import searchModal from '@component/Modal/SearchModal.vue'
import extendedRegistrationModal from '@component/Modal/ExtendedRegistrationModal.vue'
import ImportFormModal from '@component/Modal/ImportFormModal.vue'

// Error page
import errorPage from '@pages/errors/errorPage.vue'


const GlobalComponents = {
    install (Vue) {
        Vue.component('navbar', navbar)
        Vue.component('tw-dropdown', twDropDown)
        Vue.component('ecm-select', ecmSelect)
        Vue.component('ecm-autocomplete', ecmAutocomplete)
        Vue.component('user-menu', userMenuComponent)
        Vue.component('notifications-menu', notificationsMenuComponent)
        Vue.component('notifications', notifications)
        Vue.component('alert', alert)
        Vue.component('global-messages', globalMessages)
        Vue.component('text-input', textInput)
        Vue.component('key-input', keyInput)
        Vue.component('code-input', codeInput)
        Vue.component('ecm-switch', () => import('@component/Inputs/EcmSwitch.vue'))
        Vue.component('tabs', tabs)
        Vue.directive('dropdown', dropdown)
        Vue.directive('accordion', accordion)
        Vue.directive('tabbed', tabbed)
        Vue.directive('scrollable-table', scrollableTable)
        Vue.component('folders', folders)
        Vue.component('move-to-folder', moveToFolder)
        Vue.component('inline-edit', inlineEdit)
        Vue.component('campaigns-selector', campaignsSelector)
        Vue.component('icon', icon)
        Vue.component('pagination', pagination)
        Vue.component('simple-pagination', simplePagination)
        Vue.component('dynamic-pagination', dynamicPagination)

        Vue.component('create-list-warning', createListWarning)
        Vue.component('delete-list-warning', deleteListWarning)

        Vue.component('list-settings', listSettings)
        Vue.component('optin-settings', optinSettings)
        Vue.component('optout-settings', optoutSettings)
        Vue.component('notifications-settings', notificationsSettings)
        Vue.component('translation-settings', translationSettings)
        Vue.component('webhook-settings', webhookSettings)
        Vue.component('facebook-connect', facebookConnect)
        Vue.component('messenger-domains-form', messengerDomainsForm)
        Vue.component('add-segment-form', addSegmentForm)
        Vue.component('custom-field-form', customFieldForm)
        Vue.component('new-form', newForm)
        Vue.component('new-campaign', newCampaign)
        Vue.component('rename-campaign', renameCampaign)
        Vue.component('edit-automation', editAutomation)
        Vue.component('new-automation', newAutomation)
        Vue.component('duplicate-automation-form', duplicateAutomationForm)
        Vue.component('tailor-made-automation-form', tailorMadeAutomationForm)
        Vue.component('scenario-preview', scenarioPreview)
        Vue.component('new-template', newTemplate)
        Vue.component('subscriber-form', subscriberForm)
        Vue.component('add-contact-form', addContactForm)
        Vue.component('choose-mjml', ChooseMjml)

        Vue.component('marketer-upgrade', MarketerUpgrade)

        Vue.component('basic-modal', basicModal)
        Vue.component('tw-modal', twModal)
        Vue.component('support-modal', supportModal)
        Vue.component('tw-confirm', twConfirm)
        Vue.component('search-modal', searchModal)
        Vue.component('extended-registration-modal', extendedRegistrationModal)
        Vue.component('checkbox', checkbox)
        Vue.component('import-form-modal', ImportFormModal)

        Vue.component('error-page', errorPage)
    }
}

export default GlobalComponents
