<template>

    <ValidationObserver ref="observer" slim>

        <form novalidate class="flex flex-col" method="post" @submit.prevent="saveSettings">

            <alert v-if="success === true" class="mt-4 mb-10" type="success" :message="message" />

            <alert v-if="success === false" class="mt-4 mb-10" type="error" :message="message" />

            <div class="settings flex flex-col card-left no-hover card-white" :class="{card: doubleOptin, 'mt-5': !doubleOptin && !message}">
                <ecm-switch v-model="doubleOptin">
                    {{ $t('list_settings.double_optin_label') }}
                </ecm-switch>
                <alert v-if="fromEmailDomainNotValidated" type="error" :message="$t('list_settings.optin_domain_not_validated', {domain})" class="mt-4" />

                <div v-if="doubleOptin" class="w-full mt-3 pl-switch flex flex-col">

                    <ValidationProvider v-slot="{ errors }" name="subSubject" rules="required" slim>
                        <div class="input w-full flex flex-col">
                            <label for="subSubject" class="tw-label mb-2.5">{{ $t('list_settings.double_optin_subject_label') }}</label>
                            <input id="subSubject" v-model="subSubject" :disabled="fromEmailDomainNotValidated" name="subSubject" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.double_optin_subject_placeholder')">
                            <span class="mt-1 text-gray-900 txt-12">{{ $t('list_settings.double_optin_subject_help') }}</span>
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <div class="input flex flex-col mt-3">
                        <label class="tw-label mb-5">{{ $t('list_settings.double_optin_type_label') }}</label>
                        <div class="flex flex-col">
                            <div class="tw-radio mb-4">
                                <label for="html">
                                    <input id="html" v-model="doubleOptinType" :disabled="fromEmailDomainNotValidated" type="radio" name="doubleOptinType" value="html">
                                    <span class="radiomark mr-3" />
                                    <span>{{ $t('list_settings.double_optin_type_html') }}</span>
                                </label>
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <div class="tw-radio mb-4">
                                <label for="plaintext">
                                    <input id="plaintext" v-model="doubleOptinType" :disabled="fromEmailDomainNotValidated" type="radio" name="doubleOptinType" value="plaintext">
                                    <span class="radiomark mr-3" />
                                    <span>{{ $t('list_settings.double_optin_type_plain') }}</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div :class="{hidden: doubleOptinType !== 'html'}">
                        <ValidationProvider v-slot="{ errors }" name="confTemplate" :rules="`${doubleOptinType === 'html' ? 'required' : ''}`" slim>
                            <div class="input flex flex-col mb-8">
                                <ecm-select 
                                        id="confTemplate"
                                        v-model="confTemplate"
                                        class="w-full"
                                        :options="templates"
                                        option-value-attribute="id"
                                        option-display-attribute="name"
                                        :disabled="fromEmailDomainNotValidated"
                                        :title="isLoading ? $t('automations.templates_loading') : $t('automations.choose_template')" />
                                <span class="mt-1 text-gray-900 txt-12" v-html="$t('list_settings.double_optin_type_help')" />
                                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div :class="{hidden: doubleOptinType === 'html'}">
                        <ValidationProvider v-slot="{ errors }" name="doubleOptinPlain" :rules="`${doubleOptinPlain === 'plaintext' ? 'required' : ''}`" slim>
                            <div class="input flex flex-col mb-8">
                                <textarea id="doubleOptinPlain" v-model="doubleOptinPlain" :disabled="fromEmailDomainNotValidated" name="conf_message" class="tw-input" style="min-height: 260px;" />
                                <span class="mt-1 text-gray-900 txt-12" v-html="$t('list_settings.double_optin_type_help')" />
                                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>

                    <checkbox v-model="subRedirect" :disabled="fromEmailDomainNotValidated">
                        {{ $t('list_settings.optin_redirect_label') }}
                    </checkbox>

                    <div v-if="subRedirect" class="w-full mt-3 pl-checkbox flex flex-col">

                        <ValidationProvider v-slot="{ errors }" name="subPage" rules="required|url" slim>
                            <div class="input w-full flex flex-col">
                                <span class="text-gray-900 txt-14 mb-4">{{ $t('list_settings.optin_redirect_info') }}</span>
                                <label for="subPage" class="tw-label mb-2.5 text-gray-700">{{ $t('list_settings.optin_redirect_label_input') }}</label>
                                <input id="subPage" v-model="subPage" :disabled="fromEmailDomainNotValidated" name="subPage" type="text" class="tw-input max-w-xs" placeholder="http://">
                                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                    </div>

                </div>
            </div>

            <div class="mt-8" />
            <div v-if="doubleOptin && subRedirectSuccess" class="mt-2.5" />

            <div class="settings flex flex-col card-left no-hover card-white" :class="{card: subRedirectSuccess}">
                <ecm-switch v-model="subRedirectSuccess">
                    {{ $t('list_settings.register_redirect_label') }}
                </ecm-switch>

                <div v-if="subRedirectSuccess" class="w-full mt-3 pl-switch flex flex-col">

                    <ValidationProvider v-slot="{ errors }" name="subPage" rules="required|url" slim>
                        <div class="input w-full flex flex-col">
                            <span class="text-gray-900 txt-14 mb-4">{{ $t('list_settings.optin_success_redirect_info') }}</span>
                            <label for="subPageSuccess" class="tw-label mb-2.5">{{ $t('list_settings.optin_success_redirect_label_input') }}</label>
                            <input id="subPageSuccess" v-model="subPageSuccess" :class="{'tw-err': errors.length > 0}" name="subPageSuccess" type="text" class="tw-input max-w-xs" placeholder="http://">
                            <span class="mt-1 text-gray-900 txt-12" v-html="$t('list_settings.optin_redirect_help')" />
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                </div>
            </div>

            <div>
                <button
                        v-if="hasChanges"
                        v-tooltip.top="{content: fromEmailDomainNotValidated ? $t('list_settings.optin_domain_not_validated', {domain}) : undefined, autoHide: false}"
                        :disabled="fromEmailDomainNotValidated"
                        class="btn btn-lg btn-primary mt-8"
                        :class="{'ml-4': subRedirectSuccess}">
                    <div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />
                    {{ $t('list_settings.save_changes') }}
                </button>
            </div>

        </form>

    </ValidationObserver>

</template>

<script>
import {ValidationObserver} from 'vee-validate'
import Tracker from '@js/tracker'

export default {

    /**
     * @TODO preklady, refactor ty props?, call z formu redirect
     */

    name: 'OptinSettings',

    props: ['list', 'domains'],

    components: {
        ValidationObserver,
    },

    data() {
        return {
            sending: false,
            success: null,
            isLoading: true,
            message: '',
            doubleOptin: this.list.double_optin,
            subSubject: this.list.conf_subject,
            doubleOptinType: this.list.conf_template ? 'html' : 'plaintext',
            confTemplate: this.list.conf_template,
            doubleOptinPlain: this.list.conf_message,
            subRedirect: !!this.list.sub_confirmed_page,
            subRedirectSuccess: !!this.list.sub_success_page,
            subPageSuccess: this.list.sub_success_page,
            subPage: this.list.sub_confirmed_page,
            templates: null,
            unsubType: 'oneClick',
            unsubTarget: 'fromList',
            hasChanges: false
        }
    },

    computed: {
        fromEmailDomainNotValidated() {
            if(!this.doubleOptin) {
                return false
            }
            if(!this.list.from_email || !this.domains)
                return true
            return !this.domains.includes(this.domain)
        },
        domain() {
            return this.list.from_email.split('@')[1]
        }
    },

    watch: {
        doubleOptin() {
            this.hasChanges = true
        },
        subRedirect() {
            this.hasChanges = true
        },
        subRedirectSuccess() {
            this.hasChanges = true
        },
        subPage() {
            this.hasChanges = true
        },
        subPageSuccess() {
            this.hasChanges = true
        },
        doubleOptinType() {
            this.hasChanges = true
        },
        doubleOptinPlain() {
            this.hasChanges = true
        },
        confTemplate() {
            this.hasChanges = true
        },
        subSubject() {
            this.hasChanges = true
        },
    },

    created() {
        this.getTemplates()
    },

    methods: {
        async getTemplates() {
            const response = await this.$http.get('/spa/templates', {params: {'getAll': true}})
            this.templates = response.data.templates
            this.isLoading = false
        },
        
        async saveSettings(evt) {

            evt.preventDefault()
            this.success = null
            this.message = null
            const isValid = await this.$refs.observer.validate()

            if(isValid) {
                this.sending = true

                try {
                    await this.$http.post(`/spa/contacts/${this.list.id}/settings`, {
                        double_optin: this.doubleOptin ? 1 : 0,
                        conf_subject: this.subSubject,
                        conf_message: this.doubleOptinPlain,
                        conf_template: this.doubleOptinType !== 'plaintext' ? this.confTemplate : null,
                        sub_confirmed_page: this.subRedirect ? this.subPage !== '' ? this.subPage : null : null,
                        sub_success_page: this.subRedirectSuccess ? this.subPageSuccess !== '' ? this.subPageSuccess : null : null,
                    })
                    this.success = true
                    if (this.doubleOptin) {
                        new Tracker().trackEvent('BUTTON_CLICKED', {
                            scope: 'optin',
                            name: 'Contacts DOI activated'
                        })
                    }
                    this.message = this.$t('common.changes_saved')
                    this.hasChanges = false
                }
                catch(error) {
                    this.success = false
                    this.message = error
                }

                this.sending = false
            }
        }
    }
}
</script>
