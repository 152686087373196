
<div>
    <portal v-if="activated" to="contacts-section-header">
        <div class="flex flex-grow items-center justify-between">
            <div class="txt-18 text-gray-1000">
                {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
            </div>
            <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                <icon class="mr-1" icon="caret-left-solid" size="small" />
                <span class="txt-12">{{ $t('common.back') }}</span>
            </router-link>
        </div>
    </portal>

    <div class="flex flex-col h-full">
        <div v-if="!overviewLoaded" class="w-4 h-4 mt-4 double-loader" />

        <div v-else-if="listNotEmpty">
            <alert v-if="message" class="mb-4" :type="success ? 'success' : 'error'" :message="message" />

            <div class="flex flex-col-reverse md:flex-row justify-between mb-4">
                <div class="mt-5 md:mt-0 w-full md:w-auto">
                    <div v-dropdown="'open'" class="tw-dropdown">
                        <button class="btn no-focus bordered btn-secondary flex icon-right w-full md:w-auto" style="min-width: 220px;" dropdown-toggle>
                            <span class="flex-1 text-left">{{ $te(`contacts.overview.status.${currentStatus}`) ? $t(`contacts.overview.status.${currentStatus}`) : $t('contacts.overview.status.invalid_status') }}</span>
                            <span class="pill outline flex-none">{{ statusStats[currentStatus] }}</span>
                            <icon icon="caret-down-solid" size="small" />
                        </button>
                        <div class="tw-dropdown-items tw-dropdown-left flex flex-col overflow-hidden" style="min-width: 270px;">
                            <a v-for="(value, status, index) in statusStats" :key="`status-${index}`" href="#" class="flex justify-between items-center bg-white section-dropdown-item" @click.prevent="changeStatus(status)">
                                <span>{{ $t(`contacts.overview.status.${status}`) }}</span>
                                <span class="tw-lbl outline bg-white ml-5">{{ value }}</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="flex justify-between md:justify-start space-x-2">
                    <button class="btn btn-tertiary w-min" @click="addContact">{{ $t('contacts.overview.add_contact_button') }}</button>
                    <router-link v-if="user.role !== 'custom' || checkRole(user, 'manage-contacts-import')" class="btn btn-primary icon-left w-min" :to="{name: 'subscribersImport', props: {list: list.id}}">
                        <icon icon="plus-solid" size="small" /><span>{{ $t('contacts.overview.import_contacts_button') }}</span>
                    </router-link>
                </div>
            </div>

            <template v-if="statusStats[currentStatus] > 0">
                <div class="divider" />

                <div class="flex flex-col-reverse md:flex-row justify-between mt-4">
                    <div class="flex justify-between md:justify-start items-center space-x-2">
                        <div ref="displayOptions" v-dropdown="'open'" class="tw-dropdown">
                            <button class="btn btn-tertiary icon-both" type="button" dropdown-toggle>
                                <icon icon="columns-solid" size="small" />
                                {{ $t('contacts.overview.columns_select_button') }}
                                <icon icon="caret-down-solid" size="small" />
                            </button>
                            <div class="tw-dropdown-items tw-dropdown-left bg-grey-10 p-4 pt-2.5 flex flex-col z-40" style="min-width: 270px;" dropdown-stay>
                                <draggable v-model="displayOptions" v-bind="dragOptions" handle=".tab-handle">
                                    <div v-for="(label, index) in displayOptions" :key="`display-option-${index}`" class="flex items-center bb px-2 py-1.5 whitespace-nowrap justify-between">
                                        <div class="mr-2">
                                            <div class="tw-checkbox tw-checkbox-tiny">
                                                <input :id="label" type="checkbox" :name="label" :value="label" :checked="selectedDisplayOptions.includes(label)" @change="changeDisplayOptions">
                                                <label :for="label">{{ label.includes('c_fields') ? list.custom_fields[stripCustomField(label)].name : $t(`contacts.overview.columns.${label}`) }}</label>
                                            </div>
                                        </div>
                                        <icon icon="grip-vertical-solid" size="big" class="text-gray-600 cursor-move tab-handle" />
                                    </div>
                                </draggable>
                                <div class="flex justify-between mt-4">
                                    <button :disabled="columnsResetting" class="btn btn-text btn-text-p15" type="button" @click="saveDisplayOptions(true)"><div v-if="columnsResetting" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.overview.columns_reset_button') }}</button>
                                    <button :disabled="columnsSaving" class="btn btn-secondary" type="button" @click="saveDisplayOptions()"><div v-if="columnsSaving" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.overview.columns_save_button') }}</button>
                                </div>
                            </div>
                        </div>
                        <button :disabled="exportSending" class="btn btn-tertiary icon-left" @click="getExport">
                            <div v-if="exportSending" class="double-loader loader-sm loader-grey mr-2" />
                            <icon v-else icon="download-solid" size="small" />
                            {{ $t('contacts.overview.export_button') }}
                        </button>
                        <div v-dropdown="'open'" class="tw-dropdown">
                            <button class="btn btn-tertiary icon-right" type="button" :disabled="!selectedEmails.length" dropdown-toggle>
                                <span>{{ $t('contacts.overview.selected_contacts_button') }}</span>
                                <icon icon="caret-down-solid" size="small" />
                            </button>
                            <div class="tw-dropdown-items tw-dropdown-left bg-grey-10 flex flex-col" dropdown-stay>
                                <button v-if="currentStatus === 'active'" class="flex items-center" :disabled="massUnsubSending" @click="massUnsubscribe()"><div v-if="massUnsubSending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.overview.unsubscribe_button') }}</button>
                                <div v-if="lists && Object.keys(lists).length" v-dropdown="'open'" class="tw-dropdown">
                                    <button type="button" :disabled="movingToList" class="tw-dropdown tw-sub-dropdown-trigger-right" dropdown-toggle>
                                        <div v-if="movingToList" class="double-loader loader-sm loader-grey mr-2" />
                                        <span>{{ $t('contacts.overview.move_to_list_button') }}</span>
                                        <icon icon="caret-right-solid" class="text-gray-900" size="small" />
                                    </button>
                                    <div class="tw-sub-dropdown-items tw-sub-dropdown-right bg-grey-10 flex flex-col hov w-min">
                                        <button v-for="(listToMove, index) in lists" :key="`list-to-move-${index}`" @click="moveToList(listToMove)">{{ listToMove.name }}</button>
                                    </div>
                                </div>
                                <button v-else class="tw-dropdown tw-sub-dropdown-trigger-right cursor-not-allowed" disabled>
                                    <span class="text-gray-600">{{ $t('contacts.overview.move_to_list_button') }}</span>
                                    <icon icon="caret-right-solid" class="text-gray-600" size="small" />
                                </button>
                            </div>
                        </div>
                        <div v-if="selectedEmails.length" class="font-semibold text-gray-700" v-html="$tc('contacts.overview.selected_contacts_count', allEmailsSelected ? statusStats[currentStatus] : selectedEmails.length, {count: allEmailsSelected ? statusStats[currentStatus] : selectedEmails.length})" />
                    </div>
                    <div class="mb-5 md:mb-0">
                        <form class="mb-0" method="get" @submit.prevent="handleSearch">
                            <div class="tw-input-group flex" style="width: 310px; max-width: 100%">
                                <div class="tw-input-with-icon flex-grow">
                                    <label for="contactSearch" class="flex-none"><icon icon="search-solid" size="small" /></label>
                                    <input id="contactSearch" v-model="query" type="text" :placeholder="$t('contacts.overview.search_placeholder')" name="search" class="tw-input w-full">
                                </div>
                                <button class="btn btn-tertiary" :disabled="searching" type="submit"><div v-if="searching" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.overview.search_button') }}</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div v-if="currentQuery" class="flex txt-14 text-gray-1000 items-center mt-4">
                    <span>{{ $t('campaigns.query_results') }}</span>
                    <h2 class="ml-2">
                        {{ currentQuery }}
                    </h2>
                    <a href="#" class="btn btn-tertiary ml-3 icon-left" @click.prevent="refreshContacts(true)"><icon icon="times-solid" />{{ $t('campaigns.new.cancel') }}</a>
                </div>

                <div v-if="currentQuery && (!contacts || !Object.keys(contacts).length)" class="panel px-16 py-8 bg-white mt-3 mb-20">
                    <div class="flex flex-row items-center justify-between">
                        <div class="flex flex-col">
                            <h2 class="mb-2">
                                {{ $t('contacts.overview.empty_search_heading') }}
                            </h2>
                        </div>
                        <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-file.png" style="width: 164px" alt="feed-ilustration" class="px-4">
                    </div>
                </div>

                <template v-else>
                    <div class="w-full overflow-x-auto md:overflow-x-visible mt-4">
                        <div class="table-scrollable">
                            <div class="scrollable z-0" :class="{'has-scroll': tableOverflowing}">
                                <div class="border-radius">
                                    <div class="table tw-table txt-16">
                                        <div class="table-header-group">
                                            <div class="table-row">
                                                <div class="table-cell subcheckbox relative z-20 w-5">
                                                    <div class="tw-checkbox checkbox-solo w-5">
                                                        <input id="checkAll" type="checkbox" value="yes" name="checkAll" @change="onSelectAll">
                                                        <label for="checkAll">&nbsp;</label>
                                                    </div>
                                                    <div v-if="showSelectAllOptions" class="select-all-options">
                                                        <button @click="selectContacts('page')">{{ $t('contacts.overview.select_page_button') }}</button>
                                                        <button @click="selectContacts('all')">{{ $t('contacts.overview.select_all_button') }}</button>
                                                    </div>
                                                </div>
                                                <div class="table-cell submail w-max z-10 pl-0" :class="{ 'active': currentOrderBy === 'email' }">
                                                    <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('email')">
                                                        {{ $t('contacts.overview.columns.email') }}
                                                        <icon icon="sort-solid" />
                                                    </a>
                                                </div>
                                                <template v-if="list.display_settings && Object.keys(list.display_settings).length">
                                                    <div v-for="(displayKey, index) in Array.isArray(list.display_settings) ? list.display_settings : Object.keys(list.display_settings)" :key="`displayed-col-heading-${index}`" class="table-cell" :class="{ 'active': currentOrderBy === displayKey, 'text-right': index === Object.keys(list.display_settings).length - 1 }">
                                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" :class="{'pointer-events-none': displayKey === 'status'}" @click.prevent="orderResults(displayKey)">
                                                            <template v-if="displayKey.includes('c_fields') && list.custom_fields[stripCustomField(displayKey)]">
                                                                {{ list.custom_fields[stripCustomField(displayKey)].name }}
                                                            </template>
                                                            <template v-else-if="displayKey.includes('c_fields')">
                                                                {{ $t('contacts.overview.columns.deleted_custom_field') }}
                                                            </template>
                                                            <template v-else-if="displayKey === 'subscribed'">
                                                                {{ currentStatus !== 'unsubscribed' ? $t('contacts.overview.columns.subscribed') : null }}
                                                                {{ currentStatus === 'unsubscribed' ? $t('contacts.overview.columns.unsubscribed') : null }}
                                                            </template>
                                                            <template v-else>
                                                                {{ $t(`contacts.overview.columns.${displayKey}`) }}
                                                            </template>
                                                            <icon v-if="displayKey !== 'status'" icon="sort-solid" />
                                                        </a>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="table-cell" :class="{ 'active': currentOrderBy === 'tags' }">
                                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('tags')">
                                                            {{ $t('contacts.overview.columns.tags') }}
                                                            <icon icon="sort-solid" />
                                                        </a>
                                                    </div>
                                                    <div class="table-cell" :class="{ 'active': currentOrderBy === 'name' }">
                                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('name')">
                                                            {{ $t('contacts.overview.columns.name') }}
                                                            <icon icon="sort-solid" />
                                                        </a>
                                                    </div>
                                                    <div class="table-cell" :class="{ 'active': currentOrderBy === 'surname' }">
                                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('surname')">
                                                            {{ $t('contacts.overview.columns.surname') }}
                                                            <icon icon="sort-solid" />
                                                        </a>
                                                    </div>
                                                    <div class="table-cell" :class="{ 'active': currentOrderBy === 'rating' }">
                                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('rating')">
                                                            {{ $t('contacts.overview.columns.rating') }}
                                                            <icon icon="sort-solid" />
                                                        </a>
                                                    </div>
                                                    <div class="table-cell">
                                                        <span class="font-semibold txt-13">
                                                            {{ $t('contacts.overview.columns.status') }}
                                                        </span>
                                                    </div>
                                                    <div v-if="currentStatus === 'unsubscribed'" class="table-cell text-right" :class="{ 'active': currentOrderBy === 'unsubscribed_at' }">
                                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('unsubscribed_at')">
                                                            {{ $t('contacts.overview.columns.unsubscribed') }}
                                                            <icon icon="sort-solid" />
                                                        </a>
                                                    </div>
                                                    <div v-else class="table-cell text-right" :class="{ 'active': currentOrderBy === 'subscribed_at' }">
                                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('subscribed_at')">
                                                            {{ $t('contacts.overview.columns.subscribed') }}
                                                            <icon icon="sort-solid" />
                                                        </a>
                                                    </div>
                                                    
                                                </template>
                                            </div>
                                        </div>

                                        <div v-for="(contact, index) in contacts" :key="`subscriber-${index}`" class="table-row txt-13">
                                            <template v-if="contact.subscriber">
                                                <div class="table-cell subcheckbox z-10 w-5">
                                                    <div class="tw-checkbox checkbox-solo w-5">
                                                        <input :id="`subscriber-${index}`" v-model="selectedEmails" type="checkbox" :value="contact.email">
                                                        <label :for="`subscriber-${index}`">&nbsp;</label>
                                                    </div>
                                                </div>
                                                <div class="table-cell submail truncate w-max z-10 pl-0">
                                                    <a class="semibold link-blue" href="#" @click.prevent="showSubscriberModal(contact.email)">{{ contact.email }}</a>
                                                </div>
                                                <template v-if="list.display_settings && Object.keys(list.display_settings).length">
                                                    <template v-for="(displayKey, displayIndex) in Array.isArray(list.display_settings) ? list.display_settings : Object.keys(list.display_settings)">
                                                        <div v-if="displayKey === 'tags'" :key="`displayed-col-${index}-${displayIndex}`" class="table-cell z-0 space-x-1.5" :class="{'text-right': displayIndex === Object.keys(list.display_settings).length - 1}">
                                                            <template v-if="contact.subscriber.tags && contact.subscriber.tags.length">
                                                                <span v-for="(tag, tagIndex) in contact.subscriber.tags" :key="`subscriber-${index}-tag-${tagIndex}`" class="tag tag-sm tag-info">{{ tag }}</span>
                                                            </template>
                                                        </div>
                                                        <div v-else :key="`displayed-col-${index}-${displayIndex}`" class="table-cell" :class="{'text-right w-0 whitespace-nowrap': displayIndex === Object.keys(list.display_settings).length - 1}">
                                                            <template v-if="displayKey === 'groups' && contact.groups">
                                                                {{ Array.isArray(Object.values(contact.groups)) ? Object.values(contact.groups).join(', ') : '' }}
                                                            </template>
                                                            <template v-else-if="displayKey === 'country' && contact.subscriber.country">
                                                                {{ $t(`demography.${contact.subscriber.country}`) }}
                                                            </template>
                                                            <template v-else-if="displayKey === 'gender' && contact.subscriber.gender">
                                                                {{ $t(`contacts.overview.gender_options.${contact.subscriber.gender}`) }}
                                                            </template>
                                                            <template v-else-if="displayKey === 'rating'">
                                                                <span class="tag tag-sm pl-5"><icon class="relative" icon="star-solid" size="small" /><span class="ml-3"> {{ contact.subscriber.rating }}</span></span>
                                                            </template>
                                                            <template v-else-if="displayKey === 'status'">
                                                                <span v-html="$t(`contacts.overview.status_tag.${contact.status}`)" />
                                                            </template>
                                                            <template v-else-if="displayKey === 'subscribed'">
                                                                {{ currentStatus !== 'unsubscribed' && contact.subscribed_at ? getNumberDate(getIsoDate(contact.subscribed_at)) : null }}
                                                                {{ currentStatus === 'unsubscribed' && contact.unsubscribed_at ? getNumberDate(getIsoDate(contact.unsubscribed_at)) : null }}
                                                            </template>
                                                            <template v-else-if="displayKey.includes('c_fields') && contact.c_fields && JSON.parse(contact.c_fields)[stripCustomField(displayKey)]">
                                                                {{ Array.isArray(JSON.parse(contact.c_fields)[stripCustomField(displayKey)]) || typeof JSON.parse(contact.c_fields)[stripCustomField(displayKey)] === 'object' ? JSON.stringify(JSON.parse(contact.c_fields)[stripCustomField(displayKey)]) : JSON.parse(contact.c_fields)[stripCustomField(displayKey)] }}
                                                            </template>
                                                            <template v-else>
                                                                {{ contact.subscriber[displayKey] }}
                                                            </template>
                                                        </div>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <div class="table-cell space-x-1.5">
                                                        <template v-if="contact.subscriber.tags && contact.subscriber.tags.length">
                                                            <span v-for="(tag, tagIndex) in contact.subscriber.tags" :key="`subscriber-${index}-tag-${tagIndex}`" class="tag tag-sm tag-info">{{ tag }}</span>
                                                        </template>
                                                    </div>
                                                    <div class="table-cell">
                                                        {{ contact.subscriber.name }}
                                                    </div>
                                                    <div class="table-cell">
                                                        {{ contact.subscriber.surname }}
                                                    </div>
                                                    <div class="table-cell w-1">
                                                        <span class="tag tag-sm pl-5"><icon class="relative" icon="star-solid" size="small" /><span class="ml-3"> {{ contact.subscriber.rating }}</span></span>
                                                    </div>
                                                    <div class="table-cell w-1" v-html="$t(`contacts.overview.status_tag.${contact.status}`)" />
                                                    <div v-if="currentStatus === 'unsubscribed'" class="table-cell text-right w-0 whitespace-nowrap">
                                                        {{ contact.unsubscribed_at ? getNumberDate(getIsoDate(contact.unsubscribed_at)) : null }}
                                                    </div>
                                                    <div v-else class="table-cell text-right w-0 whitespace-nowrap">
                                                        {{ contact.subscribed_at ? getNumberDate(getIsoDate(contact.subscribed_at)) : null }}
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                    <div v-if="tableOverflowing" class="in-shadow" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between my-8">
                        <div v-dropdown="'open'" class="tw-dropdown">
                            <div class="btn-group">
                                <button class="btn btn-tertiary">
                                    {{ $t('contacts.overview.per_page_button', {count: paginationOverview.per_page}) }}
                                </button><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                            </div>
                            <div class="tw-dropdown-items flex-col min-w-min">
                                <button v-for="(perPage, index) in perPageOptions" :key="`per-page-${index}`" @click="setPerPage(perPage)">{{ perPage }}</button>
                            </div>
                        </div>
                        <pagination :total="paginationOverview.total" :per-page="paginationOverview.per_page" :current-page="paginationOverview.current_page" :last-page="paginationOverview.last_page" @handlePageChange="handlePageChange" />
                    </div>
                </template>
            </template>

            <div v-else class="panel px-16 py-8 bg-white mt-3 mb-20">
                <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-col">
                        <h2 class="mb-2">
                            {{ $t('contacts.overview.empty_status_heading') }}
                        </h2>
                    </div>
                    <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-file.png" style="width: 164px" alt="feed-ilustration" class="px-4">
                </div>
            </div>
        </div>

        <div v-else class="flex items-center space-x-10 mt-7 px-0 md:px-20">
            <div class="flex flex-col">
                <h2>{{ $t('contacts.overview.heading') }}</h2>
                <span class="txt-14 text-gray-900 mt-2.5" v-html="$t('contacts.overview.description')" />
                <div class="flex flex-row mt-6 space-x-4">
                    <button class="btn btn-primary btn-lg icon-left w-min" @click="addContact"><icon icon="plus-solid" size="small" /><span>{{ $t('contacts.overview.create_contact_button') }}</span></button>
                    <router-link v-if="user.role !== 'custom' || checkRole(user, 'manage-contacts-import')" class="btn btn-primary btn-lg icon-left w-min" :to="{name: 'subscribersImport', props: {list: list.id}}">
                        <icon icon="plus-solid" size="small" /><span>{{ $t('contacts.overview.import_contacts_button') }}</span>
                    </router-link>
                </div>
            </div>
            <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-lists.png" style="width: 304.76px" alt="list-ilustration" class="hidden md:block">
        </div>
    </div>

    <tw-confirm ref="moveToListModal" @confirm-create="moveToListConfirm" />

    <tw-confirm ref="massUnsubscribeModal" width="750" @confirmDelete="massUnsubscribeConfirm" />

    <tw-modal ref="subscriberDetailModal" :title="$t('appforms.subscriber.title')" close-button="yes" classname="subscriberDetail" :visible-on-load="$route.query['contact-modal']">
        <template #default="slotProps">

            <subscriber-form :modal="slotProps" @contactDeleted="contactDeleted" @contactUpdated="refreshContacts" />

        </template>
    </tw-modal>

    <tw-modal ref="addContactModal" :title="$t('contacts.overview.add_new_contact')" close-button="yes" size="xlarge" content-width>
        <template #default="slotProps">

            <add-contact-form :modal="slotProps" :list-id="slotProps.mdata.listId" @refreshContacts="contactAdded" />

        </template>
    </tw-modal>
</div>
