<template>

    <div class="overlay-panel-bg" :class="{'is-displayed': panelOverlay}" @click.self.prevent="closeModal">
        <div class="overlay-panel mc-r ec-container mx-auto" :class="{'is-displayed': panelOverlay}">
            <div class="main-panel">

                <div class="h2 flex">
                    <h2 class="txt-22">
                        {{ $t('campaigns.create_campaign') }}
                    </h2>
                </div>
                <ValidationObserver ref="observer" slim>
                    <form ref="newCampaign" novalidate method="post" @submit.prevent="createCampaign">
                        <div class="container py-3 md:py-5 mx-auto px-10 md:px-0" style="max-width: 640px;">

                            <template v-if="aiGenerating">
                                <div>
                                    <div style="max-width: 250px; margin: 0 auto;">
                                        <video loading="lazy" muted="muted" src="https://ecomail-assets.s3.amazonaws.com/images/mobile-ui-7493885-6136371.mp4" type="video/mp4" autoplay="autoplay" loop="loop"></video>
                                    </div>
                                    <div style="text-align: center; font-size: 15px">
                                        <div v-if="!aiCampaignId">
                                            <strong>Generuji novou kampaň</strong>
                                        </div>
                                        <div v-else>
                                            <strong>Generuji obsah kampaně</strong> <span v-if="aiBlocksTotal">({{ aiProgress }} / {{ aiBlocksTotal }})</span>
                                        </div>
                                        <div style="font-size: 12px; margin-top: 10px; text-align: left; height: 55px; position: relative; overflow: hidden;">
                                            <pre style="position: absolute; bottom: 0;">
                                                {{ aiChatMsg }}
                                            </pre>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <h2 class="mt-5" :class="{'mb-5 md:mb-10': success === null}">
                                    {{ $t('campaigns.new.start') }}
                                </h2>

                                <alert v-if="success !== null" class="my-4" :type="success === 'true' ? 'success' : 'error'" :message="message" />

                                <ValidationProvider v-slot="{ errors }" name="name" rules="required" slim>
                                    <div class="input flex flex-col">
                                        <label for="campaignTitle" class="tw-label mb-2" style="line-height: 14px;">{{ type === 'ai' ? $t('campaigns.new.step1.prompt') : $t('campaigns.new.step1.title') }}</label>
                                        <input id="campaignTitle" v-model="name" name="campaign_name" type="text" class="tw-input max-w-xs" :placeholder="type === 'ai' ? $t('campaigns.new.step1.title_ai_ph') : $t('campaigns.new.step1.title_ph')">
                                        <span class="tw-errors">{{ errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                                <div class="flex items-center dimmed90 mb-5 md:mb-10" style="margin-top: 2px">
                                    <div class="svg-wrapper text-alert-550 mr-2">
                                        <svg-vue icon="info-circle-solid" />
                                    </div>
                                    <span>
                                        {{ type === 'ai' ? $t('campaigns.new.step1.ai_help') : $t('campaigns.new.step1.help') }}
                                    </span>
                                </div>

                                <div id="campaignTypes" class="flex flex-col md:flex-row mt-5 md:-mx-2">
                                    <button type="button" class="type-email flex justify-between items-center panel flex-col w-full md:w-1/2 md:mx-2 has-green-hover mb-3 md:mb-0" :class="{selected: type === 'email'}" @click="setType('email')">
                                        <img class="mt-4" src="https://d70shl7vidtft.cloudfront.net/app/assets/images/campaigns/campaign-email.png" alt="">
                                        <div class="text-center w-full my-4 dimmed90 txt-13">{{ $t('campaigns.new.step1.email') }}</div>
                                    </button>
                                    <button v-if="aiEnabled" type="button" class="type-email flex justify-between items-center panel flex-col w-full md:w-1/2 md:mx-2 has-green-hover mb-3 md:mb-0" :class="{selected: type === 'ai'}" @click="setType('ai')">
                                        <img class="mt-4" src="https://d70shl7vidtft.cloudfront.net/app/assets/images/campaigns/campaign-email.png" alt="">
                                        <div class="text-center w-full my-4 dimmed90 txt-13">{{ $t('campaigns.new.step1.email_ai') }}</div>
                                    </button>
                                    <button type="button" class="type-sms flex justify-between items-center panel flex-col w-full md:w-1/2 md:mx-2 has-green-hover mb-3 md:mb-0" :class="{selected: type === 'sms'}" @click="setType('sms')">
                                        <img class="mt-4" src="https://d70shl7vidtft.cloudfront.net/app/assets/images/campaigns/campaign-sms.png" alt="">
                                        <div class="text-center w-full my-4 dimmed90 txt-13">{{ $t('campaigns.new.step1.sms') }}</div>
                                    </button>

                                    <!-- Facebook campaign option hidden until ready -->
                                    <button type="button" class="hidden type-fb flex justify-between items-center panel flex-col w-full md:w-1/2 md:mx-2 mb-3 md:mb-0 relative opacity-75 cursor-not-allowed" disabled>
                                        <span class="tw-lbl blue -mt-3 px-5" style="z-index: 500;">{{ $t('campaigns.new.step1.coming-soon') }}</span>
                                        <img class="mt-4" src="https://d70shl7vidtft.cloudfront.net/app/assets/images/campaigns/campaign-fb.png" alt="">
                                        <div class="text-center w-full my-4 dimmed90 txt-13">{{ $t('campaigns.new.step1.fb') }}</div>
                                    </button>
                                </div>
                            </template>

                            <template v-if="type === 'sms'">
                                <template v-if="account.isEnglishAccount">
                                    <alert
                                            v-if="account.smsCredits === 0"
                                            class="mt-4"
                                            type="warning"
                                            :label="$t('campaigns.new.step1.sms_alert_no_credits.label')"
                                            :message="$t('campaigns.new.step1.sms_alert_no_credits.message_alternative')"
                                            :primary-action="{text: $t('common.contact_support')}"
                                            @confirmPrimary="$root.$refs['supportModal'].showModal()" />
                                </template>
                                <template v-else>
                                    <alert
                                            v-if="account.plan === 0"
                                            class="mt-4"
                                            type="warning"
                                            :label="$t('campaigns.new.step1.sms_alert_free_account.label')"
                                            :message="$t('campaigns.new.step1.sms_alert_free_account.message')"
                                            :primary-action="{text: $t('campaigns.new.step1.sms_alert_free_account.primary_action'), url: '/account/payments/change-plan'}" />

                                    <alert
                                            v-else-if="account.plan !== 0 && account.smsCredits === 0"
                                            class="mt-4"
                                            type="warning"
                                            :label="$t('campaigns.new.step1.sms_alert_no_credits.label')"
                                            :message="$t('campaigns.new.step1.sms_alert_no_credits.message')"
                                            :primary-action="{text: $t('campaigns.new.step1.sms_alert_no_credits.primary_action'), url: '/account/payments/sms'}" />
                                </template>
                            </template>
                            <alert
                                    v-else-if="!account.hasVerifiedAnyDomains && account.plan !== 0 && type === 'email'"
                                    class="mt-4"
                                    type="warning"
                                    :label="$t('campaigns.new.step1.no_verified_domain_alert.label')"
                                    :message="$t('campaigns.new.step1.no_verified_domain_alert.message')"
                                    :primary-action="{text: $t('campaigns.new.step1.no_verified_domain_alert.primary_action'), url: '/account/domains'}" />

                            <div v-if="!aiGenerating" class="flex mt-5 md:mt-8 mb-20 justify-between">
                                <button type="button" class="btn btn-tertiary btn-lg" @click="closeModal">{{ $t('campaigns.new.cancel') }}</button>
                                <button class="btn btn-primary btn-lg" :disabled="sending || disabledSms" type="submit"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" /> {{ $t('campaigns.new.continue') }}</button>
                            </div>

                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>

</template>

<script>
import store from '@stores/index'
import {ValidationObserver} from 'vee-validate'
import { mapState } from 'vuex'
import Tracker from "@js/tracker";
import Pusher from 'pusher-js'

export default {

    name: 'NewCampaign',
    
    store,

    props: ['panelDisplayed'],

    components: {
        ValidationObserver
    },

    data () {
        return {
            sending: false,
            success: null,
            message: null,
            name: '',
            type: 'email',
            panelOverlay: false,
            aiGenerating: false,
            aiProgress: 0,
            aiBlocksTotal: 0,
            aiCampaignId: null,
            aiCampaignReady: false,
            aiChatMsg: '',
            aiChatTagProcessing: false
        }
    },

    computed: {
        ...mapState(['account']),
        disabledSms() {
            return this.type === 'sms' && (this.account.plan === 0 || this.account.smsCredits === 0)
        },
        aiEnabled() {
            return window.location.search.includes('forceAiTesting')
        }
    },

    watch: {
        panelDisplayed(val) {
            this.panelOverlay = val
        }
    },

    created() {
        store.dispatch('loadAccountData')
    },

    methods: {
        resetAlert() {
            this.success = null
            this.message = null
        },
        showModal() {
            this.panelOverlay = true
        },
        closeModal() {
            this.panelOverlay = false
            this.$emit('closed')
        },
        setType(type) {
            this.type = type
        },
        async createCampaign(evt) {
            this.resetAlert()

            evt.preventDefault()
            const isValid = await this.$refs.observer.validate()
            if(isValid) {
                this.sending = true

                if(this.type === 'ai') {
                    try {
                        this.aiGenerating = true
                        this.pusher = new Pusher('d9227f1530fb2760f117', {
                            cluster: 'eu',
                            secret: 'dd261a845fc434b77a4b',
                            appId: '199657',
                        })

                        const channelName = `openai-generate-${this.account.name}`
                        // const channelName = 'openai-generate-news'
                        this.pusher = this.pusher.subscribe(channelName)

                        this.pusher.bind('campaign-created', (data) => {
                            console.log('campaign-created')
                            console.log(data)
                            if(this.aiCampaignId === null) {
                                this.aiCampaignId = data.campaign_id
                            }
                        })
                        this.pusher.bind('generating-content', (data) => {
                            console.log(data)
                            if(data.message.includes('<')) {
                                let parts = data.message.split('<')
                                this.aiChatTagProcessing = true
                                this.aiChatMsg += parts[0]
                            } else if(data.message.includes('>')) {
                                let parts = data.message.split('>')
                                this.aiChatTagProcessing = false
                                this.aiChatMsg += parts[1]
                            } else if(!this.aiChatTagProcessing) {
                                this.aiChatMsg += data.message
                            }
                        })
                        this.pusher.bind('block-generated', async (data) => {
                            console.log('block-generated')
                            console.log(data)
                            if(data.campaign_id === this.aiCampaignId) {
                                if(data.progress === data.total_blocks) {
                                    this.aiCampaignReady = true
                                    window.location.href = `/campaigns/${data.campaign_id}/edit-content`
                                    //const response = await this.$http.get('/campaigns/' + this.aiCampaignId)
                                    //console.log(response)
                                    console.log('ready')
                                } else {
                                    this.aiProgress = data.progress
                                    this.aiBlocksTotal = data.total_blocks
                                }
                            }
                        })

                        await this.$http.post('/campaigns/ai', {
                            campaign_type: 'email',
                            title: this.name,
                        })

                        new Tracker().trackEvent('CAMPAIGN_CREATION_AI')
                    }
                    catch(error) {
                        this.success = false
                        this.message = error.message
                    }
                } else {
                    try {
                        const response = await this.$http.post('/campaigns', {
                            campaign_type: this.type,
                            title: this.name,
                        })
                        window.location.href = `/campaigns/${response.data.campaign_id}/edit`
                        new Tracker().trackEvent('BUTTON_CLICKED', {
                            scope: 'campaign creation',
                            name: 'Create campaign',
                            campaign_type: this.type
                        })
                        new Tracker().trackEvent('CAMPAIGN_CREATION_STEP', {
                            step: 'Campaign recipients'
                        })
                    }
                    catch(error) {
                        this.success = false
                        this.message = error.message
                    }
                }
                this.sending = false
            }
        },
    },

}
</script>
