

<ValidationObserver ref="observer" slim>
    <form novalidate class="mb-0 mt-4" @submit.prevent="saveSegment">
        <alert v-if="success === false" class="mb-5" type="error" :message="message" />

        <ValidationProvider v-slot="{ errors }" name="name" rules="required|max:255" slim>
            <div class="input flex flex-col">
                <label for="fieldName" class="tw-label mb-2.5">{{ $t('appforms.contacts.segment.name') }}</label>
                <input id="fieldName" v-model="name" type="text" name="name" class="tw-input" :class="{'tw-err': errors.length > 0 }" :placeholder="$t('appforms.contacts.segment.name_ph')">
                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>

        <div class="tw-modal-buttons">
            <button type="button" class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('common.cancel') }}</button>
            <button :disabled="sending" type="submit" class="btn btn-primary float-right btn-lg"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.contacts.segment.add-segment') }}</button>
        </div>
    </form>
</ValidationObserver>

