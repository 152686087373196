
<transition name="long-fadeout">
    <div class="ec-container md:spa-container relative main-container flex flex-wrap mx-auto flex-col w-full md:flex-row">
        <div class="mc-r w-full h-full">
            <div class="main-panel solo spa" :class="{scrolled: scrolled}">

                <h2 class="flex flex-grow items-center justify-between">
                    <h1 class="text-gray-800" v-html="$t('search.heading', {query: query})" />
                    <button class="btn btn-tertiary items-center" @click="goToPreviousPage()">
                        <icon class="mr-2 -ml-1" icon="caret-left-solid" />
                        <span class="-mr-1">{{ $t('common.back') }}</span>
                    </button>
                </h2>

                <div v-if="loading" ref="content-layout" class="w-4 h-4 mt-4 double-loader" />
                <div v-else-if="!campaigns.length && !subscribers.length && !pipelines.length" ref="content-layout" class="flex flex-col mt-16 justify-center items-center">
                    <div class="text-gray-1000" style="font-size: 18px">
                        {{ $t('search.no_results') }}
                    </div>
                    <img alt="empty-payments-illustration" src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-results.png" class="mt-12" style="width: 304.76px">
                    <button class="btn btn-primary btn-lg mb-15 mt-6" @click="showSearchModal()">{{ $t('search.search_again_button') }}</button>
                </div>
                <vue-custom-scrollbar v-else ref="content-layout" :settings="settings" class="spa-container" @ps-y-reach-start="scrolled = false" @ps-scroll-y="handleScroll()">
                    <template v-if="user.role !== 'reader'">
                        <h2  v-html="$tc('search.subscribers', subscribers.length, {count: subscribers.length})" />
                        <span v-if="!subscribers.length" class="mt-2.5 text-gray-900 txt-14">{{ $t('search.no_results_partial') }}</span>
                        <div v-else class="mt-8 w-full overflow-x-auto md:overflow-x-visible">
                            <div class="table tw-table txt-16">
                                <div class="table-header-group">
                                    <div class="table-row">
                                        <div class="table-cell whitespace-nowrap txt-13 semibold">
                                            {{ $t('search.columns.email') }}
                                        </div>
                                        <div class="table-cell whitespace-nowrap txt-13 semibold">
                                            {{ $t('search.columns.tags') }}
                                        </div>
                                        <div class="table-cell whitespace-nowrap txt-13 semibold">
                                            {{ $t('search.columns.name') }}
                                        </div>
                                        <div class="table-cell whitespace-nowrap txt-13 semibold">
                                            {{ $t('search.columns.surname') }}
                                        </div>
                                        <div class="table-cell whitespace-nowrap txt-13 semibold text-right">
                                            {{ $t('search.columns.rating') }}
                                        </div>
                                    </div>
                                </div>

                                <div v-for="(subscriber, index) in subscribers" :key="`subscriber-${index}`" class="table-row txt-13">
                                    <div class="table-cell whitespace-nowrap">
                                        <a class="semibold link-blue" href="#" @click.prevent="showSubscriberModal(subscriber.email)">{{ subscriber.email }}</a>
                                    </div>
                                    <div class="table-cell space-x-1.5 whitespace-nowrap">
                                        <template v-if="subscriber.tags && subscriber.tags.length">
                                            <span v-for="(tag, tagIndex) in subscriber.tags" :key="`subscriber-${index}-tag-${tagIndex}`" class="tag tag-sm tag-info">{{ tag }}</span>
                                        </template>
                                    </div>
                                    <div class="table-cell whitespace-nowrap">
                                        {{ subscriber.name }}
                                    </div>
                                    <div class="table-cell whitespace-nowrap">
                                        {{ subscriber.surname }}
                                    </div>
                                    <div class="table-cell whitespace-nowrap text-right">
                                        <span class="tag tag-sm pl-5"><icon class="relative" icon="star-solid" size="small" /><span class="ml-3"> {{ subscriber.rating }}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <h2 class="mt-12" v-html="$tc('search.campaigns', campaigns.length, {count: campaigns.length})" />

                    <span v-if="!campaigns.length" class="mt-2.5 text-gray-900 txt-14">{{ $t('search.no_results_partial') }}</span>
                    <div v-else class="mt-8 overflow-x-auto md:overflow-x-visible">
                        <div class="table tw-table tw-table-large max-w-full">
                            <div v-for="(campaign, index) in campaigns" :key="`campaign-${index}`" class="table-row ">
                                <div class="table-cell align-middle">
                                    <span class="tag whitespace-nowrap" :class="getClass(campaign.status)">{{ $t(`campaigns.status.status_${campaign.status}`, {type: $t(`campaigns.type.${campaign.campaign_type}`)}) }}</span>
                                </div>
                                <div class="table-cell align-middle w-full max-w-0">
                                    <div class="flex">
                                        <a v-if="campaign.status === 0" class="txt-14 semibold link-blue whitespace-normal" :href="`/campaigns/${campaign.id}/edit`">{{ campaign.title }}</a>
                                        <router-link :is="checkRole(user, 'manage-reports-overview') ? 'router-link' : 'span'" v-else :to="{name: 'reportsOverview', params: {campaign: campaign.id}}" class="txt-14 link-blue semibold">
                                            {{ campaign.title }}
                                        </router-link>
                                        <span v-if="campaign.hidden === 1" class="tag ml-2 whitespace-nowrap">{{ $t('campaigns.deleted') }}</span>
                                        <span v-if="campaign.campaign_type === 'sms'" class="tag tag-purple ml-2">SMS</span>
                                        <span v-if="campaign.campaign_type === 'ab'" class="tag tag-light-blue ml-2 whitespace-nowrap">A/B test</span>
                                        <span v-if="campaign.fb" class="tag tag-blue ml-2 whitespace-nowrap">Facebook followup</span>
                                    </div>
                                </div>
                                <div class="table-cell align-middle text-gray-900 txt-12">
                                    <div class="flex items-center">
                                        <icon icon="list-regular" class="mr-1" />
                                        <div class="flex flex-col">
                                            <span v-for="(list, listIndex) in campaign.lists" :key="`campaign-${index}-list-${listIndex}`" class="whitespace-nowrap">
                                                <template v-if="listsAndSegments[list]">
                                                    {{ listsAndSegments[list].name }}
                                                </template>
                                                <template v-else>
                                                    [N/A]
                                                </template>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-cell align-middle whitespace-nowrap text-gray-800 txt-12">
                                    <span v-if="campaign.status === 0 || campaign.status === 1">
                                        {{ $t('campaigns.last_edited') }}: {{ getNumberDate(campaign.updated_at) }}
                                    </span>
                                    <span v-if="campaign.status === 7">
                                        {{ $t('campaigns.scheduled') }}: {{ getNumberDate(campaign.scheduled_at) }}
                                    </span>
                                    <span v-if="campaign.sent_at">
                                        {{ $t('campaigns.sent') }}: {{ getNumberDate(campaign.sent_at) }}
                                    </span>
                                </div>
                                <div class="table-cell align-middle text-right">
                                    <div v-if="campaign.status === 0 && user.role !== 'reader'" v-dropdown="'open'" class="tw-dropdown">
                                        <div class="btn-group">
                                            <a :href="`/campaigns/${campaign.id}/edit`" class="btn btn-tertiary whitespace-nowrap">{{ $t('campaigns.edit') }}</a><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                        </div>
                                        <div class="tw-dropdown-items flex-col">
                                            <a :href="`campaigns/render/${campaign.id}/${campaign.render_hash}`">{{ $t('campaigns.show_email') }}</a>
                                            <a :href="`campaigns/${campaign.id}/duplicate`">{{ $t('campaigns.duplicate') }}</a>
                                            <a v-if="campaign.html_text" href="#" @click.prevent="saveToTemplates(campaign.id)">{{ $t('campaigns.save_to_templates') }}</a>
                                            <a v-if="campaign.hidden === 1" :href="`campaigns/${campaign.id}/undelete`">Undelete</a>
                                            <a v-if="checkRole(user, 'manage-campaigns-delete')" class="dd-divided dd-critical" href="#" @click.prevent="deleteCampaign(campaign)">{{ $t('common.delete') }}</a>
                                        </div>
                                    </div>
                                    <div v-else-if="campaign.status === 7 && user.role !== 'reader'" v-dropdown="'open'" class="tw-dropdown">
                                        <div class="btn-group">
                                            <a :href="`/campaigns/${campaign.id}/cancel`" class="btn btn-tertiary whitespace-nowrap">{{ $t('campaigns.cancel_scheduled') }}</a><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                        </div>
                                        <div class="tw-dropdown-items flex-col">
                                            <a :href="`campaigns/${campaign.id}/duplicate`">{{ $t('campaigns.duplicate') }}</a>
                                            <a v-if="campaign.html_text" href="#" @click.prevent="saveToTemplates(campaign.id)">{{ $t('campaigns.save_to_templates') }}</a>
                                            <a v-if="campaign.hidden === 1" :href="`campaigns/${campaign.id}/undelete`">Undelete</a>
                                        </div>
                                    </div>
                                    <div v-else v-dropdown="'open'" class="tw-dropdown">
                                        <div class="btn-group">
                                            <a :href="checkRole(user, 'manage-reports-overview') ? `/reports/${campaign.id}` : 'javascript:void(0)'" class="btn btn-tertiary whitespace-nowrap">{{ $t('campaigns.show_results') }}</a><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                        </div>
                                        <div class="tw-dropdown-items flex-col">
                                            <a :href="`campaigns/render/${campaign.id}/${campaign.render_hash}`">{{ $t('campaigns.show_email') }}</a>
                                            <a v-if="user.role !== 'reader'" :href="`campaigns/${campaign.id}/duplicate`">{{ $t('campaigns.duplicate') }}</a>
                                            <a v-if="user.role !== 'reader' && campaign.html_text" href="#" @click.prevent="saveToTemplates(campaign.id)">{{ $t('campaigns.save_to_templates') }}</a>
                                            <a href="#" @click.prevent="renameCampaign(campaign.id, campaign.title)">{{ $t('campaigns.rename') }}</a>
                                            <a v-if="campaign.hidden === 1" :href="`campaigns/${campaign.id}/undelete`">Undelete</a>
                                            <a v-if="checkRole(user, 'manage-campaigns-delete')" class="dd-divided dd-critical" href="#" @click.prevent="deleteCampaign(campaign)">{{ $t('common.delete') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 class="mt-12" v-html="$tc('search.automations', pipelines.length, {count: pipelines.length})" />

                    <span v-if="!pipelines.length" class="mt-2.5 text-gray-900 txt-14">{{ $t('search.no_results_partial') }}</span>
                    <div v-else class="mt-8 overflow-x-auto md:overflow-x-visible">
                        <div class="table tw-table tw-table-large max-w-full">
                            <div class="table-header-group">
                                <div class="table-row">
                                    <div class="table-cell whitespace-nowrap txt-13 semibold">
                                        {{ $t('search.automation_columns.title') }}
                                    </div>
                                    <div class="table-cell whitespace-nowrap txt-13 semibold">
                                        {{ $t('search.automation_columns.state') }}
                                    </div>
                                </div>
                            </div>
                            <div v-for="(pipeline, index) in pipelines" :key="`pipeline-${index}`" class="table-row ">
                                <div class="table-cell">
                                    <a class="txt-14 semibold link-blue whitespace-normal" :href="`/automation/${pipeline.id}/report`">{{ pipeline.name }}</a>
                                </div>
                                <div class="table-cell whitespace-nowrap">
                                    {{ pipeline.started ? $t('search.pipeline_running') : $t('search.pipeline_stopped') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-custom-scrollbar>                    
            </div>
        </div>

        <tw-confirm ref="deleteCampaignConfirm" @confirmDelete="deleteCampaignConfirmed" />

        <tw-modal ref="renameCampaignModal" size="large" :title="$t('campaigns.rename_modal.title')" close-button="yes">
            <template #default="slotProps">

                <rename-campaign :modal="slotProps" @reloadCampaigns="getSearchData" />

            </template>
        </tw-modal>

        <tw-modal ref="subscriberDetailModal" :title="$t('appforms.subscriber.title')" close-button="yes" classname="subscriberDetail" :visible-on-load="$route.query['contact-modal']">
            <template #default="slotProps">

                <subscriber-form :modal="slotProps" @contactDeleted="contactDeleted" @contactUpdated="getSearchData" />

            </template>
        </tw-modal>
    </div>
</transition>
