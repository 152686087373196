<template>
    <transition name="long-fadeout">
        <div class="ec-container md:spa-container relative main-container flex flex-wrap mx-auto flex-col w-full md:flex-row">
            <div class="mc-r w-full h-full">
                <div class="main-panel solo spa" :class="{'scrolled': scrolled && !checked.length, 'scrolled-checked': scrolled && checked.length}">

                        
                    <h2 class="flex justify-between items-center px-9 py-3">
                        <div class="flex items-center">
                            <a href="#" class="txt-22 hover:text-gray-1000 hover:no-underline" :class="{ 'pointer-events-none text-gray-1000' : !activeFilter, 'text-gray-800': activeFilter }" @click.prevent="loadAllCampaigns">{{ $t('campaigns.title') }}</a>
                            <icon v-if="activeFilter" class="text-gray-700 mx-3" icon="long-arrow-alt-right-solid" />
                            <span v-if="activeFilter" class="text-gray-1000 txt-22">{{ activeFilter }}</span>
                        </div>
                        <button class="btn btn-primary btn-lg icon-left" @click="showCreateCampaign"><icon icon="plus-solid" size="small" /><span>{{ $t('campaigns.new_campaign') }}</span></button>
                    </h2>

                    <div v-if="pagination && pagination.notice_of_longer_campaign_processing" class="mx-9 mt-4">
                        <alert type="info" :message="pagination.notice_of_longer_campaign_processing" />
                    </div>

                    <div v-if="!campaignsLoaded" ref="content-layout" class="w-4 h-4 mt-6 ml-9 double-loader" />

                    <div v-else-if="!campaigns" class="px-16 py-8 bg-white mt-3 md:mx-40 lg:mx-64">
                        <div class="flex flex-row items-center justify-between">
                            <div class="flex flex-col">
                                <p class="mb-2.5 txt-18 text-gray-1000">
                                    {{ $t('campaigns.no_campaigns_sent') }}
                                </p>
                                <div>
                                    <span class="txt-14 text-gray-900" v-html="$t('campaigns.create_one_now')" />
                                    <br>
                                    <button class="btn btn-primary btn-lg icon-left" @click="showCreateCampaign"><icon icon="plus-solid" size="small" /><span>{{ $t('campaigns.new_campaign') }}</span></button>
                                </div>
                            </div>
                            <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-campaigns.png" style="width: 314.76px" alt="feed-ilustration">
                        </div>
                    </div>

                    <template v-else>
                        <div v-if="checked.length" class="flex flex-col md:flex-row justify-between items-start md:items-center pt-4 pb-5 space-y-4 md:space-y-0 px-9">
                            <div class="flex flex-col md:flex-row items-start md:items-center space-x-0 md:space-x-5 space-y-4 md:space-y-0">
                                <folders :initfolders="folders" :label="$t('cf.all_campaigns')" :alllabel="$t('cf.all_campaigns')" basepath="campaigns" @goToFolder="goToFolder">

                                    <template #default="slotProps">
                                        <campaign-folder-filters :status="0" :count="statusCount[0]" :modal="slotProps" @filterCampaigns="filterCampaigns" />
                                        <campaign-folder-filters :status="7" :count="statusCount[7]" :modal="slotProps" @filterCampaigns="filterCampaigns" />
                                        <campaign-folder-filters :status="3" :count="statusCount[3]" :modal="slotProps" @filterCampaigns="filterCampaigns" />
                                    </template>
                                </folders>
                                <move-to-folder :shown="checked && checked.length" :initfolders="folders" :checked="checked" basepath="campaigns" :label="$t('cf.move_to_folder')" @moved="movedToFolder" />
                                <button v-if="checked.length > 1" class="btn btn-secondary btn-danger" @click.stop.prevent="deleteSelectedCampaigns">{{ $t('campaigns.delete_selected') }}</button>
                            </div>
                            <form method="get" class="mb-0" @submit.prevent="handleSearch">
                                <div class="tw-input-group flex" style="width: 330px; max-width: 100%">
                                    <div class="tw-input-with-icon flex-grow">
                                        <label for="campaignSearch" class="flex-none"><icon size="small" icon="search-solid" /></label>
                                        <input id="campaignSearch" v-model="query" type="text" :placeholder="$t('campaigns.placeholder')" name="search" class="tw-input w-full">
                                    </div>
                                    <button class="btn btn-tertiary" :disabled="sending" type="submit"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('campaigns.search') }}</button>
                                </div>
                            </form>
                        </div>

                        <vue-custom-scrollbar ref="content-layout" class="spa-container" :settings="settings" @ps-y-reach-start="scrolled = false" @ps-scroll-y="handleScroll()">

                            <global-messages :global-messages="globalMessages" />

                            <div v-if="!checked.length" class="flex flex-col md:flex-row justify-between items-start md:items-center pb-5 space-y-4 md:space-y-0">
                                <div class="flex flex-col md:flex-row items-start md:items-center space-x-0 md:space-x-5 space-y-4 md:space-y-0">
                                    <folders :initfolders="folders" :label="$t('cf.all_campaigns')" :alllabel="$t('cf.all_campaigns')" basepath="campaigns" @goToFolder="goToFolder">

                                        <template #default="slotProps">
                                            <campaign-folder-filters :status="0" :count="statusCount[0]" :modal="slotProps" @filterCampaigns="filterCampaigns" />
                                            <campaign-folder-filters :status="7" :count="statusCount[7]" :modal="slotProps" @filterCampaigns="filterCampaigns" />
                                            <campaign-folder-filters :status="3" :count="statusCount[3]" :modal="slotProps" @filterCampaigns="filterCampaigns" />
                                        </template>
                                    </folders>
                                </div>
                                <form method="get" class="mb-0" @submit.prevent="handleSearch">
                                    <div class="tw-input-group flex" style="width: 330px; max-width: 100%">
                                        <div class="tw-input-with-icon flex-grow">
                                            <label for="campaignSearch" class="flex-none"><icon size="small" icon="search-solid" /></label>
                                            <input id="campaignSearch" v-model="query" type="text" :placeholder="$t('campaigns.placeholder')" name="search" class="tw-input w-full">
                                        </div>
                                        <button class="btn btn-tertiary" :disabled="sending" type="submit"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('campaigns.search') }}</button>
                                    </div>
                                </form>
                            </div>

                            <div class="w-full overflow-x-auto md:overflow-x-visible">
                                <div v-if="currentQuery" class="flex txt-14 text-gray-1000 items-center mb-6">
                                    <span>{{ $t('campaigns.query_results') }}</span>
                                    <h2 class="ml-2">
                                        {{ currentQuery }}
                                    </h2>
                                    <a href="#" class="btn btn-tertiary ml-3 icon-left" @click.prevent="loadAllCampaigns"><icon icon="times-solid" />{{ $t('campaigns.new.cancel') }}</a>
                                </div>
                                <div v-if="!campaigns.length" class="flex flex-col items-center w-full mt-8">
                                    <img alt="empty-templates-illustration" src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-payments.png" style="width: 304.76px">
                                    <h3 class="mt-4">
                                        {{ $t('campaigns.query_results_empty') }}
                                    </h3>
                                </div>
                                <div v-else class="table tw-table tw-table-large mb-10 max-w-full">
                                    <div v-for="(campaign, index) in campaigns" :key="`campaign-${index}`" class="table-row">
                                        <div class="table-cell align-middle w-12 pr-0">
                                            <div class="tw-checkbox checkbox-solo">
                                                <input :id="`campaign-${index}`" v-model="checked" type="checkbox" :value="campaign.id">
                                                <label :for="`campaign-${index}`">&nbsp;</label>
                                            </div>
                                        </div>
                                        <div class="table-cell align-middle w-40 pr-0">
                                            <span class="tag whitespace-nowrap" :class="getClass(campaign.status)">{{ $t(`campaigns.status.status_${campaign.status}`, {type: $t(`campaigns.type.${campaign.campaign_type}`)}) }}</span>
                                        </div>
                                        <div class="table-cell truncate">
                                            <div class="flex flex-col space-y-1">
                                                <div>
                                                    <a v-if="campaign.status === 0" class="txt-14 semibold link-blue whitespace-normal" :href="`/campaigns/${campaign.id}/edit`">{{ campaign.title }}</a>
                                                    <a v-else class="txt-14 semibold link-blue whitespace-normal" :href="checkRole(user, 'manage-reports-overview') ? `/reports/${campaign.id}` : 'javascript:void(0)'">{{ campaign.title }}</a>
                                                    <span v-if="campaign.hidden === 1" class="tag ml-2 whitespace-nowrap">{{ $t('campaigns.deleted') }}</span>
                                                    <span v-if="campaign.campaign_type === 'sms'" class="tag tag-purple ml-2">SMS</span>
                                                    <span v-if="campaign.campaign_type === 'ab'" class="tag tag-light-blue ml-2 whitespace-nowrap">A/B test</span>
                                                    <span v-if="campaign.fb" class="tag tag-blue ml-2 whitespace-nowrap">Facebook followup</span>
                                                </div>
                                                <div v-if="campaign.recepient_lists" class="flex txt-12 text-gray-900">
                                                    <div v-if="campaign.recepient_lists['segments'] && campaign.recepient_lists['segments'][0]" class="flex flex-wrap items-center">
                                                        <icon size="small" icon="list-regular" />
                                                        <span v-if="listsAndSegments[campaign.recepient_lists['segments'][0]['id']]">
                                                            {{ listsAndSegments[campaign.recepient_lists['segments'][0]['id']]['list'] }} [{{ listsAndSegments[campaign.recepient_lists['segments'][0]['id']]['name'] }}]
                                                        </span>
                                                        <span v-else>{{ $t('campaigns.deleted_segment') }}</span>
                                                    </div>
                                                    <div v-else-if="campaign.recepient_lists['segments']" class="flex flex-wrap items-center">
                                                        <icon size="small" icon="list-regular" />
                                                        <span v-if="listsAndSegments[campaign.recepient_lists['segments']['id']]">
                                                            {{ listsAndSegments[campaign.recepient_lists['segments']['id']]['list'] }} [{{ listsAndSegments[campaign.recepient_lists['segments']['id']]['name'] }}]
                                                        </span>
                                                        <span v-else>{{ $t('campaigns.deleted_segment') }}</span>
                                                    </div>
                                                    <div v-else>
                                                        <div v-if="showAllLists.includes(campaign.id)" class="flex flex-wrap items-center">
                                                            <icon size="small" icon="list-regular" />
                                                            <div v-for="(list, listIndex) in campaign.recepient_lists" :key="`campaign-${index}-list-${listIndex}`">
                                                                <span v-if="listsAndSegments[list]">
                                                                    <span v-if="listIndex!== 0">, </span>
                                                                    <span>{{ listsAndSegments[list]['name'] }}</span>
                                                                </span>
                                                                <span v-else>[N/A]</span>
                                                            </div>
                                                        </div>
                                                        <div v-else class="flex flex-wrap items-center">
                                                            <icon size="small" icon="list-regular" />
                                                            <div v-for="(list, listIndex) in campaign.recepient_lists.slice(0,3)" :key="`campaign-${index}-list-${listIndex}`">
                                                                <span v-if="listsAndSegments[list]">
                                                                    <span v-if="listIndex!== 0">, </span>
                                                                    <span>{{ listsAndSegments[list]['name'] }}</span>
                                                                </span>
                                                                <span v-else>[N/A]</span>
                                                            </div>
                                                            <a v-if="campaign.recepient_lists.length > 3 && !showAllLists.includes(campaign.id)" href="#" @click.prevent="showAllLists.push(campaign.id)" v-html="$tc('campaigns.multiple_lists', campaign.recepient_lists.length-3 , {length: campaign.recepient_lists.length-3})" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <span class="txt-12 text-gray-800">
                                                    <span v-if="campaign.status === 0 || campaign.status === 1">
                                                        {{ $t('campaigns.last_edited') }}: {{ getHumanizedDate(campaign.updated_at) }}
                                                    </span>
                                                    <span v-if="campaign.status === 7">
                                                        {{ $t('campaigns.scheduled') }}: {{ getHumanizedDate(campaign.scheduled_at) }}
                                                    </span>
                                                    <span v-if="campaign.sent_at">
                                                        {{ $t('campaigns.sent') }}: {{ getHumanizedDate(campaign.sent_at) }}
                                                    </span>                                                
                                                </span>
                                            </div>
                                        </div>
                                        <div class="table-cell w-72 text-right align-middle whitespace-nowrap">
                                            <div v-if="campaign.status === 0 && user.role !== 'reader'" v-dropdown="'open'" class="tw-dropdown">
                                                <div class="btn-group">
                                                    <a :href="`/campaigns/${campaign.id}/edit`" class="btn btn-tertiary whitespace-nowrap">{{ $t('campaigns.edit') }}</a><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                                </div>
                                                <div class="tw-dropdown-items flex-col">
                                                    <a :href="`/campaigns/render/${campaign.id}/${campaign.render_hash}`" target="_blank">{{ $t('campaigns.show_email') }}</a>
                                                    <a :href="`/campaigns/${campaign.id}/duplicate`">{{ $t('campaigns.duplicate') }}</a>
                                                    <a v-if="user.role !== 'reader' && (campaign.has_html_raw === 1 || campaign.has_html)" href="#" @click.prevent="saveToTemplates(campaign.id)">{{ $t('campaigns.save_to_templates') }}</a>
                                                    <a v-if="campaign.hidden === 1" :href="`/campaigns/${campaign.id}/undelete`">Undelete</a>
                                                    <a v-if="loggedFromImpersonator" href="#" @click.prevent="makeScreenshot(campaign.id)">Udělat screenshot [pouze pro ADMIN]</a>
                                                    <a v-if="checkRole(user, 'manage-campaigns-delete')" class="dd-divided dd-critical" href="#" @click.prevent="deleteCampaign(campaign)">{{ $t('common.delete') }}</a>
                                                </div>
                                            </div>
                                            <div v-else-if="campaign.status === 7 && user.role !== 'reader'" v-dropdown="'open'" class="tw-dropdown">
                                                <div class="btn-group">
                                                    <a :href="`/campaigns/${campaign.id}/cancel`" class="btn btn-tertiary whitespace-nowrap">{{ $t('campaigns.cancel_scheduled') }}</a><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                                </div>
                                                <div class="tw-dropdown-items flex-col">
                                                    <a :href="`/campaigns/render/${campaign.id}/${campaign.render_hash}`" target="_blank">{{ $t('campaigns.show_email') }}</a>
                                                    <a :href="`/campaigns/${campaign.id}/duplicate`">{{ $t('campaigns.duplicate') }}</a>
                                                    <a v-if="user.role !== 'reader' && (campaign.has_html_raw === 1 || campaign.has_html)" href="#" @click.prevent="saveToTemplates(campaign.id)">{{ $t('campaigns.save_to_templates') }}</a>
                                                    <a v-if="campaign.hidden === 1" :href="`/campaigns/${campaign.id}/undelete`">Undelete</a>
                                                    <a v-if="loggedFromImpersonator" href="#" @click.prevent="makeScreenshot(campaign.id)">Udělat screenshot [pouze pro ADMIN]</a>
                                                </div>
                                            </div>
                                            <div v-else v-dropdown="'open'" class="tw-dropdown">
                                                <div class="btn-group">
                                                    <a :href="checkRole(user, 'manage-reports-overview') ? `/reports/${campaign.id}` : 'javascript:void(0)'" class="btn btn-tertiary whitespace-nowrap">{{ $t('campaigns.show_results') }}</a><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                                </div>
                                                <div class="tw-dropdown-items flex-col">
                                                    <a :href="`/campaigns/render/${campaign.id}/${campaign.render_hash}`" target="_blank">{{ $t('campaigns.show_email') }}</a>
                                                    <a v-if="user.role !== 'reader'" :href="`/campaigns/${campaign.id}/duplicate`">{{ $t('campaigns.duplicate') }}</a>
                                                    <a v-if="user.role !== 'reader' && (campaign.has_html_raw === 1 || campaign.has_html)" href="#" @click.prevent="saveToTemplates(campaign.id)">{{ $t('campaigns.save_to_templates') }}</a>
                                                    <a href="#" @click.prevent="renameCampaign(campaign.id, campaign.title)">{{ $t('campaigns.rename') }}</a>
                                                    <a v-if="campaign.hidden === 1" :href="`/campaigns/${campaign.id}/undelete`">Undelete</a>
                                                    <a v-if="checkRole(user, 'manage-campaigns-delete')" class="dd-divided dd-critical" href="#" @click.prevent="deleteCampaign(campaign)">{{ $t('common.delete') }}</a>
                                                    <a v-if="loggedFromImpersonator" href="#" @click.prevent="makeScreenshot(campaign.id)">Udělat screenshot [pouze pro ADMIN]</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <pagination :total="pagination.total" :per-page="pagination.per_page" :current-page="pagination.current_page" :last-page="pagination.last_page" @handlePageChange="handlePageChange" />
                        
                        </vue-custom-scrollbar>
                    </template>
                </div>
            </div>

            <new-campaign ref="createCampaign" />

            <tw-confirm ref="deleteCampaignConfirm" @confirmDelete="deleteCampaignConfirmed" />

            <tw-confirm ref="deleteCampaignsSelectedConfirm" @confirmDelete="deleteCampaignsSelectedConfirmed" />

            <tw-modal ref="renameCampaignModal" size="large" :title="$t('campaigns.rename_modal.title')" close-button="yes">
                <template #default="slotProps">

                    <rename-campaign :modal="slotProps" @reloadCampaigns="loadAllCampaigns" />

                </template>
            </tw-modal>

        </div>
    </transition>
</template>

<script>
import store from '@stores/index'
import toTemplate from '@mixins/toTemplate'
import campaignFolderFilters from '@component/Folders/CampaignFolderFilters.vue'
import { mapState } from 'vuex'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import permissions from '@mixins/permissions'
import Tracker from '@js/tracker'

export default {
    name: 'Campaigns',
    
    mixins: [toTemplate, permissions],

    components: {
        vueCustomScrollbar,
        campaignFolderFilters
    },

    store,

    data() {
        return {
            settings: {
                suppressScrollX: true,
                tagname: 'div',
                scrollYMarginOffset: 48,
                handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel']
            },
            scrolled: false,
            globalMessages: [],
            showAllLists: [],
            checked: [],
            query: '',
            sending: false,
            activeFilter: null,
            currentStatus: null,
            currentFolder: null,
            currentQuery: null,
        }
    },

    computed: {
        ...mapState(['user', 'loggedFromImpersonator']),
        ...mapState('campaignsModule', [
            'campaignsLoaded', 'campaigns', 'statusCount', 'folders', 'listsAndSegments', 'pagination'
        ])
    },
    
    async beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('campaignsModule/loadCampaigns')
            vm.$root.showSpaLoader = false
        })
    },

    watch: {
        '$root.modalOpened'(opened) {
            opened ? this.settings.handlers = [] : this.settings.handlers = ['click-rail', 'drag-thumb', 'keyboard', 'wheel']
            opened ? document.body.style['overflowY'] = 'hidden' : document.body.style['overflowY'] = 'auto'
        }
    },

    mounted() {
        let vueAppEl = document.getElementById('vueApp')
        vueAppEl.addEventListener('wheel', (event) => this.scrolledContainer(event))
        this.globalMessages = window.globalMessages
    },

    methods: {
        scrolledContainer(event) {
            if(event.target.id === 'vueApp') {
                event.stopPropagation()
                event.preventDefault()
                event.cancelBubble = true
                let mouseEvent = new MouseEvent('wheel')
                mouseEvent.deltaY = event.deltaY
                mouseEvent.deltaX = event.deltaX
                mouseEvent.deltaZ = event.deltaZ
                if(this.$refs['content-layout'] && this.$refs['content-layout'].$el)
                    this.$refs['content-layout'].$el.dispatchEvent(mouseEvent)
            }
        },
        handleScroll() {
            if(this.$refs['content-layout'] && this.$refs['content-layout'].$el)
                this.scrolled = this.$refs['content-layout'].$el.scrollTop !== 0
        },
        async makeScreenshot(campaignId) {
            const response = await this.$http.post(`/spa/campaigns/${campaignId}/make-screenshot`)
            if(response.data.success) {
                this.$message.success(this.$t('campaigns.screenshot_made'))
            } else if(response.data.error) {
                this.$message.error(response.data.error)
            }
        },
        clearFilters(isSearch = false) {
            this.activeFilter = null
            this.currentStatus = null
            this.currentFolder = null
            this.currentQuery = null
            this.checked = []
            if(!isSearch) {
                this.query = null
            }
        },
        async loadAllCampaigns() {
            await store.dispatch('campaignsModule/loadCampaigns', { force: true })
            this.clearFilters()
        },
        showCreateCampaign() {
            this.$refs['createCampaign'].showModal()
            new Tracker().trackEvent('BUTTON_CLICKED', {
                scope: 'campaign creation',
                name: 'New campaign'
            })
        },
        async deleteCampaignConfirmed(campaign) {
            let page = this.pagination.current_page
            const response = await this.$http.get(`/spa/campaigns/${campaign.id}/delete`)
            if(response.data.success) {
                store.dispatch('campaignsModule/loadCampaigns', { page: page, force: true })
                this.$message.success(this.$t('campaigns.campaign_deleted'))
            } else if(response.data.error) {
                this.$message.error(response.data.error)
            }
        },
        deleteCampaign(campaign) {
            this.$refs['deleteCampaignConfirm'].setData({
                twConfirmTitle: this.$t('campaigns.delete_campaign'),
                twConfirmText: this.$t('campaigns.confirm_campaign_deletion', {'campaign': campaign.title}),
                data: campaign,
                twClose: this.$t('common.close'),
                twDelete: this.$t('campaigns.delete_campaign'),
            })
            this.$refs['deleteCampaignConfirm'].showModal()
        },
        deleteSelectedCampaigns() {
            this.$refs['deleteCampaignsSelectedConfirm'].setData({
                twConfirmTitle: this.$t('campaigns.delete_multiple_modal.title'),
                twConfirmText: this.$t('campaigns.delete_multiple_modal.text', { count: this.checked.length}),
                data: this.checked,
                twClose: this.$t('common.close'),
                twDelete: this.$t('campaigns.delete_multiple_modal.button'),
            })
            this.$refs['deleteCampaignsSelectedConfirm'].showModal()
        },
        async deleteCampaignsSelectedConfirmed(selectedCampaigns) {
            const response = await this.$http.get(`/spa/campaigns/${selectedCampaigns.toString()}/delete`)
            if(response.data.success) {
                this.clearFilters()
                store.dispatch('campaignsModule/loadCampaigns', { force: true })

                if (response.data.message){
                    this.$message.warning(response.data.message)
                } else {
                    this.$message.success(this.$t('campaigns.campaigns_deleted'))
                }
            }
            else if(response.data.error) {
                this.$message.error(response.data.error)
            }
        },
        renameCampaign(campaignId, campaignName) {
            this.$refs['renameCampaignModal'].showModalRawData({
                campaignId: campaignId,
                campaignName: campaignName
            })
        },
        getClass(status) {
            if([10,4,11].includes(status))
                return 'tag-danger'
            else if([8].includes(status))
                return 'tag-light-blue'
            else if([1,2,5,7].includes(status))
                return 'tag-warning'
            else if(status === 3)
                return 'pill-success'
            return 'pill-lavender'
        },
        async handlePageChange(page) {
            if(this.$refs['content-layout'] && this.$refs['content-layout'].$el)
                this.$refs['content-layout'].$el.scrollTop = 0
            await store.dispatch('campaignsModule/loadCampaigns', {page: page, folder: this.currentFolder, status: this.currentStatus, query: this.query, force: true})
                .catch(() => {
                    this.$message.error('Error')
                })
        },
        async goToFolder(folder) {
            this.clearFilters()
            if(folder === null) {
                await this.loadAllCampaigns()
                return
            }
            await store.dispatch('campaignsModule/loadCampaigns', {folder: folder, force: true})
                .then(() => {
                    this.currentFolder = folder
                    this.activeFilter = this.folders.find((x) => x.id === folder).name
                })
                .catch(() => {
                    this.$message.error('Error')
                })
        },
        async filterCampaigns(status) {
            this.clearFilters()
            await store.dispatch('campaignsModule/loadCampaigns', {status: status, force: true})
                .then(() => {
                    this.currentStatus = status
                    this.activeFilter = this.$t(`campaigns.folders.status_${status}`)
                })
                .catch(() => {
                    this.$message.error('Error')
                })
        },
        movedToFolder(folders) {
            store.dispatch('campaignsModule/setFolders', folders)
        },
        async handleSearch() {
            if (this.query && this.query !== '') {
                this.clearFilters(true)
                this.sending = true
                await store.dispatch('campaignsModule/loadCampaigns', {query: this.query, force: true})
                    .then(() => {
                        this.currentQuery = this.query
                        this.sending = false
                    })
                    .catch(() => {
                        this.$message.error('Error')
                    })
            }
        },
        async saveToTemplates(campaignId) {
            const response = await this.$http.get(`/campaigns/${campaignId}/save-to-templates`)
            if(response && response.data && response.data.success)
                this.$message.success(this.$t('campaigns.saved_to_templates'))
            else
                this.$message.error('Error')
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    .mc-r .main-panel.spa.scrolled-checked {
        position: relative;

        &:after {
          background: linear-gradient(180deg, rgba(206, 206, 206, 0.5) 0%, rgba(239, 239, 239, 0) 100%);
          top: 140px;
          width: 100%;
          height: 10px;
          position: absolute;
          content: "";
        }
    }

</style>
