<template>
    <div>

        <ValidationObserver ref="observer" slim>
            <form novalidate class="" method="post" @submit.prevent="addContact">

                <div class="flex flex-wrap mt-2">

                    <ValidationProvider v-slot="{ errors }" name="email" rules="required" slim>
                        <div class="flex w-full mb-4">
                            <div class="flex flex-col w-1/2 pr-2.5">
                                <label for="email" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.email_label') }}</label>
                                <input id="email" v-model="email" type="email" :placeholder="$t('appforms.contacts.new_contact.email_placeholder')" name="email" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                            </div>
                        </div>
                    </ValidationProvider>

                    <div v-if="extended" class="mt-2 mb-4 cancel-padding bg-gray-500" style="height: 1px" />

                    <ValidationProvider v-slot="{ errors }" name="firstName" rules="person_name" slim>
                        <div class="flex flex-col w-1/2 pr-2.5 mb-4">
                            <label for="firstName" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.first_name_label') }}</label>
                            <input id="firstName" v-model="firstName" type="text" :placeholder="$t('appforms.contacts.new_contact.first_name_placeholder')" name="firstName" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ errors }" name="lastName" rules="person_name" slim>
                        <div class="flex flex-col w-1/2 pl-2.5 mb-4">
                            <label for="lastName" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.last_name_label') }}</label>
                            <input id="lastName" v-model="lastName" type="text" :placeholder="$t('appforms.contacts.new_contact.last_name_placeholder')" name="lastName" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <div v-if="!extended" class="w-full mt-2 mb-4">
                        <button class="btn btn-tertiary whitespace-nowrap" @click="extended = true"><icon class="mr-1" icon="plus-solid" size="tiny" /> {{ $t('appforms.contacts.new_contact.load_more_button') }}</button>
                    </div>

                    <div v-if="extended" class="flex flex-wrap">
                        <div class="flex flex-col w-1/2 pr-2.5 mb-4">
                            <label for="titleBefore" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.title_before_label') }}</label>
                            <input id="titleBefore" v-model="titleBefore" type="text" :placeholder="$t('appforms.contacts.new_contact.title_before_placeholder')" name="titleBefore" class="tw-input">
                        </div>

                        <div class="flex flex-col w-1/2 pl-2.5 mb-4">
                            <label for="titleAfter" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.title_after_label') }}</label>
                            <input id="titleAfter" v-model="titleAfter" type="text" :placeholder="$t('appforms.contacts.new_contact.title_after_placeholder')" name="titleAfter" class="tw-input">
                        </div>

                        <ValidationProvider v-slot="{ errors }" name="vokativFirstName" rules="person_name" slim>
                            <div class="flex flex-col w-1/2 pr-2.5 mb-4">
                                <label for="vokativFirstName" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.vokativ_first_name_label') }}</label>
                                <input id="vokativFirstName" v-model="vokativFirstName" type="text" :placeholder="$t('appforms.contacts.new_contact.vokativ_first_name_placeholder')" name="vokativFirstName" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                        <ValidationProvider v-slot="{ errors }" name="vokativLastName" rules="person_name" slim>
                            <div class="flex flex-col w-1/2 pl-2.5 mb-4">
                                <label for="vokativLastName" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.vokativ_last_name_label') }}</label>
                                <input id="vokativLastName" v-model="vokativLastName" type="text" :placeholder="$t('appforms.contacts.new_contact.vokativ_last_name_placeholder')" name="vokativLastName" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                        <div class="flex w-full mb-4">
                            <div class="w-1/3 pr-2.5">
                                <ecm-select v-model="gender" :options="genderOptions" option-key="gender_list" option-value-attribute="value" :label="$t('appforms.contacts.new_contact.gender.label')" :title="$t('appforms.contacts.new_contact.gender.title')" />
                            </div>
                            <div class="flex flex-col w-1/3 px-2.5">
                                <span class="txt-14 tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.nameday_label') }}</span>
                                <date-picker v-model="nameday" :placeholder="$t('appforms.contacts.new_contact.nameday_placeholder')" :options="namedayOptions" />
                            </div>
                            <div class="flex flex-col w-1/3 pl-2.5">
                                <span class="txt-14 tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.birthday_label') }}</span>
                                <date-picker v-model="birthday" :placeholder="$t('appforms.contacts.new_contact.birthday_placeholder')" :options="birthdayOptions" />
                            </div>
                        </div>

                    </div>

                    <div v-if="extended" class="mt-2 mb-4 cancel-padding bg-gray-500" style="height: 1px" />

                    <div v-if="extended" class="flex flex-wrap">
                        <div class="flex w-full mb-4">
                            <div class="flex flex-col w-3/5 pr-2.5">
                                <label for="street" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.street_label') }}</label>
                                <input id="street" v-model="street" type="text" :placeholder="$t('appforms.contacts.new_contact.street_placeholder')" name="street" class="tw-input">
                            </div>
                        </div>

                        <div class="flex w-full mb-4">
                            <div class="flex flex-col w-2/5 pr-2.5">
                                <label for="city" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.city_label') }}</label>
                                <input id="city" v-model="city" type="text" :placeholder="$t('appforms.contacts.new_contact.city_placeholder')" name="city" class="tw-input">
                            </div>

                            <ValidationProvider v-slot="{ errors }" name="postalCode" rules="zip" slim>
                                <div class="flex flex-col w-1/5 px-2.5">
                                    <label for="postalCode" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.postal_code_label') }}</label>
                                    <input id="postalCode" v-model="postalCode" type="text" :placeholder="$t('appforms.contacts.new_contact.postal_code_placeholder')" name="postalCode" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>

                        <div class="mb-4">
                            <ecm-select v-model="country" :searchable="true" :options="countryOptions" option-key="country_list" :label="$t('appforms.contacts.new_contact.country_label')" :title="$t('appforms.contacts.new_contact.country_placeholder')" />
                        </div>

                        <div class="flex w-full mb-4">
                            <div class="flex flex-col w-1/2 pr-2.5">
                                <label for="company" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.company_label') }}</label>
                                <input id="company" v-model="company" type="text" :placeholder="$t('appforms.contacts.new_contact.company_placeholder')" name="company" class="tw-input">
                            </div>

                            <ValidationProvider v-slot="{ errors }" name="phoneNumber" rules="phone" slim>
                                <div class="flex flex-col w-1/2 pl-2.5">
                                    <label for="phoneNumber" class="tw-label mb-2.5">{{ $t('appforms.contacts.new_contact.phone_number_label') }}</label>
                                    <input id="phoneNumber" v-model="phoneNumber" type="text" :placeholder="$t('appforms.contacts.new_contact.phone_number_placeholder')" name="phoneNumber" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>

                    </div>

                    <div class="mt-2 border-t border-gray-500 cancel-padding">
                        <div class="tw-modal-buttons w-full px-9">
                            <button class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('appforms.contacts.new_contact.cancel') }}</button>
                            <button :disabled="sending" class="btn btn-primary float-right btn-lg"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.contacts.new_contact.confirmation') }}</button>
                        </div>  
                    </div>
                </div>
            </form>
        </ValidationObserver>
        
    </div> 
</template>

<script>
import Vue from 'vue'
import { ValidationObserver } from 'vee-validate'
import DatePicker from '@component/Inputs/DatePicker.vue'
import { Message } from 'element-ui'

Vue.prototype.$message = Message

export default {
    name: 'AddContactForm',

    props: ['modal'],

    components: {
        ValidationObserver,
        DatePicker
    },

    data() {
        return {
            sending: false,
            success: null,
            message: false,
            extended: false,
            namedayOptions: {weekday: undefined, year: undefined, month: 'numeric', day: 'numeric'},
            birthdayOptions: {weekday: undefined, year: 'numeric', month: 'numeric', day: 'numeric'},
            email: null,
            firstName: null,
            lastName: null,
            titleBefore: null,
            titleAfter: null,
            vokativFirstName: null,
            vokativLastName: null,
            gender: null,
            genderOptions: [
                {
                    value: 'male',
                    name: this.$t('appforms.contacts.new_contact.gender.man_choice')
                },
                {
                    value: 'female',
                    name: this.$t('appforms.contacts.new_contact.gender.woman_choice')
                },
            ],
            nameday: null,
            birthday: null,
            street: null,
            city: null,
            postalCode: null,
            country: null,
            countryOptions: [],
            company: null,
            phoneNumber: null
        }
    },

    mounted() {
        this.getCountries()
    },

    methods: {

        closeModal() {
            this.modal.mclose()
        },

        async getCountries() {
            try {
                const response = await this.$http.get('/contacts/countries')
                this.countryOptions = response.data.countries
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }
        },

        async addContact(evt) {
            evt.preventDefault()
            this.message = null
            this.success = null

            const isValid = await this.$refs.observer.validate()
            if(isValid) {
                this.sending = true

                try {
                    await this.$http.post(`/contacts/${this.modal.mdata.listId}/add-subscriber`, {
                        email: this.email,
                        name: this.firstName,
                        surname: this.lastName,
                        pretitle: this.titleBefore,
                        surtitle: this.titleAfter,
                        vokativ: this.vokativFirstName,
                        vokativ_s: this.vokativLastName,
                        gender: this.gender,
                        nameday: this.nameday ? this.nameday.toISOString().substring(5,10) : null,
                        birthday: this.birthday,
                        street: this.street,
                        city: this.city,
                        zip: this.postalCode,
                        country: this.country,
                        company: this.company,
                        phone: this.phoneNumber,
                    })

                    this.$emit('refreshContacts', this.email)
                    this.closeModal()
                }
                catch(error) {
                    this.success = false
                    this.$message.error(error.message)
                }

                this.sending = false
            }

        }

    }
}
</script>
