<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.enrich_subscribers.title') }}</span>
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="pt-2 px-0 md:px-20 space-y-9">
            <alert v-if="message && success !== null" :type="success === true ? 'success' : 'error'" :message="message" />

            <div class="flex items-center space-x-10">
                <div class="flex flex-col">
                    <h2>{{ $t('contacts.enrich_subscribers.heading') }}</h2>
                    <span class="txt-14 text-gray-900 mt-2.5">{{ $t('contacts.enrich_subscribers.description') }}</span>
                    <button class="btn btn-primary btn-lg my-6 w-min" @click="triggerEnrich">{{ $t('contacts.enrich_subscribers.button') }}</button>
                    <span v-if="!account.isEnglishAccount" class="txt-12 text-gray-700" v-html="$t('contacts.enrich_subscribers.supported_countries')" />
                </div>
                <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-lists.png" style="width: 304.76px" alt="list-ilustration" class="hidden md:block">
            </div>
        </div>
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'
import Tracker from "@js/tracker";

export default {
    name: 'EnrichSubscribers',

    store,

    props: ['list'],

    data() {
        return {
            activated: true,
            message: null,
            success: null
        }
    },

    computed: {
        ...mapState(['account']),
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    async beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$root.showSpaLoader = false
        })
    },

    methods: {
        async triggerEnrich() {
            try {
                await this.$http.get(`/contacts/${this.list.id}/enrich-subscribers-trigger`)
                this.message = this.$t('contacts.enrich_subscribers.enrichment_queued_alert')
                this.success = true
                new Tracker().trackEvent('BUTTON_CLICKED', {
                    scope: 'contacts',
                    name: 'enrich contacts'
                })
            }
            catch(error) {
                this.message = error.message
                this.success = false
            }
        }
    }
}
</script>