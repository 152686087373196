
<div @keyup.enter="signIn">

    <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]">
        {{ $t('auth.login.sign_in') }}
    </div>

    <div class="text-auth-base text-auth-gray-500 text-center">
        {{ $t('auth.login.account_address') }} <span class="semibold text-auth-gray-900">{{ accountUrl }}</span>
    </div>

    <hr class="mt-5 mb-6">

    <alert v-if="requestError" class="mb-5" type="error" :message="requestError" />

    <FormInput
            id="loginEmail"
            :value="email"
            :errors="emailErrors"
            :autofocus="!autofocusPassword"
            class="w-full"
            type="email"
            name="email"
            :label="$t('auth.login.email.label')"
            required
            :placeholder="$t('auth.login.email.placeholder')"
            @input="emailChanged" />
    <FormInput id="loginPassword" v-model="password" :errors="passwordErrors" :autofocus="autofocusPassword" name="password" class="w-full mt-5" type="password" :label="$t('auth.login.password.label')" required :placeholder="$t('auth.login.password.placeholder')" />
    <FormCheckbox v-model="rememberPassword" class="mt-6" :label="$t('auth.login.remember_password')" />

    <FormButton type="button" :loading="loading" xlarge primary square class="mt-8 w-full" @click="signIn">
        <span class="text-auth-base">{{ $t('auth.login.sign_in_cta') }}</span>
    </FormButton>

    <div class="text-auth-gray-500 text-auth-sm text-center semibold mt-[42px]">
        <span>{{ $t('auth.login.forgot_password') }}&nbsp;</span>
        <router-link :to="{name: 'passwordReset'}" class="cursor-pointer hover:no-underline text-auth-green-500">
            {{ $t('auth.login.forgot_password_cta') }}
        </router-link>
    </div>
</div>
