<template>

    <div v-on-clickaway="hideDropdown" :class="{open: opened}" class="ecomail-select">

        <div v-if="label" class="ecomail-select-label" :class="{ 'text-auth-gray-700': !hasError, 'text-auth-red-800': hasError }">
            <span>{{ label }}</span>
            <svg
                    v-if="required && showRequireIndicatior"
                    :class="{ 'text-auth-green-500': !hasError, 'text-auth-red-800': hasError }"
                    class="w-3 h-3 mt-1 ml-1"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                <path
                        d="M6.00003 1.75781V6.00045M6.00003 6.00045L6.00003 10.2431M6.00003 6.00045L10.2427 6.00045M6.00003 6.00045L1.75739 6.00045"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                <path
                        d="M9 3L6 6M6 6L3 9M6 6L9 9M6 6L3 3"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
            </svg>
        </div>
        <div
                ref="title"
                class="relative flex px-[14px] justify-between items-center h-[44px] transition duration-150 ease-in-out border border-auth-gray-300 text-auth-sm sm:leading-5 whitespace-nowrap cursor-pointer"
                :class="{
                    'disabled': disabled,
                    'rounded-[4px]': !opened,
                    'rounded-t-[4px] outline-none': opened,
                    'searching': opened && searchable,
                    'text-auth-red-800 searching bg-white border-auth-red-800 border-red-800': opened && hasError,
                    'bg-auth-white': opened && !hasError,
                    'text-auth-red-800 bg-red-50 hover:bg-white border-auth-red-300 placeholder-auth-red-500': !opened && hasError,
                    'hover:bg-auth-white bg-auth-gray-50 placeholder-auth-gray-500': !hasError
                }"
                @click.prevent="titleAction">
            <input v-if="opened && searchable" ref="search-input" v-model="search" :placeholder="title" type="text" @click.stop="">
            <span v-else-if="opened">{{ title }}</span>
            <span v-else-if="value" class="selected flex items-center">
                <slot name="icon" />
                <slot name="selectedLabel" />
            </span>
            <span v-else>{{ title }}</span>
            <div
                    v-if="hasError"
                    class="absolute inset-y-0 right-0 flex items-center pr-3 mt-1 pointer-events-none">
                <svg
                        class="w-5 h-5 text-auth-red-500"
                        fill="currentColor"
                        viewBox="0 0 20 20">
                    <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd" />
                </svg>
            </div>
            <icon v-else icon="angle-down-regular" size="big" />
        </div>
        <div v-show="opened" ref="items" class="ecomail-select-items bg-white" :style="{maxHeight: `${optionsDropdownHeight * optionHeight}px`}">
            <vue-custom-scrollbar ref="scroll" tagname="div" class="scroll" :settings="settings">
                <slot :trigger-select="handleSelect" :search="search" />
            </vue-custom-scrollbar>
        </div>
        <div v-if="hasError && !opened">
            <p
                    v-for="(error, i) in errors"
                    :key="i"
                    class="text-auth-sm text-left text-auth-red-600">
                {{ error }}
            </p>
        </div>
    </div>

</template>

<script>

import vueCustomScrollbar from 'vue-custom-scrollbar'
import { mixin as clickaway } from 'vue-clickaway'

export default {

    name: 'FormSelect',

    mixins: [clickaway],

    props: {
        value: String,
        label: {
            type: String,
        },
        title: {
            type: String,
            default() {
                return this.$t('forms.select_please')
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: 'ecm-select',
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        optionsDropdownHeight: {
            type: Number,
            default: 6
        },

        errorVuelidate: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        required: {
            type: Boolean,
            default: false,
        },
        showRequireIndicatior: {
            type: Boolean,
            default: true,
        },
    },

    components: {
        vueCustomScrollbar,
    },

    data () {
        return {
            opened: false,
            settings: {
                suppressScrollX: true
            },
            search: '',
            optionHeight: 49,
        }
    },

    computed: {
        hasError() {
            if (this.errorVuelidate || this.errors.length > 0) {
                return true
            } else return false
        },
    },

    methods: {
        titleAction() {
            if(this.opened)
                this.handleSelect(null)
            else
                this.toggleDropdown()
        },
        toggleDropdown() {
            if(this.disabled) {
                this.opened = false
                return
            }
            this.opened = !this.opened
            this.$refs['scroll'].$el.scrollTop = 0
            if(this.opened) {
                this.$nextTick(() => {
                    let width = Math.max(this.$refs['items'].offsetWidth, this.$refs['title'].offsetWidth)
                    this.$refs['items'].style.width = `${width}px`
                    this.$refs['title'].style.width = `${width}px`
                    if(this.searchable === true && this.$refs['search-input']) {
                        this.$refs['search-input'].focus()
                    }
                })
            } else {
                this.$refs['title'].style.width = 'auto'
                this.search = ''
            }
        },
        hideDropdown() {
            this.opened = false
            this.$refs['scroll'].$el.scrollTop = 0
            this.$refs['title'].style.width = 'auto'
        },
        handleSelect(value) {
            this.hideDropdown()
            this.$emit('input', value)
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    .ecomail-select {
        position: relative;
        display: flex;
        flex-direction: column;

        .ecomail-select-title {
            white-space: nowrap;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            color: #222222;
            border-radius: 6px;
            display: flex;
            justify-content: space-between;
            padding: 8px 14px;
            font-size: 14px;
            line-height: 28px;
            align-items: center;

            &:disabled {
                cursor: not-allowed;
                color: $grey-70;
                background-color: $grey-10;
                border-color: $grey-50;
            }

            &:hover, &:focus {
                cursor: pointer;
                text-decoration: none;
            }
        }

        &.open {
            .ecomail-select-title {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .ecomail-select-label {
            font-weight: 600;
            font-size: 14px;
            text-align: left;
            margin-bottom: 4px;
        }

        .ecomail-select-items {
            display: flex;
            flex-direction: column;
            overflow: auto;
            position: absolute;
            top: 100%;
            left: 0;
            border-bottom: 1px solid #D7D7D7;
            border-left: 1px solid #D7D7D7;
            border-right: 1px solid #D7D7D7;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
            min-width: 150px;
            text-align: left;
            z-index: 50;
            border-radius: 0px 0px 4px 4px;
            list-style-type: none;
            width: auto;
        }
    }

</style>
