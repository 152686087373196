<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.facebook_lead.title') }}</span>
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="flex flex-col lg:flex-row lg:space-x-5 space-y-2.5 lg:space-y-0 items-start">
            <div class="flex flex-col w-full lg:w-2/6 lg:mt-2">
                <h4 class="text-gray-1000 font-normal mb-1">
                    {{ $t('contacts.facebook_lead.heading') }}
                </h4>
                <p class="text-gray-900 txt-14 mb-6" v-html="$t('contacts.facebook_lead.description')" />
                <p v-if="showMessenger" class="text-gray-900 txt-14" v-html="$t('contacts.facebook_lead.description_messenger')" />
            </div>

            <div class="w-full lg:w-4/6 flex flex-col space-y-4">

                <iframe
                        ref="iframe"
                        :src="`${fbIframeHostname}/facebook/leads`"
                        width="100%"
                        @load="sendParametersToIframe(), iframeLoaded = true" />

                <div v-if="!leadsLoaded" class="w-4 h-4 mt-4 double-loader" />

                <div v-else-if="connectedLeads && Object.keys(connectedLeads).length" class="panel panel-white pt-7.5 p-8 space-y-5">
                    <h3>{{ $t('contacts.facebook_lead.connected_sites_title') }}</h3>
                    <div class="w-full overflow-x-auto md:overflow-x-visible">
                        <div class="table tw-table tw-table-large max-w-full">
                            <div v-for="(lead, index) in connectedLeads" :key="`facebook-page-${index}`" class="table-row ">
                                <div class="table-cell align-middle truncate w-full" style="max-width: 0px;">
                                    <a v-if="showMessenger" href="#" class="txt-14 link-blue semibold" @click.prevent="showMessengerSettingsModal(lead.page_id)">{{ lead.page_name }} ({{ lead.page_id }})</a>
                                    <span v-else class="font-semibold">{{ lead.page_name }} ({{ lead.page_id }})</span>
                                </div>
                                <div class="table-cell align-middle text-right">
                                    <div class="flex items-center space-x-2.5 justify-end">
                                        <button class="btn btn-tertiary icon-only" @click="showDisconnectLeadModal(lead)"><div v-if="disconnecting === lead.page_id" class="double-loader loader-sm loader-grey" /><icon v-else class="text-gray-900" icon="trash-solid" /></button>
                                        <button v-if="showMessenger" class="btn btn-tertiary" @click="showMessengerSettingsModal(lead.id)">{{ $t('contacts.facebook_lead.messenger_settings_button') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <tw-modal ref="messengerSettings" :title="$t('contacts.facebook_lead.modal.title')" width="550" close-button="yes">

            <template #default="slotProps">

                <messenger-domains-form :modal="slotProps" />

            </template>

        </tw-modal>

        <tw-confirm ref="disconnectLead" @confirmDelete="disconnectLead" />
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'
import { fbIframeHostname } from '../../fb-helpers'

export default {
    name: 'FacebookLead',

    store,

    props: ['list'],

    data() {
        return {
            activated: true,
            facebookAppId: '652619828129906',
            facebookOptions: {
                scope: 'manage_pages,pages_messaging,ads_management,leads_retrieval,pages_manage_metadata,pages_read_engagement,pages_show_list'
            },
            disconnecting: null,
            fbIframeHostname,
            iframeLoaded: false,
        }
    },

    computed: {
        ...mapState('contactsModule', [
            'leadsLoaded', 'connectedLeads'
        ]),
        showMessenger() {
            return this.$root.showMessenger
        }
    },

    watch: {
        leadsLoaded(val) {
            if(val && this.iframeLoaded) {
                this.sendParametersToIframe()
            }
        }
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('contactsModule/loadFacebookLeads', {listId: to.params.list})
        })
    },

    mounted () {
        window.addEventListener('message', this.receiveIframeRequest)
    },
    beforeDestroy () {
        window.removeEventListener('message', this.receiveIframeRequest)
    },

    methods: {
        sendParametersToIframe() {
            this.$refs.iframe.contentWindow.postMessage(
                {
                    listId: this.list.id,
                    connectedLeads: this.connectedLeads,
                    language: window.vueLocale,
                },
                fbIframeHostname
            )
        },
        receiveIframeRequest (event) {
            if(event.origin !== fbIframeHostname) return
            if(event.data.iframeHeight) {
                this.$refs.iframe.height = event.data.iframeHeight
            }
            else if(event.data.refreshLeads) {
                this.refreshLeads()
            }
        },
        showDisconnectLeadModal(lead) {
            this.$refs['disconnectLead'].setData({
                twConfirmTitle: this.$t('contacts.facebook_lead.disconnect_modal.title'),
                twConfirmText: this.$t('contacts.facebook_lead.disconnect_modal.text', {page: lead.page_name}),
                data: lead,
                twClose: this.$t('common.close'),
                twDelete: this.$t('contacts.facebook_lead.disconnect_modal.button'),
            })
            this.$refs['disconnectLead'].showModal()
        },
        async disconnectLead(lead) {
            this.disconnecting = lead.id

            try {
                await this.$http.delete(`/spa/contacts/facebook-lead/${lead.id}`)
                this.$message.success(this.$t('contacts.facebook_lead.page_disconnected_alert', {page: lead.page_name}))
                this.refreshLeads()
            }
            catch(error) {
                this.$message.error(error.message)
            }
            this.disconnecting = null
        },
        async showMessengerSettingsModal(pageId) {
            this.$refs['messengerSettings'].showModalRawData({listId: this.list.id, pageId: pageId})
        },
        refreshLeads() {
            store.dispatch('contactsModule/loadFacebookLeads', {listId: this.list.id})
        },
    }
}
</script>
