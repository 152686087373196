<template>
    <div @keyup.enter="signIn">

        <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]">
            {{ $t('auth.login.sign_in') }}
        </div>

        <div class="text-auth-base text-auth-gray-500 text-center">
            {{ $t('auth.login.account_address') }} <span class="semibold text-auth-gray-900">{{ accountUrl }}</span>
        </div>

        <hr class="mt-5 mb-6">

        <alert v-if="requestError" class="mb-5" type="error" :message="requestError" />

        <FormInput
                id="loginEmail"
                :value="email"
                :errors="emailErrors"
                :autofocus="!autofocusPassword"
                class="w-full"
                type="email"
                name="email"
                :label="$t('auth.login.email.label')"
                required
                :placeholder="$t('auth.login.email.placeholder')"
                @input="emailChanged" />
        <FormInput id="loginPassword" v-model="password" :errors="passwordErrors" :autofocus="autofocusPassword" name="password" class="w-full mt-5" type="password" :label="$t('auth.login.password.label')" required :placeholder="$t('auth.login.password.placeholder')" />
        <FormCheckbox v-model="rememberPassword" class="mt-6" :label="$t('auth.login.remember_password')" />

        <FormButton type="button" :loading="loading" xlarge primary square class="mt-8 w-full" @click="signIn">
            <span class="text-auth-base">{{ $t('auth.login.sign_in_cta') }}</span>
        </FormButton>

        <div class="text-auth-gray-500 text-auth-sm text-center semibold mt-[42px]">
            <span>{{ $t('auth.login.forgot_password') }}&nbsp;</span>
            <router-link :to="{name: 'passwordReset'}" class="cursor-pointer hover:no-underline text-auth-green-500">
                {{ $t('auth.login.forgot_password_cta') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import FormInput from '@component/Auth/FormInput.vue'
import FormCheckbox from '@component/Auth/FormCheckbox.vue'
import FormButton from '@component/Auth/FormButton.vue'
import hasher from '@mixins/hasher'
import { mapState, mapMutations } from 'vuex'
import { validate } from 'vee-validate'

export default {
    name: 'AuthLayout',

    mixins: [hasher],

    components: {
        FormInput,
        FormCheckbox,
        FormButton,
    },

    data() {
        return {
            autofocusPassword: false,
            password: null,
            rememberPassword: false,
            loading: false,
            emailErrors: [],
            passwordErrors: [],
            requestError: null,
        }
    },

    computed: {
        ...mapState(['email']),
        accountUrl() {
            return window.location.hostname
        }
    },

    watch: {
        password() {
            this.passwordErrors = []
        }
    },

    created() {
        let params = new URLSearchParams(window.location.search)
        if(params.get('email')) {
            this.SET_EMAIL({email: params.get('email')})
            this.autofocusPassword = true
        }
    },

    methods: {
        emailChanged(email) {
            this.emailErrors = []
            this.SET_EMAIL({email: email})
        },
        ...mapMutations(['SET_EMAIL']),
        async signIn() {
            try {
                const valid = await this.validateForm()
                if(!valid)
                    return

                this.loading = true
                const response = await this.$http.post('/auth/login', {
                    email: this.email,
                    password: this.hashLoginPassword(this.password),
                    remember: this.rememberPassword
                })
                // use the 2 factor as the redirectUrl
                if(response.status === 204) {
                    window.location.href = '/'
                }
                else if(response.data.redirectUrl) {
                    window.location.href = response.data.redirectUrl.replace('http://', 'https://')
                }
                this.loading = false
            } catch(e) {
                this.requestError = e.message
                this.loading = false
            }
        },
        async validateForm() {
            const emailValidation = await validate(this.email, 'required|email')
            this.emailErrors = emailValidation.errors

            const passwordValidation = await validate(this.password, 'required')
            this.passwordErrors = passwordValidation.errors

            return emailValidation.valid && passwordValidation.valid
        }
    }
}
</script>

<style scoped lang="scss">

</style>