<template>

    <transition name="slidein">
        <div v-if="visible" class="tw-modal-bg" @mousedown.self="closeModal" @keyup="keyUp">

            <div class="tw-modal-container pt-4 px-6 pb-16" :style="{width: width ? `${width}px`: ''}">
                <div class="flex items-end justify-end">
                    <a href="#" class="tw-modal-close bg-gray-500 hover:bg-gray-600 hover:text-gray-1000 rounded-full" @click.stop.prevent="closeModal"><div class="svg-wrapper"><svg-vue icon="times-solid" class="text-gray-900" /></div></a>
                </div>

                <div class="">
                    <slot :mclose="closeModal" />
                </div>

            </div>

        </div>
    </transition>

</template>

<script>

export default {
    name: 'BasicModal',

    props: ['title', 'width', 'visibleOnLoad'],
	
    data () {
        return {
            visible: false,
        }
    },

    watch: {
        visible(val) {
            this.$root.modalOpened = val
        }
    },

    mounted: function () {
        this.visible = this.visibleOnLoad ?? false
    },

    created: function () {
        document.addEventListener('keyup', (evt) => this.keyUp(evt))
    },

    methods: {

        showModal() {
            this.mdata = null
            this.visible = true
        },

        keyUp(event) {
            if (event.keyCode === 27) {
                this.closeModal()
            }
        },

        closeModal() {
            this.visible = false
            this.$emit('closed')
        }

    },

}
</script>
