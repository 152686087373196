/* global FB */

export function loadFbSdk(appId, version) {
    return new Promise(resolve => {
        window.fbAsyncInit = function () { // eslint-disable-line func-names
            FB.init({
                appId,
                xfbml: false,
                version,
                cookie: true
            })
            FB.AppEvents.logPageView()
            resolve('SDK Loaded!')
        };
        (function (d, s, id) { // eslint-disable-line func-names
            const fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) { return }
            const js = d.createElement(s); js.id = id
            js.src = '//connect.facebook.net/en_US/sdk.js'
            fjs.parentNode.insertBefore(js, fjs)
        }(document, 'script', 'facebook-jssdk'))
    })
}

export function fbLogin(options) {
    return new Promise(resolve => {
        window.FB.login(response => resolve(response), options)
    })
}

export function fbLogout() {
    return new Promise(resolve => {
        window.FB.logout(response => resolve(response))
    })
}


export const appId = '652619828129906'
export const version = 'v3.2'
export const audiencesLoginOptions = {
    auth_type: 'reauthorize',
    scope: 'ads_management,ads_read,pages_messaging,leads_retrieval,manage_pages,business_management'
}
export const leadsLoginOptions = {
    scope: 'manage_pages,pages_messaging,ads_management,leads_retrieval,pages_manage_metadata,pages_read_engagement,pages_show_list,business_management'
}

export const fbIframeHostname = 'https://facebook.ecomailapp.cz'
