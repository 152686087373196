
<transition name="long-fadeout">
    <div class="ec-container md:spa-container relative main-container flex flex-wrap mx-auto flex-col w-full md:flex-row">
        <div class="mc-r w-full h-full">
            <div class="main-panel solo spa" :class="{scrolled: scrolled}">

                <h2 class="flex justify-between items-center px-9 py-3 text-gray-1000 txt-22">
                    {{ $t('contacts.index.title') }}

                    <button class="btn btn-primary btn-lg icon-left" @click="createList"><icon icon="plus-solid" size="small" /> <span>{{ $t('contacts.index.new_list_button') }}</span></button>
                </h2>

                <div v-if="!listsLoaded" ref="content-layout" class="w-4 h-4 mt-6 ml-9 double-loader" />
                <vue-custom-scrollbar v-else ref="content-layout" :settings="settings" class="spa-container" @ps-y-reach-start="scrolled = false" @ps-scroll-y="handleScroll()">

                    <div v-if="lists && Object.keys(lists).length" class="w-full overflow-x-auto md:overflow-x-visible">
                        <alert v-if="success === true" class="mb-4" type="success" :message="message" />
                        <alert v-else-if="success === false" class="mb-4" type="error" :message="message" />

                        <div class="table tw-table tw-table-large max-w-full">
                            <div v-for="(list, index) in lists" :key="`list-${index}`" class="table-row ">
                                <div class="table-cell align-middle w-full max-w-0">
                                    <div class="flex items-center">
                                        <icon icon="list-regular" size="large" class="mr-5 grey-65" />
                                        <a @click="goToSubscribersOverview(list.id)" class="txt-14 link-blue semibold cursor-pointer">
                                            {{ list.name }}
                                        </a>
                                    </div>
                                </div>
                                <div class="table-cell align-middle text-right whitespace-nowrap text-gray-800" v-html="$tc('contacts.index.active', list.active_subscribers)" />
                                <div class="table-cell align-middle whitespace-nowrap text-gray-800" v-html="$t('contacts.index.created', {created: list.created ? list.created : 'N/A'})" />
                                <div class="table-cell align-middle text-right" style="min-width: 200px">
                                    <div v-dropdown="'open'" class="tw-dropdown">
                                        <div class="btn-group">
                                            <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="btn btn-tertiary whitespace-nowrap">
                                                <div v-if="exportSending === list.id" class="double-loader loader-sm loader-grey mr-2" /><span v-else>{{ $t('contacts.index.overview') }}</span>
                                            </router-link><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                        </div>
                                        <div class="tw-dropdown-items flex-col">
                                            <router-link v-if="user.role !== 'custom' || checkRole(user, 'manage-contacts-import')" :to="{name: 'subscribersImport', params: {list: list.id}}">
                                                {{ $t('contacts.index.import_button') }}
                                            </router-link>
                                            <router-link v-if="user.role !== 'custom' || checkRole(user, 'manage-contacts-forms')" :to="{name: 'subscribersForms', params: {list: list.id}}">
                                                {{ $t('contacts.index.form_button') }}
                                            </router-link>
                                            <button @click="getExport(list.id)">{{ $t('contacts.index.export_button') }}</button>
                                            <button class="dd-divided dd-critical" @click="showDeleteList(list)">{{ $t('contacts.index.delete_button') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else class="px-16 py-8 bg-white mt-3 md:mx-40 lg:mx-64">
                        <div class="flex flex-row items-center justify-between">
                            <div class="flex flex-col">
                                <p class="mb-2.5 txt-18 text-gray-1000">
                                    {{ $t('contacts.index.heading') }}
                                </p>
                                <div>
                                    <span class="txt-14 text-gray-900" v-html="$t('contacts.index.description')" />
                                    <br>
                                    <button class="btn btn-primary btn-lg icon-left mt-6" @click="createList"><icon icon="plus-solid" size="small" /><span>{{ $t('contacts.index.new_list_button') }}</span></button>
                                </div>
                            </div>
                            <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-lists.png" style="width: 305px" alt="feed-ilustration">
                        </div>
                    </div>
                </vue-custom-scrollbar>
            </div>
        </div>

        <tw-modal ref="createListWarning" :title="$t('contacts.index.create_list_warning.title')" close-button="yes" width="512">
            <template #default="slotProps">

                <create-list-warning :modal="slotProps" @continue="showCreateList" />

            </template>
        </tw-modal>

        <tw-modal ref="createListModal" :title="$t('contacts.index.create_new_list')" width="472" close-button="yes">
            <template #default="slotProps">

                <new-list :modal="slotProps" @listCreated="listCreated" />

            </template>
        </tw-modal>

        <tw-modal ref="deleteListWarning" :title="$t('contacts.index.delete_list_warning.title')" close-button="yes" width="512">
            <template #default="slotProps">

                <delete-list-warning :modal="slotProps" />

            </template>
        </tw-modal>

        <tw-confirm ref="deleteListModal" @confirmDelete="deleteList" />

    </div>
</transition>
