<template>

    <ValidationObserver ref="observer" slim>

        <form class="-mb-3" novalidate method="post" @submit.prevent="saveSettings">

            <alert v-if="success === true" class="mt-4 mb-10" type="success" :message="message" />

            <alert v-if="success === false" class="mt-4 mb-10" type="error" :message="message" />

            <div class="settings flex flex-col card-left no-hover card-white" :class="{card: webhooksEnabled, 'mt-5': !webhooksEnabled}">
                <ecm-switch v-model="webhooksEnabled">
                    {{ $t('list_settings.webhook_settings_label') }}
                </ecm-switch>

                <ValidationProvider v-slot="{ errors }" name="webhookUrl" rules="required|url" slim>
                    <div v-if="webhooksEnabled" class="input w-full flex flex-col mt-3 pl-switch">
                        <label for="webhookUrl" class="tw-label mb-2.5">{{ $t('list_settings.webhook_settings_url_label') }}</label>
                        <input id="webhookUrl" v-model="webhookUrl" type="text" class="tw-input max-w-xs" placeholder="http://">
                        <span class="tw-errors">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>

            <button v-if="hasChanges || webhooksEnabled" type="submit" class="btn btn-lg btn-primary mt-8" :class="{'ml-4': webhooksEnabled}"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('list_settings.save_changes') }}</button>

        </form>

    </ValidationObserver>

</template>

<script>
import {ValidationObserver} from 'vee-validate'

export default {

    name: 'WebhooksSettings',

    props: ['listId', 'url'],

    components: {
        ValidationObserver
    },

    data () {
        return {
            sending: false,
            success: null,
            message: '',
            webhooksEnabled: false,
            webhookUrl: null,
            hasChanges: false
        }
    },

    watch: {
        webhookUrl(newVal) {
            this.hasChanges = newVal !== this.url
        },
        webhooksEnabled(enabled) {
            if(!enabled) {
                this.webhookUrl = ''
            } else {
                this.hasChanges = true
                this.webhookUrl = this.url
            }
        },
    },

    created() {
        this.webhookUrl = this.url
        if(this.url) {
            this.webhookUrl = this.url
            this.webhooksEnabled = true
        }
    },

    methods: {
        async saveSettings(evt) {

            evt.preventDefault()
            this.success = null
            this.message = null
            const isValid = await this.$refs.observer.validate()

            if(isValid) {
                this.sending = true

                try {
                    await this.$http.post(`/spa/contacts/${this.listId}/settings`, {
                        settings: {
                            webhook: {url: this.webhookUrl !== '' ? this.webhookUrl : null}
                        }
                    })
                    this.success = true
                    this.message = this.$t('common.changes_saved')
                    this.hasChanges = false

                }
                catch(error) {
                    this.success = false
                    this.message = error.message
                }

                this.sending = false
            }
        }
    }
}
</script>
