import confetti from 'canvas-confetti'

function randomInRange(min, max) {
    return Math.random() * (max - min) + min
}

const ukraine_colors = ['#0066cc', '#ffcc00']

export default {
    methods: {
        fireworks(duration) {
            duration *= 1000
            const animationEnd = Date.now() + duration
            const config = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 1000 }
            var interval = setInterval(function() {
                var timeLeft = animationEnd - Date.now()

                if (timeLeft <= 0) {
                    return clearInterval(interval)
                }

                var particleCount = 50 * (timeLeft / duration) + 8
                // since particles fall down, start a bit higher than random
                window.setTimeout(() => {
                    confetti(Object.assign({}, config, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }, colors: ukraine_colors }))
                }, 180)
                confetti(Object.assign({}, config, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }, colors: ukraine_colors }))
            }, 450)
        },
        bigConfetti(origin) {
            const config = { zIndex: 1000, origin: origin, ticks: 500 }
            confetti(Object.assign({}, config,{
                particleCount: 100,
                spread: 26,
                startVelocity: 55,
                decay: 0.92,
                colors: ukraine_colors
            }))
            confetti(Object.assign({}, config,{
                particleCount: 40,
                spread: 60,
                colors: ukraine_colors
            }))
            confetti(Object.assign({}, config,{
                particleCount: 65,
                spread: 100,
                decay: 0.92,
                colors: ukraine_colors
            }))
            confetti(Object.assign({}, config,{
                particleCount: 20,
                spread: 120,
                startVelocity: 25,
                decay: 0.92,
                colors: ukraine_colors
            }))
            confetti(Object.assign({}, config,{
                particleCount: 20,
                spread: 120,
                startVelocity: 45,
                colors: ukraine_colors
            }))
        },
        schoolPride(duration) {
            duration *= 1000
            const animationEnd = Date.now() + duration

            // var colors = ['#85C900', '#2B5800', '#ffffff'];

            (function frame() {
                confetti({
                    particleCount: 3,
                    angle: 45,
                    spread: 45,
                    origin: { x: 0, y: 0.6 },
                    colors: ukraine_colors,
                    startVelocity: 43,
                })
                confetti({
                    particleCount: 3,
                    angle: 135,
                    spread: 45,
                    origin: { x: 1, y: 0.6 },
                    colors: ukraine_colors,
                    startVelocity: 43,
                })

                if (Date.now() < animationEnd) {
                    requestAnimationFrame(frame)
                }
            }())
        },
    }
}