
<transition name="slidein">
    <ValidationObserver ref="observer" slim>
        <div v-if="visible" class="tw-modal-bg" @mousedown.self="closeModal" @keyup="keyUp">
            <div class="tw-modal-container tw-modal-600">
                <form method="post" @submit.prevent="importForm">
                    <div class="tw-modal-head">
                        <h2 class="m-0 txt-18 text-gray-1000">
                            {{ $t('forms.import') }}
                        </h2>
                        <a href="#" class="tw-modal-close bg-gray-500 hover:bg-gray-600 hover:text-gray-1000 rounded-full" @click.stop.prevent="closeModal"><div class="svg-wrapper"><svg-vue icon="times-solid" class="text-gray-900" /></div></a>
                    </div>

                    <div class="tw-modal-content">
                        <div class="mb-6 text-gray-900 text-sm" v-html="$t('forms.import_hint')" />
                        <ValidationProvider v-slot="{ errors }" name="formTitle" rules="required" slim>
                            <div class="input flex flex-col mb-6">
                                <label for="formTitle" class="tw-label mb-2">{{ $t('forms.new.step1.title') }}</label>
                                <input id="formTitle" v-model="localName" type="text" name="name" class="tw-input max-w-xs" :placeholder="$t('forms.new.step1.title_ph')">
                                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <upload id="formJsonSelector" key="upload-json" accepted=".json,application/json" bucket="s3-form-imports" name="fileUrl" @removed="fileRemoved" @uploaded="fileUploaded" />

                        <div class="tw-modal-buttons">
                            <button class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('forms.form_close_props_editor') }}</button>
                            <button :disabled="sending || !fileUrl" class="btn btn-primary float-right btn-lg" data-cy="submit-create-template" type="submit"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('templates.continue') }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </ValidationObserver>
</transition>

