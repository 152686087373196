<template>

    <div id="newTemplate">

        <div class="hidden md:block" style="padding-top: 14px">
            <ValidationObserver ref="observer" slim>
                <form ref="newtemplateform" novalidate data-cy="create-template-form" @submit.prevent="createTemplate">

                    <alert v-if="success !== null" class="mb-4" :type="success === 'true' ? 'success' : 'error'" :message="message" />
                    <ValidationProvider v-slot="{ errors }" name="templateName" rules="required" slim>
                        <div class="input flex flex-col">
                            <label for="templateName" class="tw-label mb-2">{{ $t('templates.new-template-name') }}</label>
                            <input id="templateName" v-model="name" data-cy="template_name-input" name="name" type="text" class="tw-input" :placeholder="$t('templates.new-template-name-ph')" :class="{'tw-err': errors.length > 0 }">
                            <span class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <div class="flex mt-5 space-x-2.5">
                        <button type="button" class="flex justify-between items-center panel flex-col w-1/4 has-green-hover" :class="{selected: type === 'mjml'}" @click="setType('mjml')">
                            <img class="mt-4" src="https://ecomail-assets.s3.amazonaws.com/old/images/templates/icon-Editor.png" style="width: 78px; height: 78px;" alt="">
                            <div class="text-center w-full my-4 dimmed90 txt-13">{{ $t('templates.interactive') }}</div>
                        </button>
                        
                        <button data-cy="template-type-html" type="button" class="flex justify-between items-center panel flex-col w-1/4 has-green-hover" :class="{selected: type === 'html'}" @click="setType('html')">
                            <img src="https://ecomail-assets.s3.amazonaws.com/old/images/templates/icon-HTML.png" style="margin-top: 20px;width: 88px; height: 68px;" alt="">
                            <div class="text-center w-full my-4 dimmed90 txt-13">{{ $t('templates.html') }}</div>
                        </button>
                        <button type="button" class="flex justify-between items-center panel flex-col w-1/4 has-green-hover" :class="{selected: type === 'zip'}" @click="setType('zip')">
                            <img src="https://ecomail-assets.s3.amazonaws.com/old/images/templates/icon-ZIP.png" style="margin-top: 15px;width: 57px; height: 75px;" alt="">
                            <div class="text-center w-full my-4 dimmed90 txt-13">{{ $t('templates.zip') }}</div>
                        </button>
                        <button type="button" class="flex justify-between items-center panel flex-col w-1/4 has-green-hover" :class="{selected: type === 'json'}" @click="setType('json')">
                            <img src="https://ecomail-assets.s3.amazonaws.com/old/images/templates/icon-JSON.png" style="margin-top: 15px;width: 67px; height: 75px;" alt="">
                            <div class="text-center w-full my-4 dimmed90 txt-13">{{ $t('templates.json') }}</div>
                        </button>
                    </div>

                    <div v-if="type === 'mjml'" class="flex w-full bg-messages-info-100 border border-messages-info-200 mt-8" style="border-radius: 3px;">
                        <div class="relative flex-shrink-0 transform -translate-x-2" style="width: 140px">
                            <img class="absolute bottom-0" src="https://ecomail-assets.s3.amazonaws.com/old/images/new-template-mjml.png" style="width: 140px;" alt="Interactive templates illustration">
                        </div>
                        <p class="text-messages-info-400 txt-14 py-4 px-3" v-html="$t('templates.interactive-help')" />
                    </div>

                    <div v-if="type === 'html'" class="mt-4">
                        <alert type="info" :message="$t('templates.html-help')" />
                    </div>

                    <div v-if="type === 'zip'" class="mt-4">
                        <alert type="info" :message="$t('templates.zip-help')" />
                        <upload id="templateFileSelecetor" key="upload-zip" accepted="application/zip,application/octet-stream,application/x-zip-compressed" bucket="ecomail-zipped-templates" @removed="fileUploaded = false" @uploaded="uploaded" />
                    </div>
                    <div v-else-if="type === 'json'" class="mt-4">
                        <alert type="info" :message="$t('templates.json-help')" />
                        <upload id="templateJsonSelecetor" key="upload-json" accepted=".json,application/json" bucket="ecomail-zipped-templates" name="file_json" @removed="fileUploaded = false" @uploaded="uploaded" />
                    </div>

                    <div class="tw-modal-buttons">
                        <button type="button" class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('forms.form_close_props_editor') }}</button>
                        <button :disabled="sending || (fileUploaded === false && (type === 'zip' || type === 'json'))" class="btn btn-primary float-right btn-lg" data-cy="submit-create-template" type="submit"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('templates.continue') }}</button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
        <div class="block md:hidden">
            Please use desktop
        </div>

    </div>

</template>

<script>
import upload from '../Upload/UploadNew.vue'
import { ValidationObserver } from 'vee-validate'
import store from './store'
import Tracker from "@js/tracker";

export default {

    name: 'NewTemplate',

    props: ['modal'],

    components: {
        upload,
        ValidationObserver
    },

    store,

    data () {
        return {
            sending: false,
            success: null,
            message: null,
            name: '',
            type: 'mjml',
            fileUploaded: false,
            fileName: null
        }
    },

    computed: {
        templatetype() {
            if (this.type === 'html') {
                return 'bare'
            } else if (this.type === 'zip') {
                return 'upload'
            } else if (this.type === 'json') {
                return 'import'
            }

            return 'mjml'
        },
        folder() {
            return this.$route.query.folder ?? null
        }
    },

    methods: {
        resetAlert() {
            this.success = null
            this.message = null
        },
        setType(type) {
            new Tracker().trackEvent('BUTTON_CLICKED', {
                type,
                name: 'Campaign type',
                scope: 'campaign creation'
            })
            this.resetAlert()
            this.type = type
        },
        uploaded(file) {
            this.fileName = file,
            this.fileUploaded = true
        },
        async createTemplate(evt) {
            this.resetAlert()

            evt.preventDefault()
            const isValid = await this.$refs.observer.validate()
            if(isValid) {
                this.sending = true
                try {
                    const response = await this.$http.post('/templates', {
                        template_type: this.templatetype,
                        folder: this.folder,
                        name: this.name,
                        file_url: this.type === 'zip' ? this.fileName : null,
                        file_json: this.type === 'json' ? this.fileName : null
                    })
                    if(this.type === 'mjml') {
                        this.$emit('show-overlay')
                        this.$store.dispatch('setNewTemplateName', this.name)
                        this.closeModal()
                        return
                    }
                    window.location.href = `/templates/${response.data.template_id}/${response.data.editor_type}`
                }
                catch(error) {
                    this.success = false
                    this.message = error.response.data ? error.response.data.error : 'Error'
                }
                this.sending = false
            }
        },
        closeModal() {
            this.modal.mclose()
        },
    }
}
</script>
