<template>
    <div style="margin-left: 16px;">

        <template v-if="loading">
            Loading
        </template>
        <automation-builder
                v-else
                ref="automation-builder"
                mode="chatbot"
                :marketer="true"
                :can-create-sms-campaign="true"
                :editable="editable"
                list="N/A"
                list-id="1"
                list-name="name"
                fbpages="[]"
                :initpipeline="chatbot"
                :plumbing="plumbing"
                integrations="[]"
                :customfields="[]"
                strategy="s"
                :is-blocked="0" />

    </div>
</template>

<script>
import store from '@stores/index'
import toTemplate from '@mixins/toTemplate'
import {mapState} from 'vuex'

export default {
    name: 'chatbots',

    store,
  
    mixins: [toTemplate],

    data() {
        return {
            activated: true,
            editable: false
        }
    },

    computed: {
        ...mapState('chatbotsModule', [
            'loading', 'chatbot', 'plumbing'
        ])
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('chatbotsModule/loadChatbot', to.params.bot_id)
        })
    },

    created() {
        const urlParams = new URLSearchParams(window.location.search)
        this.editable = urlParams.has('edit')
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },
}
</script>
