<template>
    <div v-if="pages.length || nextPage">
        <ul class="ecm-pagination">
            <li class="page-items-container">
                <div v-for="(page, index) in pages" :key="`page-${page}`" class="page-item" :class="{'active': page === currentPage}">
                    <a href="#" @click.prevent="changePage(page)">{{ index + 1 }}</a>
                </div>
            </li>

            <li>
                <button class="arrow-button right-arrow" :disabled="!nextPageToken" @click.prevent="nextPage(nextPageToken)"><icon key="right" icon="angle-right-solid" /></button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'DynamicPagination',

    props: ['pages', 'currentPage', 'nextPageToken'],

    methods: {
        changePage(pageNumber) {
            this.$emit('handlePageChange', pageNumber)
        },
        nextPage(pageNumber) {
            this.$emit('handleNextPage', pageNumber)
        }
    }
}
</script>

<style lang="scss" scoped>

    @import '~sass/variables';

    ul.ecm-pagination {
        display: flex;
        overflow-x: auto;

        .page-items-container {
            display: flex;
        }

        .page-item {

            span, a {
                display: inline-block;
                padding: 6px 13px;
                font-weight: $semibold;
                font-size: 14px;
                color: $grey-90;
            }

            &:hover {
                a {
                    background-color: $grey-10;
                    text-decoration: none;
                }
            }

            &.active {
                span, a {
                    background-color: $grey-10;
                    box-shadow: inset 0px -2px 0px $green-40
                }
            }

            &.disabled {
                span, a {
                    color: $grey-60;
                    pointer-events: none;
                }
            }
        }

        .arrow-button {
            padding: 7px;
            border-radius: 3px;
            background-color: $grey-50;
            color: $grey-90;

            &.left-arrow {
                margin-right: 12px;
            }
            &.right-arrow {
                margin-left: 12px;
            }
        }
    }
</style>
