
<transition name="long-fadeout">
    <div class="ec-container md:spa-container relative main-container flex flex-wrap mx-auto flex-col w-full md:flex-row">
        <div class="mc-r w-full h-full">
            <div class="main-panel solo spa" :class="{scrolled: scrolled}">
                    
                <h2 class="flex justify-between items-center px-9 py-3">
                    <div class="flex items-center">
                        <a href="#" class="txt-22 hover:text-gray-1000 hover:no-underline" :class="{ 'pointer-events-none text-gray-1000' : !currentFolderName, 'text-gray-800': currentFolderName }" @click.prevent="refreshTemplates({resetAll: true})">{{ $t('templates.title') }}</a>
                        <icon v-if="currentFolderName" class="text-gray-700 mx-3" icon="long-arrow-alt-right-solid" />
                        <span v-if="currentFolderName" class="text-gray-1000 txt-22">{{ currentFolderName }}</span>
                    </div>
                    <button class="btn btn-primary btn-lg icon-left" data-cy="templates-add-button" @click="showCreateTemplate"><icon icon="plus-solid" size="small" /><span>{{ $t('templates.new_template') }}</span></button>
                </h2>

                <div v-if="!templatesLoaded" ref="content-layout" class="w-4 h-4 mt-6 ml-9 double-loader" />

                <div v-else-if="!templates" class="px-16 py-8 bg-white mt-3 md:mx-40 lg:mx-64">
                    <div class="flex flex-row items-center justify-between">
                        <div class="flex flex-col">
                            <p class="mb-2.5 txt-18 text-gray-1000">
                                {{ $t('templates.no_templates_sent') }}
                            </p>
                            <div>
                                <span class="txt-14 text-gray-900" v-html="$t('templates.create_one_now')" />
                                <br>
                                <button class="btn btn-primary btn-lg icon-left" @click="showCreateTemplate"><icon icon="plus-solid" size="small" /><span>{{ $t('templates.new_template') }}</span></button>
                            </div>
                        </div>
                        <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-payments.png" style="width: 314.76px" alt="feed-ilustration">
                    </div>
                </div>

                <vue-custom-scrollbar v-else ref="content-layout" :settings="settings" class="spa-container" @ps-y-reach-start="scrolled = false" @ps-scroll-y="handleScroll()">
                    <div class="flex flex-col">
                        <div class="flex justify-between items-center mt-4 mb-8">
                            <div class="flex items-center space-x-5">
                                <folders :initfolders="folders" :label="$t('templates.folders')" :alllabel="$t('templates.all-templates')" basepath="templates" @goToFolder="goToFolder" @setFolders="setFolders" />
                                <move-to-folder :shown="selected && selected.length" :initfolders="folders" :label="$t('folders.move-to-folder')" basepath="templates" :checked="selected" @moved="movedToFolder" />
                                <div v-if="selected.length > 1" class="ml-4">
                                    <div v-dropdown="'open'" class="tw-dropdown">
                                        <button id="massActionsDropdown" class="btn btn-tertiary icon-right" type="button" dropdown-toggle>
                                            <span>{{ $t('templates.sub_selected') }}</span>
                                            <icon icon="caret-down-solid" />
                                        </button>
                                        <div class="tw-dropdown-items tw-dropdown-left bg-grey-10 flex flex-col" dropdown-stay>
                                            <a :href="'/templates/export-selected/' + selected + ($route.query.folder ? '?folder=' + $route.query.folder : '')">{{ $t('templates.export') }}</a>
                                            <a v-if="account.isAgency" href="" @click.stop.prevent="replicateSelectedToSubaccount">{{ $t('templates.copy_to_subaccounts') }}</a>
                                            <a href="" class="dd-divided dd-critical" @click.stop.prevent="deleteSelectedTemplates">{{ $t('common.delete') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form method="get" @submit.prevent="handleSearch">
                                <div class="tw-input-group flex" style="width: 330px; max-width: 100%">
                                    <div class="tw-input-with-icon flex-grow">
                                        <label for="templateSearch" class="flex-none"><icon size="small" icon="search-solid" /></label>
                                        <input id="templateSearch" v-model="query" type="text" :placeholder="$t('templates.sub_search_in_list')" name="search" class="tw-input w-full">
                                    </div>
                                    <button class="btn btn-tertiary" :disabled="sending" type="submit"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('common.search') }}</button>
                                </div>
                            </form>
                        </div>

                        <div class="w-full overflow-x-auto md:overflow-x-visible">
                            <div v-if="currentQuery" class="flex txt-14 text-gray-1000 items-center mb-6">
                                <span>{{ $t('campaigns.query_results') }}</span>
                                <h2 class="ml-2">
                                    {{ currentQuery }}
                                </h2>
                                <a href="#" class="btn btn-tertiary ml-3 icon-left" @click.prevent="refreshTemplates({resetQuery: true})"><icon icon="times-solid" />{{ $t('campaigns.new.cancel') }}</a>
                            </div>
                            <div class="templates-list flex flex-wrap -mx-2" data-cy="templates_dashboard">
                                <div v-if="!templates.length" class="flex flex-col items-center w-full mt-8">
                                    <img alt="empty-templates-illustration" src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-payments.png" style="width: 304.76px">
                                    <h3 class="mt-4">
                                        {{ $t('templates.empty_templates') }}
                                    </h3>
                                </div>

                                <div v-if="templates && templates.length" class="px-2 w-full md:w-1/3 xl:w-1/4">
                                    <div class="card card-templates card-light no-pad template-box">
                                        <a class="screenshot new-template" href="#" @click.prevent="showCreateTemplate">
                                            <div class="pl-4 pb-6">
                                                <img src="https://ecomail-assets.s3.amazonaws.com/old/images/new-template.png" alt="Create new template">
                                            </div>
                                            <p class="text-sail-500 semibold txt-14">{{ $t('templates.new_template') }}</p>
                                            <p class="text-gray-900 txt-13 text-center mx-10">{{ $t('templates.new_template_prompt_message') }}</p>
                                        </a>
                                    </div>
                                </div>

                                <div v-for="template in templates" :key="template.id" class="px-2 w-full md:w-1/3 xl:w-1/4">
                                    <div class="card card-templates card-light no-pad template-box group" :class="{'card-checked': selected.includes(template.id.toString())}" data-cy="templates_dashboard-template-preview" style="overflow: visible">
                                        <a class="screenshot" :href="`/templates/${template.id}/editor-new`">
                                            <img :src="'https://ecomail-screenshots.s3.amazonaws.com/' + account.name + '/screenshots/' + template.id + '.jpg'" :alt="template.name" onerror="this.src='https://ecomail-assets.s3.amazonaws.com/old/images/screenshot-placeholder.png'" @error="getScreenshot(template.id)">
                                        </a>

                                        <div class="tw-checkbox-solid">
                                            <input :id="'template-check-' + template.id" :name="'template-check-' + template.id" type="checkbox" :value="template.id" :checked="selected.includes(template.id.toString())" @change="onCheck">
                                            <label :for="'template-check-' + template.id">&nbsp;</label>
                                        </div>
                                        <tiny-drop-down class="dropdown-template-card" data-cy="templates_dashbaord-template-dropdown">
                                            <button @click="renameTemplate(template)">{{ $t('templates.rename') }}</button>
                                            <a href="#" @click.prevent="duplicateTemplate(template.id)">{{ $t('templates.duplicate') }}</a>
                                            <a :href="'/templates/' + template.id + '/download'">{{ $t('templates.download') }}</a>
                                            <a :href="'/templates/' + template.id + '/export'">{{ $t('templates.export') }}</a>
                                            <a v-if="hasEnabledOldEditorExports" :href="'/templates/' + template.id + '/export?forceOld'">{{ $t('templates.export_old_editor') }}</a>
                                            <a v-if="account.isAgency" @click.stop.prevent="replicateToSubaccount(template.id)">{{ $t('templates.copy_to_subaccounts') }}</a>
                                            <button class="dd-divided dd-critical" data-cy="templates_dashbaord-template-dropdown-delete" @click="deleteTemplate(template)">{{ $t('templates.delete') }}</button>
                                        </tiny-drop-down>

                                        <a v-if="editing !== template.id" class="info text-gray-700 group-hover:text-gray-900" :href="'templates/' + template.id + '/editor-new'">
                                            <strong class="txt-14">{{ template.name }}</strong>
                                            <div class="flex justify-between">
                                                <span class="flex timestamp items-center"><svg-vue icon="clock-solid" class="w-3 h-3" /> {{ template.timestamp }}</span>
                                                <span class="flex size" :class="{oversize: template.size > sizelimit}"><svg-vue v-if="template.size > sizelimit" icon="exclamation-circle-solid" class="w-3 h-3" /> {{ template.size_human }}</span>
                                            </div>
                                        </a>
                                        <div v-else>
                                            <div class="tw-inline-edit-input">
                                                <div class="flex items-stretch p-3 pb-4">
                                                    <input ref="folderName" v-model="tname" class="tw-input" type="text" style="width: calc(100% - 40px)">
                                                    <button class="btn btn-primary" @click="saveTemplateName(template)"><svg-vue icon="check-solid" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <pagination :total="pagination.total" :per-page="pagination.per_page" :current-page="pagination.current_page" :last-page="pagination.last_page" @handlePageChange="handlePageChange" />
                    </div>
                </vue-custom-scrollbar>

            </div>
        </div>

        <tw-confirm ref="deleteTemplateConfirm" @confirmDelete="deleteTemplateConfirmed" />
        <tw-confirm ref="deleteTemplateSelectedConfirm" @confirmDelete="deleteTemplateSelectedConfirmed" />

        <tw-modal ref="createTemplate" size="xlarge" :title="$t('templates.new_template')" close-button="yes">
            <template #default="slotProps">
                
                <new-template :modal="slotProps" @show-overlay="panelOverlay = !panelOverlay" />
            
            </template>
        </tw-modal>

        <tw-modal ref="replicateTemplateToSubaccounts" size="large" :title="$t('templates.copy_to_subaccounts')" close-button="yes">
            <template>

                <form ref="replicateToSubs" action="templates/copy-to-subaccounts" method="POST">

                    <input type="hidden" name="_token" :value="csrf">
                    <input type="hidden" name="template" :value="copyTemplate">

                    <div v-for="subaccount in subaccounts" :key="subaccount.name" class="tw-checkbox py-3">
                        <input :id="'subacc' + subaccount.id" type="checkbox" :value="subaccount.name" name="subaccounts[]">
                        <label :for="'subacc' + subaccount.id">{{ subaccount.name }}</label>
                    </div>

                    <div class="tw-modal-buttons text-right">
                        <button :disabled="sending" type="submit" class="btn btn-lg btn-primary" @click.stop.prevent="replicateToSubs"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('templates.copy_to_subaccounts') }}</button>
                    </div>
                </form>
                
            </template>
        </tw-modal>

        <div class="overlay-panel-bg" :class="{'is-displayed': panelOverlay}" @click.self.prevent="panelOverlay = false">
            <div class="overlay-panel mc-r ec-container mx-auto" data-cy="templates_mjml-overlay" :class="{'is-displayed': panelOverlay}">
                <div class="main-panel">

                    <div class="h2 flex justify-between">
                        <portal-target name="overlay-title" />
                        <button class="btn btn-tertiary icon-left" @click.stop.prevent="panelOverlay = false"><icon icon="times-solid" size="small" /><span>{{ $t('common.close') }}</span></button>
                    </div>

                    <div class="p-9 pt-10">
                        <div class="mb-8">
                            <div class="text-gray-1000 text-lg">
                                {{ $t('templates.choose_starting_template') }}
                            </div>
                            <div class="text-gray-900 text-sm mt-1">
                                {{ $t('templates.choose_template_or_own') }}
                            </div>
                        </div>
                        <choose-mjml :templates="mjmlTemplates" :hostname="hostname" />
                    </div>

                </div>
            </div>
        </div>
        
    </div>
</transition>
