<template>

    <div class="flex flex-col w-1/4">
        <div class="filters px-4 items-center">
            <div v-if="loadingStatus" class="w-4 h-4 mt-4 double-loader" />
            <ecm-select v-else :options="folders" :value="status" option-value-attribute="status" option-display-attribute="html" option-icon-attribute="icon" @input="filterStatus" />
            <ecm-select :options="sortOptions" :value="selectedSort" option-value-attribute="sort" option-display-attribute="name" @input="sortConversations" />
        </div>

        <div class="flex flex-col flex-grow overflow-hidden">
            <div id="messagesPreview" class="overflow-y-auto h-full">
                <div v-if="conversations === 'loading'" class="w-4 h-4 mt-4 double-loader" />
                <template v-else>
                    <div v-for="conversation in conversations" :key="conversation.chat_id" :class="{selected: conversation.chat_id === selectedChatId}" @click="selectConversation(conversation.chat_id)">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <div class="avatar border-white border" :style="{ backgroundColor: stringToColor(conversation.contact) }">
                                    {{ conversation.contact[0] }}
                                </div>
                                <div class="flex flex-col ml-3">
                                    <h5 class="truncate text-gray-1000">
                                        {{ conversation.contact }}
                                    </h5>
                                    <span v-if="conversation.contact_email" class="truncate text-gray-900">
                                        {{ conversation.contact_email }}
                                    </span>
                                </div>
                            </div>
                            <span ref="timestamp" :data-time="conversation.last_message" class="timestamp dimmed90">{{ reformatTimestamp(conversation.last_message) }}</span>
                            <a v-if="status === 'open'" class="closeButton dimmed90" @click="closeConversation(conversation.chat_id)"><icon icon="check-solid" /></a>
                            <a v-else class="closeButton dimmed90" @click="reopenConversation(conversation.chat_id)"><icon icon="inbox-solid" /></a>
                        </div>
                        <div class="messagePreview txt-13">
                            <div v-if="isTypingInChat(conversation.chat_id)" class="isTyping">
                                <div class="dot dot1" />
                                <div class="dot dot2" />
                                <div class="dot dot3" />
                            </div>
                            <template v-else>
                                {{ getLastMessageFromConversation(conversation).message }}
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

</template>

<script>

import store from '@stores/index'
import {mapState} from 'vuex'
import dayjs from 'dayjs'

export default {

    name: 'ChatPreviews',

    store: store,

    props: ['counters', 'agent'],

    data () {
        return {
            loadingStatus: false,
            status: 'open',
            selectedSort: 'newest',
            sortOptions: [
                {
                    name: this.$t('chat.conversations.newest_sort'),
                    sort: 'newest'
                },
                {
                    name: this.$t('chat.conversations.oldest_sort'),
                    sort: 'oldest'
                }
            ],
        }
    },

    computed: {
        ...mapState('chatModule', [
            'conversations',
            'typingConversations',
            'selectedChatId',
            'selectedAgent',
        ]),
        selectedChat() {
            return store.getters['chatModule/getSelectedChat']
        },
        folders() {
            let currentlyOpened = this.selectedAgent === 'all' ? this.counters['open'] : (this.counters[this.selectedAgent + '_open'] ?? 0)
            let currentlyClosed = this.selectedAgent === 'all' ? this.counters['closed'] : (this.counters[this.selectedAgent + '_closed'] ?? 0)

            return [
                {
                    status: 'open',
                    html: this.$t('chat.conversations.opened_folder') + ' (' + currentlyOpened + ')',
                    icon: 'inbox-solid'
                },
                {
                    status: 'closed',
                    html: this.$t('chat.conversations.closed_folder') + ' (' + currentlyClosed + ')',
                    icon: 'check-solid'
                }
            ]
        }
    },

    mounted() {
        setInterval(() => {
            if(this.$refs.timestamp) {
                this.$refs.timestamp.forEach(element => {
                    element.innerText = this.reformatTimestamp(element.getAttribute('data-time'))
                })
            }
        }, 60 * 1000) // 60 * 1000 milsec
    },

    methods: {
        selectConversation(chat_id) {
            store.dispatch('chatModule/setConversationId', chat_id)
        },
        isTypingInChat(chat_id) {
            return this.typingConversations.indexOf(chat_id) !== -1
        },
        reformatTimestamp(timestamp) {
            return dayjs.unix(Math.round(timestamp / 1000)).fromNow()
        },
        closeConversation(chat_id) {
            store.dispatch('chatModule/closeConversation', {chat: chat_id, agent: this.agent})
        },
        reopenConversation(chat_id) {
            store.dispatch('chatModule/reopenConversation', {chat: chat_id, agent: this.agent})
        },
        getLastMessageFromConversation (conversation) {
            // console.log(conversation.last_message_content)
            return conversation.last_message_content
        },
        async filterStatus(status) {
            this.status = status
            this.loadingStatus = true
            await store.dispatch('chatModule/loadConversations', {agent: this.selectedAgent, status: this.status, sortBy: this.selectedSort})
            this.loadingStatus = false
            this.$emit('statusChange', status)
        },
        sortConversations(sort) {
            this.selectedSort = sort
            store.dispatch('chatModule/loadConversations', {agent: this.selectedAgent, status: this.status, sortBy: this.selectedSort})
        },
        stringToColor(str) {
            var hash = 0
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash)
            }
            var colour = '#'
            for (let i = 0; i < 3; i++) {
                var value = (hash >> (i * 8)) & 0xFF
                colour += ('00' + value.toString(16)).substr(-2)
            }
            return colour
        }
    }
}
</script>