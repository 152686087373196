<template>

    <div class="flex flex-col w-3/4">

        <div v-if="conversations.length === 0 || selectedChat == null" class="flex h-full items-center justify-center border border-gray-500">
            <h2>{{ $t('chat.conversations.no_conversations') }}</h2>
        </div>

        <ChatHeader v-else :agent="agent" :status="status" @showSubscriberModal="showSubscriberModal"></ChatHeader>

        <ChatWindow v-if="selectedChat" :agent="agent"></ChatWindow>

    </div>

</template>

<script>

import store from '@stores/index'
import {mapState} from 'vuex'

import ChatHeader from '@component/Chat/ChatHeader.vue'
import ChatWindow from '@component/Chat/ChatWindow.vue'

export default {

    name: 'Chat',

    store: store,

    props: ['agent', 'status'],

    components: {
        ChatHeader,
        ChatWindow
    },

    data () {
        return {
        }
    },

    computed: {
        ...mapState('chatModule', [
            'conversations',
        ]),
        selectedChat() {
            return store.getters['chatModule/getSelectedChat']
        },
    },

    methods: {
        showSubscriberModal(email) {
            this.$emit('showSubscriberModal', email)
        }
    }
}
</script>