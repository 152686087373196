
<div class="chat-container">

    <div class="conversations-header">
        <h2 class="section-header-container">
            <ecm-select v-if="localAgents && selectedAgent" class="-ml-5" :options="localAgents" :value="selectedAgent" option-value-attribute="agent" option-display-attribute="name" @input="setAgent" />
            <div v-else class="w-4 h-4 mt-4 double-loader" />
        </h2>
    </div>

    <section id="chatContainer" class="flex h-full min-h-0">

        <ChatPreviews :counters="counters" :agent="agent" @statusChange="setStatus" ref="chatPreviews"></ChatPreviews>

        <Chat :agent="agent" :status="status" @showSubscriberModal="showSubscriberModal"></Chat>

        <tw-modal ref="subscriberDetail" :title="$t('appforms.subscriber.title')" close-button="yes" classname="subscriberDetail" :visible-on-load="$route.query['contact-modal']">

            <template #default="slotProps">

                <subscriber-form :modal="slotProps" />

            </template>

        </tw-modal>

    </section>

</div>
