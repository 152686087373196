<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.groups.title') }}</span>
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="pt-2 flex flex-col">

            <div v-if="!groupsLoaded" class="w-4 h-4 mt-4 double-loader" />

            <div v-else-if="groups && Object.keys(groups).length" class="pt-2 space-y-7">
                <div class="flex flex-col md:flex-row justify-end">
                    <button class="btn btn-primary icon-left" @click.stop.prevent="showGroupModal()"><icon icon="plus-solid" size="small" /> <span>{{ $t('contacts.groups.new_group_button') }}</span></button>
                </div>

                <div class="w-full overflow-x-auto md:overflow-x-visible">
                    <div class="table tw-table tw-table-large max-w-full">
                        <div v-for="(group, index) in groups" :key="`group-${index}`" class="table-row ">
                            <div class="table-cell align-middle truncate w-full" style="max-width: 0px;">
                                <div class="flex items-center space-x-4 group">
                                    <icon class="text-gray-650 group-hover:text-gray-800" icon="user-friends-solid" size="large" />
                                    <a href="#" class="txt-14 link-blue semibold" @click.prevent="showGroupModal(group)">{{ group.name ? group.name : 'N/A' }}</a>
                                </div>
                            </div>
                            <div class="table-cell align-middle text-right">
                                <div class="flex items-center space-x-2.5 justify-end">
                                    <button class="btn btn-tertiary icon-only" @click="deleteGroup(group)"><icon class="text-gray-900" icon="trash-solid" /></button>
                                    <button class="btn btn-tertiary" @click="showGroupModal(group)">{{ $t('common.edit') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="flex items-center space-x-10 mt-7 px-0 md:px-20">
                <div class="flex flex-col">
                    <h2>{{ $t('contacts.groups.heading') }}</h2>
                    <span class="txt-14 text-gray-900 mt-2.5" v-html="$t('contacts.groups.description')" />
                    <button class="btn btn-primary btn-lg icon-left mt-6 w-min" @click="showGroupModal()"><icon icon="plus-solid" size="small" /><span>{{ $t('contacts.groups.create_group_button') }}</span></button>
                </div>
                <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-lists.png" style="width: 304.76px" alt="list-ilustration" class="hidden md:block">
            </div>
        </div>

        <tw-modal ref="newGroupModal" :title="$t('appforms.contacts.groups.title')" width="756" close-button="yes">

            <template #default="slotProps">
                <lists-groups-form :modal="slotProps" :list-id="list.id" @refreshGroups="refreshGroups" />
            </template>

        </tw-modal>

        <tw-confirm ref="deleteGroupConfirm" @confirmDelete="deleteGroupConfirmed" />
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'
import Tracker from "@js/tracker";

export default {
    name: 'PreferenceGroups',

    store,

    props: ['list'],

    data() {
        return {
            activated: true
        }
    },

    computed: {
        ...mapState('contactsModule', [
            'groupsLoaded', 'groups'
        ])
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('contactsModule/loadGroups', {listId: to.params.list})
        })
    },

    methods: {
        showGroupModal(group) {
            if(group)
                this.$refs['newGroupModal'].showModalRawData(group)
            else
                this.$refs['newGroupModal'].showModal()
            new Tracker().trackEvent('BUTTON_CLICKED', {
                scope: 'contacts',
                name: 'create new group'
            })
        },
        deleteGroup(group) {
            this.$refs['deleteGroupConfirm'].setData({
                twConfirmTitle: this.$t('contacts.groups.delete_group.title'),
                twConfirmText: this.$t('contacts.groups.delete_group.text', {group: group.name}),
                data: group.id,
                twClose: this.$t('common.close'),
                twDelete: this.$t('common.delete_permanently'),
            })
            this.$refs['deleteGroupConfirm'].showModal()
        },
        async deleteGroupConfirmed(group) {  
            try {
                await this.$http.delete(`/spa/contacts/${this.list.id}/groups/${group}`)
                this.$message.success(this.$t('contacts.groups.group_deleted'))
                this.refreshGroups()
            }
            catch(error) {
                this.$message.error(error.message)
            }
        },
        refreshGroups() {
            store.dispatch('contactsModule/loadGroups', {listId: this.list.id})
        }
    }
}
</script>
