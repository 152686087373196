

<div>

    <div v-if="success" class="flex flex-col">

        <div v-if="success === true" class="alert alert-success mb-5" v-html="message" />
        <button class="btn btn-lg btn-primary self-center" onClick="window.location.reload()">{{ $t('appforms.upgrade.reload') }}</button>

    </div>

    <form v-else ref="buyForm" novalidate action="/account/change-plan-process" method="post" @submit.prevent="orderPlan">

        <div v-if="success === false" class="alert alert-danger mb-5">
            <span v-if="message" v-html="message" />
            <span v-else-if="payment_error">
                {{ payment_error }} <br>
                {{ braintree_error }}
            </span>
        </div>

        <div v-if="braintree_load_error" class="alert alert-danger mb-5">
            {{ braintree_load_error }}
        </div>

        <p class="txt-14 dimmed90">
            {{ $t('appforms.account.upgrade.intro') }}
        </p>

        <template v-if="step === 1">
            <p class="txt-13 semibold grey-100 mt-5">
                {{ $t('appforms.account.upgrade.intro2') }}
            </p>

            <div class="flex mt-5">
                <div class="w-1/2">
                    <ul class="tw-list tw-list-with-checks tw-list-green-checks dimmed90 txt-13 lh-24">
                        <li v-for="feature in features1" :key="feature" :class="{semibold: highlight === feature || highlight.includes(feature), 'grey-100': highlight === feature || highlight.includes(feature)}">{{ $t('appforms.account.upgrade.' + feature) }}</li>
                    </ul>
                </div>
                <div class="w-1/2">
                    <ul class="tw-list tw-list-with-checks tw-list-green-checks dimmed90 txt-13 lh-24">
                        <li v-for="feature in features2" :key="feature" :class="{semibold: highlight === feature || highlight.includes(feature), 'grey-100': highlight === feature || highlight.includes(feature)}">{{ $t('appforms.account.upgrade.' + feature) }}</li>
                    </ul>
                </div>
            </div>
        </template>

        <template v-else>

            <div class="tw-well tw-well-info tw-well-lg txt-16 dimmed90 mb-5 mt-10 flex justify-between">
                <div>
                    {{ $t('appforms.cashier.account_in_version') }}<br>
                    <span class="semibold grey-100">Marketer+ {{ $t('appforms.cashier.till') }} {{ limit ? limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '...' }} {{ $t('appforms.cashier.contacts') }}</span>
                </div>
                <div class="flex items-end">
                    <span><span class="semibold grey-100">{{ price_with_vat ? numberToLocale(price_with_vat) : '...' }}</span> {{ currencytxt }} {{ hasVat ? $t('appforms.cashier.with_vat'): '' }} {{ frequency ? '/ ' + $t('appforms.cashier.cycle_' + frequency) : '' }}</span>
                </div>
            </div>

            <div class="tw-well tw-well-info tw-well-lg txt-14 dimmed90 mb-5 w-full">
                <span class="semibold grey-100 break-normal">{{ $t('appforms.cashier.plan_prorate_info') }}</span>
                <br>
                {{ $t('appforms.cashier.plan_prorate_info2') }}
            </div>

            <div id="dropin-container" />

        </template>

        <div v-if="step === 1" class="tw-modal-buttons">
            <button type="button" class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('forms.form_close_props_editor') }}</button>
            <button class="btn btn-primary float-right btn-lg" @click="initUpgrade">{{ $t('appforms.account.upgrade.upgrade') }}</button>
        </div>

        <div v-else class="tw-modal-buttons text-right">
            <a href="https://www.braintreegateway.com/merchants/djbwkm2xzbvjbz9m/verified" target="_blank" class="float-left"><img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png" width="164px" height="44px" border="0"></a>
            <button :disabled="!inited || sending" class="btn btn-lg btn-primary"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.cashier.plan_order_and_pay') }} {{ price_with_vat ? price_with_vat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '' }} {{ currencytxt }}</button>
        </div>

    </form>

</div>

