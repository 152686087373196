<template>

    <ValidationObserver ref="observer" slim>

        <form novalidate class="flex flex-col" method="post" @submit.prevent="saveSettings">

            <alert v-if="success === true" class="mb-10" type="success" :message="message" />

            <alert v-if="success === false" class="mb-10" type="error" :message="message" />

            <div class="input flex flex-col mb-5 mx-4">
                <label class="tw-label mb-5">{{ $t('list_settings.unsub_optout_help') }}</label>
                <div class="flex flex-col">
                    <div class="tw-radio mb-4">
                        <label for="oneClick">
                            <input id="oneClick" v-model="unsubType" type="radio" name="double_optout" value="oneClick">
                            <span class="radiomark mr-3" />
                            <span>{{ $t('list_settings.unsub_single_optout_label') }}</span>
                        </label>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="tw-radio mb-4">
                        <label for="doubleOptOut">
                            <input id="doubleOptOut" v-model="unsubType" type="radio" name="double_optout" value="doubleOptOut">
                            <span class="radiomark mr-3" />
                            <span>{{ $t('list_settings.unsub_double_optout_label') }}</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="input flex flex-col mx-4">
                <label class="tw-label mb-5 flex items-center space-x-2">
                    <div>{{ $t('list_settings.unsub_from_help') }}</div>
                    <div v-tooltip="$t('list_settings.unsub_from_tooltip')">
                        <icon icon="info-circle-solid" class="text-sail-500" />
                    </div>    
                </label>
                <div class="flex flex-col">
                    <div class="tw-radio mb-4">
                        <label for="fromList">
                            <input id="fromList" v-model="unsubTarget" type="radio" name="unsub_from_all" value="fromList">
                            <span class="radiomark mr-3" />
                            <span>{{ $t('list_settings.unsub_from_list_label') }}</span>
                        </label>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="tw-radio">
                        <label for="fromAll">
                            <input id="fromAll" v-model="unsubTarget" type="radio" name="unsub_from_all" value="fromAll">
                            <span class="radiomark mr-3" />
                            <span>{{ $t('list_settings.unsub_from_all_label') }}</span>
                        </label>
                    </div>
                </div>
            </div>

            <div :class="{'mt-7': unsubRedirect, 'mt-11': !unsubRedirect}" />

            <div class="settings flex flex-col card-left no-hover card-white" :class="{card: unsubRedirect, 'mx-4': !unsubRedirect}">
                <ecm-switch v-model="unsubRedirect">
                    {{ $t('list_settings.unsub_redirect_label') }}
                </ecm-switch>

                <div v-if="unsubRedirect" class="w-full mt-3 pl-switch flex flex-col">

                    <ValidationProvider v-slot="{ errors }" name="unsubPage" rules="required|url" slim>
                        <div class="input w-full flex flex-col">
                            <span class="text-gray-900 txt-14 mb-4">{{ $t('list_settings.unsub_redirect_info') }}</span>
                            <label for="unsubPage" class="tw-label mb-2.5">{{ $t('list_settings.unsub_redirect_label_input') }}</label>
                            <input id="unsubPage" v-model="unsubPage" name="unsubPage" type="text" class="tw-input max-w-xs" placeholder="http://">
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                </div>
            </div>

            <div>
                <button v-if="hasChanges" class="btn btn-lg btn-primary ml-4 mt-8"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('list_settings.save_changes') }}</button>
            </div>

        </form>

    </ValidationObserver>

</template>

<script>
import {ValidationObserver} from 'vee-validate'

export default {

    name: 'OptoutSettings',

    props: ['list'],

    components: {
        ValidationObserver
    },

    data () {
        return {
            sending: false,
            success: null,
            message: '',
            unsubType: this.list.double_optout ? 'doubleOptOut' : 'oneClick',
            unsubTarget: this.list.unsub_from_all ? 'fromAll' : 'fromList',
            unsubRedirect: !!this.list.unsub_page,
            unsubPage: this.list.unsub_page,
            hasChanges: false,
        }
    },

    watch: {
        unsubRedirect() {
            this.hasChanges = true
        },
        unsubType() {
            this.hasChanges = true
        },
        unsubTarget() {
            this.hasChanges = true
        },
        unsubPage() {
            this.hasChanges = true
        },
    },

    methods: {
        async saveSettings(evt) {

            evt.preventDefault()
            this.success = null
            this.message = null
            const isValid = await this.$refs.observer.validate()

            if(isValid) {
                this.sending = true

                try {
                    await this.$http.post(`/spa/contacts/${this.list.id}/settings`, {
                        unsub_from_all: this.unsubTarget === 'fromAll' ? 1 : 0,
                        double_optout: this.unsubType !== 'oneClick' ? 1 : 0,
                        unsub_page: this.unsubPage !== '' && this.unsubRedirect ? this.unsubPage : null,
                    })
                    this.success = true
                    this.message = this.$t('common.changes_saved')
                    this.hasChanges = false
                }
                catch(error) {
                    this.success = false
                    this.message = 'Unknown error'
                }

                this.sending = false
            }
        }
    }
}
</script>
