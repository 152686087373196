<template>
    <div class="mc-r w-full">
        <div class="main-panel solo">
            <h2 class="section-header-container">
                <span>{{ $t('chat.m-start') }}</span>
            </h2>
            <vue-custom-scrollbar ref="content-layout" :settings="settings" class="spa-container" @ps-y-reach-start="scrolled = false">
                <a class="btn btn-primary btn-lg my-10" href="/spa/chat/enable">Start chatting</a>
            </vue-custom-scrollbar>
        </div>
    </div>
</template>

<script>
import toTemplate from '@mixins/toTemplate'
import vueCustomScrollbar from 'vue-custom-scrollbar'

export default {
    name: 'chatStart',
  
    mixins: [toTemplate],

    components: {
        vueCustomScrollbar
    },

    data() {
        return {
            settings: {
                suppressScrollX: true,
                tagname: 'div'
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$root.showSpaLoader = false
        })
    },

    mounted() {
        let vueAppEl = document.getElementById('vueApp')
        vueAppEl.addEventListener('wheel', (event) => this.scrolledContainer(event))
    },

    methods: {
        scrolledContainer(event) {
            if(event.target.id === 'vueApp') {
                event.stopPropagation()
                event.preventDefault()
                event.cancelBubble = true
                let mouseEvent = new MouseEvent('wheel')
                mouseEvent.deltaY = event.deltaY
                mouseEvent.deltaX = event.deltaX
                mouseEvent.deltaZ = event.deltaZ
                this.$refs['content-layout'].$el.dispatchEvent(mouseEvent)
            }
        }
    }
}
</script>
