<template>
    <div v-if="imports" class="panel px-8 py-5 mb-8">
        <div class="mb-4">
            <span class="text-gray-1000 font-semibold txt-14">{{ $t('appforms.contacts.import.currently_running_imports') }}</span>
        </div>
        <div class="space-y-6">
            <div v-for="(importProgress, i) in imports" :key="`import-bar-${i}`" class="flex items-end text-gray-900 semibold" style="font-size: 13px">
                <div class="flex-grow">
                    <div>
                        <span class="font-normal">{{ $t('appforms.contacts.import.target_list') }} <span class="text-gray-1000 font-semibold">{{ importProgress.list_name }}</span></span>
                        <span v-if="!importProgress.error" class="float-right">{{ importProgress.chunked_count }} <span class="text-gray-700">/ {{ importProgress.chunk_total_count }}</span></span>
                        <span v-else class="float-right">ERROR</span>
                    </div>
                    <div class="mt-2 mb-1 relative rounded-lg bg-gray-300 w-full" style="height: 6px">
                        <div :class="{'bg-green-400': !importProgress.error, 'bg-messages-error-100': importProgress.error}" :style="{width: Math.round(importProgress.percentage) + '%'}" class="absolute rounded-lg left-0 h-full" style="transition: 0.5s" />
                    </div>
                </div>
                <div class="text-right ml-9">
                    <icon v-show="importProgress.complete" :id="`import-status-${importProgress.import_id}`" class="float-right text-green-400" icon="check-circle-solid" size="large" />

                    <icon v-if="importProgress.error" v-tooltip="importProgress.error_message" class="float-right text-messages-error-100" icon="times-circle-solid" size="large" />

                    <span v-else class="text-gray-900 font-semibold txt-13">{{ Math.round(importProgress.percentage) }} %</span>
                </div>
            </div>
        </div>
        <div class="text-gray-900 txt-13 mt-5">
            {{ $t('appforms.contacts.import.progress_bar_hint') }}
        </div>
    </div>
</template>

<script>
import confetti from 'canvas-confetti'
import Pusher from 'pusher-js'

export default {
    name: 'ImportProgressBar',

    props: ['accountName', 'listName'],

    data() {
        return {
            imports: null,
            pusher: null
        }
    },

    created() {
        this.pusher = new Pusher('d9227f1530fb2760f117', {
            cluster: 'eu',
            secret: 'dd261a845fc434b77a4b',
            appId: '199657',
        })

        const channelName = `import-progress.${this.accountName}`
        this.pusher = this.pusher.subscribe(channelName)
    },

    mounted() {
        this.pusher.bind('chunk-imported', (data) => {
            if(!this.imports) {
                this.imports = { [data.import_id]: {} }
            }
            this.imports[data.import_id] = {
                chunk_total_count: data.chunk_total_count,
                chunked_count: data.chunked_count,
                percentage: data.percentage,
                list_name: data.list_name,
                import_id: data.import_id,
            }
            this.$forceUpdate()
        })

        this.pusher.bind('chunk-import-error', (data) => {
            if(!this.imports) {
                this.imports = { [data.import_id]: {} }
            }
            this.imports[data.import_id] = {
                list_name: data.list_name,
                import_id: data.import_id,
                error: true,
                error_message: data.error_message
            }
            this.$forceUpdate()
        })

        this.pusher.bind('import-completed', (data) => {
            if(!this.imports || !this.imports[data.import_id]) {
                return
            }
            this.imports[data.import_id].chunk_total_count = data.chunk_total_count
            this.imports[data.import_id].complete = true
            this.imports[data.import_id].percentage = 100
            this.imports[data.import_id].chunked_count = this.imports[data.import_id].chunk_total_count
            this.$forceUpdate()
            this.$nextTick(() => {
                const element = document.querySelector(`#import-status-${data.import_id}`)
                const rect= element.getBoundingClientRect()
                const origin = {
                    x: (rect.x + 10) / window.screen.width,
                    y: (rect.y + 25) / window.screen.height
                }
                window.setTimeout(() => {
                    confetti({
                        spread: 40,
                        startVelocity: 35,
                        particleCount: 40,
                        origin: origin
                    })
                }, 500)
            })
        })
    }
}
</script>

<style scoped>

</style>
