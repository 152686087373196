
<ValidationObserver ref="observer" slim>
    <div>
        <div class="mb-7">
            <h2>{{ $t('mapper.import_field_settings') }}</h2>
            <span class="txt-14 text-gray-900">{{ $t('mapper.import_field_settings_hint') }}</span>
        </div>

        <alert v-if="message" class="mb-4" type="error" :message="message" />

        <div class="wrapper mb-10">
            <div class="table">
                <div class="table-header-group mb-6">
                    <div class="table-row">
                        <column
                                v-for="(column, index) in columnsCount"
                                :key="`header-cell-${index}`"
                                :preselected="mappedFields && mappedFields[index] ? mappedFields[index] : '__skip__'"
                                :index="index" />
                        <div key="header-cell-empty" class="table-cell w-full" />
                    </div>
                </div>

                <div class="table-row-group">
                    <div
                            v-for="(row, rowindex) in tableData"
                            :key="'row' + rowindex"
                            class="table-row">
                        <div
                                v-for="(col, colindex) in row"
                                :key="`cell-${rowindex}-${colindex}`"
                                class="table-cell">
                            <span>{{ col }}</span>
                        </div>
                        <div key="cell-empty" class="table-cell w-full" />
                    </div>
                </div>

                <div class="table-row-group table-end-group">
                    <div class="table-row">
                        <div v-for="(col, colindex) in tableData[0]" :key="`table-end-${colindex}`" class="table-cell table-end" />
                        <div key="table-end-empty" class="table-cell table-end w-full" />
                    </div>
                </div>
            </div>
        </div>

        <h2 class="txt-14 font-semibold text-gray-1000 mb-4">
            {{ $t('mapper.import_as') }}
        </h2>

        <div class="tw-radio mb-4">
            <label for="subscribe">
                <input id="subscribe" v-model="status" name="status" type="radio" value="subscribe">
                <span class="radiomark mr-3" />
                <span v-html="$t('mapper.import_as_subs')" />
            </label>
        </div>

        <div class="tw-radio mb-4">
            <label for="unsubscribe">
                <input id="unsubscribe" v-model="status" name="status" type="radio" value="unsubscribe">
                <span class="radiomark mr-3" />
                <span v-html="$t('mapper.import_as_unsubs')" />
            </label>
        </div>

        <div class="tw-radio mb-6">
            <label for="bounce">
                <input id="bounce" v-model="status" name="status" type="radio" value="bounce">
                <span class="radiomark mr-3" />
                <span v-html="$t('mapper.import_as_bounced')" />
            </label>
        </div>

        <div class="flex border-t border-b pb-6 border-gray-500 pt-4">
            <div class="w-1/3 space-y-4">
                <span class="mb-4 txt-14 font-semibold text-gray-1000">{{ $t('mapper.import_found_contacts') }}</span>
                <div class="tw-checkbox">
                    <input id="updateExisting" v-model="updateExisting" name="updateExisting" type="checkbox" :value="true">
                    <label for="updateExisting"><span class="text-black">{{ $t('mapper.import_update_existing') }}</span></label>
                </div>
            </div>
            <div class="w-1/3 space-y-4">
                <span class="mb-4 txt-14 font-semibold text-gray-1000">{{ $t('mapper.tag_contacts') }}</span>
                <div class="tw-checkbox">
                    <input id="tagContacts" v-model="tagger" name="tagContacts" type="checkbox" :value="true">
                    <label class="text-black" for="tagContacts"><span class="text-black">{{ $t('mapper.tag_contacts_checkbox') }}</span></label>
                </div>
                <div v-if="tagger" id="tagsBox" class="form-group pl-checkbox">
                    <ecm-autocomplete
                            v-model="tagsInput"
                            :title="$t('mapper.tag_placeholder')"
                            :options="tagsMatching"
                            name="tagsInput"
                            option-display-attribute="value"
                            allow-multiple
                            :max-length="50"
                            @input="getTagsForInput" />
                    <p class="text-gray-900 mt-1">
                        {{ $t('mapper.import_tags') }}
                    </p>
                </div>
            </div>
            <div class="w-1/3 space-y-4">
                <span class="mb-4 txt-14 font-semibold text-gray-1000">{{ $t('mapper.import_automation') }}</span>
                <div class="tw-checkbox">
                    <input id="triggerAutomation" v-model="triggerAutomation" name="triggerAutomation" type="checkbox" :value="true">
                    <label class="text-black" for="triggerAutomation"><span class="text-black">{{ $t('mapper.import_trigger_automation') }}</span></label>
                </div>
            </div>
        </div>

        <div class="tw-modal-buttons mt-7 mb-4">
            <button class="btn btn-tertiary tw-modal-close-button btn-lg" @click="goBack">{{ $t('common.back') }}</button>
            <button class="btn btn-primary btn-lg float-right" @click.prevent="checkImport">{{ $t('mapper.import_import') }}</button>
        </div>

        <tw-confirm ref="importConfirm" width="750" @confirmDelete="importConfirmed" />
    </div>
</ValidationObserver>
