

<div class="flex flex-col w-1/4">
    <div class="filters px-4 items-center">
        <div v-if="loadingStatus" class="w-4 h-4 mt-4 double-loader" />
        <ecm-select v-else :options="folders" :value="status" option-value-attribute="status" option-display-attribute="html" option-icon-attribute="icon" @input="filterStatus" />
        <ecm-select :options="sortOptions" :value="selectedSort" option-value-attribute="sort" option-display-attribute="name" @input="sortConversations" />
    </div>

    <div class="flex flex-col flex-grow overflow-hidden">
        <div id="messagesPreview" class="overflow-y-auto h-full">
            <div v-if="conversations === 'loading'" class="w-4 h-4 mt-4 double-loader" />
            <template v-else>
                <div v-for="conversation in conversations" :key="conversation.chat_id" :class="{selected: conversation.chat_id === selectedChatId}" @click="selectConversation(conversation.chat_id)">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center">
                            <div class="avatar border-white border" :style="{ backgroundColor: stringToColor(conversation.contact) }">
                                {{ conversation.contact[0] }}
                            </div>
                            <div class="flex flex-col ml-3">
                                <h5 class="truncate text-gray-1000">
                                    {{ conversation.contact }}
                                </h5>
                                <span v-if="conversation.contact_email" class="truncate text-gray-900">
                                    {{ conversation.contact_email }}
                                </span>
                            </div>
                        </div>
                        <span ref="timestamp" :data-time="conversation.last_message" class="timestamp dimmed90">{{ reformatTimestamp(conversation.last_message) }}</span>
                        <a v-if="status === 'open'" class="closeButton dimmed90" @click="closeConversation(conversation.chat_id)"><icon icon="check-solid" /></a>
                        <a v-else class="closeButton dimmed90" @click="reopenConversation(conversation.chat_id)"><icon icon="inbox-solid" /></a>
                    </div>
                    <div class="messagePreview txt-13">
                        <div v-if="isTypingInChat(conversation.chat_id)" class="isTyping">
                            <div class="dot dot1" />
                            <div class="dot dot2" />
                            <div class="dot dot3" />
                        </div>
                        <template v-else>
                            {{ getLastMessageFromConversation(conversation).message }}
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</div>

