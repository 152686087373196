<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.forms.title') }}</span>
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="pt-2 flex flex-col h-full">

            <div v-if="!formsLoaded" class="w-4 h-4 mt-4 double-loader" />

            <div v-else-if="forms && forms.length" class="pt-2 space-y-7">
                <div class="flex flex-col md:flex-row justify-end">
                    <button class="btn btn-primary icon-left" @click.stop.prevent="toggleCreateForm"><icon icon="plus-solid" size="small" /> <span>{{ $t('contacts.forms.new_form_button') }}</span></button>
                </div>

                <div class="w-full overflow-x-auto md:overflow-x-visible">
                    <div class="table tw-table tw-table-large max-w-full">
                        <div class="table-header-group">
                            <div class="table-row">
                                <div class="table-cell">
                                    <span class="txt-13 text-gray-1000 semibold">{{ $t('contacts.forms.name') }}</span>
                                </div>
                                <div class="table-cell">
                                    <span class="txt-13 text-gray-800 semibold">{{ $t('contacts.forms.type') }}</span>
                                </div>
                                <div class="table-cell" />
                            </div>
                        </div>
                        <div v-for="(form, index) in forms" :key="`coupon-${index}`" class="table-row ">
                            <div class="table-cell align-middle truncate w-full" style="max-width: 0px; min-width: 150px;">
                                <a class="txt-14 link-blue semibold" :href="`/contacts/${list.id}/forms/${form.id}`">{{ form.name }}</a>
                            </div>
                            <div class="table-cell align-middle" style="min-width: 300px">
                                <span v-if="form.settings && form.settings.properties && form.settings.properties.type" class="txt-14 text-gray-1000">{{ $t(`contacts.forms.form_type.${form.settings.properties.type}`) }}</span>
                            </div>
                            <div class="table-cell align-middle text-right">
                                <div class="flex items-center space-x-2.5 justify-end">
                                    <button class="btn btn-tertiary icon-only" @click="deleteForm(form)"><icon class="text-gray-900" icon="trash-solid" /></button>
                                    <div v-dropdown="'open'" class="tw-dropdown">
                                        <div class="btn-group">
                                            <a class="btn btn-tertiary" :href="`/contacts/${list.id}/forms/${form.id}`">{{ $t('contacts.forms.dropdown.edit') }}</a><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                        </div>
                                        <div class="tw-dropdown-items flex-col">
                                            <a href="#" @click.prevent="duplicateForm(form.id)">{{ $t('contacts.forms.dropdown.duplicate') }}</a>
                                            <a v-if="lists && Object.keys(lists).length" href="#" @click.prevent="showDuplicateAndMove(form.id)">{{ $t('contacts.forms.dropdown.duplicate_and_move') }}</a>
                                            <a :href="`/contacts/forms/${form.id}/export`">{{ $t('contacts.forms.dropdown.export') }}</a>
                                            <a href="#" class="dd-divided dd-critical" @click.prevent="deleteForm(form)">{{ $t('contacts.forms.dropdown.delete') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-10 mb-10">
                        <div class="text-gray-800 text-right txt-12 mb-2">
                            <span v-html="$t('contacts.forms.old_forms_link', {link: `/contacts/${list.id}/forms`})" />
                        </div>

                        <div class="panel p-4 w-full flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-5 items-start lg:items-center">
                            <p class="lg:w-1/3 text-gray-900" v-html="$t('contacts.forms.hosted_form')" />
                            <key-input class="lg:w-2/3" :value="formUrl" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="flex flex-col h-full mt-7 px-0 md:px-20">
                <div class="flex items-center space-x-10">
                    <div class="flex flex-col">
                        <h2>{{ $t('contacts.forms.heading') }}</h2>
                        <span class="txt-14 text-gray-900 mt-2.5" v-html="$t('contacts.forms.description')" />
                        <button class="btn btn-primary btn-lg icon-left mt-6 w-min" @click="toggleCreateForm"><icon icon="plus-solid" size="small" /><span>{{ $t('contacts.forms.new_form_button') }}</span></button>
                    </div>
                    <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-lists.png" style="width: 304.76px" alt="list-ilustration" class="hidden md:block">
                </div>
            
                <div class="mt-auto mb-10">
                    <div class="text-gray-800 text-right txt-12 mb-2">
                        <span v-html="$t('contacts.forms.old_forms_link', {link: `/contacts/${list.id}/forms`})" />
                    </div>

                    <div class="panel p-4 w-full flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-5 items-start lg:items-center">
                        <p class="lg:w-1/3 text-gray-900" v-html="$t('contacts.forms.hosted_form')" />
                        <key-input class="lg:w-2/3" :value="formUrl" />
                    </div>
                </div>
            </div>
        </div>

        <tw-confirm ref="deleteFormConfirm" @confirmDelete="deleteFormConfirmed" />

        <tw-modal ref="duplicateAndMove" width="600" :title="$t('contacts.forms.duplicate_modal.title')" close-button="yes">

            <template #default="slotProps">
                <p class="txt-14 text-gray-900 mb-4" v-html="$t('contacts.forms.duplicate_modal.description')" />
                <ValidationObserver ref="observer" slim>
                    <ValidationProvider v-slot="{ errors }" name="listToMove" rules="required" slim>
                        <ecm-select 
                                id="listToMove"
                                v-model="chosenList"
                                :options="Object.values(lists)"
                                option-value-attribute="id"
                                option-display-attribute="name"
                                :label="$t('contacts.forms.duplicate_modal.select_label')"
                                :title="$t('contacts.forms.duplicate_modal.select_title')" />
                        <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div class="tw-modal-buttons mt-7">
                        <button class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="slotProps.mclose()">{{ $t('common.cancel') }}</button>
                        <button :disabled="sending || !chosenList" class="btn btn-primary float-right btn-lg" @click="duplicateFormAndMove(slotProps)"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.forms.duplicate_modal.button') }}</button>
                    </div>
                </ValidationObserver>
            </template>

        </tw-modal>

        <import-form-modal ref="importFormModal" :list-id="list.id" />

        <div class="overlay-panel-bg" :class="{'is-displayed': panelOverlay}" @click.self.prevent="toggleCreateForm">
            <div class="overlay-panel mc-r ec-container mx-auto" :class="{'is-displayed': panelOverlay}" @click.self.prevent="toggleCreateForm">
                <div class="main-panel">
                    <h2 class="section-header-container">
                        <portal-target name="new-form-section-header" slim />
                    </h2>

                    <new-form ref="newForm" :list-id="list.id" :locale="account.isEnglishAccount ? 'en' : 'cs'" @close="toggleCreateForm" @showImportFormModal="showImportFormModal" />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'

export default {
    name: 'SubscribeForms',

    store,

    props: ['list'],

    data() {
        return {
            activated: true,
            panelOverlay: false,
            sending: false,
            chosenList: null,
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState('contactsModule', [
            'formsLoaded', 'forms', 'lists', 'formUrl'
        ])
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('contactsModule/loadForms', {listId: to.params.list})
        })
    },

    methods: {
        toggleCreateForm() {
            this.panelOverlay = !this.panelOverlay
            this.$root.modalOpened = this.panelOverlay
            if(!this.panelOverlay) {
                this.$refs.newForm.resetData()
            }
        },
        showImportFormModal() {
            this.panelOverlay = false
            this.$refs.newForm.resetData()
            this.$refs['importFormModal'].showModal()
        },
        deleteForm(form) {
            this.$refs['deleteFormConfirm'].setData({
                twConfirmTitle: this.$t('contacts.forms.delete_form.title'),
                twConfirmText: this.$t('contacts.forms.delete_form.text', {form: form.name}),
                data: form.id,
                twClose: this.$t('common.close'),
                twDelete: this.$t('common.delete_permanently'),
            })
            this.$refs['deleteFormConfirm'].showModal()
        },
        async deleteFormConfirmed(form) {     
            try {       
                await this.$http.delete(`/spa/contacts/forms/${form}`)
                this.$message.success(this.$t('contacts.forms.form_deleted'))
                this.refreshForms()
            }
            catch(error) {
                this.$message.error(error.message)
            }
        },
        async duplicateForm(form) {
            try {
                await this.$http.post(`/spa/contacts/forms/${form}/duplicate`)
                this.$message.success(this.$t('contacts.forms.form_duplicated'))
                this.refreshForms()
            }
            catch(error) {
                this.$message.error(error.message)
            }
        },
        showDuplicateAndMove(formId) {
            this.$refs.duplicateAndMove.setTitle(this.$t('contacts.forms.duplicate_modal.title'))
            this.$refs.duplicateAndMove.showModalWithData(JSON.stringify({formId: formId}))
        },
        async duplicateFormAndMove(slotProps) {
            const validated = await this.$refs.observer.validate()
            if(validated) {
                this.sending = true

                try {
                    await this.$http.post(`/spa/contacts/forms/${slotProps.mdata.formId}/duplicate-and-move/`, {targetListId: this.chosenList})
                    this.chosenList = null
                    this.$message.success(this.$t('contacts.forms.form_duplicated'))
                    slotProps.mclose()
                }
                catch(error) {
                    this.$message.error(error.message)
                }

                this.sending = false
            }
        },
        refreshForms() {
            store.dispatch('contactsModule/loadForms', {listId: this.list.id})
        }
    }
}
</script>
