<template>

    <ValidationObserver ref="observer" slim>
        <form ref="addfolder" novalidate class="flex flex-col mb-0" method="post" @submit.prevent="renameCampaign">

            <alert class="mb-5" type="warning" :message="$t('campaigns.rename_modal.warning', {name: oldName})" />

            <alert v-if="success === true" class="mb-5" type="success" :message="message" />

            <alert v-if="success === false" class="mb-5" type="error" :message="message" />

            <ValidationProvider v-slot="{ errors }" name="campaignName" rules="required|max:255" slim>
                <div class="input flex flex-col">
                    <label for="campaignName" class="tw-label mb-2">{{ $t('campaigns.rename_modal.name_label') }}</label>
                    <input id="campaignName" v-model="newName" type="text" name="name" class="tw-input" :placeholder="$t('campaigns.rename_modal.name_placeholder')" :class="{'tw-err': errors.length > 0 }">
                    <span class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div class="tw-modal-buttons">
                <button type="button" class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('common.cancel') }}</button>
                <button :disabled="sending" class="btn btn-primary float-right btn-lg" type="submit"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('campaigns.rename_modal.button') }}</button>
            </div>
        </form>
    </ValidationObserver>

</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {

    name: 'RenameCampaign',

    props: ['modal'],

    components: {
        ValidationObserver
    },

    data () {
        return {
            sending: false,
            campaignId: null,
            oldName: '',
            newName: '',
            success: null,
            message: null,
        }
    },

    mounted() {
        this.oldName = this.modal.mdata.campaignName
        this.campaignId = this.modal.mdata.campaignId
    },

    methods: {
        closeModal() {
            this.modal.mclose()
        },

        async renameCampaign(evt) {
            evt.preventDefault()
            this.success = null
            this.message = null

            const isValid = await this.$refs.observer.validate()
            if(isValid) {
                this.sending = true
                try {
                    await this.$http.post(`/campaigns/${this.campaignId}/rename`, {
                        name: this.newName,
                    })
                    this.$message.success(this.$t('campaigns.campaign_renamed'))
                    this.$emit('reloadCampaigns')
                    this.closeModal()
                }
                catch(error) {
                    this.success = false
                    this.message = error.message
                }
                this.sending = false
            }
        }
    }
}
</script>
