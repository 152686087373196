<template>
    <div class="table-cell relative align-top" :class="{'bottom-margin': Object.values(selectfields).includes('__newfield__')}">
        <div v-if="field === '__newfield__'" class="dummy" />

        <div class="container" :class="{'absolute top-0 new_field': field === '__newfield__'}">
            <ecm-select v-model="field" :options="options" searchable />

            <div v-if="field == '__newfield__'" class="flex flex-col">
                <ValidationProvider ref="mergetype" v-slot="{ errors }" rules="required" slim>
                    <div class="flex flex-col mt-2">
                        <ecm-select v-model="ftype" class="w-full" :options="customFieldOptions" />
                        <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                
                <ValidationProvider ref="mergename" v-slot="{ errors }" rules="required|max:255|uniquefieldname" slim>
                    <div class="flex flex-col mt-2">
                        <input
                                v-model="fname"
                                :placeholder="$t('mapper.newfield_name')"
                                class="tw-input"
                                :class="{'tw-err': errors.length > 0}"
                                type="text"
                                name="fieldName">
                        <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <ValidationProvider ref="mergetag" v-slot="{ errors }" rules="required|alpha_dash|startwithletter|max:255|uniquemergetag" slim>
                    <div class="flex flex-col mt-2">
                        <input
                                v-model="merge"
                                :class="{'tw-err': errors.length > 0}"
                                :placeholder="$t('mapper.newfield_merge')"
                                class="tw-input"
                                name="fieldMerge"
                                type="text">
                        <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>

        </div>
    </div>
</template>

<script>
import { extend } from 'vee-validate'

export default {

    name: 'Column',

    props: ['index', 'preselected'],

    data () {
        return {
            field: '',
            merge: '',
            fname: '',
            ftype: '',
            options: [
                {
                    id: '__skip__',
                    name: this.$t('mapper.skip_field')
                },
                {
                    id: 'email',
                    name: this.$t('mapper.email')
                },
                {
                    id: 'name',
                    name: this.$t('mapper.name')
                },
                {
                    id: 'surname',
                    name: this.$t('mapper.surname')
                },
                {
                    id: 'name_surname',
                    name: this.$t('mapper.name_surname')
                },
                {
                    id: 'surname_name',
                    name: this.$t('mapper.surname_name')
                },
                {
                    id: 'company',
                    name: this.$t('mapper.company')
                },
                {
                    id: 'street',
                    name: this.$t('mapper.street')
                },
                {
                    id: 'city',
                    name: this.$t('mapper.city')
                },
                {
                    id: 'zip',
                    name: this.$t('mapper.zip')
                },
                {
                    id: 'country',
                    name: this.$t('mapper.country')
                },
                {
                    id: 'phone',
                    name: this.$t('mapper.phone')
                },
                {
                    id: 'pretitle',
                    name: this.$t('mapper.pretitle')
                },
                {
                    id: 'surtitle',
                    name: this.$t('mapper.surtitle')
                },
                {
                    id: 'nameday',
                    name: this.$t('mapper.nameday')
                },
                {
                    id: 'birthday',
                    name: this.$t('mapper.birthday')
                },
                {
                    id: 'gender',
                    name: this.$t('mapper.gender')
                }
            ],
            customFieldOptions: [
                {
                    id: 'string',
                    name: this.$t('mapper.cf_type_text')
                },
                {
                    id: 'int',
                    name: this.$t('mapper.cf_type_integer')
                },
                {
                    id: 'float',
                    name: this.$t('mapper.cf_type_float')
                },
                {
                    id: 'date',
                    name: this.$t('mapper.cf_type_date')
                },
                {
                    id: 'url',
                    name: this.$t('mapper.cf_type_url')
                },
                {
                    id: 'json',
                    name: this.$t('mapper.cf_type_json')
                }
            ]
        }
    },

    computed: {
        selectfields: function() {
            return this.$store.state.selectedFields
        },
        customfields: function() {
            return this.$store.state.customFields
        }
    },

    watch: {
        field(newVal, oldVal) {
            if(newVal && newVal !== '' && newVal !== oldVal) {
                this.$store.dispatch('selectField', {index: this.index, val: newVal})
            }
            if(newVal !== oldVal && newVal === '__newfield__'){
                this.$store.dispatch('addField', {
                    index: this.index,
                    type: '',
                    name: '',
                    merge: ''
                })
            }
            if(newVal !== oldVal && oldVal === '__newfield__'){
                this.$store.dispatch('removeField', this.index)
            }
        },
        ftype(newVal) {
            this.$store.dispatch('setFieldProp', {index: this.index, prop: 'type', val: newVal})
        },
        merge(newVal) {
            this.$store.dispatch('setFieldProp', {index: this.index, prop: 'merge', val: newVal})
        },
        fname(newVal) {
            this.$store.dispatch('setFieldProp', {index: this.index, prop: 'name', val: newVal})
        }
    },

    created() {
        this.field = this.preselected

        extend('uniquemergetag', {
            validate: (value) => {
                return this.$store.state.usedMerges.indexOf(value.toLowerCase()) === -1
            },
            message: this.$t('mapper.merge_used')
        })

        extend('uniquefieldname', {
            validate: (value) => {
                return this.$store.state.usedMergeNames.indexOf(value.toLowerCase()) === -1
                    && !this.options.find(o => o.name.toLowerCase() === value.toLowerCase())
            },
            message: this.$t('mapper.field_name_used')
        })

        for(const [key, value] of Object.entries(this.customfields)) {
            this.options.push({ id: key, name: `${this.$t('mapper.custom')} ${value.name}` })
        }
        this.options.push({ id: '__newfield__', name: this.$t('mapper.new_field') })
    },
}
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    .dummy {
        width: 182px;
        height: 176px;
    }

    .table-cell {
        &.bottom-margin {
            border-bottom: 24px white solid;
        }

        &:not(:first-child) {
          border-left: 2px white solid;
        }

        &:not(:last-child) {
          border-right: 2px white solid;
        }
    
        > .container {
            padding: 16px 16px 14px;
            background-color: $grey-10;

            &.new_field {
                background: $white-bg;
                border: 2px $sail-30 solid;
                box-sizing: border-box;
            }
        }
    }
</style>