import braintree from 'braintree-web-drop-in'
import i18n from '@js/i18n'
import Bugsnag from '@bugsnag/js'
/**
 *  Mount Braintree dropin to html element, based on selector given as parameter.
 *  The dropin instance will be set to this.btinstance.
 */

export default {
    methods: {
        initiateBraintree(token, container, threeDSecure = true, retry = 0) {
            const dropinElement = document.querySelector(container)
            if(!dropinElement && retry <= 3) {
                Bugsnag.leaveBreadcrumb(`Dropin element not found. Selector: ${container}. Retry: ${{retry}}`)
                this.$nextTick(() => this.initiateBraintree(token, container, threeDSecure, retry + 1))
                return
            }

            braintree.create({
                authorization: token,
                container: container,
                translations: i18n.t('account.braintree'),
                threeDSecure: threeDSecure,
            }, (error, instance) => {

                if(!error) {
                    this.inited = true
                    this.btinstance = instance
                    return
                }

                this.inited = false
                this.braintree_load_error = error
                Bugsnag.notify(error)
            })
        },
        refreshBraintree(token, container, threeDSecure = true) {
            this.inited = false
            this.btinstance.teardown((error) => {
                if(!error) {
                    this.initiateBraintree(token, container, threeDSecure)
                    return
                }
                console.error(error)
                this.braintree_load_error = 'Loading error'
            })
        }
    }
}