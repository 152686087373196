<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                    </router-link>
                    <div class=" hidden lg:block">
                        <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.import_subscribers.title') }}</span>
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="pt-2 px-0 md:px-20">

            <div v-if="consentRequired">
                <alert v-if="message && success === false" class="mb-6" type="error" :message="message" />

                <alert
                        type="info"
                        :message="$t('contacts.import_subscribers.consent')" />

                <div class="flex justify-between mt-8">
                    <button class="btn btn-danger btn-lg" @click="goBack">{{ $t('common.cancel') }}</button>
                    <button :disabled="sending" class="btn btn-primary btn-lg" @click="sendImport"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.import_subscribers.continue') }}</button>
                </div>
            </div>

            <ValidationObserver v-else ref="observer" slim>

                <import-progress-bar :account-name="account.name" :list-name="list.name" />

                <tab-row :value="type" class="mb-4" :options="['stretch']" @input="changeTab">
                    <template #tab="slotProps">
                        <tab-item v-for="(tab, index) in tabs" :key="`recipient-group-option-${index}`" :value="tab.code" :title="tab.text" :icon="{name: tab.icon, size: 'small'}" :selected="type" :trigger-select="slotProps.triggerSelect" :options="slotProps.options" />
                    </template>
                </tab-row>

                <p class="txt-14 text-gray-900 mt-6">
                    {{ $t('appforms.contacts.import.p_' + type) }}
                </p>

                <alert
                        v-if="assistedImportSuccessful"
                        class="mt-6"
                        type="success"
                        :message="$t('contacts.import_subscribers.assisted_import_successful')" />

                <alert
                        v-if="type !== 'assisted'"
                        type="info"
                        class="mt-6"
                        :label="$t('appforms.contacts.import.callout')"
                        :message="$t('appforms.contacts.import.callout2') + '<br>' + $t('appforms.contacts.import.callout_list')"
                        :primary-action="account.isEnglishAccount ? null : {text: $t('appforms.contacts.import.callout_action'), url: 'https://support.ecomail.cz/cs/articles/2492259-jak-upravim-chybnou-diakritiku-u-kontaktu'}" />


                <div v-if="type === 'paste' || type === 'excel'" class="mt-6">
                    <ValidationProvider v-slot="{ errors }" name="importData" rules="required|max:150000" slim>
                        <textarea id="importData" v-model="importText" :name="type === 'paste' ? 'paste' : 'excelData'" rows="6" class="tw-input w-full resize-none" :placeholder="$t('appforms.contacts.import.ph_' + type)" />
                        <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <div v-else class="mt-6">
                    <ValidationProvider v-slot="{ errors }" name="file_url" :rules="{required: true, csv_format: type === 'csv'}" slim>
                        <upload id="csvUpload" name="file_json" bucket="ecomail-imports" @changed="changedFile" />
                        <input type="hidden" name="file_url" :value="importFile">
                        <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <div v-if="type === 'assisted'" class="mt-4 flex flex-col">
                    <label for="importNotes" class="txt-14 font-semibold text-gray-1000 mb-2.5">{{ $t('appforms.contacts.import.note') }}</label>
                    <textarea id="importNotes" v-model="importNote" name="note" rows="3" class="tw-input w-full resize-none" :placeholder="$t('appforms.contacts.import.ph_note')" />
                </div>

                <div class="flex flex-row-reverse mt-8">
                    <button :disabled="emptyInput" class="btn btn-primary btn-lg" @click="continueToConsent">{{ $t('common.continue') }}</button>
                </div>

                <div v-if="showVerifyPrompt" class="panel p-4 flex w-full space-x-4" style="margin-top: 50px">
                    <icon icon="lightbulb-on-solid" size="large" class="text-green-600" />
                    <div class="text-gray-900 txt-14" v-html="$t('appforms.contacts.import.verify_emails_prompt')" />
                    <a href="#" @click.prevent="hideVerifyPrompt"><icon icon="times-solid" size="small" class="text-gray-800 ml-auto" /></a>
                </div>

            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'
import { ValidationObserver, extend } from 'vee-validate'

import TabRow from '@component/Tabs/TabRow.vue'
import TabItem from '@component/Tabs/TabItem.vue'
import upload from '@component/Upload/UploadNew.vue'
import ImportProgressBar from '@component/AppForms/Lists/ImportProgressBar.vue'
import Tracker from "@js/tracker";

export default {
    name: 'ImportSubscribers',

    store,

    props: ['list'],

    components: {
        ValidationObserver,

        upload,
        ImportProgressBar,
        TabRow,
        TabItem
    },

    data () {
        return {
            activated: true,
            sending: false,
            message: null,
            success: null,
            assistedImportSuccessful: false,
            tabs: [
                {
                    'code': 'paste',
                    'text': this.$t('appforms.contacts.import.type_paste'),
                    'icon': 'copy-solid'
                },
                {
                    'code': 'csv',
                    'text': this.$t('appforms.contacts.import.type_csv'),
                    'icon': 'file-solid'
                },
                {
                    'code': 'excel',
                    'text': this.$t('appforms.contacts.import.type_excel'),
                    'icon': 'file-solid'
                },
                {
                    'code': 'assisted',
                    'text': this.$t('appforms.contacts.import.type_assisted'),
                    'icon': 'life-ring-solid'
                }
            ],
            type: 'paste',
            importText: '',
            importFile: '',
            importNote: '',
            verifyPromptClosed: false
        }
    },

    computed: {
        ...mapState(['account']),
        emptyInput() {
            if(this.type === 'paste' || this.type === 'excel')
                return this.importText === ''
            return this.importFile === ''
        },
        consentRequired() {
            return this.$route.name === 'subscribersImportConsent'
        },
        showVerifyPrompt() {
            return !localStorage.getItem('hideVerifyEmailsPrompt') && !this.verifyPromptClosed
        }
    },

    watch: { 
        '$route.query.assisted': {
            handler(val) {
                this.assistedImportSuccessful = val === 'successful'
            },
            deep: true,
            immediate: true
        }
    },

    created() {
        extend('csv_format', {
            validate: (value) => {
                var pattern = /^.*\.(csv)$/
                return pattern.test(value)
            },
            message: this.$t('validation.csv_format')
        })
    },

    mounted() {
        // redirect back to default import view, if user tries to access consent without import data
        // if(this.consentRequired && !this.importText && !this.importFile)
        //     this.$router.push({name: 'subscribersImport', params: {list: this.list.id}})
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    async beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$root.showSpaLoader = false
        })
    },

    methods: {
        hideVerifyPrompt() {
            this.verifyPromptClosed = true
            localStorage.setItem('hideVerifyEmailsPrompt', true)
        },

        changeTab(type) {
            this.type = type
            this.importText = ''
            this.importFile = ''

            this.$refs.observer.reset()
        },

        changedFile(file) {
            this.importFile = file
        },

        async continueToConsent() {
            const isValid = await this.$refs.observer.validate()
            if(isValid) {
                this.$router.push({name: 'subscribersImportConsent', params: {list: this.list.id}})
                new Tracker().trackEvent('BUTTON_CLICKED', {
                    scope: 'contacts',
                    name: 'Continue in contact import',
                    type: this.type
                })
            }
        },

        goBack() {
            this.$router.push({name: 'subscribersImport', params: {list: this.list.id}})
        },

        async sendImport(evt) {
            evt.preventDefault()
            this.success = null
            this.message = null

            this.sending = true
            let url = ''
            let data = {}

            switch(this.type) {
            case 'paste':
                url = `/spa/contacts/${this.list.id}/import-copy-paste`
                data['paste'] = this.importText
                break
            case 'csv':
                url = `/spa/contacts/${this.list.id}/import-csv-file`
                data['file_url'] = this.importFile
                break
            case 'excel':
                url = `/spa/contacts/${this.list.id}/import-excel`
                data['excelData'] = this.importText
                break
            default:
                url = `/spa/contacts/${this.list.id}/import-assisted`
                data['file_url'] = this.importFile
                data['note'] = this.importNote
            }

            try {
                const response = await this.$http.post(url, data)
                new Tracker().trackEvent('BUTTON_CLICKED', {
                    scope: 'contacts',
                    name: 'Save contacts',
                    type: this.type
                })
                if(this.type === 'assisted') {
                    this.importFile = ''
                    this.importNote = ''
                    this.$router.push({name: 'subscribersImport', params: {list: this.list.id}, query: {assisted: 'successful'}})
                }
                else
                    this.$router.push({name: 'subscribersImportMap', params: {list: this.list.id, file: response.data.fileUrl}})
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }

            this.sending = false
        }
    }
}
</script>