

<form novalidate method="post" @submit.prevent="saveSettings">

    <alert v-if="success === true" class="mt-4 mb-10" type="success" :message="message" />

    <alert v-if="success === false" class="mt-4 mb-10" type="error" :message="message" />

    <div class="settings flex flex-col card-left no-hover card-white" :class="{card: translationsEnabled, 'mt-5': !translationsEnabled}">
        <ecm-switch v-model="translationsEnabled">
            {{ $t('list_settings.unsub_translation_label') }}
        </ecm-switch>

        <div v-if="translationsEnabled" class="w-full mt-3 pl-switch">
            <ul class="tw-list tw-list-big tw-list-with-ellipses text-black">
                <li class="mb-2">
                    <div class="semibold mb-3">{{ $t('list_settings.set_unsub_from_list_title') }}</div>
                    <div class="input w-full flex flex-col">
                        <label for="unsub_page_title" class="mb-2.5">{{ $t('list_settings.set_accident') }}</label>
                        <input id="unsub_page_title" v-model="unsubtexts.unsub_page_title" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                </li>
                <li class="mb-4">
                    <div class="semibold mb-3">{{ $t('list_settings.set_resub_option') }}</div>
                    <div class="input w-full flex flex-col">
                        <label for="unsub_page_mistake_1" class="mb-2.5">{{ $t('list_settings.set_accident') }}</label>
                        <input id="unsub_page_mistake_1" v-model="unsubtexts.unsub_page_mistake_1" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_page_mistake_2" class="mb-2.5">{{ $t('list_settings.set_resub_title') }}</label>
                        <input id="unsub_page_mistake_2" v-model="unsubtexts.unsub_page_mistake_2" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_email_placeholder" class="mb-2.5">{{ $t('list_settings.unsub_insert_email') }}</label>
                        <input id="unsub_email_placeholder" v-model="unsubtexts.unsub_email_placeholder" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                </li>
                <li class="mb-4">
                    <div class="semibold mb-3">{{ $t('list_settings.set_btn_unsub_text') }}</div>
                    <div class="input w-full flex flex-col">
                        <label for="unsub_button" class="mb-2.5">{{ $t('list_settings.set_unsub') }}</label>
                        <input id="unsub_button" v-model="unsubtexts.unsub_button" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                </li>
                <li class="mb-4">
                    <div class="semibold mb-3">{{ $t('list_settings.set_unsub_confirm') }}</div>
                    <div class="input w-full flex flex-col">
                        <label for="unsub_page_confirm_1" class="mb-2.5">{{ $t('list_settings.set_unsub_success') }}</label>
                        <input id="unsub_page_confirm_1" v-model="unsubtexts.unsub_page_confirm_1" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_page_confirm_2" class="mb-2.5">{{ $t('list_settings.set_feedback_title') }}</label>
                        <input id="unsub_page_confirm_2" ref="unsub_page_confirm_2" v-model="unsubtexts.unsub_page_confirm_2" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                </li>

                <li class="mb-4">
                    <div class="semibold mb-3">{{ $t('list_settings.set_feedback_texts') }}</div>
                    <div class="input w-full flex flex-col">
                        <label for="unsub_res_1" class="mb-2.5">{{ $t('list_settings.unsub_not_wanted') }}</label>
                        <input id="unsub_res_1" v-model="unsubtexts.unsub_res_1" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_res_2" class="mb-2.5">{{ $t('list_settings.unsub_did_not_subscribed') }}</label>
                        <input id="unsub_res_2" v-model="unsubtexts.unsub_res_2" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_res_3" class="mb-2.5">{{ $t('list_settings.unsub_unappropriate') }}</label>
                        <input id="unsub_res_3" v-model="unsubtexts.unsub_res_3" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_res_4" class="mb-2.5">{{ $t('list_settings.unsub_is_spam') }}</label>
                        <input id="unsub_res_4" v-model="unsubtexts.unsub_res_4" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_res_5" class="mb-2.5">{{ $t('list_settings.unsub_other_reason') }}</label>
                        <input id="unsub_res_5" v-model="unsubtexts.unsub_res_5" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                </li>
                <li class="mb-4">
                    <div class="semibold mb-3">{{ $t('list_settings.set_btn_send_feedback_text') }}</div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_confirm" class="mb-2.5">{{ $t('list_settings.set_confirm') }}</label>
                        <input id="unsub_confirm" v-model="unsubtexts.unsub_confirm" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                </li>
                <li class="mb-4">
                    <div class="semibold mb-3">{{ $t('list_settings.set_feedback') }}</div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_response_thanks" class="mb-2.5">{{ $t('list_settings.set_thanks_for_feedback') }}</label>
                        <input id="unsub_response_thanks" v-model="unsubtexts.unsub_response_thanks" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                </li>
                <li class="mb-4">
                    <div class="semibold mb-3">{{ $t('list_settings.unsub_confirmation') }}</div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_double_title" class="mb-2.5">{{ $t('list_settings.unsub_double_title') }}</label>
                        <input id="unsub_double_title" v-model="unsubtexts.unsub_double_title" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_double_text" class="mb-2.5">{{ $t('list_settings.unsub_double_text') }}</label>
                        <input id="unsub_double_text" v-model="unsubtexts.unsub_double_text" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_double_btn" class="mb-2.5">{{ $t('list_settings.unsub_double_btn') }}</label>
                        <input id="unsub_double_btn" v-model="unsubtexts.unsub_double_btn" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.translation_placeholder')">
                    </div>
                </li>
            </ul>
        </div>

    </div>

    <button v-if="hasChanges || translationsEnabled" type="submit" class="btn btn-lg btn-primary mt-8" :class="{'ml-4': translationsEnabled}"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('list_settings.save_changes') }}</button>

</form>

