
<div class="mc-r w-full">
    <div class="main-panel solo">
        <h2 class="section-header-container">
            <span>{{ $t('chat.m-start') }}</span>
        </h2>
        <vue-custom-scrollbar ref="content-layout" :settings="settings" class="spa-container" @ps-y-reach-start="scrolled = false">
            <a class="btn btn-primary btn-lg my-10" href="/spa/chat/enable">Start chatting</a>
        </vue-custom-scrollbar>
    </div>
</div>
