<template>
    <div>
        <portal v-if="activated" to="chat-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'chatDashboard'}" class="txt-18 text-gray-800 hover:no-underline hover:text-gray-1000">
                        {{ $t('chat.chat') }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('chat.m-agents') }}</span>
                </div>
                <div class="hidden lg:flex">
                    <router-link :to="{name: 'chatDashboard'}" class="btn btn-tertiary items-center">
                        <icon class="mr-2 -ml-1" icon="caret-left-solid" />
                        <span class="-mr-1">{{ $t('common.back') }}</span>
                    </router-link>
                </div>
            </div>
        </portal>
        
        <div class="flex flex-col md:flex-row mb-10 justify-between items-center">
            <div class="flex flex-col mb-5 md:mb-0">
                <h4 class="light">
                    {{ $t('chat.agents.h') }}
                </h4>
                <p class="light">
                    {{ $t('chat.agents.p') }}
                </p>
            </div>
        </div>

        <alert v-if="success === true" class="mb-5" type="success" :message="message" />

        <alert v-if="success === false" class="mb-5" type="error" :message="message" />

        <div class="w-full overflow-x-auto">
            <div class="w-full overflow-x-auto">
                <chat-agents-form :users="users" :agents="agents" :logged="user.email" @showAlert="showAlert" />
            </div>
        </div>
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'

export default {
    name: 'agents',

    store,

    data() {
        return {
            activated: true,
            success: null,
            message: null
        }
    },

    computed: {
        ...mapState(['user']),
        ...mapState('chatModule', ['users', 'agents']),
    },


    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('chatModule/loadAgents')
        })
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    methods: {
        showAlert({success, message}) {
            console.log(success)
            this.success = success
            this.message = message
        }
    }
}
</script>
