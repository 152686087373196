
<div style="margin-left: 16px;">

    <template v-if="loading">
        Loading
    </template>
    <automation-builder
            v-else
            ref="automation-builder"
            mode="chatbot"
            :marketer="true"
            :can-create-sms-campaign="true"
            :editable="editable"
            list="N/A"
            list-id="1"
            list-name="name"
            fbpages="[]"
            :initpipeline="chatbot"
            :plumbing="plumbing"
            integrations="[]"
            :customfields="[]"
            strategy="s"
            :is-blocked="0" />

</div>
