<template>
    <div>
        <portal v-if="activated" to="chat-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'chatDashboard'}" class="txt-18 text-gray-800 hover:no-underline hover:text-gray-1000">
                        {{ $t('chat.chat') }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('chat.m-settings') }}</span>
                </div>
                <div class="hidden lg:flex">
                    <router-link :to="{name: 'chatDashboard'}" class="btn btn-tertiary items-center">
                        <icon class="mr-2 -ml-1" icon="caret-left-solid" />
                        <span class="-mr-1">{{ $t('common.back') }}</span>
                    </router-link>
                </div>
            </div>
        </portal>
        
        <template v-if="settings">
            <div class="flex flex-col md:flex-row">
                <div class="w-full md:w-2/6 pr-10 mb-5 md:mb-0">
                    <h4 class="mb-3 light">
                        {{ $t('chat.settings.locale.h') }}
                    </h4>
                    <p class="light">
                        {{ $t('chat.settings.locale.p') }}
                    </p>
                </div>
                <div class="panel p-9 w-full md:w-4/6">
                    <chat-settings-form :settings="settings" @reloadSettings="reloadSettings" />
                </div>
            </div>

            <hr class="my-5">

            <div class="flex flex-col md:flex-row">

                <div class="w-full md:w-2/6 pr-10 mb-5 md:mb-0">
                    <h4 class="mb-3.5 light">
                        {{ $t('chat.settings.install.h') }}
                    </h4>
                    <p class="light">
                        {{ $t('chat.settings.install.p') }}
                    </p>
                </div>

                <div class="panel p-10 w-full md:w-4/6">
                    <chat-code :account="account.name" :appid="app_id" />
                </div>

            </div>
        </template>

        <div v-else class="w-4 h-4 mt-4 double-loader" />
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'

export default {
    name: 'settings',

    store,

    data() {
        return {
            activated: true
        }
    },
  
    computed: {
        ...mapState(['account']),
        ...mapState('chatModule', ['settings', 'app_id'])
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('chatModule/loadSettings')
        })
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    methods: {
        reloadSettings(settings) {
            store.dispatch('chatModule/setSettings', settings)
        }
    }

}
</script>
