<template>

    <ValidationObserver ref="observer" slim>
        <form novalidate class="mb-0 mt-4" @submit.prevent="createField">
            <alert v-if="success === false" class="mb-5" type="error" :message="message" />

            <p class="txt-14 dimmed90 mb-6" style="max-width: 450px">
                {{ $t('appforms.contacts.field.help') }}
            </p>

            <ValidationProvider v-slot="{ errors }" name="name" rules="required|max:255" slim>
                <div class="input flex flex-col mb-4">
                    <label for="fieldName" class="tw-label mb-2.5">{{ $t('appforms.contacts.field.name') }}</label>
                    <input id="fieldName" v-model="name" type="text" name="field_name" class="tw-input" :class="{'tw-err': errors.length > 0 }" :placeholder="$t('appforms.contacts.field.name_ph')">
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="merge" rules="required|alpha_dash|startwithletter|max:255" slim>
                <div class="input flex flex-col mb-4">
                    <label for="fieldMerge" class="tw-label mb-2.5 flex items-center">
                        <div>
                            {{ $t('appforms.contacts.field.merge') }}
                        </div>
                        <div v-tooltip.top="{content: $t('appforms.contacts.field.merge_tip'), autoHide: false}" class="svg-wrapper ml-1.5 pt-1 flex items-center">
                            <svg-vue icon="info-circle-solid" class="text-alert-550" />
                        </div></label>
                    <input id="fieldMerge" v-model="merge" type="text" name="tag_name" class="tw-input" :class="{'tw-err': errors.length > 0 }" :placeholder="$t('appforms.contacts.field.merge_ph')">
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="type" rules="required" slim>
                <div class="input flex flex-col">
                    <ecm-select
                            id="type"
                            v-model="type"
                            :options="options"
                            option-value-attribute="value"
                            option-display-attribute="name"
                            :label="$t('appforms.contacts.field.type')"
                            :title="$t('appforms.contacts.field.select')" />
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div class="tw-modal-buttons">
                <button type="button" class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('common.cancel') }}</button>
                <button :disabled="sending" type="submit" class="btn btn-primary float-right btn-lg"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.contacts.field.add-field') }}</button>
            </div>
        </form>
    </ValidationObserver>

</template>

<script>
import {ValidationObserver} from 'vee-validate'
import Tracker from '@js/tracker'

export default {

    name: 'CustomFieldForm',

    props: ['modal', 'listId'],

    components: {
        ValidationObserver
    },

    data () {
        return {
            sending: false,
            success: null,
            message: null,
            name: '',
            merge: '',
            type: '',
            options: [
                {
                    value: 'string',
                    name: this.$t('appforms.contacts.field.cf_type_text')
                },
                {
                    value: 'int',
                    name: this.$t('appforms.contacts.field.cf_type_integer')
                },
                {
                    value: 'float',
                    name: this.$t('appforms.contacts.field.cf_type_float')
                },
                {
                    value: 'date',
                    name: this.$t('appforms.contacts.field.cf_type_date')
                },
                {
                    value: 'url',
                    name: this.$t('appforms.contacts.field.cf_type_url')
                },
                {
                    value: 'json',
                    name: this.$t('appforms.contacts.field.cf_type_json')
                }
            ]
        }
    },

    methods: {

        closeModal() {
            this.modal.mclose()
        },

        async createField() {
            this.success = null
            this.message = null

            const isValid = await this.$refs.observer.validate()
            if(isValid) {
                this.sending = true
                try {
                    await this.$http.post(`/contacts/${this.listId}/custom-fields`, {
                        tag_name: this.merge,
                        field_name: this.name,
                        field_type: this.type,
                    })
                    this.success = true
                    this.$message.success(this.$t('appforms.contacts.field.field_created_alert'))
                    new Tracker().trackEvent('BUTTON_CLICKED', {
                        scope: 'contacts',
                        name: 'custom field created successfully'
                    })
                    this.$emit('refreshCustomFields')
                    this.closeModal()
                }
                catch(error) {
                    this.success = false
                    this.message = error.message
                }
                this.sending = false
            }
        }
    }
}
</script>
