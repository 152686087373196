<template>

    <div>

        <div v-if="success" class="flex flex-col">

            <div v-if="success === true" class="alert alert-success mb-5" v-html="message" />
            <button class="btn btn-lg btn-primary self-center" onClick="window.location.reload()">{{ $t('appforms.upgrade.reload') }}</button>

        </div>

        <form v-else ref="buyForm" novalidate action="/account/change-plan-process" method="post" @submit.prevent="orderPlan">

            <div v-if="success === false" class="alert alert-danger mb-5">
                <span v-if="message" v-html="message" />
                <span v-else-if="payment_error">
                    {{ payment_error }} <br>
                    {{ braintree_error }}
                </span>
            </div>

            <div v-if="braintree_load_error" class="alert alert-danger mb-5">
                {{ braintree_load_error }}
            </div>

            <p class="txt-14 dimmed90">
                {{ $t('appforms.account.upgrade.intro') }}
            </p>

            <template v-if="step === 1">
                <p class="txt-13 semibold grey-100 mt-5">
                    {{ $t('appforms.account.upgrade.intro2') }}
                </p>

                <div class="flex mt-5">
                    <div class="w-1/2">
                        <ul class="tw-list tw-list-with-checks tw-list-green-checks dimmed90 txt-13 lh-24">
                            <li v-for="feature in features1" :key="feature" :class="{semibold: highlight === feature || highlight.includes(feature), 'grey-100': highlight === feature || highlight.includes(feature)}">{{ $t('appforms.account.upgrade.' + feature) }}</li>
                        </ul>
                    </div>
                    <div class="w-1/2">
                        <ul class="tw-list tw-list-with-checks tw-list-green-checks dimmed90 txt-13 lh-24">
                            <li v-for="feature in features2" :key="feature" :class="{semibold: highlight === feature || highlight.includes(feature), 'grey-100': highlight === feature || highlight.includes(feature)}">{{ $t('appforms.account.upgrade.' + feature) }}</li>
                        </ul>
                    </div>
                </div>
            </template>

            <template v-else>

                <div class="tw-well tw-well-info tw-well-lg txt-16 dimmed90 mb-5 mt-10 flex justify-between">
                    <div>
                        {{ $t('appforms.cashier.account_in_version') }}<br>
                        <span class="semibold grey-100">Marketer+ {{ $t('appforms.cashier.till') }} {{ limit ? limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '...' }} {{ $t('appforms.cashier.contacts') }}</span>
                    </div>
                    <div class="flex items-end">
                        <span><span class="semibold grey-100">{{ price_with_vat ? numberToLocale(price_with_vat) : '...' }}</span> {{ currencytxt }} {{ hasVat ? $t('appforms.cashier.with_vat'): '' }} {{ frequency ? '/ ' + $t('appforms.cashier.cycle_' + frequency) : '' }}</span>
                    </div>
                </div>

                <div class="tw-well tw-well-info tw-well-lg txt-14 dimmed90 mb-5 w-full">
                    <span class="semibold grey-100 break-normal">{{ $t('appforms.cashier.plan_prorate_info') }}</span>
                    <br>
                    {{ $t('appforms.cashier.plan_prorate_info2') }}
                </div>

                <div id="dropin-container" />

            </template>

            <div v-if="step === 1" class="tw-modal-buttons">
                <button type="button" class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('forms.form_close_props_editor') }}</button>
                <button class="btn btn-primary float-right btn-lg" @click="initUpgrade">{{ $t('appforms.account.upgrade.upgrade') }}</button>
            </div>

            <div v-else class="tw-modal-buttons text-right">
                <a href="https://www.braintreegateway.com/merchants/djbwkm2xzbvjbz9m/verified" target="_blank" class="float-left"><img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png" width="164px" height="44px" border="0"></a>
                <button :disabled="!inited || sending" class="btn btn-lg btn-primary"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.cashier.plan_order_and_pay') }} {{ price_with_vat ? price_with_vat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '' }} {{ currencytxt }}</button>
            </div>

        </form>

    </div>

</template>

<script>

import braintreeMixin from '@mixins/braintree'
import toTemplate from '@mixins/toTemplate'

export default {

    name: 'MarketerUpgrade',

    mixins: [toTemplate, braintreeMixin],

    props: ['modal', 'highlight', 'strings', 'braintreeThreeDSecureParameters'],

    components: {

    },

    directives: {

    },

    data () {
        return {
            step: 1,
            features1: ['productFeeds', 'ecommerce', 'fbLeads', 'webtracking', 'purchases', 'segmentation'],
            features2: ['automations', 'abandonedBaskets', 'dynamicContent', 'abTesting', 'fbAudiences', 'coupons'],
            nonce: null,
            inited: false,
            btinstance: null,
            sending: false,
            success: null,
            message: null,
            hasVat: false,
            payment_error: null,
            braintree_error: null,
            limit: null,
            price: null,
            price_with_vat: null,
            currency: null,
            fittingplan: null,
            frequency: null,
            braintree_load_error: null,
            braintree_dropin_selector: '#dropin-container',
        }
    },

    computed: {
        currencytxt: function () {
            if(this.currency === 'CZK') {
                return 'Kč'
            } else {
                return this.currency
            }
        },
        currencySuffix() {
            if(this.currency === 'CZK') {
                return ''
            }
            return `_${this.currency}`
        }
    },

    watch: {
        token(val) {
            this.initiateBraintree(val, this.braintree_dropin_selector)
        }
    },

    created: function () {
        if(this.token) {
            this.initiateBraintree(this.token, this.braintree_dropin_selector)
        }
    },

    methods: {

        closeModal() {
            this.modal.mclose()
        },

        async initUpgrade() {
            this.step = 2
            try {
                const response = await this.$http.get('/account/marketer-upgrade-init')

                this.initiateBraintree(response.data.clientToken, this.braintree_dropin_selector)
                this.fittingplan = response.data.fittingPlan
                this.limit = response.data.fittingPlan.limits.upper
                this.frequency = response.data.frequency === 12 ? 'yearly': 'monthly'
                this.currency = response.data.currencyCode
                this.price = response.data.fittingPlan.prices[this.frequency]['marketer' + this.currencySuffix]
                this.price_with_vat = response.data.fittingPlan.prices[this.frequency]['marketer_with_vat_total' + this.currencySuffix]
            }
            catch(error) {
                console.error(error)
            }
        },

        orderPlan(evt) {
            evt.preventDefault()
            this.success = null
            this.message = null
            this.payment_error = null
            this.braintree_error = null
            if(this.inited && this.btinstance !== null) {
                const threeDSecure = {...this.braintreeThreeDSecureParameters, amount: this.price_with_vat}
                this.btinstance.requestPaymentMethod({
                    threeDSecure: threeDSecure
                }, async (requestPaymentMethodErr, payload) => {
                    this.sending = true
                    // Submit payload.nonce to your server
                    if(payload && payload.nonce) {

                        this.nonce = payload.nonce

                        try {
                            this.$http.post('/account/change-plan-process', {
                                limit: this.limit,
                                tier: 0,
                                marketer: 1,
                                version: 2,
                                currency: this.currency,
                                charge: this.price_with_vat,
                                cycle: this.frequency,
                                payment_method_nonce: this.nonce
                            })
                            this.sending = false
                            this.success = true
                            this.message = this.$t('appforms.cashier.subscription_success')

                        }
                        catch(error) {
                            this.sending = false
                            this.success = false
                            if(error.response.data.payment_error) {
                                this.payment_error = error.response.data.payment_error
                                this.braintree_error = error.response.data.braintree_error
                            }
                            if(error.response.data.message) {
                                this.message = error.response.data.message
                            }
                            this.refreshBraintree(this.token, this.braintree_dropin_selector)
                        }
                    }
                })
            }
        }

    },

}
</script>
