<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="txt-18 text-gray-1000">
                    {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" size="small" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="flex flex-col h-full">
            <div v-if="!overviewLoaded" class="w-4 h-4 mt-4 double-loader" />

            <div v-else-if="listNotEmpty">
                <alert v-if="message" class="mb-4" :type="success ? 'success' : 'error'" :message="message" />

                <div class="flex flex-col-reverse md:flex-row justify-between mb-4">
                    <div class="mt-5 md:mt-0 w-full md:w-auto">
                        <div v-dropdown="'open'" class="tw-dropdown">
                            <button class="btn no-focus bordered btn-secondary flex icon-right w-full md:w-auto" style="min-width: 220px;" dropdown-toggle>
                                <span class="flex-1 text-left">{{ $te(`contacts.overview.status.${currentStatus}`) ? $t(`contacts.overview.status.${currentStatus}`) : $t('contacts.overview.status.invalid_status') }}</span>
                                <span class="pill outline flex-none">{{ statusStats[currentStatus] }}</span>
                                <icon icon="caret-down-solid" size="small" />
                            </button>
                            <div class="tw-dropdown-items tw-dropdown-left flex flex-col overflow-hidden" style="min-width: 270px;">
                                <a v-for="(value, status, index) in statusStats" :key="`status-${index}`" href="#" class="flex justify-between items-center bg-white section-dropdown-item" @click.prevent="changeStatus(status)">
                                    <span>{{ $t(`contacts.overview.status.${status}`) }}</span>
                                    <span class="tw-lbl outline bg-white ml-5">{{ value }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between md:justify-start space-x-2">
                        <button class="btn btn-tertiary w-min" @click="addContact">{{ $t('contacts.overview.add_contact_button') }}</button>
                        <router-link v-if="user.role !== 'custom' || checkRole(user, 'manage-contacts-import')" class="btn btn-primary icon-left w-min" :to="{name: 'subscribersImport', props: {list: list.id}}">
                            <icon icon="plus-solid" size="small" /><span>{{ $t('contacts.overview.import_contacts_button') }}</span>
                        </router-link>
                    </div>
                </div>

                <template v-if="statusStats[currentStatus] > 0">
                    <div class="divider" />

                    <div class="flex flex-col-reverse md:flex-row justify-between mt-4">
                        <div class="flex justify-between md:justify-start items-center space-x-2">
                            <div ref="displayOptions" v-dropdown="'open'" class="tw-dropdown">
                                <button class="btn btn-tertiary icon-both" type="button" dropdown-toggle>
                                    <icon icon="columns-solid" size="small" />
                                    {{ $t('contacts.overview.columns_select_button') }}
                                    <icon icon="caret-down-solid" size="small" />
                                </button>
                                <div class="tw-dropdown-items tw-dropdown-left bg-grey-10 p-4 pt-2.5 flex flex-col z-40" style="min-width: 270px;" dropdown-stay>
                                    <draggable v-model="displayOptions" v-bind="dragOptions" handle=".tab-handle">
                                        <div v-for="(label, index) in displayOptions" :key="`display-option-${index}`" class="flex items-center bb px-2 py-1.5 whitespace-nowrap justify-between">
                                            <div class="mr-2">
                                                <div class="tw-checkbox tw-checkbox-tiny">
                                                    <input :id="label" type="checkbox" :name="label" :value="label" :checked="selectedDisplayOptions.includes(label)" @change="changeDisplayOptions">
                                                    <label :for="label">{{ label.includes('c_fields') ? list.custom_fields[stripCustomField(label)].name : $t(`contacts.overview.columns.${label}`) }}</label>
                                                </div>
                                            </div>
                                            <icon icon="grip-vertical-solid" size="big" class="text-gray-600 cursor-move tab-handle" />
                                        </div>
                                    </draggable>
                                    <div class="flex justify-between mt-4">
                                        <button :disabled="columnsResetting" class="btn btn-text btn-text-p15" type="button" @click="saveDisplayOptions(true)"><div v-if="columnsResetting" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.overview.columns_reset_button') }}</button>
                                        <button :disabled="columnsSaving" class="btn btn-secondary" type="button" @click="saveDisplayOptions()"><div v-if="columnsSaving" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.overview.columns_save_button') }}</button>
                                    </div>
                                </div>
                            </div>
                            <button :disabled="exportSending" class="btn btn-tertiary icon-left" @click="getExport">
                                <div v-if="exportSending" class="double-loader loader-sm loader-grey mr-2" />
                                <icon v-else icon="download-solid" size="small" />
                                {{ $t('contacts.overview.export_button') }}
                            </button>
                            <div v-dropdown="'open'" class="tw-dropdown">
                                <button class="btn btn-tertiary icon-right" type="button" :disabled="!selectedEmails.length" dropdown-toggle>
                                    <span>{{ $t('contacts.overview.selected_contacts_button') }}</span>
                                    <icon icon="caret-down-solid" size="small" />
                                </button>
                                <div class="tw-dropdown-items tw-dropdown-left bg-grey-10 flex flex-col" dropdown-stay>
                                    <button v-if="currentStatus === 'active'" class="flex items-center" :disabled="massUnsubSending" @click="massUnsubscribe()"><div v-if="massUnsubSending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.overview.unsubscribe_button') }}</button>
                                    <div v-if="lists && Object.keys(lists).length" v-dropdown="'open'" class="tw-dropdown">
                                        <button type="button" :disabled="movingToList" class="tw-dropdown tw-sub-dropdown-trigger-right" dropdown-toggle>
                                            <div v-if="movingToList" class="double-loader loader-sm loader-grey mr-2" />
                                            <span>{{ $t('contacts.overview.move_to_list_button') }}</span>
                                            <icon icon="caret-right-solid" class="text-gray-900" size="small" />
                                        </button>
                                        <div class="tw-sub-dropdown-items tw-sub-dropdown-right bg-grey-10 flex flex-col hov w-min">
                                            <button v-for="(listToMove, index) in lists" :key="`list-to-move-${index}`" @click="moveToList(listToMove)">{{ listToMove.name }}</button>
                                        </div>
                                    </div>
                                    <button v-else class="tw-dropdown tw-sub-dropdown-trigger-right cursor-not-allowed" disabled>
                                        <span class="text-gray-600">{{ $t('contacts.overview.move_to_list_button') }}</span>
                                        <icon icon="caret-right-solid" class="text-gray-600" size="small" />
                                    </button>
                                </div>
                            </div>
                            <div v-if="selectedEmails.length" class="font-semibold text-gray-700" v-html="$tc('contacts.overview.selected_contacts_count', allEmailsSelected ? statusStats[currentStatus] : selectedEmails.length, {count: allEmailsSelected ? statusStats[currentStatus] : selectedEmails.length})" />
                        </div>
                        <div class="mb-5 md:mb-0">
                            <form class="mb-0" method="get" @submit.prevent="handleSearch">
                                <div class="tw-input-group flex" style="width: 310px; max-width: 100%">
                                    <div class="tw-input-with-icon flex-grow">
                                        <label for="contactSearch" class="flex-none"><icon icon="search-solid" size="small" /></label>
                                        <input id="contactSearch" v-model="query" type="text" :placeholder="$t('contacts.overview.search_placeholder')" name="search" class="tw-input w-full">
                                    </div>
                                    <button class="btn btn-tertiary" :disabled="searching" type="submit"><div v-if="searching" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.overview.search_button') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div v-if="currentQuery" class="flex txt-14 text-gray-1000 items-center mt-4">
                        <span>{{ $t('campaigns.query_results') }}</span>
                        <h2 class="ml-2">
                            {{ currentQuery }}
                        </h2>
                        <a href="#" class="btn btn-tertiary ml-3 icon-left" @click.prevent="refreshContacts(true)"><icon icon="times-solid" />{{ $t('campaigns.new.cancel') }}</a>
                    </div>

                    <div v-if="currentQuery && (!contacts || !Object.keys(contacts).length)" class="panel px-16 py-8 bg-white mt-3 mb-20">
                        <div class="flex flex-row items-center justify-between">
                            <div class="flex flex-col">
                                <h2 class="mb-2">
                                    {{ $t('contacts.overview.empty_search_heading') }}
                                </h2>
                            </div>
                            <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-file.png" style="width: 164px" alt="feed-ilustration" class="px-4">
                        </div>
                    </div>

                    <template v-else>
                        <div class="w-full overflow-x-auto md:overflow-x-visible mt-4">
                            <div class="table-scrollable">
                                <div class="scrollable z-0" :class="{'has-scroll': tableOverflowing}">
                                    <div class="border-radius">
                                        <div class="table tw-table txt-16">
                                            <div class="table-header-group">
                                                <div class="table-row">
                                                    <div class="table-cell subcheckbox relative z-20 w-5">
                                                        <div class="tw-checkbox checkbox-solo w-5">
                                                            <input id="checkAll" type="checkbox" value="yes" name="checkAll" @change="onSelectAll">
                                                            <label for="checkAll">&nbsp;</label>
                                                        </div>
                                                        <div v-if="showSelectAllOptions" class="select-all-options">
                                                            <button @click="selectContacts('page')">{{ $t('contacts.overview.select_page_button') }}</button>
                                                            <button @click="selectContacts('all')">{{ $t('contacts.overview.select_all_button') }}</button>
                                                        </div>
                                                    </div>
                                                    <div class="table-cell submail w-max z-10 pl-0" :class="{ 'active': currentOrderBy === 'email' }">
                                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('email')">
                                                            {{ $t('contacts.overview.columns.email') }}
                                                            <icon icon="sort-solid" />
                                                        </a>
                                                    </div>
                                                    <template v-if="list.display_settings && Object.keys(list.display_settings).length">
                                                        <div v-for="(displayKey, index) in Array.isArray(list.display_settings) ? list.display_settings : Object.keys(list.display_settings)" :key="`displayed-col-heading-${index}`" class="table-cell" :class="{ 'active': currentOrderBy === displayKey, 'text-right': index === Object.keys(list.display_settings).length - 1 }">
                                                            <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" :class="{'pointer-events-none': displayKey === 'status'}" @click.prevent="orderResults(displayKey)">
                                                                <template v-if="displayKey.includes('c_fields') && list.custom_fields[stripCustomField(displayKey)]">
                                                                    {{ list.custom_fields[stripCustomField(displayKey)].name }}
                                                                </template>
                                                                <template v-else-if="displayKey.includes('c_fields')">
                                                                    {{ $t('contacts.overview.columns.deleted_custom_field') }}
                                                                </template>
                                                                <template v-else-if="displayKey === 'subscribed'">
                                                                    {{ currentStatus !== 'unsubscribed' ? $t('contacts.overview.columns.subscribed') : null }}
                                                                    {{ currentStatus === 'unsubscribed' ? $t('contacts.overview.columns.unsubscribed') : null }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ $t(`contacts.overview.columns.${displayKey}`) }}
                                                                </template>
                                                                <icon v-if="displayKey !== 'status'" icon="sort-solid" />
                                                            </a>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="table-cell" :class="{ 'active': currentOrderBy === 'tags' }">
                                                            <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('tags')">
                                                                {{ $t('contacts.overview.columns.tags') }}
                                                                <icon icon="sort-solid" />
                                                            </a>
                                                        </div>
                                                        <div class="table-cell" :class="{ 'active': currentOrderBy === 'name' }">
                                                            <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('name')">
                                                                {{ $t('contacts.overview.columns.name') }}
                                                                <icon icon="sort-solid" />
                                                            </a>
                                                        </div>
                                                        <div class="table-cell" :class="{ 'active': currentOrderBy === 'surname' }">
                                                            <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('surname')">
                                                                {{ $t('contacts.overview.columns.surname') }}
                                                                <icon icon="sort-solid" />
                                                            </a>
                                                        </div>
                                                        <div class="table-cell" :class="{ 'active': currentOrderBy === 'rating' }">
                                                            <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('rating')">
                                                                {{ $t('contacts.overview.columns.rating') }}
                                                                <icon icon="sort-solid" />
                                                            </a>
                                                        </div>
                                                        <div class="table-cell">
                                                            <span class="font-semibold txt-13">
                                                                {{ $t('contacts.overview.columns.status') }}
                                                            </span>
                                                        </div>
                                                        <div v-if="currentStatus === 'unsubscribed'" class="table-cell text-right" :class="{ 'active': currentOrderBy === 'unsubscribed_at' }">
                                                            <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('unsubscribed_at')">
                                                                {{ $t('contacts.overview.columns.unsubscribed') }}
                                                                <icon icon="sort-solid" />
                                                            </a>
                                                        </div>
                                                        <div v-else class="table-cell text-right" :class="{ 'active': currentOrderBy === 'subscribed_at' }">
                                                            <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('subscribed_at')">
                                                                {{ $t('contacts.overview.columns.subscribed') }}
                                                                <icon icon="sort-solid" />
                                                            </a>
                                                        </div>
                                                        
                                                    </template>
                                                </div>
                                            </div>

                                            <div v-for="(contact, index) in contacts" :key="`subscriber-${index}`" class="table-row txt-13">
                                                <template v-if="contact.subscriber">
                                                    <div class="table-cell subcheckbox z-10 w-5">
                                                        <div class="tw-checkbox checkbox-solo w-5">
                                                            <input :id="`subscriber-${index}`" v-model="selectedEmails" type="checkbox" :value="contact.email">
                                                            <label :for="`subscriber-${index}`">&nbsp;</label>
                                                        </div>
                                                    </div>
                                                    <div class="table-cell submail truncate w-max z-10 pl-0">
                                                        <a class="semibold link-blue" href="#" @click.prevent="showSubscriberModal(contact.email)">{{ contact.email }}</a>
                                                    </div>
                                                    <template v-if="list.display_settings && Object.keys(list.display_settings).length">
                                                        <template v-for="(displayKey, displayIndex) in Array.isArray(list.display_settings) ? list.display_settings : Object.keys(list.display_settings)">
                                                            <div v-if="displayKey === 'tags'" :key="`displayed-col-${index}-${displayIndex}`" class="table-cell z-0 space-x-1.5" :class="{'text-right': displayIndex === Object.keys(list.display_settings).length - 1}">
                                                                <template v-if="contact.subscriber.tags && contact.subscriber.tags.length">
                                                                    <span v-for="(tag, tagIndex) in contact.subscriber.tags" :key="`subscriber-${index}-tag-${tagIndex}`" class="tag tag-sm tag-info">{{ tag }}</span>
                                                                </template>
                                                            </div>
                                                            <div v-else :key="`displayed-col-${index}-${displayIndex}`" class="table-cell" :class="{'text-right w-0 whitespace-nowrap': displayIndex === Object.keys(list.display_settings).length - 1}">
                                                                <template v-if="displayKey === 'groups' && contact.groups">
                                                                    {{ Array.isArray(Object.values(contact.groups)) ? Object.values(contact.groups).join(', ') : '' }}
                                                                </template>
                                                                <template v-else-if="displayKey === 'country' && contact.subscriber.country">
                                                                    {{ $t(`demography.${contact.subscriber.country}`) }}
                                                                </template>
                                                                <template v-else-if="displayKey === 'gender' && contact.subscriber.gender">
                                                                    {{ $t(`contacts.overview.gender_options.${contact.subscriber.gender}`) }}
                                                                </template>
                                                                <template v-else-if="displayKey === 'rating'">
                                                                    <span class="tag tag-sm pl-5"><icon class="relative" icon="star-solid" size="small" /><span class="ml-3"> {{ contact.subscriber.rating }}</span></span>
                                                                </template>
                                                                <template v-else-if="displayKey === 'status'">
                                                                    <span v-html="$t(`contacts.overview.status_tag.${contact.status}`)" />
                                                                </template>
                                                                <template v-else-if="displayKey === 'subscribed'">
                                                                    {{ currentStatus !== 'unsubscribed' && contact.subscribed_at ? getNumberDate(getIsoDate(contact.subscribed_at)) : null }}
                                                                    {{ currentStatus === 'unsubscribed' && contact.unsubscribed_at ? getNumberDate(getIsoDate(contact.unsubscribed_at)) : null }}
                                                                </template>
                                                                <template v-else-if="displayKey.includes('c_fields') && contact.c_fields && JSON.parse(contact.c_fields)[stripCustomField(displayKey)]">
                                                                    {{ Array.isArray(JSON.parse(contact.c_fields)[stripCustomField(displayKey)]) || typeof JSON.parse(contact.c_fields)[stripCustomField(displayKey)] === 'object' ? JSON.stringify(JSON.parse(contact.c_fields)[stripCustomField(displayKey)]) : JSON.parse(contact.c_fields)[stripCustomField(displayKey)] }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ contact.subscriber[displayKey] }}
                                                                </template>
                                                            </div>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <div class="table-cell space-x-1.5">
                                                            <template v-if="contact.subscriber.tags && contact.subscriber.tags.length">
                                                                <span v-for="(tag, tagIndex) in contact.subscriber.tags" :key="`subscriber-${index}-tag-${tagIndex}`" class="tag tag-sm tag-info">{{ tag }}</span>
                                                            </template>
                                                        </div>
                                                        <div class="table-cell">
                                                            {{ contact.subscriber.name }}
                                                        </div>
                                                        <div class="table-cell">
                                                            {{ contact.subscriber.surname }}
                                                        </div>
                                                        <div class="table-cell w-1">
                                                            <span class="tag tag-sm pl-5"><icon class="relative" icon="star-solid" size="small" /><span class="ml-3"> {{ contact.subscriber.rating }}</span></span>
                                                        </div>
                                                        <div class="table-cell w-1" v-html="$t(`contacts.overview.status_tag.${contact.status}`)" />
                                                        <div v-if="currentStatus === 'unsubscribed'" class="table-cell text-right w-0 whitespace-nowrap">
                                                            {{ contact.unsubscribed_at ? getNumberDate(getIsoDate(contact.unsubscribed_at)) : null }}
                                                        </div>
                                                        <div v-else class="table-cell text-right w-0 whitespace-nowrap">
                                                            {{ contact.subscribed_at ? getNumberDate(getIsoDate(contact.subscribed_at)) : null }}
                                                        </div>
                                                    </template>
                                                </template>
                                            </div>
                                        </div>
                                        <div v-if="tableOverflowing" class="in-shadow" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex justify-between my-8">
                            <div v-dropdown="'open'" class="tw-dropdown">
                                <div class="btn-group">
                                    <button class="btn btn-tertiary">
                                        {{ $t('contacts.overview.per_page_button', {count: paginationOverview.per_page}) }}
                                    </button><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                </div>
                                <div class="tw-dropdown-items flex-col min-w-min">
                                    <button v-for="(perPage, index) in perPageOptions" :key="`per-page-${index}`" @click="setPerPage(perPage)">{{ perPage }}</button>
                                </div>
                            </div>
                            <pagination :total="paginationOverview.total" :per-page="paginationOverview.per_page" :current-page="paginationOverview.current_page" :last-page="paginationOverview.last_page" @handlePageChange="handlePageChange" />
                        </div>
                    </template>
                </template>

                <div v-else class="panel px-16 py-8 bg-white mt-3 mb-20">
                    <div class="flex flex-row items-center justify-between">
                        <div class="flex flex-col">
                            <h2 class="mb-2">
                                {{ $t('contacts.overview.empty_status_heading') }}
                            </h2>
                        </div>
                        <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-file.png" style="width: 164px" alt="feed-ilustration" class="px-4">
                    </div>
                </div>
            </div>

            <div v-else class="flex items-center space-x-10 mt-7 px-0 md:px-20">
                <div class="flex flex-col">
                    <h2>{{ $t('contacts.overview.heading') }}</h2>
                    <span class="txt-14 text-gray-900 mt-2.5" v-html="$t('contacts.overview.description')" />
                    <div class="flex flex-row mt-6 space-x-4">
                        <button class="btn btn-primary btn-lg icon-left w-min" @click="addContact"><icon icon="plus-solid" size="small" /><span>{{ $t('contacts.overview.create_contact_button') }}</span></button>
                        <router-link v-if="user.role !== 'custom' || checkRole(user, 'manage-contacts-import')" class="btn btn-primary btn-lg icon-left w-min" :to="{name: 'subscribersImport', props: {list: list.id}}">
                            <icon icon="plus-solid" size="small" /><span>{{ $t('contacts.overview.import_contacts_button') }}</span>
                        </router-link>
                    </div>
                </div>
                <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-lists.png" style="width: 304.76px" alt="list-ilustration" class="hidden md:block">
            </div>
        </div>

        <tw-confirm ref="moveToListModal" @confirm-create="moveToListConfirm" />

        <tw-confirm ref="massUnsubscribeModal" width="750" @confirmDelete="massUnsubscribeConfirm" />

        <tw-modal ref="subscriberDetailModal" :title="$t('appforms.subscriber.title')" close-button="yes" classname="subscriberDetail" :visible-on-load="$route.query['contact-modal']">
            <template #default="slotProps">

                <subscriber-form :modal="slotProps" @contactDeleted="contactDeleted" @contactUpdated="refreshContacts" />

            </template>
        </tw-modal>

        <tw-modal ref="addContactModal" :title="$t('contacts.overview.add_new_contact')" close-button="yes" size="xlarge" content-width>
            <template #default="slotProps">

                <add-contact-form :modal="slotProps" :list-id="slotProps.mdata.listId" @refreshContacts="contactAdded" />

            </template>
        </tw-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

import store from '@stores/index'
import { mapState } from 'vuex'

import toTemplate from '@mixins/toTemplate'
import permissions from '@mixins/permissions'

export default {
    name: 'SubscribersOverview',

    store,

    mixins: [toTemplate, permissions],

    props: ['list'],

    components: {
        draggable
    },

    data() {
        return {
            activated: true,
            tableOverflowing: null,

            columnsSaving: false,
            columnsResetting: false,
            exportSending: false,
            massUnsubSending: false,
            movingToList: false,
            searching: false,

            success: null,
            message: null,

            selectedDisplayOptions: [],
            displayOptions: [],

            showSelectAllOptions: false,
            selectedEmails: [],
            allEmailsSelected: false,

            query: '', 
            perPageOptions: ['25', '50', '100', '200'],
        }
    },

    computed: {
        ...mapState(['user']),
        ...mapState('contactsModule', [
            'overviewLoaded', 'paginationOverview', 'statusStats', 'contacts', 'lists'
        ]),
        currentStatus() {
            return this.$route.query.status ?? 'active'
        },
        currentQuery() {
            return this.$route.query.query ?? null
        },
        currentOrderBy() {
            return this.$route.query.orderBy ?? 'subscribed_at'
        },
        currentOrderDir() {
            return this.$route.query.orderDir ?? 'DESC'
        },
        currentPage() {
            return this.$route.query.page ?? '1'
        },
        listNotEmpty() {
            return Object.values(this.statusStats).reduce((a, b) => a + b, 0)
        },
        dragOptions() {
            return {
                animation: 200,
                group: 'displayOptions',
            }
        }
    },

    watch: {
        'list.display_settings'(val) {
            if(val) {
                if(!this.selectedDisplayOptions.length) {
                    this.selectedDisplayOptions = Array.isArray(val) ? JSON.parse(JSON.stringify(val)) : Object.keys(val)

                    this.selectedDisplayOptions.forEach((el, index) => {
                        this.displayOptions.splice(index, 0, this.displayOptions.splice(this.displayOptions.indexOf(el), 1)[0])
                    })
                }
            }
        },
        selectedEmails(val) {
            if(this.contacts && this.contacts.length && val.length < this.contacts.length)
                this.allEmailsSelected = false
        }
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('contactsModule/loadOverview', {listId: to.params.list, status: to.query.status, query: to.query.query, orderBy: to.query.orderBy, orderDir: to.query.orderDir, page: to.query.page})
        })
    },

    beforeRouteUpdate(to, from, next) {
        store.dispatch('contactsModule/loadOverview', {listId: to.params.list, status: to.query.status, query: to.query.query, orderBy: to.query.orderBy, orderDir: to.query.orderDir, page: to.query.page})
        next()
    },

    mounted() {
        this.setDefaultDisplayOptions()
        this.selectedEmails = []
    },

    updated(){
        if(this.tableOverflowing === null) {
            this.$nextTick(()=>{
                this.setTableOverflowing()
            })
        }
    },

    methods: {
        resetAlert() {
            this.success = null
            this.message = null
        },
        resetSelectedEmails() {
            this.allEmailsSelected = false
            this.selectedEmails = []
        },
        changeStatus(status) {
            this.resetAlert()
            this.resetSelectedEmails()

            if(this.currentStatus !== status)
                this.$router.replace({query: {status: status}})
        },
        addContact() {
            this.resetAlert()

            this.$refs['addContactModal'].showModalRawData({
                listId: this.list.id,
            })
        },
        contactAdded(email) {
            this.success = true
            this.message = this.$t('contacts.overview.contact_added_alert', {contact: email})
              
            this.refreshContacts()
        },
        contactDeleted(email) {
            this.success = true
            this.message = this.$t('contacts.overview.contact_deleted_alert', {contact: email})
            
            this.refreshContacts()
        },
        setDefaultDisplayOptions() {
            this.selectedDisplayOptions = []
            this.displayOptions = ['tags', 'name', 'surname', 'vokativ', 'vokativ_s', 'gender', 'pretitle', 'surtitle', 'company', 'city', 'street', 'zip', 'country', 'phone', 'nameday', 'birthday', 'groups', 'rating', 'status', 'subscribed']
            Object.keys(this.list.custom_fields).forEach(merge => {
                this.displayOptions.push(`c_fields->${merge}`)
            })
        },
        changeDisplayOptions(e) {
            if(e.target.checked) {
                this.selectedDisplayOptions.push(e.target.value)
            }
            else {
                let index = this.selectedDisplayOptions.indexOf(e.target.value)
                if (index !== -1) this.selectedDisplayOptions.splice(index, 1)
            }
        },
        async saveDisplayOptions(resetOptions = false) {
            this.resetAlert()
            if(resetOptions) 
                this.columnsResetting = true
            else
                this.columnsSaving = true
            const selectedOptions = resetOptions ? [] : this.displayOptions.filter(option => this.selectedDisplayOptions.includes(option))

            try {
                await this.$http.post(`/spa/contacts/${this.list.id}/display-settings`, {
                    display_settings: selectedOptions
                })
                this.$refs.displayOptions.firstChild.click()
                this.success = true
                this.message = this.$t('contacts.overview.columns_saved_alert')

                this.setDefaultDisplayOptions()
                this.refreshContacts()
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }
            this.columnsResetting = false
            this.columnsSaving = false
        },
        async getExport() {
            this.resetAlert()
            this.exportSending = true

            try {
                await this.$http.get(`/contacts/${this.list.id}/export`, {params: {status: this.currentStatus}})
                this.success = true
                this.message = this.$t('contacts.overview.export_queued_alert')
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }
            this.exportSending = false
        },
        moveToList(list) {
            this.$refs['moveToListModal'].setData({
                twConfirmTitle: this.$t('contacts.overview.move_to_list_modal.title', {list: list.name}),
                twConfirmText: this.$t('contacts.overview.move_to_list_modal.description'),
                data: list,
                twClose: this.$t('common.cancel'),
                twCreate: this.$t('contacts.overview.move_to_list_modal.button'),
            })
            this.$refs['moveToListModal'].showModal()
        },
        async moveToListConfirm(listToMove) {
            this.resetAlert()
            this.movingToList = true

            try {
                await this.$http.post(`/contacts/${this.list.id}/mass-action`, {
                    emails: JSON.stringify(this.allEmailsSelected ? [] : this.selectedEmails),
                    moveAll: this.allEmailsSelected,
                    status: this.allEmailsSelected ? this.currentStatus : null,
                    action: 'moveToList',
                    moveList: listToMove.id.toString()
                })

                this.success = true
                if (this.allEmailsSelected) {
                    this.message = this.$t('contacts.overview.move_to_list_queued_alert')
                }

                this.refreshContacts()
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }
            this.movingToList = false
        },
        massUnsubscribe() {
            const emailCount = this.allEmailsSelected ? this.statusStats[this.currentStatus] : this.selectedEmails.length
            this.$refs['massUnsubscribeModal'].setData({
                twConfirmTitle: this.$t('contacts.overview.mass_unsubscribe_modal.title'),
                twConfirmText: this.$t('contacts.overview.mass_unsubscribe_modal.description', {count: emailCount}),
                data: emailCount,
                twClose: this.$t('common.cancel'),
                twDelete: this.$t('contacts.overview.mass_unsubscribe_modal.button'),
            })
            this.$refs['massUnsubscribeModal'].showModal()
        },
        async massUnsubscribeConfirm(emailCount) {
            this.resetAlert()
            this.massUnsubSending = true

            try {
                await this.$http.post(`/contacts/${this.list.id}/mass-action`, {
                    emails: JSON.stringify(this.allEmailsSelected ? [] : this.selectedEmails),
                    status: this.allEmailsSelected ? this.currentStatus: null,
                    action: 'unsubscribe'
                })

                this.success = true
                this.message = this.$t('contacts.overview.unsubscribe_alert', {count: emailCount})
                
                
                this.refreshContacts()
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }
            this.massUnsubSending = false
        },
        handleSearch() {
            this.resetAlert()
            this.resetSelectedEmails()

            if (this.query && this.query !== '' && this.query !== this.currentQuery) {
                this.searching = true
                this.$router.replace({query: {...this.$route.query, query: this.query, page: undefined}})
                this.searching = false
            }
        },
        setPerPage(perPage) {
            this.resetAlert()
            localStorage.setItem('contactsPerPage', perPage)
            this.refreshContacts()
            
        },
        handlePageChange(page) {
            this.resetAlert()
            this.resetSelectedEmails()

            if(page.toString() !== this.currentPage)
                this.$router.replace({query: {...this.$route.query, page: page}})
        },
        orderResults(orderBy) {
            this.resetAlert()
            this.resetSelectedEmails()
            let orderDir = this.currentOrderDir

            if(orderBy === 'subscribed')
                orderBy = this.currentStatus === 'unsubscribed' ? 'unsubscribed_at' : 'subscribed_at'

            if(this.currentOrderBy === orderBy) 
                orderDir = this.currentOrderDir === 'DESC' ? 'ASC' : 'DESC' 
            this.$router.replace({query: {...this.$route.query, page: undefined, orderBy: orderBy, orderDir: orderDir}})
        },
        onSelectAll(e) {
            if(e.target.checked) {
                this.showSelectAllOptions = true
            }
            else {
                this.showSelectAllOptions = false
                this.allEmailsSelected = false
                this.selectedEmails = []
                
            }
        },
        selectContacts(option) {
            this.showSelectAllOptions = false
            if(option === 'page') {
                this.selectedEmails = this.contacts.map(x => x.email)
                this.allEmailsSelected = false
            }
            else {
                this.selectedEmails = this.contacts.map(x => x.email)
                this.allEmailsSelected = true
            }
        },
        showSubscriberModal(email) {
            this.$refs['subscriberDetailModal'].showModalRawData({
                email: email,
                list: this.list.id
            })
        },
        stripCustomField(field) {
            return field.replace('c_fields->', '')
        },
        setTableOverflowing() {
            if(document.getElementsByClassName('table-scrollable')[0] && document.getElementsByClassName('scrollable')[0])
                this.tableOverflowing = document.getElementsByClassName('table-scrollable')[0].clientWidth < document.getElementsByClassName('scrollable')[0].childNodes[0].childNodes[0].childNodes[0].clientWidth
        },
        refreshContacts(resetQuery = false) {
            this.resetSelectedEmails()

            if(resetQuery && this.currentQuery)
                this.$router.replace({query: {...this.$route.query, query: undefined, page: undefined}})
            else if(this.currentPage !== '1')
                this.$router.replace({query: {...this.$route.query, page: undefined}})
            else {
                store.dispatch('contactsModule/loadOverview', {listId: this.list.id, status: this.currentStatus, query: this.currentQuery, orderBy: this.currentOrderBy, orderDir: this.currentOrderDir})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~sass/variables';


    .sortable-chosen {

        &:not(.draggable-action) {
          margin: 0;
        }
        
        .svg-wrapper {
            color: $grey-80 !important;
        }
    }

    .border-radius {
        border-radius: $border-radius;
    }

    .section-dropdown-item:hover {
        background-color: $grey-10;
        position: relative;
        box-shadow: 0px 1px 0px 0px #E4E4E4B2,
                    0px -1px 0px 0px #E4E4E4B2;

        &:first-of-type {
            box-shadow: 0px 0px 0px 0px #E4E4E4B2,
                        0px 1px 0px 0px #E4E4E4B2;
        }

        &:last-of-type {
            box-shadow: 0px -1px 0px 0px #E4E4E4B2,
                        0px 0px 0px 0px #E4E4E4B2;
        }
    }

    .select-all-options {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 10px;
        left: 44px;
        border-radius: 3px;
        border: 1px solid $grey-50;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
        background-color: $grey-10;
        text-align: left;

        > * {
            padding: 9px 14px;
            color: $grey-100;
            font-size: 12px;
            white-space: nowrap;
            text-align: left;

            &:focus {
                outline: none;
            }

            &:last-child {
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &:hover {
                text-decoration: none;
                background-color: $grey-30;
            }
        }
    }
</style>
