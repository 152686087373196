<template>

  <div class="tw-dropdown open">
    <button class="tiny-square" @click.stop.prevent="toggleDropdown" v-on-clickaway="hideDropdown"><svg-vue icon="caret-down-solid"></svg-vue></button>
    <div v-if="opened" class="tw-dropdown-items flex-col">
      <slot />
    </div>
  </div>

</template>

<script>

import { mixin as clickaway } from 'vue-clickaway';

export default {

	name: 'TinyDropDown',

	props: [],

    mixins: [clickaway],

	data () {
		return {
			opened: false,
		}
	},

    methods: {
        toggleDropdown: function () {
            this.opened === true ? this.opened = false : this.opened = true
        },
        hideDropdown: function () {
            this.opened = false
        },
    }
}
</script>