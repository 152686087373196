

<div v-on-clickaway="hideDropdown" :class="{open: opened}" class="ecomail-select">

    <div v-if="label" class="ecomail-select-label" :class="{ 'text-auth-gray-700': !hasError, 'text-auth-red-800': hasError }">
        <span>{{ label }}</span>
        <svg
                v-if="required && showRequireIndicatior"
                :class="{ 'text-auth-green-500': !hasError, 'text-auth-red-800': hasError }"
                class="w-3 h-3 mt-1 ml-1"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
            <path
                    d="M6.00003 1.75781V6.00045M6.00003 6.00045L6.00003 10.2431M6.00003 6.00045L10.2427 6.00045M6.00003 6.00045L1.75739 6.00045"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
            <path
                    d="M9 3L6 6M6 6L3 9M6 6L9 9M6 6L3 3"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
        </svg>
    </div>
    <div
            ref="title"
            class="relative flex px-[14px] justify-between items-center h-[44px] transition duration-150 ease-in-out border border-auth-gray-300 text-auth-sm sm:leading-5 whitespace-nowrap cursor-pointer"
            :class="{
                'disabled': disabled,
                'rounded-[4px]': !opened,
                'rounded-t-[4px] outline-none': opened,
                'searching': opened && searchable,
                'text-auth-red-800 searching bg-white border-auth-red-800 border-red-800': opened && hasError,
                'bg-auth-white': opened && !hasError,
                'text-auth-red-800 bg-red-50 hover:bg-white border-auth-red-300 placeholder-auth-red-500': !opened && hasError,
                'hover:bg-auth-white bg-auth-gray-50 placeholder-auth-gray-500': !hasError
            }"
            @click.prevent="titleAction">
        <input v-if="opened && searchable" ref="search-input" v-model="search" :placeholder="title" type="text" @click.stop="">
        <span v-else-if="opened">{{ title }}</span>
        <span v-else-if="value" class="selected flex items-center">
            <slot name="icon" />
            <slot name="selectedLabel" />
        </span>
        <span v-else>{{ title }}</span>
        <div
                v-if="hasError"
                class="absolute inset-y-0 right-0 flex items-center pr-3 mt-1 pointer-events-none">
            <svg
                    class="w-5 h-5 text-auth-red-500"
                    fill="currentColor"
                    viewBox="0 0 20 20">
                <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd" />
            </svg>
        </div>
        <icon v-else icon="angle-down-regular" size="big" />
    </div>
    <div v-show="opened" ref="items" class="ecomail-select-items bg-white" :style="{maxHeight: `${optionsDropdownHeight * optionHeight}px`}">
        <vue-custom-scrollbar ref="scroll" tagname="div" class="scroll" :settings="settings">
            <slot :trigger-select="handleSelect" :search="search" />
        </vue-custom-scrollbar>
    </div>
    <div v-if="hasError && !opened">
        <p
                v-for="(error, i) in errors"
                :key="i"
                class="text-auth-sm text-left text-auth-red-600">
            {{ error }}
        </p>
    </div>
</div>

