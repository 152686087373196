

<transition name="long-fadeout">
    <div v-if="visible" class="register-modal-bg">

        <div class="register-modal-container">

            <div class="register-modal-panel">
                <img src="https://ecomail-assets.s3.amazonaws.com/old/images/logo-tiny.png" class="register-modal-logo" alt="Ecomail logo">
                <div class="mt-10 space-y-3">
                    <div v-for="(step, index) in steps" :key="`side-panel-description-${index}`" class="flex items-center">
                        <template v-if="stepIsCompleted(step)">
                            <svg-vue icon="check-circle-regular" class="w-6 text-auth-green-500" style="min-width: 1.5rem" />
                            <span class="text-auth-xs ml-4">{{ $t(`appforms.extended_registration.side_panel.${step}`) }}</span>
                        </template>
                        <template v-else-if="stepIsInProgress(step)">
                            <svg-vue icon="arrow-circle-right-regular" class="w-6 text-white" style="min-width: 1.5rem" />
                            <span class="text-auth-xs ml-4">{{ $t(`appforms.extended_registration.side_panel.${step}`) }}</span>
                        </template>
                        <template v-else>
                            <span class="text-auth-xs ml-10 text-auth-gray-400">{{ $t(`appforms.extended_registration.side_panel.${step}`) }}</span>
                        </template>
                    </div>
                </div>

                <div class="mt-auto">
                    <img src="https://ecomail-assets.s3.amazonaws.com/old/images/kaucky-photo.png" class="rounded-full w-20" alt="Testimonial photo">
                    <p class="text-auth-sm mt-7.5" v-html="$t('appforms.extended_registration.quote')" />
                    <span class="text-auth-gray-400 mt-3.5 block">{{ $t('appforms.extended_registration.quote_author') }}</span>
                </div>
            </div>

            <div class="register-modal-content">
                
                <h2 class="text-auth-2xl text-auth-black font-semibold" v-html="$t(`appforms.extended_registration.content.${activeStep}.title`)" />
                <p class="mt-3 text-auth-gray-700">
                    {{ $t(`appforms.extended_registration.content.${activeStep}.description`) }}
                </p>

                <template v-if="activeStep === 'account_info'">
                    <FormInput
                            key="website"
                            v-model="website"
                            name="website"
                            type="url"
                            :errors="websiteErrors"
                            :label="$t('appforms.extended_registration.content.account_info.website_label')"
                            :placeholder="$t('appforms.extended_registration.content.account_info.website_placeholder')"
                            class="w-full mt-9" />

                    <FormInput
                            v-if="!needsProvisioning"
                            key="phone"
                            v-model="phone"
                            name="phone"
                            type="text"
                            :errors="phoneErrors"
                            :label="$t('appforms.extended_registration.content.account_info.phone_label')"
                            :placeholder="$t('appforms.extended_registration.content.account_info.phone_placeholder')"
                            class="w-full mt-3.5" />

                    <FormSelect
                            key="userbase-select"
                            v-model="userbase"
                            name="subscribersPlan"
                            :errors="subscribersErrors"
                            :label="$t('appforms.extended_registration.content.account_info.subscribers_label')"
                            class="mt-3.5">
                        <template v-if="userbase" #selectedLabel>
                            {{ subscribersPlans.find(plan => plan.value === userbase).label }}
                        </template>
                        <template #default="{ triggerSelect, search }">
                            <FormSelectOption v-for="(plan, index) in subscribersPlans" :key="`plan-${index}`" :label="plan.label" :value="plan.value" :trigger-select="triggerSelect" :search="search" />
                        </template>
                    </FormSelect>
                    <div class="mt-7.5">
                        <div class="text-auth-gray-700 text-auth-sm mb-1 font-semibold">
                            {{ $t('appforms.extended_registration.content.account_info.purpose_label') }}
                        </div>

                        <div class="flex flex-col md:flex-row md:justify-between space-y-4 md:space-y-0">
                            <div v-for="(value, key, index) in eshopPlatformOptions" :key="`purpose-${index}`" class="box-border py-2.5 px-4.5 bg-white border rounded-md border-auth-gray-200 hover:border-auth-green-500 cursor-pointer font-semibold text-auth-sm text-auth-gray-1000" :class="{'purpose-shadow border-2 border-auth-green-500 -m-px': value}" @click="eshopPlatformOptions[key] = !eshopPlatformOptions[key]">
                                {{ $t(`appforms.extended_registration.content.account_info.eshop_platform_options.${key}`) }}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else-if="activeStep === 'eshop_platform'">
                    <FormSelect
                            key="eshopPlatform"
                            v-model="eshopPlatform"
                            name="eshopPlatform"
                            :errors="eshopErrors" 
                            :title="$t('appforms.extended_registration.content.eshop_platform.select_placeholder')"
                            class="mt-10 mb-7">
                        <template v-if="eshopPlatform" #icon>
                            <img :src="`/images/integrations/${eshopOptions.find(eshop => eshop.value === eshopPlatform).image}.png`" class="h-8 mr-5" :alt="eshopPlatform">
                        </template>
                        <template v-if="eshopPlatform" #selectedLabel>
                            {{ eshopOptions.find(eshop => eshop.value === eshopPlatform).label }}
                        </template>
                        <template #default="{ triggerSelect }">
                            <FormSelectOption v-for="(platform, index) in eshopOptions" :key="`platform-${index}`" :label="platform.label" :value="platform.value" :trigger-select="triggerSelect">
                                <template #icon>
                                    <img :src="`/images/integrations/${platform.image}.png`" class="h-8 mr-5" :alt="platform.label">
                                </template>
                            </FormSelectOption>
                        </template>
                    </FormSelect>

                    <div class="panel p-3 panel-white" :class="{'border-green': customEshop}">
                        <div class="tw-checkbox-solid">
                            <input id="customEshop" v-model="customEshop" name="customEshop" type="checkbox">
                            <label for="customEshop">
                                <span class="font-semibold text-auth-gray-700" v-html="$t('appforms.extended_registration.content.eshop_platform.checkbox')" />
                            </label>
                        </div>
                    </div>

                    <FormInput
                            v-if="customEshop"
                            key="customEshopValue"
                            v-model="customEshopValue"
                            name="customEshopValue"
                            :errors="customEshopValueErrors"
                            :label="$t('appforms.extended_registration.content.eshop_platform.custom_eshop_label')"
                            :placeholder="$t('appforms.extended_registration.content.eshop_platform.custom_eshop_placeholder')"
                            class="w-full mt-3.5" />
                </template>

                <template v-else-if="activeStep === 'approval_request'">
                    <FormSelect
                            key="verification-option"
                            v-model="verificationOption"
                            name="verificationOption"
                            :errors="verificationOptionErrors" 
                            :title="$t('appforms.extended_registration.content.approval_request.select_label')"
                            :placeholder="$t('appforms.extended_registration.content.approval_request.select_placeholder')"
                            class="mt-8">
                        <template v-if="verificationOption" #selectedLabel>
                            {{ verificationOptions.find(option => option.value === verificationOption).label }}
                        </template>
                        <template #default="{ triggerSelect }">
                            <FormSelectOption v-for="(option, index) in verificationOptions" :key="`verification-${index}`" :label="option.label" :value="option.value" :trigger-select="triggerSelect" />
                        </template>
                    </FormSelect>

                    <FormInput
                            v-if="verificationOption === 'web'"
                            key="verification-website"
                            v-model="website"
                            name="website"
                            type="url"
                            :errors="verificationInputErrors"
                            :label="$t('appforms.extended_registration.content.approval_request.website_label')"
                            :placeholder="$t('appforms.extended_registration.content.approval_request.website_placeholder')"
                            class="w-full mt-4" />

                    <FormInput
                            v-else-if="verificationOption === 'non_web'"
                            key="verification-social"
                            v-model="socialMedia"
                            name="website"
                            type="url"
                            :errors="verificationInputErrors"
                            :label="$t('appforms.extended_registration.content.approval_request.social_label')"
                            :placeholder="$t('appforms.extended_registration.content.approval_request.social_placeholder')"
                            class="w-full mt-4" />

                    <div class="flex flex-col h-full mt-5">
                        <label for="verificationInfo" class="flex flex-col">
                            <span class="text-auth-black font-semibold">{{ $t('appforms.extended_registration.content.approval_request.about_title') }}</span>
                            <span class="text-auth-gray-600">{{ $t('appforms.extended_registration.content.approval_request.about_subtitle') }}</span>
                        </label>
                        <textarea
                                id="verificationInfo"
                                v-model="verificationInformation"
                                name="verificationInfo"
                                class="block mt-1 px-[14px] py-[8px] w-full focus:ring-4 h-[44px] focus:outline-none transition duration-150 ease-in-out border rounded-[4px] border-auth-gray-300 text-auth-sm leading-5"
                                style="min-height: 154px"
                                :class="{'text-auth-red-800 bg-red-50 hover:bg-white focus:bg-white  focus-visible:border-auth-red-800 border-auth-red-300 placeholder-auth-red-500 ring-red-500/25 focus:border-red-800': verificationInformationErrors.length > 0,
                                         'ring-auth-green-500/25 hover:bg-auth-white focus:border-auth-green-600 focus:bg-auth-white bg-auth-gray-50 placeholder-auth-gray-500': verificationInformationErrors.length === 0,}" />
                        <div v-if="verificationInformationErrors[0]" class="text-auth-sm text-left text-auth-red-600">
                            {{ verificationInformationErrors[0] }}
                        </div>
                    </div>
                </template>

                <div class="flex mt-auto justify-between flex-col md:flex-row-reverse">
                    <FormButton class="mt-7.5" type="button" :loading="loading" base primary square @click="nextStep">
                        <span class="text-auth-base">{{ $t('common.continue') }}</span>
                    </FormButton>
                    <FormButton v-if="!needsProvisioning" class="hidden md:flex mt-7.5" type="button" :loading="loading" base secondary square @click="skipStep">
                        <span class="text-auth-base">{{ $t('common.skip') }}</span>
                    </FormButton>
                    <FormButton v-if="activeStep !== 'account_info'" class="mt-7.5" type="button" :loading="loading" base secondary square @click="previousStep">
                        <span class="text-auth-base">{{ $t('common.back') }}</span>
                    </FormButton>
                </div>

                <a v-if="!needsProvisioning" href="#" class="md:hidden mt-7.5 txt-16 text-center font-semibold text-auth-green-500" @click="skipStep">{{ $t('common.skip') }}</a>
            </div>
        </div>

    </div>
</transition>

