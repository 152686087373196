

<div>
    <portal v-if="activated" to="new-form-section-header">
        <div class="flex flex-grow items-center justify-between">
            <div class="flex items-center space-x-5">
                <a href="#" class="txt-22 text-gray-800 hover:no-underline hover:text-gray-1000" :class="{ 'pointer-events-none text-gray-1000' : step === 1 }" @click.prevent="step = 1">
                    {{ $t('contacts.forms.create_form_button') }}
                </a>
                <icon v-if="step === 2" class="text-gray-700" icon="long-arrow-alt-right-solid" />
                <span v-if="step === 2" class="txt-22 text-gray-1000">{{ $t(`forms.new.form_type.${type}`) }}</span>
            </div>
            <button class="btn btn-tertiary icon-left" @click="close"><icon key="form-close-icon" icon="times-solid" size="small" /> <span>{{ $t('common.close') }}</span></button>
        </div>
    </portal>
    <ValidationObserver ref="observer" slim>
        <div v-if="step === 1" class="container py-5 mx-auto px-10 md:px-0" style="max-width: 630px;">

            <h2 class="mt-5">
                {{ $t('forms.new.start') }}
            </h2>

            <p class="txt-14 dimmed90 mb-8">
                {{ $t('forms.new.step1.help') }}
            </p>

            <ValidationProvider v-slot="{ errors }" name="name" rules="required" slim>
                <div class="input flex flex-col">
                    <label for="formTitle" class="tw-label mb-2">{{ $t('forms.new.step1.title') }}</label>
                    <input id="formTitle" v-model="name" type="text" name="form_title" class="tw-input max-w-xs" :placeholder="$t('forms.new.step1.title_ph')">
                    <span class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div id="newFormTypes" class="flex flex-col md:flex-row mt-5 space-x-2.5">
                <button type="button" class="type-basic flex justify-between items-center panel flex-col w-full md:w-1/3 has-green-hover mb-3 md:mb-0" :class="{selected: type === 'basic'}" @click="setType('basic')">
                    <img class="mt-4 mx-4 mb-3 object-scale-down" src="https://ecomail-assets.s3.amazonaws.com/old/images/forms/icon-page.png" alt="">
                    <div class="text-center w-full mb-5 text-gray-900 txt-13">{{ $t('forms.new.step1.basic') }}</div>
                </button>
                <button type="button" class="type-popup flex justify-between items-center panel flex-col w-full md:w-1/3 has-green-hover mb-3 md:mb-0" :class="{selected: type === 'popup'}" @click="setType('popup')">
                    <img class="mt-4 mx-4 mb-3 object-scale-down" src="https://ecomail-assets.s3.amazonaws.com/old/images/forms/icon-pop-up.png" alt="">
                    <div class="text-center w-full mb-5 text-gray-900 txt-13">{{ $t('forms.new.step1.popup') }}</div>
                </button>
                <button type="button" class="type-slider flex justify-between items-center panel flex-col w-full md:w-1/3 has-green-hover mb-3 md:mb-0" :class="{selected: type === 'slide'}" @click="setType('slide')">
                    <img class="mt-4 mx-4 mb-3 object-scale-down" src="https://ecomail-assets.s3.amazonaws.com/old/images/forms/icon-slide.png" alt="">
                    <div class="text-center w-full mb-5 text-gray-900 txt-13">{{ $t('forms.new.step1.slider') }}</div>
                </button>
                <button type="button" class="type-slider flex justify-between items-center panel flex-col w-full md:w-1/3 has-green-hover mb-3 md:mb-0" :class="{selected: type === 'mobile_popup'}" @click="setType('mobile_popup')">
                    <img class="mt-4 mx-4 mb-3 object-scale-down" src="https://ecomail-assets.s3.amazonaws.com/old/images/forms/icon-mobile.png" alt="">
                    <div class="text-center w-full mb-5 text-gray-900 txt-13">{{ $t('forms.new.step1.mobile_popup') }}</div>
                </button>
            </div>

            <div class="mt-6 text-right text-gray-900 txt-13">
                <span>{{ $t('forms.new.import.or_you_can') }} </span>
                <a class="semibold cursor-pointer" @click="showImportForm">{{ $t('forms.new.import.import_as') }}</a>
            </div>

            <div class="flex mt-6 mb-20 justify-between">
                <button class="btn btn-tertiary btn-lg" @click="close">{{ $t('common.close') }}</button>
                <button :disabled="!name" class="btn btn-primary btn-lg" @click="nextStep"><span>{{ $t('forms.new.continue') }}</span></button>
            </div>

        </div>
        <div v-else class="container py-5 px-10 mx-auto items-start" style="max-width: 1200px;">

            <h2 class="mt-5">
                {{ $t('forms.new.choose-template') }}
            </h2>

            <p class="txt-14 dimmed90 mb-10">
                {{ $t('forms.new.step2.help') }}
            </p>

            <div class="flex -ml-2">
                <div class="w-full md:w-1/4 px-2">
                    <a class="panel has-green-hover flex p-3 items-center link-blue cursor-pointer" @click.stop.prevent="selectTemplate(layouts[locale]['custom'])">
                        <img src="https://ecomail-assets.s3.amazonaws.com/old/images/forms/custom-design.png" alt="New custom form" style="width: 64px; height: 64px;" class="mr-5">
                        <span class="txt-14 semibold ">{{ $t('forms.new.step2.custom') }}</span>
                    </a>
                </div>
            </div>

            <div class="grid md:grid-cols-4 gap-5 mt-4 mb-20">
                <div v-for="(templateId, index) in layouts[locale][type]" :key="`template-${index}`">
                    <div class="w-full bg-contain bg-no-repeat bg-bottom mx-auto border border-gray-500 rounded hover:border-green-400 relative cursor-pointer box-border overflow-hidden" :style="{backgroundImage: 'url(https://ecomail-assets.s3.amazonaws.com/forms/layout' + templateId + '.jpg)'}" style="height: 256px; background-color: #B5B5B5; " @click.self="selectTemplate(templateId)" @mouseover="hoverIndex = index" @mouseleave="hoverIndex=null">
                        <transition name="slideout">
                            <div v-show="hoverIndex === index" class="absolute w-full bg-white flex-col bottom-0 cursor-default px-4 py-4 space-y-2">
                                <button class="btn btn-fluid btn-primary" @click="selectTemplate(templateId)">{{ $t('forms.new.step2.use_template') }}</button>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>

        </div>
    </ValidationObserver>
</div>

