<template>

    <div class="flex flex-col mt-1">
        <div class="space-y-2.5 mb-6">
            <p class="mb-1 text-gray-900 txt-14" v-html="$t('contacts.index.create_list_warning.description_1')" />
            <ul class="list-inside list-disc space-y-1 txt-14">
                <li v-for="(index) in 4" :key="`list-1-${index}`" class="txt-14 text-gray-1000">
                    <span class="relative left-3" v-html="$t(`contacts.index.create_list_warning.list_1_item_${index}`)" />
                </li>
            </ul>
        </div>
        
        <div class="space-y-2.5 mb-6">
            <p class="mb-1 text-gray-900 txt-14" v-html="$t('contacts.index.create_list_warning.description_2')" />
            <ul class="list-inside list-disc space-y-1 txt-14">
                <li v-for="(index) in 3" :key="`list-2-${index}`" class="txt-14 text-gray-1000">
                    <span class="relative left-3" v-html="$t(`contacts.index.create_list_warning.list_2_item_${index}`)" />
                </li>
            </ul>
        </div>

        <p class="text-gray-900 txt-14 mb-6" v-html="$t('contacts.index.create_list_warning.description_3')" />

        <div class="panel p-4">
            <div class="tw-checkbox">
                <input id="confirmCheckbox" v-model="checked" type="checkbox" :value="true">
                <label for="confirmCheckbox" class="text-black" v-html="$t('contacts.index.create_list_warning.do_not_show')" />
            </div>
        </div>

        <div class="tw-modal-buttons">
            <button class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('common.cancel') }}</button>
            <button class="btn btn-primary float-right btn-lg" @click="nextStep">{{ $t('common.continue') }}</button>
        </div>
    </div>
    
</template>

<script>
import Tracker from "@js/tracker";

export default {
    name: 'CreateListWarning',

    props: ['modal'],

    data() {
        return {
            checked: false
        }
    },

    methods: {
        closeModal() {
            this.modal.mclose()
        },
        nextStep() {
            if(this.checked)
                localStorage.setItem('createListWarningDisabled', true)
            this.closeModal()
            new Tracker().trackEvent('BUTTON_CLICKED', {
                scope: 'contacts',
                name: 'Continue in contact list creation after warning'
            })
            this.$emit('continue')
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "~sass/variables";

    ::marker {
        color: $grey-65;
    }
</style>
