<template>
    <div @keyup.enter="resetPassword">
        <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]">
            {{ $t('auth.reset_password.reset_password') }}
        </div>

        <div class="text-auth-base text-auth-gray-500 text-center">
            {{ $t('auth.login.account_address') }} <span class="semibold text-auth-gray-900">{{ accountUrl }}</span>
        </div>

        <hr class="mt-5 mb-6">

        <alert v-if="requestError" class="mb-5" type="error" :message="requestError" />

        <FormInput
                id="email"
                :errors="emailErrors"
                name="email"
                :value="email"
                class="w-full"
                type="email"
                :autofocus="!autofocusPassword"
                :label="$t('auth.login.email.label')"
                required
                :placeholder="$t('auth.login.email.placeholder')"
                @input="emailChanged" />

        <FormInput
                ref="password"
                v-model="password"
                :errors="passwordErrors"
                :autofocus="autofocusPassword"
                class="w-full mt-5"
                type="password"
                :label="$t('auth.login.password.label')"
                required
                :placeholder="$t('auth.join.choose_password')" />
        <FormInput v-model="passwordAgain" :errors="passwordAgainErrors" class="w-full mt-5" type="password" :label="$t('auth.login.password_again.label')" required :placeholder="$t('auth.join.choose_password')" />

        <FormButton type="button" :loading="loading" xlarge primary square class="mt-8 w-full" @click="resetPassword">
            <span class="text-auth-base">{{ $t('auth.reset_password.reset_password_cta') }}</span>
        </FormButton>

        <div class="text-auth-gray-500 text-auth-sm text-center semibold mt-[42px]">
            <router-link :to="{name: 'login'}" class="cursor-pointer hover:no-underline text-auth-green-500">
                {{ $t('auth.login.sign_in_cta') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import FormInput from '@component/Auth/FormInput.vue'
import FormButton from '@component/Auth/FormButton.vue'
import hasher from '@mixins/hasher'
import { mapState, mapMutations } from 'vuex'
import { validate } from 'vee-validate'

export default {
    name: 'setNewPassword',

    mixins: [hasher],

    components: {
        FormInput,
        FormButton,
    },

    data() {
        return {
            autofocusPassword: false,
            password: null,
            passwordAgain: null,
            rememberPassword: false,
            loading: false,
            emailErrors: [],
            passwordErrors: [],
            passwordAgainErrors: [],
            requestError: null,
        }
    },

    computed: {
        ...mapState(['email']),
        accountUrl() {
            return window.location.hostname
        }
    },
    
    mounted() {
        let url = new URL(window.location.href)
        const emailParam = url.searchParams.get('email')
        if(emailParam) {
            this.autofocusPassword = true
            this.SET_EMAIL({email: emailParam})
        }
    },

    methods: {
        emailChanged(email) {
            this.emailErrors = []
            this.SET_EMAIL({email: email})
        },
        ...mapMutations(['SET_EMAIL']),
        async resetPassword() {
            try {
                const valid = await this.validateForm()
                if(!valid)
                    return

                const token = this.$route.params.token

                this.loading = true
                const response = await this.$http.post('/password/reset', {
                    token: token,
                    email: this.email,
                    password: this.hashLoginPassword(this.password),
                    password_confirmation: this.hashLoginPassword(this.passwordAgain),
                })
                if(response.status === 200 || response.status === 204)
                    window.location.href = '/'
                this.loading = false
            } catch(e) {
                this.requestError = e.message
                this.loading = false
            }
        },
        async validateForm() {
            const emailValidation = await validate(this.email, 'required|email')
            this.emailErrors = emailValidation.errors

            const passwordValidation = await validate(this.password, 'required')
            this.passwordErrors = passwordValidation.errors

            const passwordAgainValidation = await validate(this.passwordAgain, 'required')
            this.passwordAgainErrors = passwordAgainValidation.errors

            return emailValidation.valid && passwordAgainValidation.valid && passwordValidation.valid
        }
    }
}
</script>

<style scoped lang="scss">

</style>