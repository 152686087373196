<template>

    <div ref="facebookPanel" class="panel">
        <div v-if="message && success !== null" class="facebook-alert">
            <alert :type="success === true ? 'success' : 'error'" :message="message" />
        </div>
        
        <div v-if="visiblePages" class="flex flex-col panel panel-white pages-panel m-5">
            <template v-if="visiblePages.length">
                <span class="txt-14 font-semibold mb-4">{{ $t('contacts.facebook_lead.select_page_label') }}</span>
                <div class="w-full overflow-x-auto md:overflow-x-visible">
                    <div class="table tw-table tw-table-large max-w-full">
                        <div v-for="(page, index) in visiblePages" :key="`facebook-page-${index}`" class="table-row ">
                            <div class="table-cell align-middle truncate w-full" style="max-width: 0px;">
                                <div class="flex items-center space-x-4 group">
                                    <a href="#" class="txt-14 link-blue semibold" @click.prevent="connectPage(page)">{{ page.name }} ({{ page.id }})</a>
                                </div>
                            </div>
                            <div class="table-cell align-middle text-right">
                                <button class="btn btn-tertiary icon-left" @click="connectPage(page)"><div v-if="connecting === page.id" class="double-loader loader-sm loader-grey mr-2" /><icon v-else class="text-gray-900" icon="plug-solid" />{{ $t('contacts.facebook_lead.select_page_button') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div v-else>
                <div class="txt-14 text-gray-900">{{ $t('contacts.facebook_lead.no_pages') }}</div>
            </div>
            <button class="btn btn-lg btn-primary w-min mt-7.5" @click="closePages">{{ $t('common.cancel') }}</button>
        </div>

        <div v-else class="m-9">
            <a href="#" class="btn btn-lg btn-fb with-img" @click.stop.prevent="connectFb"><icon icon="facebook-square-brands" size="large" class="mr-3" /> {{ $t('facebook.connect') }}</a>
            <p v-if="$te('contacts.facebook_lead.facebook_help')" class="txt-14 text-gray-900 mt-5" v-html="$t('contacts.facebook_lead.facebook_help')" />
        </div>
    </div>

</template>

<script>

import {
    loadFbSdk,
    fbLogin,
    appId,
    version,
    leadsLoginOptions,
} from '../../../fb-helpers'
import i18n from '../../../i18n'

export default {

    name: 'FacebookConnect',

    data () {
        return {
            account: null,
            listId: null,
            connectedLeads: null,

            message: null,
            success: null,
            connecting: null,
            pages: null
        }
    },

    computed: {
        visiblePages() {
            if(this.pages !== null && this.connectedLeads !== null)
                return this.pages.filter(newPage => !this.connectedLeads.find(existingPage => newPage.id === existingPage.page_id))
            return this.pages
        }
    },

    watch: {
        connectedLeadsProp(val) {
            if(val) {
                this.connectedLeads = this.connectedLeadsProp
            }
        }
    },

    created() {
        window.addEventListener('message', this.receiveParameters)
    },

    mounted() {
        window.parent.postMessage({iframeHeight: this.$refs.facebookPanel.offsetHeight}, '*')
        loadFbSdk(appId, version)
    },

    beforeDestroy () {
        window.removeEventListener('message', this.receiveParameters)
    },

    updated() {
        window.parent.postMessage({iframeHeight: this.$refs.facebookPanel.offsetHeight}, '*')
    },

    methods: {
        receiveParameters(event) {
            if(event.data.listId) {
                this.account = event.origin.split('.')[0].replace('https://', '')
                this.listId = event.data.listId
                this.connectedLeads = event.data.connectedLeads ?? null
                i18n.locale = event.data.language
            }
        },
        closePages() {
            this.pages = null
        },
        connectFb () {
            fbLogin(leadsLoginOptions)
                .then(() => {
                    window.FB.api('/me/accounts?limit=500', (response) => {
                        console.log(response)
                        this.pages = Object.values(response.data)
                    })
                })
        },
        async connectPage(page) {
            this.success = null
            this.message = null
            this.connecting = page.id

            try {
                // eslint-disable-next-line no-undef
                await FB.api(`/${page.id}/subscribed_apps`, 'post', {
                    access_token: page.access_token,
                    subscribed_fields: ['email', 'name', 'general_info', 'leadgen', 'birthday', 'location', 'phone']
                })

                await this.insertConnectedPage(page)

                // eslint-disable-next-line no-undef
                await FB.api(`/${page.id}/subscribed_apps`, 'get', {access_token: page.access_token}, response => {
                    console.log(response)
                })
            }
            catch(error) {
                this.success = false
                this.message = error.message
                this.connecting = null
            }
        },
        async insertConnectedPage(page) {
            try {

                await this.$http.post(`/public/${this.listId}/facebook-lead-save`, {
                    account: this.account,
                    page_id: page.id,
                    page_name: page.name,
                    token: page.access_token,
                })

                this.success = true
                this.message = this.$t('contacts.facebook_lead.page_connected_alert', {page: page.name})

                // refresh leads
                window.parent.postMessage({refreshLeads: true}, '*')
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }
            this.connecting = null
        }
    },

}
</script>

<style lang="scss" scoped>
    .pages-panel {
        padding: 18px 19px 36px 22px;
    }

    .facebook-alert {
        margin: 18px 19px 16px 22px;
    }
</style>
