export default {
    methods: {
        /**
         * Check whether signed account is a testing account, based on current url
         *
         * @param additionalAccounts
         * @returns boolean
         */
        isTestingAccount(additionalAccounts = []) {
            const account = window.location.host.split('.')[0]

            const testingAccounts = [
                'beta',
                'daniester',
                'panitesterova',
                'danielatesting',
                'monikap',
                'vaclavvesely',
                'hynekdevel',
                'rafatkurdi',
                'petrkral',
                'marekdev',
                'pavelmanda',
                'filounek',
                'petrakramlova',
                ...additionalAccounts,
            ]

            return testingAccounts.includes(account) || window.location.search.includes('forceTestingAccount')
        }
    }
}