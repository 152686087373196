<template>

    <div class="chat-header px-4">
        <div class="flex items-center">
            <div class="avatar border-white border" :style="{ backgroundColor: stringToColor(selectedChat.contact) }">
                {{ selectedChat.contact[0] }}
            </div>
            <div class="flex flex-col ml-3">
                <button @click="showSubscriberModal(selectedChat.contact)">
                    <h3 class="truncate text-gray-1000 font-semibold">
                        {{ selectedChat.contact }}
                    </h3>
                </button>
                <span v-if="selectedChat.contact_email" class="truncate text-gray-900">
                    {{ selectedChat.contact_email }}
                </span>
            </div>
        </div>
        <div class="flex items-center space-x-4">
            <div v-if="assigningConversation" class="w-4 h-4 mt-4 double-loader" />
            <ecm-select v-else :options="localAgents" :value="selectedChat.agent" option-value-attribute="agent" option-display-attribute="name" @input="assignConversation" />

            <button v-if="status === 'open'" class="status-switch" @click="closeConversation(selectedChatId)"><icon icon="check-solid" /></button>
            <button v-else class="status-switch" @click="reopenConversation(selectedChatId)"><icon icon="inbox-solid" /></button>
        </div>
    </div>

</template>

<script>

import store from '@stores/index'
import {mapState} from 'vuex'

export default {

    name: 'ChatHeader',

    store: store,

    props: ['agent', 'status'],

    data () {
        return {
            assigningConversation: false,
            localAsignees: [
                {
                    name: this.$t('chat.conversations.unasigned_agent'),
                    agent: null
                }
            ]
        }
    },

    computed: {
        ...mapState('chatModule', [
            'selectedChatId',
            'agents',
        ]),
        selectedChat() {
            return store.getters['chatModule/getSelectedChat']
        },
        localAgents () {
            let localAgents = []
            if(this.agents) {
                Object.values(this.agents).forEach(item => {
                    localAgents.push({name: item.agent, agent: item.agent})
                })
            }
            localAgents.push({name: this.$t('appforms.chat.filter.unassigned'), agent: 'none'})

            return localAgents
        }
    },

    methods: {
        showSubscriberModal(email) {
            this.$emit('showSubscriberModal', email)
        },
        closeConversation(chat_id) {
            store.dispatch('chatModule/closeConversation', {chat: chat_id, agent: this.agent})
        },
        reopenConversation(chat_id) {
            store.dispatch('chatModule/reopenConversation', {chat: chat_id, agent: this.agent})
        },
        async assignConversation(agent) {
            if(agent === null) {
                agent = 'none'
            }
            this.assigningConversation = true
            await store.dispatch('chatModule/assignConversation', {agent: this.agent, target: agent, chat: this.selectedChatId, status: this.status})
            this.assigningConversation = false
        },
        stringToColor(str) {
            var hash = 0
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash)
            }
            var colour = '#'
            for (let i = 0; i < 3; i++) {
                var value = (hash >> (i * 8)) & 0xFF
                colour += ('00' + value.toString(16)).substr(-2)
            }
            return colour
        },
    }
}
</script>