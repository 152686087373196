<template>
    <a
            v-if="!search || value.includes(search)"
            v-tooltip.top="{content: label, 'trigger': isTruncated(label) ? 'hover' : 'manual', autoHide: false}"
            class="ecomail-select-item text-auth-gray-700 hover:text-auth-gray-700 hover:bg-auth-gray-50"
            @click.prevent="triggerSelect(value)">
        <span class="flex items-center gap-2" :class="{'ecomail-select-item-group-title': hasChildren}">
            <slot name="icon" />
            {{ formatDisplayAttribute(label) }}
        </span>
        <slot name="meta" class="ml-2 ecomail-select-item-meta" />
        <slot />
    </a>
</template>

<script>
export default {
    name: 'FormSelectOption',

    props: {
        triggerSelect: {
            type: Function,
            required: true
        },
        label: {
            required: true
        },
        value: {
            required: true
        },
        search: {
            type: String,
            default: null
        },
        hasChildren: {
            type: Boolean,
            default: false
        },
        maxOptionLength: {
            type: Number,
            default: 0
        },
    },

    methods: {

        formatDisplayAttribute(optionDisplayAttribute) {
            return this.isTruncated(optionDisplayAttribute) ? optionDisplayAttribute.substr(0, this.maxOptionLength-1) + '...' : optionDisplayAttribute
        },

        isTruncated(option) {
            return option && option.length > this.maxOptionLength && this.maxOptionLength !== 0
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~sass/variables';
    .ecomail-select-item-group {
        border-bottom: 1px solid $grey-40;

        .ecomail-select-item-group-title {
            padding: 8px 14px;
            line-height: 28px;
            font-size: 16px;
            white-space: nowrap;
            text-align: left;
        }

        a.ecomail-select-item {
            padding: 8px 16px 8px 24px;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    a.ecomail-select-item {
        display: flex;
        padding: 8px 14px;
        line-height: 32px;
        font-size: 16px;
        white-space: nowrap;
        text-align: left;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        &:not(:last-of-type) {
            border-bottom: 1px solid #D7D7D7;
        }

        .ecomail-select-item-meta {
            font-size: 14px;
            color: $grey-80
        }

        &:focus {
            outline: none;
        }

        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
    }
</style>