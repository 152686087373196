<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.delete_subscribers.title') }}</span>
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="flex flex-col lg:flex-row lg:space-x-5 space-y-2.5 lg:space-y-0">
            <div class="flex flex-col w-full lg:w-2/6 space-y-1 lg:mt-2">
                <h4 class="text-gray-1000 font-normal">
                    {{ $t('contacts.delete_subscribers.heading') }}
                </h4>
                <p class="text-gray-900 txt-14">
                    {{ $t('contacts.delete_subscribers.description') }}
                </p>
            </div>
            <div class="panel p-9 flex flex-col w-full lg:w-4/6">
                <alert class="mb-6" type="warning" :label="$t('contacts.delete_subscribers.warning_heading')" :message="$t('contacts.delete_subscribers.warning_text')" />
                <alert v-if="message && success !== null" class="mb-6" :type="success === true ? 'success' : 'error'" :label="messageTitle" :message="message" />

                <ValidationProvider v-slot="{ errors }" name="emails" rules="required|max:150000" class="flex flex-col">
                    <label for="deleteEmails" class="tw-label mb-2.5">{{ $t('contacts.delete_subscribers.emails_label') }}</label>
                    <textarea id="deleteEmails" v-model="emails" name="emails" class="tw-input" style="min-height: 260px; resize: none;" :placeholder="$t('contacts.delete_subscribers.emails_placeholder')" />
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="mt-7">
                    <button :disabled="sending || !emails.length" class="btn btn-primary btn-lg" @click="deleteSubscribers"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('contacts.delete_subscribers.button') }}</button>
                </div>
            </div>
        </div>

        <tw-confirm ref="deleteSubscribersConfirm" @confirmDelete="deleteSubscribersConfirmed" />
    </div>
</template>

<script>
import store from '@stores/index'

export default {
    name: 'DeleteSubscribers',
    store,
    props: ['list'],
    data() {
        return {
            activated: true,
            sending: false,
            messageTitle: null,
            message: null,
            success: null,
            emails: ''
        }
    },
    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },
    async beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$root.showSpaLoader = false
        })
    },
    methods: {
        async deleteSubscribers() {
            this.success = null
            this.messageTitle = null
            this.message = null
            this.sending = true

            try {
                const response = await this.$http.post(`/spa/contacts/${this.list.id}/find-emails`, {
                    emails: this.emails
                })
                if(Object.keys(response.data.emails).length) {
                    this.$refs['deleteSubscribersConfirm'].setData({
                        twConfirmTitle: this.$t('contacts.delete_subscribers.modal.title'),
                        twConfirmText: this.$tc('contacts.delete_subscribers.modal.text', Object.keys(response.data.emails).length),
                        data: response.data.emails,
                        twCheckbox: this.$t('contacts.delete_subscribers.modal.confirm'),
                        twClose: this.$t('common.cancel'),
                        twDelete: this.$t('contacts.delete_subscribers.modal.button'),
                    })
                    this.$refs['deleteSubscribersConfirm'].showModal()
                }
                else {
                    this.success = false
                    this.message = this.$t('contacts.delete_subscribers.no_emails_alert')
                }
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }

            this.sending = false
        },
        async deleteSubscribersConfirmed(emails) {
            this.success = null
            this.messageTitle = null
            this.message = null
            this.sending = true
            
            try {
                const response = await this.$http.post(`/spa/contacts/${this.list.id}/delete-subscribers-send`, {
                    emails: emails
                })
                if(response.data.num > 0) {
                    this.success = true
                    this.messageTitle = this.$tc('contacts.delete_subscribers.contacts_deleted_alert_title', response.data.num)
                    this.message = this.$tc('contacts.delete_subscribers.contacts_deleted_alert_text', response.data.num)
                }
                else {
                    this.success = false
                    this.message = this.$t('contacts.delete_subscribers.no_contacts_alert')
                }
                this.emails = ''
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }

            this.sending = false
        },
    }
}
</script>
