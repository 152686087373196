<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon key="breadcrumb-icon-1" class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <router-link :to="`/contacts/${list.id}/import`" class="hidden lg:block txt-18 text-gray-800 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.import_subscribers.title') }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon key="breadcrumb-icon-2" class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.import_subscribers.subtitle') }}</span>
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="pt-2">
            <div v-if="importError">
                <alert type="error" :message="importError" />

                <div class="tw-modal-buttons mt-7 mb-4">
                    <button class="btn btn-tertiary tw-modal-close-button btn-lg" @click="goBack">{{ $t('common.cancel') }}</button>
                </div>
            </div>
            <contacts-mapper
                    v-else
                    :list="list"
                    :mapped-fields="importData.baseFieldIndexes"
                    :file="importData.file_url"
                    :rowdata="importData.rows"
                    :customfields="list.custom_fields"
                    :base-tags="importData.baseFieldsTags" />
        </div>
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'

import contactsMapper from '@component/AppForms/Lists/ContactsMapper.vue'

export default {
    name: 'MapSubscriberFields',

    store,

    props: ['list'],

    components: {
        contactsMapper
    },

    data () {
        return {
            activated: true
        }
    },

    computed: {
        ...mapState('contactsModule', [
            'importData', 'importError'
        ]),
        
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    async beforeRouteEnter(to, from, next) {
        await store.dispatch('contactsModule/loadImportData', {listId: to.params.list, file: to.params.file})
        next(vm => {
            vm.$root.showSpaLoader = false
        })
    },

    methods: {
        goBack() {
            this.$router.push(`/contacts/${this.list.id}/import`)
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    .wrapper {
        min-height: 260px;
        overflow-x: scroll;
    }

    .table-row-group:not(.table-end-group) {
        border-top: 5px $grey-50 solid;
        font-size: 14px;
        color: $grey-100;

        .table-row:first-child {
            .table-cell {
                border-top: 5px $grey-50 solid;
            }
        }

        .table-row {
            height: 52px;
            box-shadow: inset 0px 1px 0px rgba(228, 228, 228, 0.7);

            &:nth-child(odd) {
                background-color: $grey-10;
            }

            .table-cell {
                padding: 16px 15px 17px;
            }
        }
    }

    .table-end {
        height: 10px;
        background: url("/images/import-table-bottom.svg") repeat-x bottom left;
    }
</style>
