

<form novalidate method="post" @submit.prevent="saveSettings">
    <ValidationObserver ref="observer" slim>

        <alert v-if="success === true" class="mt-4 mb-10" type="success" :message="message" />

        <alert v-if="success === false" class="mt-4 mb-10" type="error" :message="message" />

        <div class="settings flex flex-col card-left no-hover card-white" :class="{card: singleEnabled, 'mt-5': !singleEnabled}">
            <ecm-switch v-model="singleEnabled">
                {{ $t('list_settings.notifications_single_label') }}
            </ecm-switch>
            <p class="pl-switch text-gray-900 txt-14 mt-1">
                {{ $t('list_settings.notifications_single_help') }}
            </p>

            <div v-if="singleEnabled" class="w-full mt-3 pl-switch flex flex-col">

                <ValidationProvider v-slot="{ errors }" name="sub_notify" rules="max:255|emails_separated_with_comma" slim>
                    <div class="input w-full flex flex-col">
                        <label for="sub_notify" class="tw-label mb-2.5">{{ $t('list_settings.sub_notifications') }}</label>
                        <input id="sub_notify" v-model="sub" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.sub_notifications_placeholder')">
                        <div class="tw-errors">
                            {{ errors[0] }}
                        </div>
                        <div class="txt-12 text-gray-900 mt-1">
                            {{ $t('list_settings.sub_notifications_desc') }}
                        </div>
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" name="unsub_notify" rules="max:255|emails_separated_with_comma" slim>
                    <div class="input w-full flex flex-col mt-3">
                        <label for="unsub_notify" class="tw-label mb-2.5">{{ $t('list_settings.unsub_notifications') }}</label>
                        <input id="unsub_notify" v-model="unsub" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.unsub_notifications_placeholder')">
                        <div class="tw-errors">
                            {{ errors[0] }}
                        </div>
                        <div class="txt-12 text-gray-900 mt-1">
                            {{ $t('list_settings.sub_notifications_desc') }}
                        </div>
                    </div>
                </ValidationProvider>

            </div>
        </div>

        <div :class="{'mt-4': singleEnabled && digestEnabled, 'mt-11': !singleEnabled || !digestEnabled}" />

        <div class="settings flex flex-col card-left no-hover card-white" :class="{card: digestEnabled}">
            <ecm-switch v-model="digestEnabled">
                {{ $t('list_settings.notifications_digest_label') }}
            </ecm-switch>
            <p class="pl-switch text-gray-900 txt-14 mt-1">
                {{ $t('list_settings.notifications_digest_help') }}
            </p>

            <div v-if="digestEnabled" class="w-full mt-3 pl-switch flex">

                <ValidationProvider v-slot="{ errors }" name="digest_notify" rules="max:255|emails_separated_with_comma" slim>
                    <div class="input w-full flex flex-col">
                        <label for="digest_notify" class="tw-label mb-2.5">{{ $t('list_settings.digest_notifications') }}</label>
                        <input id="digest_notify" v-model="digest" type="text" class="tw-input max-w-xs" :placeholder="$t('list_settings.digest_notifications_placeholder')">
                        <div class="tw-errors">
                            {{ errors[0] }}
                        </div>
                        <div class="txt-12 text-gray-900 mt-1">
                            {{ $t('list_settings.sub_notifications_desc') }}
                        </div>
                    </div>
                </ValidationProvider>

            </div>
        </div>

        <button v-if="hasChanges" type="submit" class="btn btn-lg btn-primary mt-8" :class="{'ml-4': digestEnabled}"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('list_settings.save_changes') }}</button>

    </ValidationObserver>
</form>

