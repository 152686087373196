<template>

    <ValidationObserver ref="observer" slim>

        <form novalidate class="flex flex-col" method="post" @submit.prevent="saveSettings">
            <alert v-if="success === true" class="mb-10" type="success" :message="message" />

            <alert v-if="success === false" class="mb-10" type="error" :message="message" />

            <ValidationProvider v-slot="{ errors }" name="listName" rules="required" slim>
                <div class="input flex flex-col mb-1">
                    <label for="listName" class="tw-label mb-2.5">{{ $t('list_settings.list_name') }}</label>
                    <input id="listName" v-model="listName" name="list_name" type="text" class="tw-input max-w-xs">
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div class="h-px mt-5 -mx-9 bg-gray-500" />

            <ValidationProvider v-slot="{ errors }" name="fromName" rules="required" slim>
                <div class="input flex flex-col mt-4">
                    <label for="fromName" class="tw-label mb-2.5">{{ $t('list_settings.from_name_label') }}</label>
                    <input id="fromName" v-model="fromName" name="from_name" type="text" class="tw-input max-w-xs">
                    <span class="mt-1 text-gray-900 txt-12">{{ $t('list_settings.from_name_help') }}</span>
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div class="input flex flex-col mt-4">
                <label for="fromEmail" class="tw-label">{{ $t('list_settings.from_email_label') }}</label>
                <div class="input flex flex-col mt-2.5">
                    <template v-if="domainsLoading || validatedDomains.length">
                        <ValidationProvider v-slot="{ errors }" name="fromEmail" rules="required|email_name" slim>
                            <div class="flex">
                                <input id="fromEmail" v-model="senderEmail" type="text" :disabled="!validatedDomains.length" class="tw-input !rounded-r-[0px] !border-r-[0px] w-full" :class="{'tw-err': errors.length > 0 }">
                                <ecm-select
                                        v-model="selectedDomain"
                                        selected-prefix="@"
                                        class="w-full domain-select"
                                        :searchable="true"
                                        :options="validatedDomainsObj"
                                        option-key="domainValue"
                                        option-display-attribute="domainLabel"
                                        option-value-attribute="domainValue"
                                        :disabled="domainsLoading"
                                        :title="domainsLoading ? $t('list_settings.domains_loading') : (selectedDomain ? selectedDomain : $t('list_settings.select_domain'))" />
                            </div>
                            <div class="mt-1 text-gray-900 txt-12">
                                {{ $t('list_settings.from_email_help') }}
                            </div>
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                            <span v-else-if="invalidDomainError" class="tw-errors" v-html="$t('list_settings.domain_not_validated', {domain: selectedDomain })" />
                        </ValidationProvider>
                    </template>
                    <div v-else>
                        <input id="fromEmail" type="text" :value="fromEmail" disabled class="w-full tw-input tw-err">
                        <div class="mt-1 text-gray-900 txt-12">
                            {{ $t('list_settings.from_email_help') }}
                        </div>
                        <div v-if="unvalidatedDomains.length" class="tw-errors mt-1" v-html="$t('list_settings.domain_not_valid_link')" />
                        <div v-else class="tw-errors mt-1" v-html="$t('list_settings.domain_not_found_link')" />
                    </div>
                </div>
            </div>

            <ValidationProvider v-slot="{ errors }" name="replyTo" rules="email" slim>
                <div class="input flex flex-col mt-4">
                    <label for="replyTo" class="tw-label mb-2.5">{{ $t('list_settings.reply_to_label') }}</label>
                    <input id="replyTo" v-model="replyTo" name="reply_to" type="text" class="tw-input max-w-xs">
                    <span class="mt-1 text-gray-900 txt-12">{{ $t('list_settings.reply_to_help') }}</span>
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div class="flex flex-col space-y-4 mt-8">
                <ecm-select v-model="listCountry" :searchable="true" :options="countryOptions" option-key="country_list" :label="$t('list_settings.country_label')" :title="$t('list_settings.country_none')" null-select-enabled class="w-[360px]" />
                <ecm-select v-model="listLocale" :options="localeOptions" option-key="locale_list" :label="$t('list_settings.locale_label')" class="w-[360px]" />
                <div class="flex space-x-4">
                    <div>
                        <ecm-select v-model="listCurrency" :options="currencyOptions" option-key="currency_list" :label="$t('list_settings.currency_label')" class="w-[360px]" />
                    </div>
                    <ValidationProvider v-if="listCurrency === 'custom'" v-slot="{ errors }" name="customCurrency" rules="required" slim>
                        <div class="flex flex-col">
                            <label for="customCurrency" class="tw-label mb-2.5">{{ $t('list_settings.currency_custom_label') }}</label>
                            <input id="customCurrency" v-model="customCurrency" name="custom_currency" type="text" class="tw-input max-w-xs">
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                </div>
            </div>

            <div>
                <button v-if="hasChanges" class="btn btn-lg btn-primary mt-8"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('list_settings.save_changes') }}</button>
            </div>

        </form>

    </ValidationObserver>

</template>

<script>

import {ValidationObserver} from 'vee-validate'
import testing from '@mixins/testing'

export default {

    name: 'ListSettings',

    mixins: [testing],

    props: ['list', 'currencies', 'domainsLoading', 'domains'],

    components: {
        ValidationObserver
    },

    data () {
        return {
            sending: false,
            success: null,
            message: '',
            listName: this.list.name,
            fromName: this.list.from_name,
            fromEmail: this.list.from_email,
            replyTo: this.list.reply_to,
            listCurrency: this.getDefaultCurrency(),
            listCountry: this.list.settings ? (this.list.settings.country ? this.list.settings.country : '') : '',
            listLocale: this.list.locale,
            hasChanges: false,
            countryOptions: [],
            localeOptions: [
                {
                    id: 'en',
                    name: this.$t('list_settings.locale_en')
                },
                {
                    id: 'cs',
                    name: this.$t('list_settings.locale_cs')
                },
                {
                    id: 'pl',
                    name: this.$t('list_settings.locale_pl')
                }
            ],
            customCurrency: this.list.currency,

            selectedDomain: null,
        }
    },

    computed: {
        currencyOptions() {
            return[
                {id: 'custom', name: this.$t('list_settings.currency_custom')},
                ...Object.entries(this.currencies).map(([key, value]) => ({id: key, name: value}))
            ]
        },
        validatedDomainsObj() {
            const domainsArrayWithChildren = this.validatedDomains ? this.validatedDomains.reduce((acc, domain) => {
                const domainSection = domain.split('.').length > 2 ? 0 : 1
                acc[domainSection].children.push({domainLabel: domain, domainValue: domain})

                return acc
            }, [{domainLabel: this.$t('list_settings.recommended_domains'), domainValue: 'recommended_domains', children: []}, {domainLabel: this.$t('list_settings.other_domains'), domainValue: 'other_domains' , children: []}]) : []
            if (domainsArrayWithChildren.length && (!domainsArrayWithChildren[0].children.length || !domainsArrayWithChildren[1].children.length)) {
                return domainsArrayWithChildren.flatMap(domain => domain.children)
            }
            return domainsArrayWithChildren
        },
        validatedDomains() {
            if(!this.domains) {
                return []
            }
            let domains = this.domains.filter(Boolean)

            // Puts domains of higher levels first.
            domains.sort((a,b) => a.split('.').length > b.split('.').length ? -1 : 1)

            return domains
        },
        unvalidatedDomains() {
            if(!this.domains) {
                return []
            }
            return this.domains.filter(d => !d)
        },
        invalidDomainError() {
            return this.selectedDomain && !this.validatedDomains.includes(this.selectedDomain)
        },
        senderEmail: {
            get() {
                if (this.validatedDomains.length && this.fromEmail) {
                    const [name, website] = this.fromEmail.split('@')
                    this.selectedDomain = website
                    return name
                }
                return this.fromEmail
            },
            set(val) {
                if (this.validatedDomains.length) {
                    if (val.includes('@')) {
                        return
                    }
                    this.fromEmail = this.selectedDomain ? `${val}@${this.selectedDomain}` : val
                }
                else {
                    this.fromEmail = val
                }
            }
        },
    },

    watch: {
        listName() {
            this.hasChanges = true
        },
        fromName() {
            this.hasChanges = true
        },
        fromEmail() {
            this.hasChanges = true
        },
        replyTo() {
            this.hasChanges = true
        },
        listCurrency() {
            this.hasChanges = true
        },
        customCurrency() {
            this.hasChanges = true
        },
        listCountry() {
            this.hasChanges = true
        },
        listLocale() {
            this.hasChanges = true
        },
        /**
         * This is a way of refershing the senderEmail writable computed,
         * when the selected domain gets updated
         */
        selectedDomain () {
            // eslint-disable-next-line no-self-assign
            this.senderEmail = this.senderEmail
        }
    },

    mounted() {
        this.getCountries()
    },

    methods: {
        async getCountries() {
            try {
                const response = await this.$http.get('/contacts/countries')
                this.countryOptions = response.data.countries
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }
        },

        getDefaultCurrency() {
            if(!this.list.currency) {
                return null
            }
            if(Object.keys(this.currencies).includes(this.list.currency)) {
                return this.list.currency
            }
            return 'custom'
        },

        async saveSettings(evt) {

            evt.preventDefault()
            this.success = null
            this.message = null
            const isValid = await this.$refs.observer.validate()

            if(isValid) {
                this.validateDomain()
                this.sending = true

                try {
                    await this.$http.post(`/spa/contacts/${this.list.id}/settings`, {
                        settings: {
                            currency: this.listCurrency === 'custom' ? this.customCurrency : this.listCurrency,
                            locale: this.listLocale,
                            country: this.listCountry !== '' ? this.listCountry : null,
                        },
                        name: this.listName,
                        from_name: this.fromName,
                        from_email: this.fromEmail,
                        reply_to: this.replyTo !== '' ? this.replyTo : null,
                    })
                    this.success = true
                    this.message = this.$t('common.changes_saved')
                    this.hasChanges = false
                    this.$store.dispatch('contactsModule/setListFromEmail', {fromEmail: this.fromEmail})
                }
                catch(error) {
                    this.success = false
                    this.message = error.message
                }

                this.sending = false
            }
        },

        validateDomain() {
            const domain = this.senderEmail.split('@')[1] ?? this.selectedDomain
            return this.validatedDomains.includes(domain)
        },
    }
}
</script>
