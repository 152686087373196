

<div class="flex flex-col w-3/4">

    <div v-if="conversations.length === 0 || selectedChat == null" class="flex h-full items-center justify-center border border-gray-500">
        <h2>{{ $t('chat.conversations.no_conversations') }}</h2>
    </div>

    <ChatHeader v-else :agent="agent" :status="status" @showSubscriberModal="showSubscriberModal"></ChatHeader>

    <ChatWindow v-if="selectedChat" :agent="agent"></ChatWindow>

</div>

