

<ValidationObserver ref="observer" slim>

    <form novalidate class="flex flex-col" method="post" @submit.prevent="saveSettings">
        <alert v-if="success === true" class="mb-10" type="success" :message="message" />

        <alert v-if="success === false" class="mb-10" type="error" :message="message" />

        <ValidationProvider v-slot="{ errors }" name="listName" rules="required" slim>
            <div class="input flex flex-col mb-1">
                <label for="listName" class="tw-label mb-2.5">{{ $t('list_settings.list_name') }}</label>
                <input id="listName" v-model="listName" name="list_name" type="text" class="tw-input max-w-xs">
                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>

        <div class="h-px mt-5 -mx-9 bg-gray-500" />

        <ValidationProvider v-slot="{ errors }" name="fromName" rules="required" slim>
            <div class="input flex flex-col mt-4">
                <label for="fromName" class="tw-label mb-2.5">{{ $t('list_settings.from_name_label') }}</label>
                <input id="fromName" v-model="fromName" name="from_name" type="text" class="tw-input max-w-xs">
                <span class="mt-1 text-gray-900 txt-12">{{ $t('list_settings.from_name_help') }}</span>
                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>

        <div class="input flex flex-col mt-4">
            <label for="fromEmail" class="tw-label">{{ $t('list_settings.from_email_label') }}</label>
            <div class="input flex flex-col mt-2.5">
                <template v-if="domainsLoading || validatedDomains.length">
                    <ValidationProvider v-slot="{ errors }" name="fromEmail" rules="required|email_name" slim>
                        <div class="flex">
                            <input id="fromEmail" v-model="senderEmail" type="text" :disabled="!validatedDomains.length" class="tw-input !rounded-r-[0px] !border-r-[0px] w-full" :class="{'tw-err': errors.length > 0 }">
                            <ecm-select
                                    v-model="selectedDomain"
                                    selected-prefix="@"
                                    class="w-full domain-select"
                                    :searchable="true"
                                    :options="validatedDomainsObj"
                                    option-key="domainValue"
                                    option-display-attribute="domainLabel"
                                    option-value-attribute="domainValue"
                                    :disabled="domainsLoading"
                                    :title="domainsLoading ? $t('list_settings.domains_loading') : (selectedDomain ? selectedDomain : $t('list_settings.select_domain'))" />
                        </div>
                        <div class="mt-1 text-gray-900 txt-12">
                            {{ $t('list_settings.from_email_help') }}
                        </div>
                        <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        <span v-else-if="invalidDomainError" class="tw-errors" v-html="$t('list_settings.domain_not_validated', {domain: selectedDomain })" />
                    </ValidationProvider>
                </template>
                <div v-else>
                    <input id="fromEmail" type="text" :value="fromEmail" disabled class="w-full tw-input tw-err">
                    <div class="mt-1 text-gray-900 txt-12">
                        {{ $t('list_settings.from_email_help') }}
                    </div>
                    <div v-if="unvalidatedDomains.length" class="tw-errors mt-1" v-html="$t('list_settings.domain_not_valid_link')" />
                    <div v-else class="tw-errors mt-1" v-html="$t('list_settings.domain_not_found_link')" />
                </div>
            </div>
        </div>

        <ValidationProvider v-slot="{ errors }" name="replyTo" rules="email" slim>
            <div class="input flex flex-col mt-4">
                <label for="replyTo" class="tw-label mb-2.5">{{ $t('list_settings.reply_to_label') }}</label>
                <input id="replyTo" v-model="replyTo" name="reply_to" type="text" class="tw-input max-w-xs">
                <span class="mt-1 text-gray-900 txt-12">{{ $t('list_settings.reply_to_help') }}</span>
                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>

        <div class="flex flex-col space-y-4 mt-8">
            <ecm-select v-model="listCountry" :searchable="true" :options="countryOptions" option-key="country_list" :label="$t('list_settings.country_label')" :title="$t('list_settings.country_none')" null-select-enabled class="w-[360px]" />
            <ecm-select v-model="listLocale" :options="localeOptions" option-key="locale_list" :label="$t('list_settings.locale_label')" class="w-[360px]" />
            <div class="flex space-x-4">
                <div>
                    <ecm-select v-model="listCurrency" :options="currencyOptions" option-key="currency_list" :label="$t('list_settings.currency_label')" class="w-[360px]" />
                </div>
                <ValidationProvider v-if="listCurrency === 'custom'" v-slot="{ errors }" name="customCurrency" rules="required" slim>
                    <div class="flex flex-col">
                        <label for="customCurrency" class="tw-label mb-2.5">{{ $t('list_settings.currency_custom_label') }}</label>
                        <input id="customCurrency" v-model="customCurrency" name="custom_currency" type="text" class="tw-input max-w-xs">
                        <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>
        </div>

        <div>
            <button v-if="hasChanges" class="btn btn-lg btn-primary mt-8"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('list_settings.save_changes') }}</button>
        </div>

    </form>

</ValidationObserver>

