import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@stores/index'
import i18n from './i18n'
const { isNavigationFailure } = VueRouter

// Auth components
import login from '@pages/auth/login.vue'
import passwordReset from '@pages/auth/passwordReset.vue'
import setNewPassword from '@pages/auth/setNewPassword.vue'
import join from '@pages/auth/join.vue'
import twoFactorEmail from '@pages/auth/twoFactorEmail.vue'
import twoFactorAuth from '@pages/auth/twoFactorAuthenticator.vue'
import twoFactorRecoveryCodes from '@pages/auth/twoFactorRecoveryCodes.vue'

// Landing page components
import shopifyConnected from '@pages/shopifyConnected.vue'

// Campaign components
import campaigns from '@pages/campaigns/index.vue'

// Contact components
import contactsIndex from '@pages/contacts/index.vue'

import subscribersOverview from '@pages/contacts/subscribersOverview.vue'
import subscribersSegmentsDetail from '@pages/contacts/segments/subscribersSegmentsDetail.vue'
import subscribersSegments from '@pages/contacts/segments/subscribersSegments.vue'
import preferenceGroups from '@pages/contacts/preferenceGroups.vue'
import customFields from '@pages/contacts/customFields.vue'
import subscribeForms from '@pages/contacts/subscribeForms.vue'
import subscribersSettings from '@pages/contacts/subscribersSettings.vue'
import importSubscribers from '@pages/contacts/import/importSubscribers.vue'
import mapSubscriberFields from '@pages/contacts/import/mapSubscriberFields.vue'
import enrichSubscribers from '@pages/contacts/enrichSubscribers.vue'
import facebookLead from '@pages/contacts/facebookLead.vue'
import removeSubscribers from '@pages/contacts/removeSubscribers.vue'
import deleteSubscribers from '@pages/contacts/deleteSubscribers.vue'

// Templates components
import templates from '@pages/templates/index.vue'

// Automations components
import automationsIndex from '@pages/automations/index.vue'
import automationsReport from '@pages/automations/report.vue'
import automationsSettings from '@pages/automations/settings.vue'
import automationsEdit from '@pages/automations/edit.vue'
import automationsActivities from '@pages/automations/activities.vue'
import automationsTesting from '@pages/automations/testing.vue'

// Chat
import chatStart from '@pages/chat/start.vue'
import chatAgents from '@pages/chat/agents.vue'
import chatConversations from '@pages/chat/conversations.vue'
import chatBots from '@pages/chat/chatbots.vue'
import chatBot from '@pages/chat/chatbot.vue'
import chatDashboard from '@pages/chat/dashboard.vue'
import chatSettings from '@pages/chat/settings.vue'

// Search
import searchPage from '@pages/search/searchPage.vue'

// Error pages
import errorPage from '@pages/errors/errorPage.vue'

const ContactsDetailLayout = () => import('@layouts/contactsDetail.vue')
const AutomationDetailLayout = () => import('@layouts/automationDetail.vue')
const ChatLayout = () => import('@layouts/chat.vue')
const AuthLayout = () => import('@layouts/auth.vue')

Vue.use(VueRouter)

const routes = [
    { path: '/auth', component: AuthLayout, meta : { title: i18n.t('meta.auth') },
        children: [
            { path: 'login', name: 'login', component: login },
            { path: 'reset-password', name: 'passwordReset', component: passwordReset },
            { path: 'set-new-password/:token', name: 'setNewPassword', component: setNewPassword },
            { path: 'join', name: 'join', component: join },
            { path: '2fa-email', name: 'twoFactorEmail', component: twoFactorEmail },
            { path: '2fa-auth', name: 'twoFactorAuth', component: twoFactorAuth },
            { path: '2fa-auth-recovery', name: 'twoFactorAuthRecovery', component: twoFactorRecoveryCodes },
        ]
    },
    { path: '/integrations/shopify-landing/connected', component: shopifyConnected, meta : { title: i18n.t('meta.account') }},
    { path: '/campaigns', name: 'campaignsIndex', component: campaigns, meta : { title: i18n.t('meta.campaigns') }},
    { path: '/contacts', name: 'subscribersLists', component: contactsIndex, meta : { title: i18n.t('meta.contacts') }},
    { path: '/contacts/:list(\\d+)', component: ContactsDetailLayout, meta : { title: i18n.t('meta.contacts') },
        children: [
            { path: '', name: 'subscribersOverview', component: subscribersOverview },
            { path: 'segments', name: 'subscribersSegments', component: subscribersSegments },
            { path: 'segments/edit/:segment', name: 'subscribersSegmentsDetail', component: subscribersSegmentsDetail },
            { path: 'groups', name: 'subscribersPreferenceGroups', component: preferenceGroups },
            { path: 'custom-fields', name: 'subscribersCustomFields', component: customFields },
            { path: 'forms-new', name: 'subscribersForms' , component: subscribeForms },
            { path: 'settings', name: 'subscribersSettings', component: subscribersSettings },
            { path: 'import', name: 'subscribersImport', component: importSubscribers },
            { path: 'import/consent', name: 'subscribersImportConsent', component: importSubscribers },
            { path: 'import-csv/:file', name: 'subscribersImportMap', component: mapSubscriberFields },
            { path: 'enrich-subscribers', name: 'subscribersEnrich', component: enrichSubscribers },
            { path: 'facebook-lead', name: 'subscribersFacebook', component: facebookLead },
            { path: 'remove-subscribers', name: 'subscribersRemove', component: removeSubscribers },
            { path: 'delete-subscribers', name: 'subscribersDelete', component: deleteSubscribers },
        ]
    },
    { path: '/templates', name: 'templatesIndex', component: templates, meta : { title: i18n.t('meta.templates') }},
    { path: '/automation', name: 'automationsIndex', component: automationsIndex, meta : { title: i18n.t('meta.automations') }},
    { path: '/automation/:automation(\\d+)', component: AutomationDetailLayout, meta : { title: i18n.t('meta.automations') },
        children: [
            { path: 'report', name: 'automationsReport', component: automationsReport},
            { path: '', name: 'automationsSettings', component: automationsSettings},
            { path: 'activities/:activity', name: 'automationsActivities', component: automationsActivities},
            { path: 'testing', name: 'automationsTesting', component: automationsTesting},
        ]
    },
    { path: '/automation/:automation(\\d+)/edit', name: 'automationsEdit', component: automationsEdit, meta : { title: i18n.t('meta.automations') }},
    { path: '/chat/start', name: 'chatStart', component: chatStart, meta : { title: i18n.t('meta.chat') } },
    { path: '/chat', component: ChatLayout, meta : { title: i18n.t('meta.chat') },
        children: [
            { path: '', name: 'chatDashboard', component: chatDashboard },
            { path: 'conversations', name: 'chatConversations', component: chatConversations },
            { path: 'chatbots', name: 'chatBots', component: chatBots },
            { path: 'agents', name: 'chatAgents', component: chatAgents },
            { path: 'settings', name: 'chatSettings', component: chatSettings },
            { path: 'chatbots/:bot_id', name: 'chatBot', component: chatBot },
        ]
    },
    { path: '/search', name: 'searchPage', component: searchPage, meta : { title: i18n.t('meta.search') }},
    { path: '/login', redirect: '/auth/login', meta : { title: i18n.t('meta.auth') } },
    { path: '/password/reset', redirect: '/auth/reset-password', meta : { title: i18n.t('meta.reset_password') } },
    { path: '/public/join', redirect: '/auth/join', meta : { title: i18n.t('meta.join') } },
    { path: '*', name: 'errorPage', component: errorPage },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

// reset vuex module when switching between sections
router.beforeEach((to, from, next) => {
    const fromSection = from.path.split('/')[1]
    const toSection = to.path.split('/')[1]
    if (fromSection !== toSection) {
        store.dispatch('resetModule', toSection)
    }
    next()
})

// set meta title
router.afterEach((to) => {
    if(to.matched && to.matched[0] && to.matched[0].meta.title) {
        const isImpersonating = window.isImpersonating
        const accountNameAbbreviated = window.location.hostname.split('.')[0].slice(0,5)
        const section = to.matched[0].meta.title
        if(isImpersonating) {
            document.title = `${accountNameAbbreviated} | ${section} | Ecomail`
        }
        else {
            document.title = `${section} | Ecomail`
        }
    }
})

// ignore duplicate navigation errors
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(error =>  {
        if(!isNavigationFailure(error))
            throw error
    })
}

export default router
