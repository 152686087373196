
<div>
    <portal v-if="activated" to="contacts-section-header">
        <div class="flex flex-grow items-center justify-between">
            <div class="flex items-center space-x-5">
                <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                    {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                </router-link>
                <div class="hidden lg:block">
                    <icon key="breadcrumb-icon-1" class="text-gray-700" icon="long-arrow-alt-right-solid" />
                </div>
                <router-link :to="`/contacts/${list.id}/import`" class="hidden lg:block txt-18 text-gray-800 hover:text-gray-1000 hover:no-underline">
                    {{ $t('contacts.import_subscribers.title') }}
                </router-link>
                <div class="hidden lg:block">
                    <icon key="breadcrumb-icon-2" class="text-gray-700" icon="long-arrow-alt-right-solid" />
                </div>
                <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.import_subscribers.subtitle') }}</span>
            </div>
            <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                <icon class="mr-1" icon="caret-left-solid" />
                <span class="txt-12">{{ $t('common.back') }}</span>
            </router-link>
        </div>
    </portal>

    <div class="pt-2">
        <div v-if="importError">
            <alert type="error" :message="importError" />

            <div class="tw-modal-buttons mt-7 mb-4">
                <button class="btn btn-tertiary tw-modal-close-button btn-lg" @click="goBack">{{ $t('common.cancel') }}</button>
            </div>
        </div>
        <contacts-mapper
                v-else
                :list="list"
                :mapped-fields="importData.baseFieldIndexes"
                :file="importData.file_url"
                :rowdata="importData.rows"
                :customfields="list.custom_fields"
                :base-tags="importData.baseFieldsTags" />
    </div>
</div>
