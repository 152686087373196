

<div class="flex flex-col mt-1">
    <p class="text-gray-900 txt-14" v-html="$t('contacts.index.delete_list_warning.description', {list: list})" />

    <div class="delete-list-frame space-y-2.5">
        <div v-if="pipelines">
            <h5 class="mb-2 text-gray-1000">
                {{ $t('contacts.index.delete_list_warning.pipelines') }}
            </h5>
            <ul class="list-inside list-disc space-y-2">
                <li v-for="(pipeline, index) in pipelines" :key="`pipeline-${index}`" class="text-gray-900 font-semibold txt-14">
                    <span class="ml-3">{{ pipeline.name }}</span>
                </li>
            </ul>
        </div>
        <div v-if="campaigns">
            <h5 class="mb-2 text-gray-1000">
                {{ $t('contacts.index.delete_list_warning.campaigns') }}
            </h5>
            <ul class="list-inside list-disc space-y-2">
                <li v-for="(campaign, index) in campaigns" :key="`campaign-${index}`" class="text-gray-900 font-semibold txt-14">
                    <span class="ml-2">{{ campaign.name }}</span>
                </li>
            </ul>
        </div>
        <div v-if="integrations">
            <h5 class="mb-2 text-gray-1000">
                {{ $t('contacts.index.delete_list_warning.integrations') }}
            </h5>
            <ul class="list-inside list-disc space-y-2">
                <li v-for="(integration, index) in integrations" :key="`integration-${index}`" class="text-gray-900 font-semibold txt-14">
                    <span class="ml-2">{{ integration }}</span>
                </li>
            </ul>
        </div>
    </div>

    <div class="text-right">
        <button class="btn btn-primary tw-modal-close-button btn-lg px-10" @click.stop.prevent="closeModal">{{ $t('common.cancel') }}</button>
    </div>
</div>

