<template>
    <div>
        <portal v-if="activated" to="contacts-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="flex items-center space-x-5">
                    <router-link :to="{name: 'subscribersOverview', params: {list: list.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                        {{ $t('contacts.general.contacts_list') }} - {{ list.name }}
                    </router-link>
                    <div class="hidden lg:block">
                        <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                    </div>
                    <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('contacts.custom_fields.title') }}</span>
                </div>
                <router-link :to="{name: 'subscribersLists'}" class="hidden lg:flex items-center">
                    <icon class="mr-1" icon="caret-left-solid" />
                    <span class="txt-12">{{ $t('common.back') }}</span>
                </router-link>
            </div>
        </portal>

        <div class="pt-2 flex flex-col">

            <div v-if="!customFieldsLoaded" class="w-4 h-4 mt-4 double-loader" />

            <div v-else-if="list.custom_fields && Object.keys(list.custom_fields).length" class="pt-2 space-y-7">
                <div class="flex flex-col md:flex-row justify-end">
                    <button class="btn btn-primary icon-left" @click.stop.prevent="showNewFieldModal()"><icon icon="plus-solid" size="small" /> <span>{{ $t('contacts.custom_fields.new_field_button') }}</span></button>
                </div>

                <div class="w-full overflow-x-auto md:overflow-x-visible">
                    <div class="table tw-table tw-table-large max-w-full">
                        <div class="table-header-group">
                            <div class="table-row">
                                <div class="table-cell">
                                    <span class="txt-13 text-gray-1000 semibold">{{ $t('contacts.custom_fields.name') }}</span>
                                </div>
                                <div class="table-cell">
                                    <span class="txt-13 text-gray-800 semibold">{{ $t('contacts.custom_fields.type') }}</span>
                                </div>
                                <div class="table-cell text-center">
                                    <span class="txt-13 text-gray-800 semibold">{{ $t('contacts.custom_fields.merge') }}</span>
                                </div>
                                <div class="table-cell" />
                            </div>
                        </div>
                        <div v-for="(data, name, index) in list.custom_fields" :key="`custom-field-${index}`" class="table-row ">
                            <div class="table-cell align-middle truncate w-full" style="max-width: 0px; min-width: 150px;">
                                <span class="txt-14 text-gray-1000 semibold">{{ data.name }}</span>
                            </div>
                            <div class="table-cell align-middle" style="min-width: 100px">
                                <span class="txt-14 text-gray-1000">{{ data.type }}</span>
                            </div>
                            <div class="table-cell align-middle text-center" style="min-width: 400px">
                                <span class="highlight-blue text-sail-500 semibold px-2.5 py-1.5 txt-13">*|{{ name }}|*</span>
                            </div>
                            <div class="table-cell align-middle text-right">
                                <div class="flex items-center space-x-2.5 justify-end">
                                    <button class="btn btn-tertiary icon-only" @click="deleteField(name)"><icon class="text-gray-900" icon="trash-solid" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="flex items-center space-x-10 mt-7 px-0 md:px-20">
                <div class="flex flex-col">
                    <h2>{{ $t('contacts.custom_fields.heading') }}</h2>
                    <span class="txt-14 text-gray-900 mt-2.5" v-html="$t('contacts.custom_fields.description')" />
                    <button class="btn btn-primary btn-lg icon-left mt-6 w-min" @click="showNewFieldModal()"><icon icon="plus-solid" size="small" /><span>{{ $t('contacts.custom_fields.create_field_button') }}</span></button>
                </div>
                <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-lists.png" style="width: 304.76px" alt="list-ilustration" class="hidden md:block">
            </div>
        </div>

        <tw-modal ref="newCustomFieldModal" :title="$t('appforms.contacts.field.title')" width="472" close-button="yes">

            <template #default="slotProps">
                <custom-field-form :modal="slotProps" :list-id="list.id" @refreshCustomFields="refreshCustomFields" />
            </template>

        </tw-modal>

        <tw-confirm ref="deleteFieldConfirm" @confirmDelete="deleteFieldConfirmed" />
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'
import Tracker from "@js/tracker";


export default {
    name: 'CustomFields',

    store,

    props: ['list'],

    data() {
        return {
            activated: true
        }
    },

    computed: {
        ...mapState('contactsModule', [
            'customFieldsLoaded', 'fieldsActiveCampaigns', 'fieldsActivePipelines'
        ])
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('contactsModule/loadCustomFields', {listId: to.params.list})
        })
    },

    methods: {
        showNewFieldModal() {
            this.$refs['newCustomFieldModal'].showModal()
            new Tracker().trackEvent('BUTTON_CLICKED', {
                scope: 'contacts',
                name: 'Create custom field'
            })
        },
        deleteField(field) {
            let confirmText = this.$t('contacts.custom_fields.delete_field.text', {field: field})
            const activeCampaigns = this.fieldsActiveCampaigns[field] ?? null
            const activePipelines = this.fieldsActivePipelines[field] ?? null

            if(activeCampaigns && activePipelines)
                confirmText += this.$t('contacts.custom_fields.delete_field.active_both', {campaigns: activeCampaigns, pipelines: activePipelines})
            else if(activeCampaigns)
                confirmText += this.$t('contacts.custom_fields.delete_field.active_campaigns', {campaigns: activeCampaigns})
            else if(activePipelines)
                confirmText += this.$t('contacts.custom_fields.delete_field.active_pipelines', {campaigns: activePipelines})

            this.$refs['deleteFieldConfirm'].setData({
                twConfirmTitle: this.$t('contacts.custom_fields.delete_field.title'),
                twConfirmText: confirmText,
                data: field,
                twClose: this.$t('common.close'),
                twDelete: this.$t('common.delete_permanently'),
            })
            this.$refs['deleteFieldConfirm'].showModal()
        },
        async deleteFieldConfirmed(field) {  
            try {
                await this.$http.delete(`/contacts/${this.list.id}/custom-fields/delete`, {
                    data: {
                        custom_field: field
                    }
                })
                this.$message.success(this.$t('contacts.custom_fields.field_deleted'))
                this.refreshCustomFields()
            }
            catch(error) {
                this.$message.error(error.message)
            }
        },
        refreshCustomFields() {
            store.dispatch('contactsModule/loadCustomFields', {listId: this.list.id})
        }
    }
}
</script>
